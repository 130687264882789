import {ApiResponse, GetTodosResponse} from "./types";
import {ApiWrapper} from "./ApiWrapper";
import {TodoFilter} from "../components/admin/presenters/admin-home-tabs/dashboard-tab/DashboardTab";
import {TodoStatus} from "../types/types";

export const adminGetTodos = async (filter: TodoFilter, page: number, pageSize: number): Promise<ApiResponse<GetTodosResponse>> => {
    let path = `/admin/todos?page=${page}&page_size=${pageSize}`;

    for (const assigneeID of filter.assigneeIDs) {
        path += `&assignee=${assigneeID}`;
    }

    path += `&kind=todo`;

    for (const status of filter.statuses) {
        path += `&status=${status}`;
    }

    if (filter.companyName) {
        path += `&company_name=${filter.companyName}`;
    }

    if (filter.dueFrom) {
        path += `&due_from=${filter.dueFrom}`;
    }

    if (filter.dueTo) {
        path += `&due_to=${filter.dueTo}`;
    }

    return ApiWrapper.get(path);
};

export const adminUpdateTodoStatus = async (todoId: string, newStatus: TodoStatus): Promise<ApiResponse<void>> => {
    const path = `/admin/todos/${todoId}/status`;
    const body = {
        status: newStatus,
    };

    return ApiWrapper.put(path, body);
};

export const adminDeleteTodo = async (todoId: string): Promise<ApiResponse<void>> => {
    const path = `/admin/todos/${todoId}`;

    return ApiWrapper.del(path, {});
};

export const adminCreateTodo = async (assigneeId: string, kind: string, entityKind: string, entityId: string, details: string, dueAt: string, sendEmail: boolean): Promise<ApiResponse<void>> => {
    const path = `/admin/todos`;
    const body = {
        entity_id: entityId,
        entity_kind: entityKind,
        assignee_id: assigneeId,
        kind: kind,
        details: details,
        due_at: dueAt,
        send_email: sendEmail,
    };

    return ApiWrapper.post(path, body);
};

export const adminUpdateTodo = async (todoId: string, assigneeId: string, details: string, dueAt: string, sendEmail: boolean): Promise<ApiResponse<void>> => {
    const path = `/admin/todos/${todoId}`;
    const body = {
        assignee_id: assigneeId,
        details: details,
        due_at: dueAt,
        send_email: sendEmail,
    };

    return ApiWrapper.put(path, body);
};
