import {Button, Input, message, Modal, Popconfirm, Select, Space, Table, Typography} from 'antd';
import {
    adminDeleteApplicationNote,
    adminGetApplicationNotes,
    adminPostApplicationNote,
    adminPutApplicationNote
} from 'api/applicationService';
import styles from 'components/admin/pages/application-drilldown/ApplicationDrilldownPage.module.scss';
import {Spacer} from 'components/common/presenters/spacer/Spacer';
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {
    adminDeleteClientNote,
    adminGetClientNotes,
    adminPostClientNote,
    adminPutClientNote
} from "../../../../../api/clientService";
import {SectionTitle} from "../../../../common/presenters/section-title/SectionTitle";
import {NoteCategory} from "../../../../../types/types";
import {GetNotesListResponse} from "../../../../../api/types";
import moment from "moment";
import {DATE_FORMAT} from "../../../../../api/apiConfig";

const {TextArea} = Input;

export enum NotesTabType {
    Client,
    Application
}

export interface NotesTabProps {
    entityId: string
    type: NotesTabType
}

export const NotesTab = (props: NotesTabProps) => {
    const {t} = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);
    const [notes, setNotes] = useState<GetNotesListResponse[]>([]);
    const [loading, setLoading] = useState(false);

    const [editingNoteId, setEditingNoteId] = useState("");
    const [editingNoteCategory, setEditingNoteCategory] = useState<NoteCategory>(NoteCategory.Other);
    const [editingNoteText, setEditingNoteText] = useState("");

    const fetchNotes = async () => {
        try {
            setLoading(true);
            let result;

            switch (props.type) {
            case NotesTabType.Client:
                result = await adminGetClientNotes(props.entityId);
                break;
            case NotesTabType.Application:
                result = await adminGetApplicationNotes(props.entityId);
                break;
            default:
                break;
            }

            setNotes(result.data || []);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handleAddNote = async () => {
        try {
            switch (props.type) {
            case NotesTabType.Client:
                await adminPostClientNote(props.entityId, editingNoteText, editingNoteCategory);
                break;
            case NotesTabType.Application:
                await adminPostApplicationNote(props.entityId, editingNoteText, editingNoteCategory);
                break;
            default:
                break;
            }
            setModalOpen(false);
            setEditingNoteText("");
            setEditingNoteCategory(NoteCategory.Other);
            message.success(t('messages:dataSaved'), 2);
            fetchNotes();
        } catch (e) {
            console.error(e);
            message.error(t('messages:couldNotSave'), 2);
        }
    };

    const handleStartEditingNote = (noteData: GetNotesListResponse) => {
        setEditingNoteId(noteData.note_id);
        setEditingNoteText(noteData.note_text);
        setEditingNoteCategory(noteData.category);

        setModalOpen(true);
    };

    const handleUpdateNote = async () => {
        try {
            switch (props.type) {
            case NotesTabType.Client:
                await adminPutClientNote(props.entityId, editingNoteId, editingNoteText, editingNoteCategory);

                message.success(t('messages:dataSaved'), 2);
                break;
            case NotesTabType.Application:
                await adminPutApplicationNote(props.entityId, editingNoteId, editingNoteText, editingNoteCategory);

                message.success(t('messages:dataSaved'), 2);
                break;
            default:
                return;
            }

            setEditingNoteId("");
            setEditingNoteText("");
            setEditingNoteCategory(NoteCategory.Other);

            setModalOpen(false);
            fetchNotes();
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }
    };

    const handleDeleteNote = async (noteId: string) => {
        try {
            switch (props.type) {
            case NotesTabType.Client:
                await adminDeleteClientNote(props.entityId, noteId);
                message.success(t('messages:success'), 2);
                break;
            case NotesTabType.Application:
                await adminDeleteApplicationNote(props.entityId, noteId);
                message.success(t('messages:success'), 2);
                break;
            default:
                return;
            }

            fetchNotes();
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }
    };

    const renderRowButtons = (noteData: GetNotesListResponse) => {
        return (
            <Space align={"center"} wrap>
                <p className={styles.blueTextPointer}
                    onClick={() => handleStartEditingNote(noteData)}
                >
                    {t(`buttons:view`)}
                </p>

                <Typography.Text className={styles.blueText} strong>{" | "}</Typography.Text>

                <Popconfirm title={t('confirmDeleteEntry')}
                    onConfirm={() => handleDeleteNote(noteData.note_id)}
                    okText={t('yes')}
                    cancelText={t('no')}
                >
                    <p className={styles.blueTextPointer}>
                        {t(`buttons:delete`)}
                    </p>
                </Popconfirm>
            </Space>
        );
    };

    const columns = [
        {
            title: t('created'),
            dataIndex: 'created_at',
            render: (value) => value ? moment(value).format(DATE_FORMAT.DE_HH_MM) : "-",
        },
        {
            title: t('author'),
            dataIndex: 'author_name',
        },
        {
            title: t('noteCategory'),
            dataIndex: 'category',
            render: (value) => value ? t(`noteCategories:${value}`) : "-",
        },
        {
            title: t('notes'),
            dataIndex: 'note_text',
        },
        {
            dataIndex: 'note_id',
            render: (_, record) => renderRowButtons(record),
        },
    ];

    useEffect(() => {
        fetchNotes();
    }, [props.entityId]);

    return (
        <div className={styles.tabXL}>
            <SectionTitle text={"Notes"}/>
            <div className={styles.container}>
                <Button className={styles.button}
                    data-cy="button_add_note"
                    onClick={() => {
                        setModalOpen(true);
                    }}>
                    {t('buttons:newNote')}
                </Button>
                <Modal title={t('modals:addNote')}
                    visible={modalOpen}
                    destroyOnClose={true}
                    footer={[
                        <div key="fr" className={styles.footerRow}>
                            <Button className={styles.buttonCancel} key="cancel"
                                onClick={() => {
                                    setModalOpen(false);
                                }}>
                                {t("buttons:cancel")}
                            </Button>
                            <Button className={styles.buttonOk} key="submit" onClick={() => {
                                if (editingNoteId) {
                                    handleUpdateNote();
                                } else {
                                    handleAddNote();
                                }
                            }}>
                                {t("buttons:submit")}
                            </Button>
                        </div>
                    ]}>
                    <Typography.Text strong>{t('noteCategory')}</Typography.Text>
                    <Select value={editingNoteCategory}
                        onChange={(e) => setEditingNoteCategory(e)}
                    >
                        {
                            Object.keys(NoteCategory).map((k, i) => {
                                const enumValue = NoteCategory[k];
                                return <Select.Option data-cy={`select_note_category_${i}`} key={enumValue}
                                    value={enumValue}>{t(`noteCategories:${enumValue}`)}</Select.Option>;
                            })
                        }
                    </Select>
                    <Spacer/>

                    <Typography.Text strong>{t('notes')}</Typography.Text>
                    <TextArea maxLength={1000}
                        autoSize={{minRows: 4, maxRows: 8}}
                        value={editingNoteText}
                        onChange={(e) => {
                            setEditingNoteText(e.target.value);
                        }}
                    />
                    <Spacer/>
                </Modal>
                <Spacer/>
                <Spacer/>
                <Table style={{whiteSpace: 'pre'}} rowKey={record => record.note_id}
                    columns={columns}
                    dataSource={notes}
                    pagination={{position: ["bottomCenter"]}}
                    loading={loading}
                />
            </div>
        </div>
    );
};
