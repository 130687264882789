import {getApplicationById, putApplicationBankInfo, putApplicationStatus} from "api/applicationService";
import {getAllContactPersonsForClient} from "api/clientContactPersonService";
import {cpGetApplicationDocuments} from "api/document-service/contactPersonOnApplicationDocuments";
import {
    CPGetApplicationResponse,
    GetDocumentsListResponse,
    PutApplicationBankInfoReq,
    PutApplicationStatusReq
} from "api/types";
import {UserBankInfoSection} from "components/common/pages/user-application-drilldown/UserBankInfoSection";
import {ContactPersonsEditor} from "components/common/presenters/contact-persons-editor/ContactPersonsEditor";
import {DocumentSectionActor, DocumentsSection} from "components/common/presenters/documents-section/DocumentsSection";
import {PaidOutTab} from "components/common/presenters/paid-out-tab/PaidOutTab";
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {ApplicationStates, DocumentType} from "types/types";
import {SubmitTab} from "../../presenters/submit-tab/SubmitTab";
import {TopInfoSection, TopInfoSectionType} from "../../presenters/top-info-section/TopInfoSection";
import styles from './UserApplicationDrilldownPage.module.scss';
import {Button, Card, message, Result} from "antd";
import {CheckCircleTwoTone} from "@ant-design/icons";
import {isIbanValid} from "validators/iban-validators";
import {NextStepsSection} from "./NextStepsSection";
import {InReviewSection} from "./InReviewSection";

export const UserApplicationDrilldownPage = () => {
    const {t} = useTranslation();
    const {applicationId} = useParams();
    const [applicationState, setApplicationState] = useState(null);
    const [appl, setAppl] = useState({} as CPGetApplicationResponse);
    const [cpList, setCpList] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [bankInfoError, setBankInfoError] = useState("");
    const [applicationDocs, setApplicationDocs] = useState([] as GetDocumentsListResponse[]);

    const getDataAndSetState = async (): Promise<any> => {
        const applReq = await getApplicationById(applicationId);

        const appl = applReq.data;

        setAppl(appl);

        await setApplicationState(appl.status);

        const cpListReq = await getAllContactPersonsForClient(appl.client_id);

        await setCpList(cpListReq.data);
    };

    const fetchApplicationDocuments = async () => {
        try {
            const result = await cpGetApplicationDocuments(applicationId);
            setApplicationDocs(result.data);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchApplicationDocuments();
    }, [applicationId]);

    useEffect(() => {
        try {
            getDataAndSetState();
        } catch (error) {
            console.error(error);
        }
    }, []);

    const handleBankInfoSave = async (bank_name: string, iban: string) => {
        setBankInfoError("");

        if (bank_name && bank_name.length <= 3) {
            setBankInfoError(t("errors:bankInfoInvalidBankName"));
            return;
        }
        if (!isIbanValid(iban)) {
            setBankInfoError(t("errors:bankInfoInvalidIBAN"));
            return;
        }

        await setLoading(true);

        const data: PutApplicationBankInfoReq = {
            client_id: appl.client_id,
            bank_name,
            iban
        };

        try {
            await putApplicationBankInfo(appl.id, data);
            await getDataAndSetState();
            message.success(t('messages:dataSaved'), 2);
        } catch (error) {
            console.error(error);
            setBankInfoError(t("errors:bankInfo"));
        }

        setLoading(false);
    };

    const handleSubmitApplication = async () => {
        await setLoading(true);

        const data: PutApplicationStatusReq = {
            client_id: appl.client_id,
            status: ApplicationStates.In_Review_Risk
        };

        try {
            await putApplicationStatus(appl.id, data);

            await getDataAndSetState();
        } catch (error) {
            console.error(error);
            setError(t("errors:submitApplication"));
        }

        setLoading(false);
    };

    return (
        <div className={styles.page}>
            <SideMenu isLoggedIn={true}/>

            <div className={styles.sectionRight}>
                <TopInfoSection type={TopInfoSectionType.TypeApplication}
                    applicationData={appl}
                    isContactPersonUI={true}
                />

                <Spacer/>
                <Spacer/>
                <Spacer/>

                {
                    applicationState === ApplicationStates.Open ?
                        <>
                            <NextStepsSection application={appl}
                                onDataChanged={getDataAndSetState}
                            />

                            <ContactPersonsEditor isAdmin={false} clientId={appl.client_id} contactPersons={cpList}
                                onDataChanged={() => getDataAndSetState()}/>

                            <UserBankInfoSection bank_name={appl.bank_name} iban={appl.iban}
                                error={bankInfoError} onSave={handleBankInfoSave}/>

                            <DocumentsSection actor={DocumentSectionActor.ContactPerson}
                                entityType={DocumentType.Application}
                                entityId={applicationId}
                                documents={applicationDocs}
                                showDocList={true}
                                companyCountry={appl.company_country}
                                onDataChanged={() => fetchApplicationDocuments()}
                            />

                            <SubmitTab title={t("userApplPage:submitForReviewTitle")} error={error}
                                loading={loading} handleContinue={handleSubmitApplication}/>
                        </>
                        :
                        null
                }

                {
                    [
                        ApplicationStates.In_Review_Risk,
                        ApplicationStates.Signature_KYC,
                        ApplicationStates.Final_Review_Operations,
                    ].includes(applicationState) ?
                        <>
                            <InReviewSection/>
                            <DocumentsSection actor={DocumentSectionActor.ContactPerson}
                                entityType={DocumentType.Application}
                                entityId={applicationId}
                                documents={applicationDocs}
                                showDocList={true}
                                companyCountry={appl.company_country}
                                onDataChanged={() => fetchApplicationDocuments()}
                            />
                        </>
                        : null
                }

                {
                    applicationState === ApplicationStates.Approved_Awaiting_Payout ?
                        <Card>
                            <Result icon={<CheckCircleTwoTone twoToneColor={"56CCF2"}/>}
                                title={t('resultApplicationApproved')}
                                subTitle={[
                                    <p>{t('resultApplicationApprovedDetails1')}</p>,
                                    <p>{t('resultApplicationApprovedDetails2')}</p>
                                ]}
                                extra={[
                                    <Button data-cy={"button_to_svea_console"}
                                        type="primary"
                                    >
                                        <a href="https://s5.svea.com" target="_blank" rel="noopener noreferrer" className={styles.link}>{t('buttons:toSveaClientPortal')}</a>
                                    </Button>
                                ]}
                            />
                        </Card>
                        :
                        null
                }

                {
                    applicationState === ApplicationStates.Paid_Out ?
                        <PaidOutTab/>
                        :
                        null
                }

                {
                    applicationState === ApplicationStates.Rejected ?
                        <Card>
                            <Result icon={<></>}
                                title={t('resultApplicationRejected')}
                                subTitle={[
                                    <p>{t('resultApplicationRejectedDetails1')}</p>,
                                    <p>{t('resultApplicationRejectedDetails2')}</p>,
                                    <p className={styles.nextStepsText}>{t('resultApplicationRejectedDetails3')}</p>,
                                ]}
                            />
                        </Card>
                        :
                        null
                }

                <Spacer/>
                <Spacer/>
                <Spacer/>
            </div>
        </div>
    );
};
