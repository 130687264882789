import {message} from "antd";
import {adminGetClientScore, adminPostClientScore, adminPutClientScore} from "api/clientContactPersonService";
import styles from 'components/admin/pages/client-drilldown/ClientDrilldownPage.module.scss';
import {CompanyDetailsTab} from "components/admin/pages/client-drilldown/company-details-tab/CompanyDetailsTab";
import {NotesTab, NotesTabType} from "components/admin/presenters/applications-drilldown-tabs/notes-tab/NotesTab";
import {ScoringTab} from "components/admin/presenters/applications-drilldown-tabs/scoring-tab/ScoringTab";
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import {TabMenu} from "components/common/presenters/tab-menu/TabMenu";
import {UserDocsTab} from "components/common/presenters/user-drilldown-tabs/user-docs-tab/UserDocsTab";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {deleteObjNullProps, parseToFloatObjNumProps} from "storage/genericHelpers";
import {adminGetClient, adminGetLatestClientArchiveURL} from "../../../../api/clientService";
import {ClientScore, GetClientResponse, ScoreChecklistItem} from "../../../../api/types";
import {ClientType} from "../../../../types/types";
import {ContactPersonsEditor} from "../../../common/presenters/contact-persons-editor/ContactPersonsEditor";
import {TopInfoSection, TopInfoSectionType} from "../../../common/presenters/top-info-section/TopInfoSection";
import {LeadDetailsTab} from "./lead-unified-tab/LeadDetailsTab";
import {DrilldownActionsMenu} from "../../presenters/drilldown-actions-menu/DrilldownActionsMenu";
import {ClientContractsTab} from "./contracts-tab/ClientContractsTab";
import {ClientLogsTab} from "./logs-tab/ClientLogsTab";
import {clearURLQueryString, openUrlInNewTab} from "../../../../helpers/uiHelpers";
import {useQuery} from "../../../../hooks/QueryParams";

export const ClientDrilldownPage = () => {
    const {t} = useTranslation();
    const queryParams = useQuery();

    let {clientId} = useParams();

    const [clientData, setClientData] = useState({} as GetClientResponse);
    const [clientScore, setClientScore] = useState({} as ClientScore);
    const [loading, setLoading] = useState(false);
    const [, setError] = useState("");

    const getDataAndSetState = async (): Promise<any> => {
        // separate try-catch block for each req group, because otherwise if 1 fails, the rest don't get executed
        try {
            const result = await adminGetClient(clientId);
            setClientData(result.data);
        } catch (error) {
            console.error(error);
        }
        try {
            const clientScoreReq = await adminGetClientScore(clientId);
            setClientScore(clientScoreReq.data);

            // console.log(JSON.stringify(clientScoreReq.data, null, 4));
    
        } catch (error) {
            console.error(error);
        }
    };


    const handleKPIsSave = async (values: any, checklistItems: ScoreChecklistItem[]) => {
        await setError("");
        await setLoading(true);

        let score = {
            ...clientScore,
            ...values,
            checklist: {
                ...clientScore.checklist,
                items: [
                    ...checklistItems
                ]		
            }
        };

        
        score = deleteObjNullProps(score);
        score = parseToFloatObjNumProps(score);

        // console.log(JSON.stringify(score, null, 4));

        try {
            if (clientScore == null || clientScore.score_id == null || clientScore.score_id == "") {
                const postKpisReq = await adminPostClientScore(clientId, score);

                if (postKpisReq && postKpisReq.status >= 300) {
                    setLoading(false);
                    setError(t("errors:postClientScore"));
                } else {
                    
                    // a short timeout, so we can show the loader for some time
                    setTimeout(() => {
                        setLoading(false);
                        message.success(t('messages:dataSaved'), 2);
                        getDataAndSetState();
                    }, 250);
                }
            } else {
                const putKpisReq = await adminPutClientScore(clientId, score);

                if (putKpisReq && putKpisReq.status >= 300) {
                    setLoading(false);
                    setError(t("errors:putClientScore"));
                } else {
                    // a short timeout, so we can show the loader for some time
                    setTimeout(() => {
                        setLoading(false);
                        message.success(t('messages:dataSaved'), 2);
                        getDataAndSetState();
                    }, 250);
                }
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
            message.error(t('messages:errorEncountered'), 2);
            setError("Error updating KPIs");
        }
    };

    const handleDeepLinkForArchive = async () => {
        const isDownloadArchive: string = queryParams.get("download_archive");
        if (isDownloadArchive === "true") {
            try {
                const zipFileURL: string = (await adminGetLatestClientArchiveURL(clientId)).data;

                openUrlInNewTab(zipFileURL);
                clearURLQueryString();
            } catch (e) {
                console.error(e);
                message.error(t('messages:errorEncountered'), 2);
            }
        }
    };

    useEffect(() => {
        getDataAndSetState();
        handleDeepLinkForArchive();
    }, [clientId]);

    let tabsTitles = [];
    let tabsContent = [];

    switch (clientData.client_type) {
    case ClientType.Client:
        tabsTitles = [
            t("admin:tabs:companyInfo"),
            t("admin:tabs:contactPersons"),
            t("admin:tabs:scoring"),
            t("admin:tabs:docs"),
            t("admin:tabs:notes"),
            t("admin:tabs:logs"),
        ];

        tabsContent = [
            <CompanyDetailsTab clientData={clientData} onDataChanged={() => getDataAndSetState()}/>,
            <ContactPersonsEditor isAdmin={true} clientId={clientId} contactPersons={clientData.contact_persons || []}
                onDataChanged={getDataAndSetState}/>,
            <ScoringTab key="st" loading={loading} error={""} isClientScore={true}
                score={clientScore}
                onKPIsSave={handleKPIsSave}/>,
            <UserDocsTab clientId={clientId}/>,
            <NotesTab entityId={clientId} type={NotesTabType.Client}/>,
            <ClientLogsTab client={clientData}/>,
        ];
        break;
    case ClientType.Lead:
        tabsTitles = [
            t("admin:tabs:companyInfo"),
            t("admin:tabs:contactPersons"),
            t("admin:tabs:docs"),
            t("admin:tabs:contracts"),
            t("admin:tabs:notes"),
            t("admin:tabs:logs"),
        ];

        tabsContent = [
            <LeadDetailsTab clientData={clientData} onDataChanged={() => getDataAndSetState()}/>,
            <ContactPersonsEditor isAdmin={true} clientId={clientId} contactPersons={clientData.contact_persons || []}
                onDataChanged={getDataAndSetState}/>,
            <UserDocsTab clientId={clientId}/>,
            <ClientContractsTab client={clientData}/>,
            <NotesTab entityId={clientId} type={NotesTabType.Client}/>,
            <ClientLogsTab client={clientData}/>,
        ];
        break;
    }

    return (
        <div className={styles.page}>
            <SideMenu isLoggedIn={true}/>

            <div className={styles.sectionRight}>
                <div className={styles.header}>
                    <TopInfoSection type={TopInfoSectionType.TypeClient}
                        clientData={clientData}
                    />

                    <DrilldownActionsMenu client={clientData} onDataChanged={getDataAndSetState}/>
                </div>
                <Spacer hx={3}/>

                <TabMenu titles={tabsTitles} content={tabsContent}/>
            </div>
        </div>
    );
};
