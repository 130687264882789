import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {GetClientResponse, PostGenerateOfferForClientRequest} from "../../../../../../api/types";
import {GenerateOfferForm} from "../../../../presenters/generate-offer-form/GenerateOfferForm";
import {Spacer} from "../../../../../common/presenters/spacer/Spacer";

interface NextStepsQuestionnaireReturnedProps {
    client: GetClientResponse
    onDataChanged: () => void
}

export const NextStepsQuestionnaireReturned = (props: NextStepsQuestionnaireReturnedProps) => {
    const {t} = useTranslation();
    const [data, setData] = useState({
        contact_person_id: "",
        interest_rate_30_days: 4.1,
        interest_rate_60_days: 4.1,
        admin_fee_per_invoice: 0,
        max_advance_payment_percent: 0,
        is_with_global_assignment: false,
        is_selective_factoring: false,
        is_risk_assessment_completed: false,
    } as PostGenerateOfferForClientRequest);

    useEffect(() => {
        if (props.client?.contact_persons?.length > 0) {
            setData({
                ...data,
                contact_person_id: props.client?.contact_persons[0].contact_person_id
            });
        }
    }, [props.client.contact_persons]);

    return (
        <>
            <p>{t('leadNextSteps:labelStepQuestionnaireReturned')}</p>
            <Spacer/>

            <GenerateOfferForm client={props.client}/>
        </>
    );
};
