import * as React from "react";
import {Form, Input, message, Modal, Select} from "antd";
import {useTranslation} from "react-i18next";
import {adminCreateBOUser} from "../../../../../api/boUserService";
import {PostBOUserRequest} from "../../../../../api/types";
import {AnchoredSelect} from "../../../../common/presenters/anchored-select/AnchoredSelect";
import {BoUserRole} from "../../../../../types/types";

export interface AddBOUserModalProps {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    onDataChanged: () => void
}

export const AddBOUserModal = (props: AddBOUserModalProps) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();

    const onFormFinish = async (values) => {
        const req: PostBOUserRequest = {
            name: values.name,
            email: values.email,
            role: values.role,
        };

        try {
            await adminCreateBOUser(req);
            message.success(t('messages:dataSaved'), 2);
            props.onDataChanged();
            props.setVisible(false);
        } catch (e) {
            if (e.response?.status === 409) {
                message.error(t('messages:userWithEmailAlreadyExists'), 2);
            } else {
                message.error(t('messages:couldNotSave'), 2);
                console.error(e);
            }
        }
    };

    return (
        <Modal visible={props.visible}
            width={1000}
            title={t('modals:addEmployee')}
            onOk={() => form.submit()}
            onCancel={() => props.setVisible(false)}
        >
            <Form form={form}
                onFinish={onFormFinish}
                layout={"vertical"}
                scrollToFirstError
            >
                <Form.Item
                    label={t('name')}
                    name="name"
                    rules={[{required: true, message: t('formErrors:fullName')}]}
                >
                    <Input data-cy={"input_bo_name"}/>
                </Form.Item>

                <Form.Item
                    label={t('email')}
                    name="email"
                    rules={[{required: true, type: "email", message: t('errors:emailInvalid')}]}
                >
                    <Input data-cy={"input_bo_email"}/>
                </Form.Item>

                <Form.Item
                    label={t('role')}
                    name="role"
                    rules={[{required: true}]}
                    initialValue={BoUserRole.ADMIN}
                >
                    <AnchoredSelect data-cy="select_bo_role">
                        {
                            Object.keys(BoUserRole).map((k, i) => {
                                const enumValue = BoUserRole[k];
                                return <Select.Option data-cy={`select_bo_role_${i}`} key={enumValue}
                                    value={enumValue}>{t(`bo_user_roles:${enumValue}`)}</Select.Option>;
                            })
                        }
                    </AnchoredSelect>
                </Form.Item>
            </Form>
        </Modal>
    );
};
