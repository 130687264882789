import {Select} from "antd";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import {useTranslation} from 'react-i18next';
import {CountryCodes} from "../../../../types/countries";

export interface CountriesDropdownProps {
    className?: string
    children?: any
}

export const CountriesDropdown = (props: CountriesDropdownProps) => {
    const { t } = useTranslation();

    return (
        <div className={props.className ? props.className : ""}>
            <h4>{ t("country") }</h4>
            <Select defaultValue={CountryCodes[0]}
                onChange={() => { }}>
                {
                    CountryCodes.map((val, i) => {
                        return <Select.Option data-cy={`company_country_option_${i}`} key={i} value={val}>{t(`countryCodes:${val}`)}</Select.Option>;
                    })
                }
            </Select>
            <Spacer/>
        </div>
    );
};
