import * as React from "react";
import styles from "components/common/presenters/spacer/Spacer.module.scss";

export interface SpacerProps {
    hx?: number // hx={2} = default_height * 2, etc.
}

/**
 * Using <Spacer/> for vertical space is deprecated according to the HTML spec and also causes problems in Firefox,
 * which doesn't render <Spacer/>
 *
 * @param props 
 */
export const Spacer = (props: SpacerProps) => {
    const defaultHeightPx = 18;
    const heightMultiplier = props.hx ? props.hx : 1;
    const height = defaultHeightPx * heightMultiplier;

    return (
        <div style={{ minHeight: height, height: height }} className={styles.spacer}></div>
    );
};
