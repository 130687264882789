import React, { useEffect, useState } from 'react';
import { Routes } from './Routes';

class ErrorBoundary extends React.Component<any, any> {
    public constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    // TODO: handle cookie expiry error and other relevant errors that are left to propagate to ErrorBoundary.
    public componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

const App: React.FC<any> = () => {
    const [, setIsAdminPage] = useState(false);

    const currentPage = window.location.pathname;

    useEffect(() => {

        if (currentPage && currentPage.indexOf("admin") != -1) {
            setIsAdminPage(true);
        } else {
            setIsAdminPage(false);
        }

    }, [currentPage]);

    return (
        <ErrorBoundary>
            <div className="App app-container">
                <Routes />
            </div>
        </ErrorBoundary>
    );
};

export default App;
