import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Select, Spin } from 'antd';
import { postApplicationAuthed } from 'api/applicationService';
import { refreshSession } from 'api/clientContactPersonService';
import {ClientTypes, CPPostApplication, CPPostClient} from 'api/types';
import styles from 'components/common/pages/application-flow/ApplicationFlow.module.scss';
import { AnchoredSelect } from 'components/common/presenters/anchored-select/AnchoredSelect';
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { BackButton } from 'components/common/presenters/back-button/BackButton';
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { Spacer } from 'components/common/presenters/spacer/Spacer';
import { goTo } from 'navigation/navigationHelpers';
import * as React from "react";
import { useTranslation } from 'react-i18next';
import { RouteStrings } from 'Routes';
import { LocalApplicationFlowDataManager } from "storage/LocalApplicationFlowDataManager";
import {useState} from "react";

const { Option } = Select;

export const ApplicationStepContactInfoPage = () => {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [checkingLoggedIn, setCheckingLoggedIn] = React.useState(false);
    const [generalError, setGeneralError] = React.useState("");
    const [state, setState] = React.useState({
        salutation: "",
        title: "",
        first_name: "",
        last_name: "",
        phone: "",
        mobile_number: "",
    });
    const [countryCode, setCountryCode] = useState<string>("");
  
    let linkTarget = RouteStrings.ApplicationFlowStepCreateAccount;

    const persistData = (values) => {
        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();

        appl.salutation = values.salutation;
        appl.title = values.title;
        appl.first_name = values.first_name;
        appl.last_name = values.last_name;
        appl.phone = values.phone;
        appl.mobile_number = values.mobile_number;

        applDataManager.update(appl);
    };

    // Only called if the user is authed
    const handleContinue = async (contact_person_id: string) => {
        if (loading) {
            return;
        }

        await setLoading(true);

        try {
            const applDataManager = new LocalApplicationFlowDataManager();

            const appl = applDataManager.get();

            const newClient: CPPostClient = {
                client_type: ClientTypes.Client,
                contact_person_id: contact_person_id,
                company_name: appl.company_name,
                company_legal_form: appl.company_type,
                address_post_code: appl.company_post_code,
                address_city: appl.company_city,
                address_country: appl.company_country,
                address_street: appl.company_street,
                address_house_number: appl.company_house_number,
            };

            const newAppl: CPPostApplication = {
                type: appl.type,
                currency: appl.currency,
                amount: appl.amount,
                duration: appl.duration,
                company_purpose: appl.company_purpose,
                purpose_info: appl.purpose_info,
                largest_debitors_list: appl.largest_debitors_list,
                tax_id: appl.tax_id,
            };

            const createApplResp = await postApplicationAuthed({
                client: newClient,
                application: newAppl
            });

            if (createApplResp && createApplResp.status == 200) {
                appl.id = createApplResp.data;
                applDataManager.update(appl);
            } else {
                appl.id = null;
            }

            goTo(RouteStrings.ApplicationFlowStepResult);
        } catch (err) {
            console.error(err);

            setGeneralError(t("errors:generalError"));
        }

        setLoading(false);
    };


    const handleOnFinish = async (values: any) => {
        persistData(values);

        // check if user's logged in and if true, skip the account registration step
        try {
            const refreshSessionReq = await refreshSession(true);

            if (refreshSessionReq?.status === 200) {
                // Create a client - the backend will get the contact_person_id automatically, then create an application
                await handleContinue(refreshSessionReq.data);
                return;
            }

        } catch (error) {
            if (error.response?.status === 403) {
                console.error("Not logged in");
            }
        }

        goTo(linkTarget);
    };

    const skipToNextStepIfLoggedIn = async () => {
        // check if user's logged in and if true, skip to the next step
        try {
            setCheckingLoggedIn(true);

            const refreshSessionReq = await refreshSession(true);

            if (refreshSessionReq?.status === 200) {
                // Create a client - the backend will get the contact_person_id automatically, then create an application
                goTo(RouteStrings.ApplicationFlowStepResult);
                return;
            }

        } catch (error) {
            setCheckingLoggedIn(false);
            
            if (error.response?.status === 403) {
                console.error("Not logged in");
            }
        }
    };

    React.useEffect(() => {
        skipToNextStepIfLoggedIn();

        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();

        if (appl == null) {
            return;
        }

        form.setFieldsValue({
            salutation: appl.salutation,
            title: appl.title,
            first_name: appl.first_name,
            last_name: appl.last_name,
            phone: appl.phone,
            mobile_number: appl.mobile_number,
        });

        setCountryCode(appl.company_country);
    }, []);

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus/>
            <CustomPageHeader />
            <div className={styles.container}>
                <BackButton route={RouteStrings.ApplicationFlowStepCompanyInfo} />

                {
                    checkingLoggedIn ?
                        <Spin className={styles.spin} indicator={<Loading3QuartersOutlined style={{ fontSize: 34 }} spin />}/>
                        :
                        <React.Fragment>

                            <h2 className={styles.processTitle}>{t("applProcess:titleStepContact")}</h2>

                            <div className={styles.innerContainer}>

                                <Form
                                    layout={"vertical"}
                                    form={form}
                                    onFinish={handleOnFinish}
                                    scrollToFirstError
                                >
                                    <Form.Item name="salutation" label={t("salutation")} hasFeedback
                                        rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                        <AnchoredSelect data-cy="salutation" className={styles.select} allowClear
                                            placeholder={t('pleaseSelectOption')}>
                                            <Option value="Herr">Herr</Option>
                                            <Option value="Frau">Frau</Option>
                                        </AnchoredSelect>
                                    </Form.Item>

                                    <Form.Item name="title" label={t("personTitle")} hasFeedback
                                        rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                        <AnchoredSelect data-cy="title" className={styles.select} allowClear>
                                            <Option value="Doktor">Doktor</Option>
                                            <Option value="Professor">Professor</Option>
                                            <Option value="Professor Doktor">Professor Doktor</Option>
                                            <Option value="Ingenieur">Ingenieur</Option>
                                            <Option value="Diplom-Ingenieur">Diplom-Ingenieur</Option>
                                            <Option value="Magister">Magister</Option>
                                        </AnchoredSelect>
                                    </Form.Item>

                                    <Form.Item name="first_name" label={t("firstName")}
                                        hasFeedback={state.first_name.length >= 2}
                                        rules={[{ required: true, message: t("errors:invalidData"), min: 2, max: 100 }]}>
                                        <Input data-cy="first_name"
                                            className={styles.input}
                                            placeholder={t("example") + " Hans"}
                                            onChange={(e) => setState({
                                                ...state,
                                                first_name: e.target.value
                                            })}
                                        />
                                    </Form.Item>

                                    <Form.Item name="last_name" label={t("lastName")}
                                        hasFeedback={state.last_name.length >= 2}
                                        rules={[{ required: true, message: t("errors:invalidData"), min: 2, max: 100 }]}>
                                        <Input data-cy="last_name"
                                            className={styles.input}
                                            placeholder={`${t("example")} Mustermann`}
                                            onChange={(e) => setState({
                                                ...state,
                                                last_name: e.target.value
                                            })}/>
                                    </Form.Item>

                                    <Form.Item name="phone" label={t("phone")}
                                        hasFeedback={state.phone.length >= 5}
                                        rules={[{ required: false, message: t("errors:invalidData"), min: 5, max: 100 }]}>
                                        <Input data-cy="phone"
                                            className={styles.input}
                                            placeholder={t("example") + " " + t(`placeholders:phone:${countryCode}`)}
                                            onChange={(e) => setState({
                                                ...state,
                                                phone: e.target.value
                                            })}/>
                                    </Form.Item>

                                    <Form.Item name="mobile_number" label={t("mobileNumber")}
                                        hasFeedback={state.mobile_number.length >= 5}
                                        rules={[{ required: true, message: t("errors:invalidData"), min: 5, max: 100 }]}>
                                        <Input data-cy="mobile_number"
                                            className={styles.input}
                                            placeholder={t("example") + " " + t(`placeholders:mobileNumber:${countryCode}`)}
                                            onChange={(e) => setState({
                                                ...state,
                                                mobile_number: e.target.value
                                            })}/>
                                    </Form.Item>

                                    { generalError ?
                                        <>
                                            <div className={styles.error}>{generalError}</div>
                                            <Spacer/>
                                        </>
                                        :
                                        null
                                    }
                        
                                    <Spacer/>
                                    <Spacer/>
                                    <Spacer/>

                                    <Form.Item hasFeedback>
                                        <Row className={styles.rowCenterX}>
                                            <Button data-cy="button_continue"
                                                type="primary"
                                                htmlType="submit">
                                                {t("buttons:continue")}
                                            </Button>
                                        </Row>
                                    </Form.Item>
                                </Form>
                            </div>
                        </React.Fragment>
                }
            </div>
        </div>
    );
};
