import { Select } from "antd";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export interface CurrenciesDropdownProps {
    className?: string
    children?: any
}

export const CurrenciesDropdown = (props: CurrenciesDropdownProps) => {
    const { t } = useTranslation();

    return (
        <div className={props.className ? props.className : ""}>
            <h4>{t("currency")}</h4>
            <Select defaultValue="EUR"
                onChange={() => { }}>
                <Option value="EUR">EUR</Option>
                <Option value="CHF">CHF</Option>
                <Option value="USD">USD</Option>
            </Select>
            <Spacer/>
        </div>
    );
};
