import {ApplicationFlowType} from "storage/LocalAppDataManager";

export interface ApplicationFlowFormData {
    id: string
    company_name: string
    currency: string
    duration: number
    amount: number
    type: ApplicationFlowType
    company_number_of_employees: string
    company_type: string
    company_country: string
    company_post_code: string
    company_city: string
    company_street: string
    company_house_number: string
    company_purpose: string
    purpose: string
    purpose_info: string
    largest_debitors_list: string
    tax_id: string
    salutation: string
    title: string
    first_name: string
    last_name: string
    phone: string
    mobile_number: string
}

/**
 * Will be used to store localy application flow form data,
 * so the user's data is persisted if the user leaves the page before the data is saved to the backend.
 */
export class LocalApplicationFlowDataManager {
    private readonly LOCAL_STORAGE_KEY: string = "applicationFlowFormData";

    public constructor() {
        this.init();
    }

    /**
     * Returns the stored form. The forst stores 1 application.
     */
    public get(): ApplicationFlowFormData {
        try {
            const localData = localStorage.getItem(this.LOCAL_STORAGE_KEY);
            return JSON.parse(localData);
        } catch (error) {
            console.error(error);
            return null;
        }
    }
	
    public update(data): void {
        try {
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(data));
        } catch (error) {
            console.error(error);
        }
    }
	
    public reset(): void {
        try {
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.getInitialStorageState()));
        } catch (error) {
            console.error(error);
        }
    }

    public getInitialStorageState(): ApplicationFlowFormData {
        return {
            id: null,
            company_name: null,
            currency: null,
            duration: 0, // because of factoring
            amount: 0, // because of factoring
            type: null,
            company_type: null,
            company_number_of_employees: null,
            company_country: null,
            company_post_code: null,
            company_city: null,
            company_street: null,
            company_house_number: null,
            company_purpose: null,
            purpose: null,
            purpose_info: null,
            largest_debitors_list: null,
            tax_id: null,
            salutation: null,
            title: null,
            first_name: null,
            last_name: null,
            phone: null,
            mobile_number: null,
        };
    }

    /**
     * If there's no object in local storage, an empty form data object is initialized and persisted.
     */
    private init(): void {
        try {
            const localDataInitalized: ApplicationFlowFormData = this.getInitialStorageState();

            const localData = localStorage.getItem(this.LOCAL_STORAGE_KEY);

            if (localData == null) {
                localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(localDataInitalized));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

