import {Button, Row} from "antd";
import {ContactPersonClient} from "api/types";
import {ContactPersonCard} from "components/common/presenters/contact-persons-editor/ContactPersonCard";
import styles from "components/common/presenters/contact-persons-editor/ContactPersonsEditor.module.scss";
import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {getContactPersonModalForm} from "../../../../helpers/clientContactPersonHelpers";
import {deleteObjNullProps} from "../../../../storage/genericHelpers";
import {
    adminPostContactPerson,
    adminPutContactPerson,
    postContactPerson,
    putContactPerson
} from "../../../../api/clientContactPersonService";
import {AddPersonModal} from "./AddPersonModal";
import {SectionTitle} from "../section-title/SectionTitle";
import { Spacer } from "components/common/presenters/spacer/Spacer";

export interface ContactPersonsEditorProps {
    isAdmin: boolean
    clientId: string
    contactPersons: ContactPersonClient[]
    onDataChanged: () => void
}

export const ContactPersonsEditor = (props: ContactPersonsEditorProps) => {
    const {t} = useTranslation();

    const [addPersonModalOpen, setAddPersonModalOpen] = useState(false);
    const [editablePersonId, setEditablePersonId] = useState(null);
    const [formData, setFormData] = useState({});
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleAddPerson = () => {
        setAddPersonModalOpen(true);
    };

    const handleEditPerson = async (contact_person_id: string) => {
        await setEditablePersonId(contact_person_id);

        const cp = props.contactPersons.find(c => c.contact_person_id === contact_person_id) || {};

        const formData = {
            ...getContactPersonModalForm(),
            ...cp
        };

        await setFormData(formData);

        await setEditing(true);

        setAddPersonModalOpen(true);
    };

    const handleCloseModal = () => {
        setAddPersonModalOpen(false);
        setError("");
        setFormData(getContactPersonModalForm());
        setLoading(false);
        setEditing(false);
        setEditablePersonId(null);
    };

    const handleSavePerson = async (values: any) => {
        await setError("");
        await setLoading(true);

        let modalData = {
            ...values,
            client_id: props.clientId
        };

        modalData = deleteObjNullProps(modalData);

        if (modalData.pct_owned_shares != null) {
            modalData.pct_owned_shares = parseFloat(modalData.pct_owned_shares);
        }

        try {
            if (editing) {
                props.isAdmin ?
                    await adminPutContactPerson(editablePersonId, modalData)
                    : await putContactPerson(editablePersonId, modalData);

            } else {
                props.isAdmin ?
                    await adminPostContactPerson(modalData)
                    : await postContactPerson(modalData);
            }

            props.onDataChanged();
            setAddPersonModalOpen(false);
        } catch (error) {
            console.error(error);
            setError(t("errors:postContactPerson"));
        }

        setLoading(false);
    };

    const renderContactPersons = () => {
        if (props.contactPersons == null || props.contactPersons.length == 0) {
            return <div>{t("userApplPage:noContactPersons")}</div>;
        }

        const els = [];

        for (let i = 0; i < props.contactPersons.length; i++) {
            const person = props.contactPersons[i];

            els.push(<ContactPersonCard key={i} person={person} onEdit={handleEditPerson}/>);
        }

        return els;
    };

    return (
        <div className={styles.tab}>
            <SectionTitle text={t("contactPersonsTitle")}/>

            {
                props.contactPersons && props.contactPersons.length >= 3 ?
                    <div>{t("errors:maxNumContactPersons")}</div>
                    :
                    <Button className={styles.button} type="primary" size="large"
                        disabled={false}
                        onClick={() => {
                            handleAddPerson();
                        }}>
                        {t("buttons:addPerson")}
                    </Button>
            }

            <Spacer hx={2}/>

            <Row className={styles.cardsContainer}>
                {renderContactPersons()}
            </Row>

            <AddPersonModal open={addPersonModalOpen} formData={formData} onOk={handleSavePerson}
                onCancel={handleCloseModal} editing={editing} loading={loading} error={error}/>
        </div>
    );
};
