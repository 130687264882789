export const en = {
    "en": {
        "common": {
            "messages": {
                "dataSaved": "Daten gespeichert",
                "couldNotSave": "Konnte nicht speichern",
            },
            "buttons": {
                "newLead": "New Lead",
                "continue": "Continue",
                "save": "Save",
                "downloadPdf": "Download PDF",
                "cancel": "Cancel",
                "ok": "OK",
                "login": "Login",
                "edit": "Edit",
                "addDebitor": "+ Add New Debitor",
                "yes": "Yes",
                "no": "No",
                "submit": "Submit",
                "addTransaction": "Add Transaction",
                "addPerson": "Add Person",
                "myLeads": "My Leads",
                "clear": "Clear",
        				"toLogin": "Zum Login"
            },
            "errors": {
                "generalError": "Something went wrong",
            },
            "headings": {
                "processStepTwo": "Please choose what kind of application you're making:",
                "processCreateAccount": "To create an application you first need to create an account.",
                "processResult": "Ihr Konto wurde erfolgreich erstellt.",
                "processSuccess1": "Ihr Benutzerkonto wurde erfolgreich erstellt. Wir haben Ihnen eine E-Mail geschickt mit der Sie Ihre E-Mail Adresse bestätigen und Ihr Nutzerkonto aktivieren können. ",
                "processSuccess2": "Nachdem Sie Ihr Nutzerkonto aktiviert haben, können Sie sich einloggen und Ihren Antrag weiter bearbeiten.",
                "processRejection1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                "processRejection2": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
                "debitorCheck": "Execute Debitor Check"
            },
            "applProcess": {
                "titleStepOne": "For what company are you applying for a loan?",
                "titleStepLoan": "Select loan amount and duration",
                "titleStepCompany": "Bitte die Unternehmensdaten angeben",
                "step": "Schritt",
                "stepCompanyName": "Firmenname",
                "stepLoanType": "Antragsart",
                "stepLoanDetails": "Kredit Details",
                "stepCompanyInfo": "Firmeninformationen",
                "stepRegistration": "Registrierung",
                "stepResult": "Antrag abschicken",
            },
            "signatories": {
                "title": "Signatories",
                "person": "Signatory",
                "removeSignatory": "Remove signatory",
                "addSignatory": "Add another signatory",
                "confirmAllSignatories": "I confirm that every signatory and every person that owns 25% or more of the company was added.",
                "finalConfirmation": "Please ensure that there are enough signatories added to be able to sign the Teylor loan contract.",
                "legalSignatory": "Is this person a legal signatory for the company?",
                "soleSignatory": "Does this signatory have sole signatory rights?",
                "willSignLoanContract": "Will this person sign the Teylor Loan Contract?",
                "doesOwnMoreThan25": "Does this person own 25% or more of the company?",
            },
            "confirm:": "Confirm Invoice Amount",
            "modals": {
                "confirmFactoring:": "Confirm Invoice Amount",
                "addDebitor": "New Debitor",
                "addLead": "Add Lead",
                "editLead": "Edit Lead",
            },
            "invoiceListPage": {
                "sumOpen": "Offene Rechnungen:",
                "sumFinanced": "Finanzierte Rechnungen:",
                "sumInReview": "In Bearbeitung:",
                "sumNotFinanced": "Nicht Finanziert:",
                "sumDepot": "Depot:"
            },
            "userInvoiceInfoPage": {
                "debitor": "Debitor:",
                "applicationId": "Antrags ID:",
                "factoring": "Factoring:",
                "status": "Status:",
                "currency": "Währung:"
            },
            "userDebitorInfoPage": {
                "debitor": "Debitor:",
                "debitorId": "Debitor ID:",
                "invoices": "Rechnungen:",
                "saldo": "Saldo:"
            },
            "admin": {
                "repaymentTab": {
                    "titleOne": "Rechnungszahlung eingeben",
                    "titleTwo": "Erfolgte Zahlungen"
                },
                "tabs": {
                    "companyInfo": "Company Info",
                    "scoring": "Scoring",
                    "docs": "Documents",
                    "invoices": "Invoices",
                    "applications": "Applications",
                    "debitors": "Debitors",
                    "notes": "Notes",
                    "payments": "Payments",
                    "logs": "Logs"
                },
                "scoringTab": {
                    "hasIncomeStatementPY": "Bilanz & Erfolgsrechnung vom Vorjahr",
                    "hasIncomeStatementCurrentYear": "(Prov.) Bialanz & Erfolgsrechnung vom aktuellen Jahr",
                    "hasDebitorList": "Aktuelle offene Debitorenliste überprüft",
                    "hasCreditorList": "Aktuelle offene Kreditorenliste überprüft",
                    "hasContractDocuments": "Vertragsunterlagen überprüft",
                    "hasCompletedRiskCheck": "Risikoprüfung ist abgeschlossen und den Kreditgenehmigen"
                }
            },
            "labels": {
                "invoiceNumber": "Invoice Number",
                "loanAccountNumber": "Kreditkontonummer",
                "dateOfReceipt": "Date of Receipt",
                "bankAccount": "Bank Account",
                "amount": "Betrag",
                "paymentType": "Art der Zahlung",
                "transactionCode": "Transaktionscode",
            },
            "gender": "Geschlecht",
            "placeOfBirth": "Geburtsort",
            "nationality": "Nationalität",
            "isSignatory": "Unterschriftenberechtigt",
            "isSoleSignatory": "Alleine Unterschriftenberechtigt",
            "isBeneficialOwner": "Besitzt mehr als 25%",
            "pctShares": "Anteile an dem Unternehmen",
            "contactPersonsTitle": "Contact Persons",
            "additionalInfo": "More Info",
            "leadStatuses" : {
                "backlog": "Lead Backlog",
                "in_bearbeitung": "In Bearbeitung",
                "lead_contacted": "Lead Contacted",
                "project_identified": "Project Identified",
                "questionnaire_sent": "Questionnaire Sent",
                "questionnaire_returned": "Questionnaire Returned",
                "proposal_sent": "Proposal Sent",
                "contract_sent": "Contract Sent",
                "contract_signed": "Contract signed",
                "won": "Won",
                "lost": "Lost",
            },
            "sectionTitles": {
                "companyInfo": "Firmeninformationen",
                "status": "Status",
                "leadInfo": "Lead Informationen",
                "bankInfo": "Bank Informationen",
            },
            "data": "Data",
            "message": "Message",
            "paymentMenu": "Zahlungsmenü",
            "createPayment": "Zahlung Erfassen",
            "yes": "Yes",
            "no": "No",
            "username": "Username",
            "password": "Password",
            "passwordRepeat": "Repeat Password",
            "forgotPassword": "Forgotten password?",
            "currency": "Currency",
            "months": "Months",
            "loan": "Loan",
            "factoring": "Factoring",
            "adminHomeTitle": "Admin Panel",
            "userHomeTitle": "Loans and Factoring",
            "userDebitorsTitle": "Debitors",
            "userMyContractTitle": "My Contract",
            "applications": "Applications",
            "clients": "Clients",
            "settings": "Settings",
            "contracts": "My Contract",
            "debitors": "Debitors",
            "debitorCheck": "Debitor Check",
            "loans": "Loans",
            "invoices": "Invoices",
            "applicationNumber": "Application Nr.",
            "company": "Company",
            "email": "Email",
            "created": "Created",
            "loanAmount": "Loan Amount",
            "loanDuration": "Loan Duration",
            "interestRate": "Interest Rate",
            "status": "Status",
            "notes": "Notes",
            "logs": "Logs",
            "save": "Save",
            "assignee": "Assignee",
            "loanInformation": "Loan Information",
            "loanPurpose": "Loan Purpose",
            "legalForm": "Legal Form",
            "postCode": "Post Code",
            "foundationDate": "Foundation Date",
            "country": "Country",
            "numberOfEmployees": "Number of Employees",
            "bankName": "Bank Name",
            "applicationState": "Application State",
            "submitToSignature": "Submit to Signature/KYC",
            "setFinalReview": "Set to Final Review",
            "setPaidOut": "Set to Paid Out",
            "rejectUser": "Reject User",
            "hideApplication": "Hide Application",
            "reopenApplication": "Re-Open Application",
            "owner": "Owner",
            "signatoriesLabel": "Signatories",
            "docs": "Docs",
            "id": "ID",
            "firstName": "First Name",
            "surname": "Surname",
            "dateOfBirth": "D.O.B",
            "address": "Address",
            "number": "No.",
            "city": "City",
            "telNumber": "Phone no.",
            "vatNumber": "VAT No.",
            "uploadedDocuments": "Uploaded Documents",
            "paymentNotifications": "Payment Notifications",
            "sendPaymentNotifications": "Sent Payment Notifications",
            "scoring": "Scoring",
            "analysis": "Analysis",
            "author": "Author",
            "applicationType": "Application Type",
            "purpose": "Purpose",
            "detailedPurpose": "Detailed Purpose (min. 30 characters)",
            "detailedPurposePlaceholder": "We need a loan for...",
            "companyDesc": "Company Description",
            "hrNUmber": "HR Number",
            "taxIdNumber": "Tax ID Number (TIN) (Optional)",
            "idOrPassportNumber": "ID or Passport Number",
            "example": "Example:",
            "applicationStates": {
                "offerRejected": "Offer Rejected",
                "open": "Open",
                "inReviewRisk": "In Review - Risk",
                "inReviewOps": "In review - Operations",
                "signature": "Signature/KYC",
                "finalReview": "Final Review ",
                "onboardingApproved": "Onboarding_Approved",
                "paidOut": "Paid Out / Outstanding",
                "delinquent1": "Delinquent - Mahnung 1",
                "delinquent2": "Delinquent - Mahnung 2",
                "defaulted": "Defaulted",
                "repaid": "Repaid",
                "rejected": "Rejected",
            },
            "industry_type": "Industry Type",
            "industry_types": {
                "agriculture": "Agriculture",
            },
            "number_of_employees": "Number of Employees",
            "company_legal_form": "Legal Form",
            "company_product": "Product / Service",
            "source_of_leads": "Lead Source",
            "test": "Lorem ipsum EN",
            "str1": "Lorem string 1",
        }
    }
};
