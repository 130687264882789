import { ApplicationStates } from "types/types";

export enum ApplicationFlowType {
    Loan = "loan",
    Factoring_Onboarding = "factoring_onboarding"
}

export const ApplicationFlowStringsMap = [
    "loan",
    "factoring",
];

// Application is used in terms of "app" here. The loan applications are called financing applications.
export interface ApplicationLocalData {
    userIsFactoringOnboarding: boolean
    activeFlowType: ApplicationFlowType
    skipLoanTypeQuestion: boolean
    userHasAnAccount: boolean
    loanFlow: {
        applicationState: any
    }
    factoringFlow: {
        applicationState: any
    }
    invoiceFlow: {
        applicationState: any
    }
    sentPaymentReminders: {
        reminderCount: number
        applicationId: string
        sentAt: Date
        userEmail: string
        userName: string
        deadline: Date
        emailBody: string
    }[]
}

/**
 * Will be used to store localy application data,
 * so the click demo is functional without a backend.
 */
class LocalAppDataManager {
    private readonly LOCAL_STORAGE_KEY: string = "applicationLocalData";

    public constructor() {
        this.init();
    }

    public get(): ApplicationLocalData {
        try {
            const localData = localStorage.getItem(this.LOCAL_STORAGE_KEY);
            return JSON.parse(localData);
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public update(data): void {
        try {
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(data));
        } catch (error) {
            console.error(error);
        }
    }

    public getInitialStorageState(): ApplicationLocalData {
        return {
            userIsFactoringOnboarding: false,
            activeFlowType: null,
            skipLoanTypeQuestion: false,
            userHasAnAccount: false,
            loanFlow: {
                applicationState: ApplicationStates.Open,
            },
            factoringFlow: {
                applicationState: null,
            },
            invoiceFlow: {
                applicationState: ApplicationStates.Open,
            },
            sentPaymentReminders: []
        };
    }

    private init(): void {
        try {
            const localDataInitalized: ApplicationLocalData = this.getInitialStorageState();

            const localData = localStorage.getItem(this.LOCAL_STORAGE_KEY);

            if (localData == null) {
                localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(localDataInitalized));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

export default new LocalAppDataManager();
