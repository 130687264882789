import { dictionary } from "i18n/dictionary";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

/**
 * Example how to use i18n: https://github.com/i18next/react-i18next/blob/master/example/react_withHOC/src/App.js
 * Docs for LanguageDetector: https://github.com/i18next/i18next-browser-languageDetector
 *
 * Resources will be resolved in this order:
 * 1) try languageCode plus countryCode, eg. 'en-US'
 * 2) alternative look it up in languageCode only, eg. 'en'
 * 3) finally look it up in definded fallback language, default: 'dev'
 * 
 * If language is not set explicitly i18next tries to detect the user language by:
 * 1) querystring parameter (?setLng=en-US)
 * cookie (i18next)
 * language set in navigator
 */
const customLangDetector = {
    name: "teylor-custom-lang-detector",

    lookup() {
        let lang;

        // Check if there's a localStorage var called i18n-custom, where the user's language choice is store.
        const i18nCustom = localStorage.getItem("i18n-custom");

        if (i18nCustom == null) {
            // Use the browser's language if it's en or de
            const browserLanguage =
				navigator.language || (navigator as any).userLanguage; // fix Chrome (Windows) bug
            lang = browserLanguage.split("-")[0];

            if (lang !== "en" && lang !== "de") {
                lang = "de"; // Default to German
            }
        } else {
            // Use the lang set by the user
            lang = localStorage.getItem("i18n-custom");

            // Check the language value, even though the language should be set correctly.
            if (lang !== "en" && lang !== "de") {
                lang = "de"; // Default to German
            }
        }

        // Return the lang code (en, de, etc.)
        return lang;
    },

    /**
     * options -> are passed in options
     * lng -> current language, will be called after init and on changeLanguage
     *
     * Note: there's custom caching implemented in the setLanguage() helper function.
     * Every setLanguage call stores the language the user selected in localStorage.
     * The language is then read from localStorage on app init.
     * That's why this method does nothing - it overrides the default cacheUserLanguage method of i18-next.
     *
     * @param lng
     * @param options
     */
    cacheUserLanguage() { }
};

const languageDetectorOptions = {
    // Order and from where user language should be detected. The custom detector should be listed here.
    order: [
        "teylor-custom-lang-detector",
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain"
    ],

    // Keys or params to lookup language from
    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    lookupLocalStorage: "i18nextLng", // The default localStorage var name. The custom language detector uses another var name.
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // Cache user language on those detectors. Method cacheUserLanguage() is called when using those detectors.
    caches: ["teylorCustomLangDetector", "localStorage", "cookie"]
    // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
};

// Initialize the custom language detector
const lngDetector = new LanguageDetector();
lngDetector.addDetector(customLangDetector);

// https://www.i18next.com/overview/configuration-options
i18n
    .use(lngDetector) // Either use lngDetector or the default LanguageDetector
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: false,
        resources: dictionary,
        lng: "de",
        detection: languageDetectorOptions, // This configures the language detector
        fallbackLng: "de", // German is our fallback language
        load: "languageOnly", // No region, so no stuff like en-GB
        keySeparator: ":", // we do not use keys in form messages.welcome
        ns: ["common"],
        defaultNS: "common",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
