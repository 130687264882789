import {Button, Input, message} from 'antd';
import {PatchClientBankDetailsRequest} from 'api/types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styles from 'components/admin/pages/client-drilldown/ClientDrilldownPage.module.scss';
import {SectionTitle} from "../../presenters/section-title/SectionTitle";
import { Spacer } from 'components/common/presenters/spacer/Spacer';

interface UserBankInfoSectionProps {
    bank_name: string
    iban: string
    error: string
    onSave: (bank_name: string, iban: string) => void
}

export const UserBankInfoSection = (props: UserBankInfoSectionProps) => {
    const {t} = useTranslation();

    const [data, setData] = useState({} as PatchClientBankDetailsRequest);

    useEffect(() => {
        setData({
            bank_name: props.bank_name,
            iban: props.iban,
        });
    }, [props.bank_name, props.iban]);

    const handleSave = async () => {
        try {
            props.onSave(data.bank_name, data.iban);
        } catch (e) {
            message.error(t('messages:couldNotSave'), 2);
            console.error(e);
        }
    };

    return (
        <div className={styles.tab}>
            <SectionTitle text={t('sectionTitles:bankInfo')}/>

            <div className={styles.container}>
                <h4>Bank Name:</h4>
                <Input data-cy="input_bank_name"
                    placeholder="Svea Bank"
                    maxLength={150}
                    value={data.bank_name}
                    onChange={(e) => {
                        setData(prevState => {
                            return {...prevState, bank_name: e.target.value};
                        });
                    }}
                />
                <Spacer/>
                <Spacer/>

                <h4>IBAN:</h4>
                <Input data-cy="input_iban"
                    placeholder="DE1232439359439"
                    maxLength={100}
                    value={data.iban}
                    onChange={(e) => {
                        setData(prevState => {
                            return {...prevState, iban: e.target.value};
                        });
					          }}
                />
                <Spacer/>
                <Spacer/>
                <div className="custom-error">{ props.error} </div>
                <Spacer/>
                <Button data-cy="button_bank_save"
                    type="primary"
                    onClick={() => handleSave()}>
                    {t('save')}
                </Button>
            </div>
        </div>
    );
};
