import {Checkbox, message, Popconfirm, Space, Table, Typography} from "antd";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import styles from "./DashboardTab.module.scss";
import {TodoEntityKind, TodoStatus} from "../../../../../types/types";
import {
    GetAdminProfileResponse,
    GetBoUsersListItem,
    GetTodosResponse,
    GetTodosResponseItem,
    PaginationResponse
} from "../../../../../api/types";
import {adminDeleteTodo, adminGetTodos, adminUpdateTodoStatus} from "../../../../../api/todoService";
import {adminGetBOUsers, adminGetSelfProfile} from "../../../../../api/boUserService";
import {DATE_FORMAT} from "../../../../../api/apiConfig";
import moment from "moment";
import {Link} from "react-router-dom";
import {RouteStrings} from "../../../../../Routes";
import {TextSearch} from "../../../../common/presenters/table-search-inputs/TextSearch";
import {FilterFilled, SearchOutlined} from "@ant-design/icons";
import {ListSearch} from "../../../../common/presenters/table-search-inputs/ListSearch";
import {TimeRangeSearch} from "../../../../common/presenters/table-search-inputs/TimeRangeSearch";
import {TodoModal} from "../../todo-modal/TodoModal";
import {useQuery} from "../../../../../hooks/QueryParams";
import {clearURLQueryString} from "../../../../../helpers/uiHelpers";

export interface TodoFilter {
    assigneeIDs: string[]
    companyName: string
    statuses: TodoStatus[]
    dueFrom: number
    dueTo: number
}

export const DashboardTab = () => {
    const {t} = useTranslation();
    const queryParams = useQuery();

    const defaultFilter: TodoFilter = {
        assigneeIDs: [],
        companyName: "",
        statuses: [TodoStatus.Open],
        dueFrom: 0,
        dueTo: 0,
    };

    const defaultPagination: PaginationResponse = {
        current_page: 1,
        items_per_page: 20,
        total_rows: 0,
    };

    const [showCompleted, setShowCompleted] = useState(false);
    const [showOnlyMine, setShowOnlyMine] = useState(true);
    const [showOnlyToday, setShowOnlyToday] = useState(false);
    const [adminProfile, setAdminProfile] = useState<GetAdminProfileResponse>({}); // TODO store it in global store
    const [boUsers, setBoUsers] = useState<GetBoUsersListItem[]>([]);
    const [filter, setFilter] = useState<TodoFilter>(defaultFilter);
    const [todos, setTodos] = useState<GetTodosResponseItem[]>();
    const [pagination, setPagination] = useState<PaginationResponse>(defaultPagination);

    const [editingTodo, setEditingTodo] = useState<GetTodosResponseItem>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const fetchTodos = async (page: number, pageSize: number) => {
        try {
            const response: GetTodosResponse = (await adminGetTodos(filter, page, pageSize)).data;
            setTodos(response.result);
            setPagination(response.pagination);
        } catch (e) {
            console.error(e);
        }
    };

    const firstFetch = async () => {
        const isFilterTodayQueryParam: string = queryParams.get("filter_today");
        if (isFilterTodayQueryParam === "true") {
            setShowOnlyToday(true);
            clearURLQueryString();
        }

        try {
            const profileResponse: GetAdminProfileResponse = (await adminGetSelfProfile()).data;
            const boUsersResponse: GetBoUsersListItem[] = (await adminGetBOUsers()).data;
            const firstFilter: TodoFilter = {
                ...defaultFilter,
                assigneeIDs: [profileResponse.bo_user_id],
                dueFrom: isFilterTodayQueryParam === "true" ? moment().startOf("day").unix() : defaultFilter.dueFrom,
                dueTo: isFilterTodayQueryParam === "true" ? moment().endOf("day").unix() : defaultFilter.dueTo,
            };

            setAdminProfile(profileResponse);
            setBoUsers(boUsersResponse);
            setFilter(firstFilter);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        firstFetch();
    }, []);

    useEffect(() => {
        fetchTodos(pagination.current_page, pagination.items_per_page);
    }, [filter]);

    const getBoUserNameById = (boUserId: string): string => {
        const foundBoUser = boUsers.find(bou => bou.bo_user_id === boUserId);
        return foundBoUser ? foundBoUser.bo_user_name : "";
    };

    const handleReopen = async (record: GetTodosResponseItem) => {
        try {
            await adminUpdateTodoStatus(record.todo_id, TodoStatus.Open);
            message.success(t('messages:dataSaved'), 2);
            fetchTodos(pagination.current_page, pagination.items_per_page);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    const handleComplete = async (record: GetTodosResponseItem) => {
        try {
            await adminUpdateTodoStatus(record.todo_id, TodoStatus.Complete);
            message.success(t('messages:dataSaved'), 2);
            fetchTodos(pagination.current_page, pagination.items_per_page);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    const handleEdit = async (record: GetTodosResponseItem) => {
        setEditingTodo(record);
        setEditModalOpen(true);
    };

    const handleDelete = async (record: GetTodosResponseItem) => {
        try {
            await adminDeleteTodo(record.todo_id);
            message.success(t('messages:dataSaved'), 2);
            fetchTodos(pagination.current_page, pagination.items_per_page);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    const onPaginationChange = (newPage: number, newPageSize: number) => {
        setPagination(prevState => ({
            ...prevState,
            current_page: newPage,
            items_per_page: newPageSize,
        }));

        fetchTodos(newPage, newPageSize);
    };

    const renderViewButton = (record: GetTodosResponseItem) => {
        let link = "";

        switch (record.entity_kind) {
        case TodoEntityKind.Application:
            link = RouteStrings.AdminApplicationDrilldown.replace(":applicationId", record.entity_id);
            break;
        case TodoEntityKind.Client:
            link = RouteStrings.AdminClientDrilldown.replace(":clientId", record.entity_id);
            break;
        }

        return (
            <Link to={link}>{t(`view`)}</Link>
        );
    };

    const renderEditButtons = (record: GetTodosResponseItem) => {
        if (record.status === TodoStatus.Complete) {
            return (
                <p className={styles.blueTextPointer}
                    onClick={() => handleReopen(record)}
                >
                    {t(`reopen`)}
                </p>
            );
        }

        return (
            <Space align={"center"}>
                {/* Sorry, <Button type="link"/> does not work due to sharedStyles.css */}
                <p className={styles.blueTextPointer}
                    onClick={() => handleComplete(record)}
                >
                    {t(`buttons:complete`)}
                </p>
                <Typography.Text className={styles.blueText} strong>{" | "}</Typography.Text>

                <p className={styles.blueTextPointer}
                    onClick={() => handleEdit(record)}
                >
                    {t(`buttons:customize`)}
                </p>
                <Typography.Text className={styles.blueText} strong>{" | "}</Typography.Text>

                <Popconfirm title={t('confirmDeleteEntry')}
                    onConfirm={() => handleDelete(record)}
                    okText={t('yes')}
                    cancelText={t('no')}
                >
                    <p className={styles.blueTextPointer}>
                        {t(`buttons:delete`)}
                    </p>
                </Popconfirm>
            </Space>
        );
    };

    const getRowClassName = (record: GetTodosResponseItem): string|undefined => {
        return record.status === TodoStatus.Complete ? styles.completedRow : undefined;
    };

    const columns = [
        {
            title: t('assignee2'),
            dataIndex: 'assignee_id',
            render: (value) => getBoUserNameById(value),
            filterDropdown: ({confirm}) => (
                <ListSearch confirm={confirm}
                    onSearch={(selectedValues) => {
                        if (!selectedValues?.includes(adminProfile.bo_user_id)) {
                            setShowOnlyMine(false);
                        }
                        setFilter({...filter, assigneeIDs: selectedValues});
                    }}
                    items={boUsers.map(bou => ({value: bou.bo_user_id, label: bou.bo_user_name}))}
                />
            ),
            filterIcon: () => <FilterFilled className={filter.companyName ? styles.blueIcon : undefined}/>,
        },
        {
            title: t('company'),
            dataIndex: 'company_name',
            filterDropdown: ({confirm}) => <TextSearch confirm={confirm} onSearch={(e) => setFilter({...filter, companyName: e})}/>,
            filterIcon: () => <SearchOutlined className={filter.companyName ? styles.blueIcon : undefined}/>,
        },
        {
            title: t('details'),
            dataIndex: 'details',
        },
        {
            title: t('when'),
            dataIndex: 'due_at',
            render: (value) => value ? moment(value).format(DATE_FORMAT.DE) : "-",
            filterDropdown: ({confirm}) => (
                <TimeRangeSearch confirm={confirm}
                    onSearch={(from, to) => setFilter({
                        ...filter,
                        dueFrom: from?.unix(),
                        dueTo: to?.unix(),
                    })}
                />
            ),
            filterIcon: () => <FilterFilled className={filter.companyName ? styles.blueIcon : undefined}/>,
        },
        {
            title: t('status'),
            dataIndex: 'status',
            render: (value) => value ? t(`todoStatuses:${value}`) : "-"
        },
        {
            title: '',
            dataIndex: '',
            render: (value, record) => renderViewButton(record)
        },
        {
            title: '',
            dataIndex: '',
            render: (value, record) => renderEditButtons(record)
        },
    ];

    return (
        <div className={styles.adminHomeTab}>
            <Space direction={"vertical"}>
                <Checkbox checked={showCompleted}
                    onChange={(e) => {
                        const checked = e.target.checked;
                        setShowCompleted(checked);
                        setFilter({
                            ...filter,
                            statuses: checked ? [TodoStatus.Open, TodoStatus.Complete] : [TodoStatus.Open]
                        });
                    }}
                >
                    {t('showCompleted')}
                </Checkbox>

                <Checkbox checked={showOnlyMine}
                    onChange={(e) => {
                        const checked = e.target.checked;
                        setShowOnlyMine(checked);
                        setFilter({
                            ...filter,
                            assigneeIDs: checked ? [adminProfile.bo_user_id] : []
                        });
                    }}
                >
                    {t('showOnlyMine')}
                </Checkbox>

                <Checkbox checked={showOnlyToday}
                    onChange={(e) => {
                        const checked = e.target.checked;
                        setShowOnlyToday(checked);
                        setFilter({
                            ...filter,
                            dueFrom: checked ? moment().startOf("day").unix() : defaultFilter.dueFrom,
                            dueTo: checked ? moment().endOf("day").unix() : defaultFilter.dueTo,
                        });
                    }}
                >
                    {t('showOnlyToday')}
                </Checkbox>
            </Space>
            <Spacer hx={3}/>

            <Table rowKey={record => record.todo_id}
                columns={columns}
                dataSource={todos}
                scroll={{x: true}}
                rowClassName={(record) => getRowClassName(record)}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: pagination.items_per_page,
                    current: pagination.current_page,
                    total: pagination.total_rows,
                    onChange: onPaginationChange,
                }}
            />

            <TodoModal currentBoUserId={adminProfile.bo_user_id}
                boUsers={boUsers}
                modalOpen={editModalOpen}
                setModalOpen={setEditModalOpen}
                onDataChanged={() => fetchTodos(pagination.current_page, pagination.items_per_page)}
                existingTodo={editingTodo}
            />
        </div>
    );
};
