import * as React from "react";
import {useState} from "react";
import styles from '../ClientDrilldownPage.module.scss';
import {useTranslation} from 'react-i18next';
import {GetClientResponse, GetContactPersonResponse} from "../../../../../api/types";
import {Button, Col, Row} from "antd";
import {DataItemsColumn} from "../../../../common/presenters/data-items-column/DataItemsColumn";
import moment from "moment";
import {ClientModal} from "../../../presenters/client-modal/ClientModal";
import {SectionTitle} from "../../../../common/presenters/section-title/SectionTitle";
import { Spacer } from "components/common/presenters/spacer/Spacer";

interface ClientInfoSectionProps {
    client: GetClientResponse
    onDataChanged: () => void
}

export const ClientInfoSection = (props: ClientInfoSectionProps) => {
    const {t} = useTranslation();
    const client = props.client;
    const firstContactPerson: GetContactPersonResponse = client.contact_persons?.sort((cp1, cp2) => moment(cp1.created_at).isBefore(cp2.created_at) ? -1 : 0)[0];

    const [editClientModalOpen, setEditClientModalOpen] = useState(false);

    return (
        <>
            <SectionTitle text={t('sectionTitles:companyInfo')}/>

            <div className={styles.container}>
                <Row gutter={36}>
                    <Col>
                        <DataItemsColumn dataItems={[
                            {itemName: t('uniqueId'), itemValue: client.client_id},
                            {itemName: t('company'), itemValue: client.company_name},
                            {itemName: t('street'), itemValue: client.address_street},
                            {itemName: t('houseNumber'), itemValue: client.address_house_number},
                            {itemName: t('postCode'), itemValue: client.address_post_code},
                            {itemName: t('city2'), itemValue: client.address_city},
                            {itemName: t('region'), itemValue: client.address_region},
                            {itemName: t('country'), itemValue: client.address_country ? t(`countryCodes:${client.address_country.toLowerCase()}`) : "-"},
                        ]}/>
                    </Col>
                    <Col>
                        <h4 className={styles.bold}>{t('contactPerson')}</h4>
                        <DataItemsColumn dataItems={[
                            {itemName: t('salutation'), itemValue: firstContactPerson?.salutation},
                            {itemName: t('firstName'), itemValue: firstContactPerson?.first_name},
                            {itemName: t('surname'), itemValue: firstContactPerson?.last_name},
                            {itemName: t('telNumber'), itemValue: firstContactPerson?.phone},
                            {itemName: t('mobileNumber'), itemValue: firstContactPerson?.mobile_phone},
                            {itemName: t('email2'), itemValue: firstContactPerson?.email},
                            {itemName: t('website'), itemValue: client.company_website},
                        ]}/>
                    </Col>
                </Row>
                
                <Spacer hx={2}/>

                <Button data-cy="button_edit_client"
                    type={"primary"}
                    onClick={() => {
                        setEditClientModalOpen(true);
                    }}
                >
                    {t('buttons:editData')}
                </Button>
            </div>

            <ClientModal existingClientData={props.client}
						 modalOpen={editClientModalOpen}
						 setModalOpen={setEditClientModalOpen}
						 onDataChanged={() => props.onDataChanged()}
            />
        </>
    );
};
