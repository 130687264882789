import {Button, Col, Form, Input, Row, Select} from 'antd';
import { postApplicationAuthed } from 'api/applicationService';
import { refreshSession } from 'api/clientContactPersonService';
import {ClientTypes, CPPostApplication, CPPostClient} from 'api/types';
import styles from 'components/common/pages/application-flow/ApplicationFlow.module.scss';
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { BackButton } from 'components/common/presenters/back-button/BackButton';
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { Spacer } from 'components/common/presenters/spacer/Spacer';
import { goTo } from 'navigation/navigationHelpers';
import * as React from "react";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { RouteStrings } from 'Routes';
import { ApplicationFlowType } from 'storage/LocalAppDataManager';
import { LocalApplicationFlowDataManager } from "storage/LocalApplicationFlowDataManager";
import { CompanyLegalForms, CompanySizes } from 'types/types';
import { getPostCodeValidationPattern } from 'validators/inputs';
import {AnchoredSelect} from "../../../presenters/anchored-select/AnchoredSelect";
import {CountryCodes} from "../../../../../types/countries";

const { Option } = Select;
const { TextArea } = Input;

export const ApplicationStepCompanyInfoPage = () => {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [isFactoring, setIsFactoring] = React.useState(false);
    const [generalError, setGeneralError] = React.useState("");
    // used for validation feedback only
    const [state, setState] = useState({
        company_type: "",
        company_number_of_employees: "",
        address_post_code: "",
        address_city: "",
        address_country: "",
        address_street: "",
        address_house_number: "",
        company_purpose: "",
        purpose_info: "",
        largest_debitors_list: "",
        tax_id: "",
    });
   
    let linkTarget = RouteStrings.ApplicationFlowStepContactInfo;

    const persistData = (values) => {
        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();

        appl.company_type = values.company_type;
        appl.company_number_of_employees = values.company_number_of_employees;
        appl.company_post_code = values.address_post_code;
        appl.company_city = values.address_city;
        appl.company_country = values.address_country;
        appl.company_street = values.address_street;
        appl.company_house_number = values.address_house_number;
        appl.company_purpose = values.company_purpose;
        appl.purpose = values.purpose;
        appl.purpose_info = values.purpose_info;
        appl.tax_id = values.tax_id;
        appl.largest_debitors_list = values.largest_debitors_list;

        applDataManager.update(appl);
    };

    // Only called if the user is authed
    const handleContinue = async (contact_person_id: string) => {
        if (loading) {
            return;
        }

        await setLoading(true);

        try {
            const applDataManager = new LocalApplicationFlowDataManager();

            const appl = applDataManager.get();

            const newClient: CPPostClient = {
                client_type: ClientTypes.Client,
                contact_person_id: contact_person_id,
                company_name: appl.company_name,
                company_number_of_employees: appl.company_number_of_employees,
                company_legal_form: appl.company_type,
                address_post_code: appl.company_post_code,
                address_city: appl.company_city,
                address_country: appl.company_country,
                address_street: appl.company_street,
                address_house_number: appl.company_house_number,
            };

            const newAppl: CPPostApplication = {
                type: appl.type,
                currency: appl.currency,
                amount: appl.amount,
                duration: appl.duration,
                company_purpose: appl.company_purpose,
                purpose_info: appl.purpose_info,
                largest_debitors_list: appl.largest_debitors_list,
                tax_id: appl.tax_id,
            };

            const createApplResp = await postApplicationAuthed({
                client: newClient,
                application: newAppl
            });

            if (createApplResp && createApplResp.status == 200) {
                appl.id = createApplResp.data;
                applDataManager.update(appl);
            } else {
                appl.id = null;
            }

            goTo(RouteStrings.ApplicationFlowStepResult);
        } catch (err) {
            console.error(err);

            setGeneralError(t("errors:generalError"));
        }

        setLoading(false);
    };


    const handleOnFinish = async (values: any) => {
        persistData(values);

        // check if user's logged in and if true, skip the account registration step
        try {
            const refreshSessionReq = await refreshSession(true);

            if (refreshSessionReq?.status === 200) {
                // Create a client - the backend will get the contact_person_id automatically, then create an application
                await handleContinue(refreshSessionReq.data);
                return;
            }

        } catch (error) {
            if (error.response?.status === 403) {
                console.error("Not logged in");
            }
        }

        goTo(linkTarget);
    };

    React.useEffect(() => {
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();

        if (appl == null) {
            return;
        }

        if (appl.type === ApplicationFlowType.Factoring_Onboarding) {
            setIsFactoring(true);
        }

        form.setFieldsValue({
            company_type: appl.company_type,
            company_number_of_employees: appl.company_number_of_employees,
            address_post_code: appl.company_post_code,
            address_city: appl.company_city,
            address_country: appl.company_country,
            address_street: appl.company_street,
            address_house_number: appl.company_house_number,
            company_purpose: appl.company_purpose,
            purpose_info: appl.purpose_info,
            largest_debitors_list: appl.largest_debitors_list,
            tax_id: appl.tax_id,
        });

    }, []);

    const renderLegalForms = () => {
        const lfArr = [];
        
        for (let i = 0; i < CompanyLegalForms.length; i++) {
            const lf = CompanyLegalForms[i];
            lfArr.push(<Option key={lf} value={encodeURIComponent(lf)}>{lf}</Option>);
        }

        return lfArr;
    };

    const companySizeOptions = () => {
        return CompanySizes.map((val, i) => {
            return <Select.Option data-cy={`company_number_of_employees_option_${i}`} key={i}
                value={val}>{val}</Select.Option>;
        });
    };

    const getBackRoute = () => {
        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();

        if (appl.type === ApplicationFlowType.Loan) {
            return RouteStrings.ApplicationFlowStepLoan;
        }

        return RouteStrings.ApplicationFlowStepTwo;
    };

    const addressCountryOptions = () => {
        return CountryCodes.map((val, i) => {
            return (
                <Select.Option data-cy={`address_country_option_${i}`} key={i} value={val}>
                    {t(`countryCodes:${val}`)}
                </Select.Option>
            );
        });
    };

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus/>
            <CustomPageHeader />
            <div className={styles.container}>
                <BackButton route={getBackRoute()} />

                <h2 className={styles.processTitle}>{t("applProcess:titleStepCompany")}</h2>

                <div className={styles.innerContainer}>

                    <Form
                        layout={"vertical"}
                        form={form}
                        onFinish={handleOnFinish}
                        scrollToFirstError
                    >
                        <Form.Item name="company_type" label={t("company_legal_form")} hasFeedback
                            rules={[{required: true, message: t('formErrors:pleaseSelectOption')}]}>
                            <Select data-cy="legal_form"
                                className={styles.select}
                                placeholder={t('pleaseSelectOption')}
                                allowClear
                            >
                                {
                                    renderLegalForms()
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name="company_number_of_employees" label={t('number_of_employees')} hasFeedback
                            rules={[{required: true, message: t('formErrors:pleaseSelectOption')}]}>
                            <Select data-cy="number_of_employees"
                                className={styles.select}
                                placeholder={t('pleaseSelectOption')}
                                allowClear
                            >
                                {
                                    companySizeOptions()
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name={"address_country"} label={t('country')} hasFeedback
                            rules={[{ required: true}]}>
                            <AnchoredSelect data-cy="select_company_country">
                                {addressCountryOptions()}
                            </AnchoredSelect>
                        </Form.Item>

                        <Row className={styles.rowDoubleCol} gutter={12}>
                            <Col className={styles.colMobile} span={16}>
                                <Form.Item name={"address_street"} label={t('street')} 
                                    hasFeedback={state.address_street?.length >= 2}
                                    rules={[{ required: true, message: t("errors:invalidData"), min: 2, max: 100 }]}>
                                    <Input data-cy="input_company_street"
                                        onChange={(e) => setState({
                                            ...state,
                                            address_street: e.target.value
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={styles.colMobile} span={8}>
                                <Form.Item name={"address_house_number"} label={t('houseNumber')}
                                    hasFeedback={state.address_house_number.length >= 1}
                                    rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                    <Input data-cy="input_company_house_number" className={styles.shortInput}
                                        onChange={(e) => setState({
                                            ...state,
                                            address_house_number: e.target.value
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className={styles.rowDoubleCol} gutter={12}>
                            <Col className={styles.colMobile} span={8}>
                                <Form.Item name={"address_post_code"} label={t('postCode')}
                                    hasFeedback={state.address_post_code.length >= 2}
                                    rules={[{required: true, message: t('formErrors:invalidPostCode'), pattern: getPostCodeValidationPattern()}]}>
                                    <Input data-cy="post_code" className={styles.shortInput} placeholder={t("example") + " 10722"}
                                        onChange={(e) => setState({
                                            ...state,
                                            address_post_code: e.target.value
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={styles.colMobile} span={16}>
                                <Form.Item name={"address_city"} label={t('city2')}
                                    hasFeedback={state.address_city.length >= 2}
                                    rules={[{ required: true, message: t("errors:invalidData"), min: 2, max: 100 }]}>
                                    <Input data-cy="city"
                                        onChange={(e) => setState({
                                            ...state,
                                            address_city: e.target.value
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item name="company_purpose" label={t("companyDesc")}
                            hasFeedback={state.company_purpose.length >= 5}
                            rules={[{ required: true, message: t("errors:invalidData"), min: 5, max: 100 }]}>
                            <Input data-cy="company_purpose"
                                className={styles.input}
                                placeholder={t("example") + " Herstellung von Maschinen"}
                                onChange={(e) => setState({
                                    ...state,
                                    company_purpose: e.target.value
                                })}
                            />
                        </Form.Item>

                        {
                            isFactoring ?
                                <Form.Item name="largest_debitors_list" label={t("largestDebitors")}
                                    hasFeedback={state.largest_debitors_list.length >= 10}
                                    rules={[{ required: true, message: t("errors:invalidData"), min: 10, max: 500 }]}>
                                    <TextArea data-cy="largest_debitors_list" rows={4} className={styles.input}
                                        placeholder={t("largestDebitorsPlaceholder")}
                                        maxLength={500}
                                        onChange={(e) => setState({
                                            ...state,
                                            largest_debitors_list: e.target.value
                                        })}
                                    />
                                </Form.Item>
                                :
                                <Form.Item name="purpose_info" label={t("detailedPurpose")}
                                    hasFeedback={state.purpose_info.length >= 5}
                                    rules={[{ required: true, message: t("errors:invalidData"), min: 5, max: 250 }]}>
                                    <TextArea data-cy="loan_purpose_detailed" rows={4} className={styles.input}
                                        placeholder={t("detailedPurposePlaceholder")}
                                        maxLength={200}
                                        onChange={(e) => setState({
                                            ...state,
                                            purpose_info: e.target.value
                                        })}
                                    />
                                </Form.Item>
                        }

                        <Form.Item name="tax_id" label={t("taxIdNumber")} hasFeedback={state.tax_id.length >= 4}
                            rules={[{ required: false, message: t("errors:invalidData"), min: 4, max: 50 }]}>
                            <Input data-cy="tax_number" className={styles.input}
                                placeholder={t("example") + " 1234567890"}
                                maxLength={200}
                                onChange={(e) => setState({
                                    ...state,
                                    tax_id: e.target.value
                                })}
                            />
                        </Form.Item>

                        { generalError ?
                            <>
                                <div className={styles.error}>{generalError}</div>
                                <Spacer/>
                            </>
                            :
                            null
                        }
                        
                        <Spacer/>
                        <Spacer/>
                        <Spacer/>

                        <Form.Item hasFeedback>
                            <Row className={styles.rowCenterX}>
                                <Button data-cy="button_continue"
                                    type="primary"
                                    htmlType="submit">
                                    {t("buttons:continue")}
                                </Button>
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};
