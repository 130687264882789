import {Alert, Button, Col, Input, message, Row, Select} from 'antd';
import styles from 'components/admin/pages/application-drilldown/ApplicationDrilldownPage.module.scss';
import * as React from "react";
import {useState} from "react";
import {useTranslation} from 'react-i18next';
import {ApplicationFlowType} from "storage/LocalAppDataManager";
import {Application, ApplicationStates} from "types/types";
import {isIbanValid} from "validators/iban-validators";
import {adminGetBOUsers} from "../../../../../api/boUserService";
import {GetBoUsersListItem} from "../../../../../api/types";
import {putApplicationAssigneeId} from "../../../../../api/applicationService";
import {SectionTitle} from "../../../../common/presenters/section-title/SectionTitle";
import {Spacer} from 'components/common/presenters/spacer/Spacer';
import moment from "moment";
import {DATE_FORMAT} from "../../../../../api/apiConfig";

export interface ApplicationBankInfo {
    bank_name: string
    iban: string
}

export interface CompanyTabProps {
    isInvoice?: boolean
    application: Application
    onBankInfoSave: (info: ApplicationBankInfo) => void
}

export const CompanyTab = (props: CompanyTabProps) => {
    const {t} = useTranslation();

    const [assigneeId, setAssigneeId] = useState(null as string);

    const [companyTypeSelectorEditable, setCompanyTypeSelectorEditable] = React.useState(true);
    const [bankInfoEditable, setBankInfoEditable] = React.useState(props.application.bank_name && props.application.bank_name.length > 0);
    const [boUsers, setBOUsers] = useState([] as GetBoUsersListItem[]);

    const [bankInfo, setBankInfo] = useState({
        bank_name: props.application.bank_name,
        iban: props.application.iban,
        ibanError: ""
    });

    // const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

    const fetchSveaContactNames = async () => {
        try {
            const result = await adminGetBOUsers();
            setBOUsers(result.data?.filter(bo => !bo.is_deleted));
        } catch (e) {
            console.error(e);
            setBOUsers([]);
        }
    };

    React.useEffect(() => {
        fetchSveaContactNames();
        setAssigneeId(props.application.bo_assignee_id ? props.application.bo_assignee_id : null);
        setBankInfo({
            bank_name: props.application.bank_name,
            iban: props.application.iban,
            ibanError: ""
        });
    }, [props]);

    const boUsersOptions = () => {
        return boUsers.map((val, i) => {
            return (
                <Select.Option data-cy={`bo_contact_name_option_${i}`} key={i}
                    value={val.bo_user_id}>{val.bo_user_name}
                </Select.Option>
            );
        });
    };

    const handleSaveAssigneeName = async () => {
        try {
            await putApplicationAssigneeId(props.application.id, assigneeId);
            message.success(t('messages:dataSaved'), 2);
        } catch (e) {
            console.error(e);
            message.error(t('messages:couldNotSave'), 2);
        }
    };

    return (
        <div className={styles.tabXL}>
            <Spacer hx={2}/>

            {
                props.application.status === ApplicationStates.Archived ?
                    <Alert type="error"
                        closable={false}
                        message={t('archivedBanner', {
                            entityType: "Antrag",
                            archivedAt: props.application.archive_initiated_at ? moment(props.application.archive_initiated_at).format(DATE_FORMAT.DE) : "-"
                        })}
                    /> : null
            }

            <Spacer hx={2}/>

            <div className={styles.container}>
                <SectionTitle text={t('owner')}/>
                <Select disabled={!companyTypeSelectorEditable}
                    value={assigneeId}
                    onChange={(e) => {
                        setAssigneeId(e);
                    }}
                >
                    {
                        [
                            <Select.Option data-cy={`bo_contact_name_option_unassigned`} key="unassigned"
                                value={null}>{t("unassigned")}</Select.Option>,
                            ...boUsersOptions(),
                        ]
                    }
                </Select>
                <Spacer/>

                {
                    companyTypeSelectorEditable ?
                        <Button className={styles.button} type="primary" size="large"
                            onClick={() => {
                                handleSaveAssigneeName();
                                setCompanyTypeSelectorEditable(!companyTypeSelectorEditable);
                            }}>
                            {t("buttons:save")}
                        </Button>
                        :
                        <Button className={styles.button} type="primary" size="large"
                            onClick={() => {
                                setCompanyTypeSelectorEditable(!companyTypeSelectorEditable);
                            }}>
                            {t("buttons:edit")}
                        </Button>
                }
            </div>

            <Spacer hx={3}/>

            {
                props.application.type === ApplicationFlowType.Loan ?
                    <>
                        <SectionTitle text={t("applInfo")}/>
                        <Row className={styles.row}>
                            <Col span={6}><b>{t("loanAmount")}:</b></Col>
                            <Col span={12}>{props.application.currency} {props.application.amount} </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col span={6}><b>{t("loanDuration")}:</b></Col>
                            <Col span={12}>{props.application.duration} {t("months")}</Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col span={6}><b>{t("loanPurpose")}:</b></Col>
                            <Col span={12}>{props.application.purpose}</Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col span={6}><b>{t("detailedPurposeNoHint")}:</b></Col>
                            <Col span={12}>{props.application.purpose_info}</Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col span={6}><b>{t("applicationSource")}</b></Col>
                            <Col span={12}>{props.application.source ? props.application.source : "-"}</Col>
                        </Row>

                        <Spacer/>
                        <Spacer/>
                        <Spacer/>
                    </>
                    :
                    null
            }

            <SectionTitle text={t("companyData")}/>
            <Row className={styles.row}>
                <Col span={6}><b>{t("company")}:</b></Col>
                <Col span={12}>{props.application.company_name}</Col>
            </Row>
            <Row className={styles.row}>
                <Col span={6}><b>{t("foundationDate")}:</b></Col>
                <Col span={12}>01.01.2010</Col>
            </Row>
            <Row className={styles.row}>
                <Col span={6}><b>{t("hrNumber")}:</b></Col>
                <Col span={12}>-</Col>
            </Row>
            <Row className={styles.row}>
                <Col span={6}><b>{t("legalForm")}:</b></Col>
                <Col span={12}>{props.application.company_type}</Col>
            </Row>
            <Row className={styles.row}>
                <Col span={6}><b>{t("country")}:</b></Col>
                <Col span={12}>{props.application.company_country}</Col>
            </Row>
            <Row className={styles.row}>
                <Col span={6}><b>{t("postCode")}:</b></Col>
                <Col span={12}>{props.application.company_post_code}</Col>
            </Row>
            <Row className={styles.row}>
                <Col span={6}><b>{t("companyDesc")}:</b></Col>
                <Col span={12}>{props.application.company_purpose}</Col>
            </Row>
            {
                props.application.type === ApplicationFlowType.Factoring_Onboarding ?
                    <Row className={styles.row}>
                        <Col span={6}><b>{t("largestDebitors")}:</b></Col>
                        <Col span={12}>{props.application.largest_debitors_list}</Col>
                    </Row>
                    :
                    null
            }
            <Row className={styles.row}>
                <Col span={6}><b>{t("numberOfEmployees")}:</b></Col>
                <Col span={12}>-</Col>
            </Row>

            <Spacer hx={3}/>

            <SectionTitle text={t("bankInfo")}/>
            <div className={styles.container}>
                <label>{t("bankName")}:</label>
                <Input className={styles.input} placeholder="" maxLength={150} disabled={!bankInfoEditable}
                    value={bankInfo.bank_name}
                    onChange={(e) => {
                        setBankInfo({
                            ...bankInfo,
                            bank_name: e.target.value
                        });
                    }}/>
                <Spacer/>
                <label>{t("iban")}:</label>
                <Input className={styles.input} placeholder="" maxLength={34} disabled={!bankInfoEditable}
                    value={bankInfo.iban}
                    onChange={(e) => {
                        setBankInfo({
                            ...bankInfo,
                            iban: e.target.value
                        });
                    }}/>
                <div className={styles.error}>{bankInfo.ibanError}</div>
                <Spacer/>
                {
                    bankInfoEditable ?
                        <Button className={styles.button} type="primary" size="large"
                            onClick={() => {
                                if (isIbanValid(bankInfo.iban)) {
                                    props.onBankInfoSave(bankInfo);

                                    setBankInfo({
                                        ...bankInfo,
                                        ibanError: ""
                                    });

                                    setBankInfoEditable(!bankInfoEditable);
                                } else {
                                    setBankInfo({
                                        ...bankInfo,
                                        ibanError: "Please enter a valid IBAN"
                                    });
                                }
                            }}>
                            {t("buttons:save")}
                        </Button>
                        :
                        <Button className={styles.button} type="primary" size="large"
                            onClick={() => {
                                setBankInfoEditable(!bankInfoEditable);
                            }}>
                            {t("buttons:edit")}
                        </Button>
                }
            </div>

            <Spacer hx={3}/>
        </div>
    );
};
