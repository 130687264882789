import { Input, Modal } from "antd";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useState } from "react";
import { ApplicationFlowType } from "../../../../../../storage/LocalAppDataManager";
import { P } from "../../../../../common/presenters/paragraph/P";
import styles from "../../../../pages/application-drilldown/ApplicationDrilldownPage.module.scss";

export interface SubmitSignatureModalProps {
    modalOpen: boolean
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    modal2Open: boolean
    setModal2Open: React.Dispatch<React.SetStateAction<boolean>>
    applicationFlowType: ApplicationFlowType
    onOk: (data: {
        amount: string
        duration: string
        interest_rate: string
        required_signature: string
    }) => void
}

export const SubmitToSignatureModal = (props: SubmitSignatureModalProps) => {
    let initInterestRate = "";

    const [loanAmount, setLoanAmount] = useState("");
    const [loanDuration, setLoanDuration] = useState("");
    const [interestRate, setInterestRate] = useState(initInterestRate);
    const [requiredSignature, setRequiredSignature] = useState("");

    return (
        <>
            <Modal title="Enter loan details"
                visible={props.modalOpen}
                onOk={() => {
                    props.setModalOpen(false);
                    props.setModal2Open(true);
                }}
                onCancel={() => {
                    props.setModalOpen(false); 
                }}
            >
                <P>Please enter the loan details, so the application state can be changed to Signature</P>
                <Spacer/>
                <label>Loan Amount:</label>
                <Input className={styles.input} placeholder="Example: €150,000" maxLength={50}
                    onChange={(e) => {
                        setLoanAmount(e.target.value);
                    }} />
                <Spacer/>
                <label>Loan Duration:</label>
                <Input className={styles.input} placeholder="Example: 60" maxLength={3}
                    onChange={(e) => {
                        setLoanDuration(e.target.value);
                    }} />
                <Spacer/>
                <label>Interest Rate %:</label>
                <Input className={styles.input} placeholder="Example: 3" maxLength={4}
                    onChange={(e) => {
                        setInterestRate(e.target.value);
                    }} />
                <Spacer/>
                <label>Required Signature:</label>
                <Input className={styles.input} placeholder="" maxLength={50}
                    onChange={(e) => {
                        setRequiredSignature(e.target.value);
                    }} />
                <Spacer/>
            </Modal>

            <Modal title={"Confirm change to Signature/KYC state."}
                visible={props.modal2Open}
                onOk={() => {
                    props.onOk({
                        amount: loanAmount,
                        duration: loanDuration,
                        interest_rate: interestRate,
                        required_signature: requiredSignature,
                    });
                    props.setModal2Open(false);
                }}
                onCancel={() => {
                    props.setModal2Open(false);
                }}>
                <P>Do you confirm you want to offer user "Test Firma GmbH" a loan of €{loanAmount} with
					a duration of {loanDuration} months
					and an interest rate {interestRate}%?</P>
                <Spacer/>
            </Modal>
        </>
    );
};
