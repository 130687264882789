import * as React from "react";
import {useEffect, useState} from "react";
import {Checkbox, Col, Form, FormInstance, Radio, Row, Space, Typography} from "antd";
import {GetClientResponse} from "../../../../../api/types";
import {useTranslation} from "react-i18next";
import {LocalizedNumberInput} from "../../../../common/presenters/localized-number-input/LocalizedNumberInput";
import {Spacer} from "../../../../common/presenters/spacer/Spacer";

export interface FactoringContractInputsProps {
    contract_id?: string
    client: GetClientResponse
    form: FormInstance
}

export const FactoringContractInputs = (props: FactoringContractInputsProps) => {
    const {t} = useTranslation();

    const [isFactoring30, setIsFactoring30] = useState(false);
    const [isFactoring45, setIsFactoring45] = useState(false);
    const [isFactoring60, setIsFactoring60] = useState(false);
    const [isFactoring90, setIsFactoring90] = useState(false);
    const [isFactoring120, setIsFactoring120] = useState(false);
    const [isMinFactoring, setIsMinFactoring] = useState(false);

    useEffect(() => {
        setIsFactoring30(!!props.form.getFieldValue("factoring_fee_30"));
        setIsFactoring45(!!props.form.getFieldValue("factoring_fee_45"));
        setIsFactoring60(!!props.form.getFieldValue("factoring_fee_60"));
        setIsFactoring90(!!props.form.getFieldValue("factoring_fee_90"));
        setIsFactoring120(!!props.form.getFieldValue("factoring_fee_120"));
        setIsMinFactoring(!!props.form.getFieldValue("min_factoring_fee"));
    }, [props.contract_id]);

    const currencySuffix = (): string => {
        switch (props.client.address_country) {
        case "de":
            return "EUR";
        case "ch":
            return "CHF";
        default:
            return "";
        }
    };

    return (
        <>
            <Typography.Text strong>{t('factoringContract')}</Typography.Text>
            <Spacer/>

            <Form.Item name={"expected_volume"} label={t("expectedFactoringVolume")}>
                <LocalizedNumberInput data-cy="input_expected_volume"
                    precision={2}
                    suffix={currencySuffix()}
                />
            </Form.Item>

            <Space>
                <Checkbox checked={isFactoring30}
                    onChange={(e) => {
                        const isChecked = e.target.checked;
                        if(isChecked){
                            setIsFactoring30(true);
                        } else {
                            props.form.setFieldsValue({factoring_fee_30: undefined});
                            setIsFactoring30(false);
                        }
                    }}
                >
                    {"30 " + t("days")}
                </Checkbox>
                <Form.Item name={"factoring_fee_30"}>
                    <LocalizedNumberInput data-cy="input_factoring_fee_30"
                        disabled={!isFactoring30}
                        precision={2}
                        suffix={"%"}
                    />
                </Form.Item>
            </Space>
            <Spacer/>

            <Space>
                <Checkbox checked={isFactoring45}
                    onChange={(e) => {
                        const isChecked = e.target.checked;
                        if(isChecked){
                            setIsFactoring45(true);
                        } else {
                            props.form.setFieldsValue({factoring_fee_45: undefined});
                            setIsFactoring45(false);
                        }
                    }}
                >
                    {"45 " + t("days")}
                </Checkbox>
                <Form.Item name={"factoring_fee_45"}>
                    <LocalizedNumberInput data-cy="input_factoring_fee_45"
                        disabled={!isFactoring45}
                        precision={2}
                        suffix={"%"}
                    />
                </Form.Item>
            </Space>
            <Spacer/>

            <Space>
                <Checkbox checked={isFactoring60}
                    onChange={(e) => {
                        const isChecked = e.target.checked;
                        if(isChecked){
                            setIsFactoring60(true);
                        } else {
                            props.form.setFieldsValue({factoring_fee_60: undefined});
                            setIsFactoring60(false);
                        }
                    }}
                >
                    {"60 " + t("days")}
                </Checkbox>
                <Form.Item name={"factoring_fee_60"}>
                    <LocalizedNumberInput data-cy="input_factoring_fee_60"
                        disabled={!isFactoring60}
                        precision={2}
                        suffix={"%"}
                    />
                </Form.Item>
            </Space>
            <Spacer/>

            <Space>
                <Checkbox checked={isFactoring90}
                    onChange={(e) => {
                        const isChecked = e.target.checked;
                        if(isChecked){
                            setIsFactoring90(true);
                        } else {
                            props.form.setFieldsValue({factoring_fee_90: undefined});
                            setIsFactoring90(false);
                        }
                    }}
                >
                    {"90 " + t("days")}
                </Checkbox>
                <Form.Item name={"factoring_fee_90"}>
                    <LocalizedNumberInput data-cy="input_factoring_fee_90"
                        disabled={!isFactoring90}
                        precision={2}
                        suffix={"%"}
                    />
                </Form.Item>
            </Space>
            <Spacer/>

            <Space>
                <Checkbox checked={isFactoring120}
                    onChange={(e) => {
                        const isChecked = e.target.checked;
                        if(isChecked){
                            setIsFactoring120(true);
                        } else {
                            props.form.setFieldsValue({factoring_fee_120: undefined});
                            setIsFactoring120(false);
                        }
                    }}
                >
                    {"120 " + t("days")}
                </Checkbox>
                <Form.Item name={"factoring_fee_120"}>
                    <LocalizedNumberInput data-cy="input_factoring_fee_120"
                        disabled={!isFactoring120}
                        precision={2}
                        suffix={"%"}
                    />
                </Form.Item>
            </Space>
            <Spacer/>

            <Space>
                <Checkbox checked={isMinFactoring}
                    onChange={(e) => {
                        setIsMinFactoring(e.target.checked);
                        props.form.setFieldsValue({min_factoring_fee: null});
                    }}
                >
                    {t("minFactoringFee")}</Checkbox>
                <Form.Item name={"min_factoring_fee"}>
                    <LocalizedNumberInput data-cy="input_min_factoring_fee"
                        disabled={!isMinFactoring}
                        precision={2}
                        suffix={"%"}
                    />
                </Form.Item>
            </Space>

            <Row gutter={24}>
                <Col>
                    <Form.Item name={"admin_fee"} label={t("adminFee")}>
                        <LocalizedNumberInput data-cy="input_admin_fee"
                            precision={2}
                            suffix={currencySuffix()}
                        />
                    </Form.Item>

                    <Form.Item name={"max_invoice_payout"} label={t("maxInvoicePayout")}>
                        <LocalizedNumberInput data-cy="input_max_invoice_payout"
                            precision={2}
                            suffix={"%"}
                        />
                    </Form.Item>

                    <Form.Item name={"max_advance_payout"} label={t("maxAdvancePayout")}>
                        <LocalizedNumberInput data-cy="input_max_advance_payout"
                            precision={2}
                            suffix={currencySuffix()}
                        />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item name={"debitor_limit_fee_amount"} label={t("debitorLimitFeeAmount")}>
                        <LocalizedNumberInput data-cy="input_debitor_limit_fee_amount"
                            precision={2}
                            suffix={currencySuffix()}
                        />
                    </Form.Item>
                </Col>
            </Row>

            {
                props.client.address_country.toLowerCase() === "de" ?
                    <>
                        <Form.Item name={"is_global_session"} valuePropName="checked">
                            <Checkbox data-cy="check_global_session">{t("isGlobalSession")}</Checkbox>
                        </Form.Item>
                        <Spacer/>

                        <Typography.Text strong>{t('guaranteeLetter')}</Typography.Text>
                        <Spacer/>
                        <Form.Item name={"liability_limit"} label={t("liabilityLimit")}>
                            <LocalizedNumberInput data-cy="input_liability_limit"
                                precision={2}
                                suffix={currencySuffix()}
                            />
                        </Form.Item>
                    </>
                    : null
            }

            <Form.Item name={"is_standard_general_assignment"} label={t("generalAssignment")}>
                <Radio.Group>
                    <Radio data-cy="radio_general_assignment_standard"
                        value={true}>{t('generalAssignmentStandard')}</Radio>
                    <Radio data-cy="radio_general_assignment_special"
                        value={false}>{t('generalAssignmentSpecial')}</Radio>
                </Radio.Group>
            </Form.Item>


            {
                props.client.address_country.toLowerCase() === "ch" ?
                    <Form.Item name={"is_standard_negative_declaration"} label={t("negativeDeclaration")}>
                        <Radio.Group>
                            <Radio data-cy="radio_negative_declaration_standard"
                                value={true}>{t('negativeDeclarationStandard')}</Radio>
                            <Radio data-cy="radio_negative_declaration_special"
                                value={false}>{t('negativeDeclarationSpecial')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    : null
            }
        </>
    );
};
