import { Tabs } from 'antd';
import * as React from "react";
import { useState } from "react";
import "./TabMenu.scss";
const { TabPane } = Tabs;

export interface TabMenuProps {
    className?: string
    defaultActiveTabIndex?: string
    titles: string[] // titles of the tabs
    content: JSX.Element[]
}

export const TabMenu = (props: TabMenuProps) => {
    let defaultActiveTabIndex = "0";
	
    if (props.defaultActiveTabIndex != null && props.defaultActiveTabIndex !== "0") {
        defaultActiveTabIndex = props.defaultActiveTabIndex;
    }

    const [ activeTab, setActiveTab ] = useState(defaultActiveTabIndex);

    const onTabChange = (key) => {
        setActiveTab(key);
    };

    const renderTabs = () => {
        let tabs = [];

        for (let i = 0; i < props.titles.length; i++) {
            tabs.push(
                <TabPane tab={props.titles[i]} key={`${i}`}>
                    {props.content[i]}
                </TabPane>
            );
        }

        return tabs;
    };

    return (
        <div className="tab-menu">
            <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={onTabChange}>
                { renderTabs() }
            </Tabs>
        </div>
    );
};
