import * as React from "react";
import {useEffect, useState} from "react";
import {DocumentSectionActor, DocumentsSection} from "../../../../common/presenters/documents-section/DocumentsSection";
import {DocumentType} from "../../../../../types/types";
import {GetDocumentsListResponse} from "../../../../../api/types";
import {adminGetApplicationDocuments} from "../../../../../api/document-service/adminOnApplicationDocuments";

export interface DocumentsTabProps {
    applicationId: string
}

export const DocumentsTab = (props: DocumentsTabProps) => {
    const [docs, setDocs] = useState([] as GetDocumentsListResponse[]);

    const fetchApplicationDocuments = async () => {
        try {
            const result = await adminGetApplicationDocuments(props.applicationId);
            setDocs(result.data);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchApplicationDocuments();
    }, [props.applicationId]);

    return (
        <DocumentsSection actor={DocumentSectionActor.Admin}
            entityType={DocumentType.Application}
            entityId={props.applicationId}
            documents={docs}
            onDataChanged={() => fetchApplicationDocuments()}
        />
    );
};
