import {Button, Col, message, Modal, Row, Typography} from "antd";
import * as React from "react";
import {useState} from "react";
import {useTranslation} from 'react-i18next';
import {GetClientResponse} from "../../../../../api/types";
import {DataItemsColumn} from "../../../../common/presenters/data-items-column/DataItemsColumn";
import {ClientModal} from "../../../presenters/client-modal/ClientModal";
import styles from '../ClientDrilldownPage.module.scss';
import {SectionTitle} from "../../../../common/presenters/section-title/SectionTitle";
import {adminCreateApplicationForClient, adminGetLastApplicationOfClient} from "../../../../../api/applicationService";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import {Application} from "../../../../../types/types";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {currencyLocaleFormatter} from "../../../../../formatters/currencyFormatters";
import {goTo} from "../../../../../navigation/navigationHelpers";
import {RouteStrings} from "../../../../../Routes";

interface ClientInfoSectionProps {
    client: GetClientResponse
    onDataChanged: () => void
}

export const ClientStatusSection = (props: ClientInfoSectionProps) => {
    const {t} = useTranslation();
    const client = props.client;

    const [editClientModalOpen, setEditClientModalOpen] = useState(false);

    const handleCreateApplication = async () => {
        try {
            const newApplId: string = (await adminCreateApplicationForClient(props.client.client_id)).data;
            props.onDataChanged();
            message.success(t('messages:dataSaved'), 2);

            goTo(RouteStrings.AdminApplicationDrilldown.replace(":applicationId", newApplId));
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    const confirmCreateApplication = async () => {
        if (!props.client.has_application) {
            handleCreateApplication();
            return;
        }

        let lastApplication: Application;
        try {
            lastApplication  = (await adminGetLastApplicationOfClient(props.client.client_id)).data;
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
            return;
        }

        Modal.confirm({
            width: 800,
            title: t('modals:clientAlreadyHasApplication'),
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <Typography.Text>{t('createApplicationConfirm1')}</Typography.Text>
                    <Spacer hx={2}/>

                    <DataItemsColumn dataItems={[
                        {itemName: t('product'), itemValue: lastApplication.type ? t(`financingTypes:${lastApplication.type}`) : "-"},
                        {itemName: t('amount'), itemValue: (lastApplication.currency && lastApplication.amount) ? `${t(`currencySymbols:${lastApplication.currency}`)} ${currencyLocaleFormatter(lastApplication.amount)}` : "-"},
                        {itemName: t('loanDuration'), itemValue: lastApplication.duration ? `${lastApplication.duration} ${t('months')}` : "-"},
                    ]}/>
                    <Spacer/>

                    <Typography.Text>{t('createApplicationConfirm2')}</Typography.Text>
                </>
            ),
            okText: t('buttons:createApplication'),
            cancelText: t('buttons:back'),
            onOk: handleCreateApplication,
        });
    };

    return (
        <>
            <SectionTitle text={t('sectionTitles:status')}/>

            <div className={styles.container}>
                <Row gutter={36}>
                    <Col>
                        <DataItemsColumn dataItems={[
                            {itemName: "Account created", itemValue: client.is_account_created ? "TRUE" : "FALSE"},
                            {itemName: "Has application", itemValue: client.has_application ? "TRUE" : "FALSE"},
                            {itemName: "Last Application Update", itemValue: "-"}, // TODO application_events table
                            {itemName: "Risk Scoring Date", itemValue: "-"}, // TODO
                        ]}/>
                    </Col>
                    <Col>
                        <Button data-cy="button_resend_email"
                            type={"primary"}
                            onClick={() => {
                            }}
                        >
                            {t('buttons:resendEmail')}
                        </Button>

                        <Spacer hx={2} />

                        <Button data-cy="button_create_application"
                            type={"primary"}
                            onClick={confirmCreateApplication}
                        >
                            {t('buttons:createApplication')}
                        </Button>
                    </Col>
                </Row>

                <Spacer />

                <Button data-cy="button_edit_client"
                    type={"primary"}
                    onClick={() => {
                        setEditClientModalOpen(true);
                    }}
                >
                    {t('buttons:editData')}
                </Button>
            </div>

            <ClientModal existingClientData={props.client}
                modalOpen={editClientModalOpen}
                setModalOpen={setEditClientModalOpen}
                onDataChanged={() => props.onDataChanged()}
            />
        </>
    );
};
