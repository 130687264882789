import { SideMenu } from "components/common/presenters/side-menu/SideMenu";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { getDummyPdfUrl } from "storage/genericHelpers";
import styles from 'components/admin/pages/my-contracts/MyContractsPage.module.scss';
import { Spacer } from "components/common/presenters/spacer/Spacer";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const MyContractsPage = () => {
    const { t } = useTranslation();

    // let { applicationId } = useParams();

    // const [applicationState, setApplicationState] = useState(null);

    // const onDocumentLoadSuccess = () => {

    // };

    // const removeTextLayerOffset = () => {
    //     const textLayers = document.querySelectorAll(".react-pdf__Page__textContent");
    //     textLayers.forEach(layer => {
    //         const { style } = layer as any;
    //         style.top = "0";
    //         style.left = "0";
    //         style.transform = "";
    //     });
    // };

    // const styledComponent = StyleSheet.create({
    //     page: {
    //         flexDirection: 'row',
    //         backgroundColor: '#E4E4E4'
    //     },
    //     section: {
    //         margin: 10,
    //         padding: 10,
    //         flexGrow: 1
    //     }
    // });

    return (
        <div className={styles.page}>
            <SideMenu isLoggedIn={true}/>

            <div className={styles.sectionRight}>
                <h2>{t("userMyContractTitle")}</h2>
                <Spacer hx={3}/>

                {/* <Button className={styles.button}
					type="primary"
					size="large"
					disabled={false}
					onClick={() => { downloadDummyPdf(); }}>
					{t("buttons:downloadPdf")}
				</Button>
				<Spacer/>
				<Spacer/> */}

                <iframe title="Contact" style={{ width: "800px", height: "1024px"}} src={getDummyPdfUrl()}></iframe>
                {/* <PDFViewer>
					<Document>
						<Page size="A4" style={styledComponent}>
							<View style={styledComponent}>
								<Text>Section #1</Text>
							</View>
							<View style={styledComponent}>
								<Text>Section #2</Text>
							</View>
						</Page>
					</Document>
				</PDFViewer> */}

                {/* <Document
					key="doc"
					file={getDummyPdfUrl()}
					onLoadSuccess={onDocumentLoadSuccess}
				>
					<Page className={styles.pageComponent} pageNumber={1} onLoadSuccess={removeTextLayerOffset} />
				</Document> */}
            </div>
        </div>
    );
};
