import * as React from "react";
import {v4 as uuidv4} from 'uuid';
import {Select} from "antd";

export const AnchoredSelect = (props) => {
    const {children, ...restProps} = props;
    const containerId = uuidv4();

    return (
        <div style={{position: "relative"}} id={containerId}>
            <Select getPopupContainer={() => document.getElementById(containerId)}
                {...restProps}
            >
                {children}
            </Select>
        </div>
    );
};
