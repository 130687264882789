import * as React from "react";
import styles from './UserApplicationDrilldownPage.module.scss';
import {SectionTitle} from "../../presenters/section-title/SectionTitle";
import {Space, Typography} from "antd";
import {MailOutlined, PhoneOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export interface InReviewSectionProps {

}

export const InReviewSection = (props: InReviewSectionProps) => {
    const {t} = useTranslation();

    return (
        <div className={styles.card}>
            <SectionTitle text={t('sectionTitles:applicationInReview')}/>
            <Typography.Text className={styles.nextStepsText}>
                {t('cpApplicationInReview')}
            </Typography.Text>
            <Space>
                <PhoneOutlined/>
                <Typography.Text strong>{t('sveaContactPhoneNumber')}</Typography.Text>
            </Space>
            <Space>
                <MailOutlined/>
                <Typography.Text strong>{t('sveaContactEmail')}</Typography.Text>
            </Space>
        </div>
    );
};
