import * as React from "react";
import {useEffect, useState} from "react";
import styles from './ClientContractsTab.module.scss';
import {Checkbox, message, Modal, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {Spacer} from "../../../../common/presenters/spacer/Spacer";
import {ClientFinancingType, ContractDocument} from "../../../../../types/types";
import {adminDownloadContractDocuments} from "../../../../../api/contractService";
import {GetContractResponse} from "../../../../../api/types";
import fileDownload from 'js-file-download';

export interface DocumentListModalProps {
    modalOpen: boolean
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>

    contract: GetContractResponse
}

export const DocumentListModal = (props: DocumentListModalProps) => {
    const {t} = useTranslation();

    const [options, setOptions] = useState<{label: string;value: string}[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    useEffect(() => {
        const documentOptions: ContractDocument[] = [];

        if (props.contract?.company_address_country === "de" && props.contract?.financing_type === ClientFinancingType.Loan) {
            documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_3);
            documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_7);
            documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_15);
            documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_16);
            documentOptions.push(ContractDocument.DocumentTemplate_Repayment_Plan);

        } else if (props.contract?.company_address_country === "de" && props.contract?.financing_type === ClientFinancingType.Factoring) {
            documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_1);
            props.contract?.is_standard_general_assignment ?
                documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_2_a) :
                documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_2_b);
            documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_6);
            documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_7);
            documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_15);
            documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_16);
            documentOptions.push(ContractDocument.DocumentTemplate_DE_Contract_17);

        } else if (props.contract?.company_address_country === "ch" && props.contract?.financing_type === ClientFinancingType.Loan) {
            documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_3);
            documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_5);
            documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_15);
            documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_17);
            documentOptions.push(ContractDocument.DocumentTemplate_Repayment_Plan);

        } else if (props.contract?.company_address_country === "ch" && props.contract?.financing_type === ClientFinancingType.Factoring) {
            documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_1);
            props.contract?.is_standard_general_assignment ?
                documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_2_a) :
                documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_2_b);
            documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_5);
            props.contract?.is_standard_negative_declaration ?
                documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_6_a) :
                documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_6_b);
            documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_7);
            documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_14);
            documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_15);
            documentOptions.push(ContractDocument.DocumentTemplate_CH_Contract_16);
        }

        setOptions(documentOptions.map((opt, i) => ({
            label: `${i+1}. ${t(`contractDocuments:${opt.toString()}`)}`,
            value: opt.toString(),
        })));
    }, [props.contract]);

    const handleDownload = async () => {
        if (selectedOptions?.length < 1) {
            props.setModalOpen(false);
            return;
        }

        try {
            const response = await adminDownloadContractDocuments(props.contract?.contract_id, selectedOptions);
            fileDownload(response.data, "documents.zip");
            props.setModalOpen(false);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }
    };

    return (
        <Modal title={t('modals:downloadContractDocuments')}
            width={800}
            centered
            visible={props.modalOpen}
            onOk={handleDownload}
            onCancel={() => props.setModalOpen(false)}
            destroyOnClose={true}
            maskClosable={false}
            okText={t('buttons:ok')}
            cancelText={t('buttons:cancel')}
        >
            <Typography.Text>{t("contractDownloadInfo")}</Typography.Text>
            <Spacer/>

            <div className={styles.verticalGroup}>
                <Checkbox.Group options={options}
                    value={selectedOptions}
                    onChange={(e) => {
                        setSelectedOptions(e.map(item => item.toString()));
                    }}
                />
            </div>

        </Modal>
    );
};
