import * as React from "react";
import styles from './UserApplicationDrilldownPage.module.scss';
import {CPGetApplicationResponse} from "../../../../api/types";
import {Col, Form, Input, message, Modal, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {Spacer} from "../../presenters/spacer/Spacer";
import {ApplicationFlowType} from "../../../../storage/LocalAppDataManager";
import {LocalizedNumberInput} from "../../presenters/localized-number-input/LocalizedNumberInput";
import {CompanyLegalForms, CompanySizes, Currencies, LoanDurations} from "../../../../types/types";
import {AnchoredSelect} from "../../presenters/anchored-select/AnchoredSelect";
import {useEffect} from "react";
import {CountryCodes} from "../../../../types/countries";
import {cpPutApplicationDetails} from "../../../../api/applicationService";

export interface UpdateApplicationModalProps {
    application: CPGetApplicationResponse
    modalOpen: boolean
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    onDataChanged: () => void
}

export const UpdateApplicationModal = (props: UpdateApplicationModalProps) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            amount: props.application.amount,
            duration: props.application.duration,
            currency: props.application.currency,
            purpose_info: props.application.purpose_info,
            largest_debitors_list: props.application.largest_debitors_list,
            company_name: props.application.company_name,
            company_type: props.application.company_type,
            company_number_of_employees: props.application.company_number_of_employees,
            tax_id: props.application.tax_id,
            company_register_number: props.application.company_register_number,
            company_purpose: props.application.company_purpose,
            company_country: props.application.company_country,
            company_street: props.application.company_street,
            company_house_number: props.application.company_house_number,
            company_post_code: props.application.company_post_code,
            company_city: props.application.company_city,
        });
    }, []);

    const handleOK = async () => {
        form.submit();

        props.setModalOpen(false);
        props.onDataChanged();
    };

    const onFormFinish = async (values) => {
        console.log(JSON.stringify(values));

        try {
            await cpPutApplicationDetails(props.application.id, values);
            props.onDataChanged();
            message.success(t('messages:dataSaved'), 2);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    const loanDurationOptions = () => {
        return Object.keys(LoanDurations).map((k, i) => {
            const enumValue = LoanDurations[k];
            return (
                <Select.Option
                    data-cy={`duration_option_${i}`}
                    key={i}
                    value={enumValue}
                >{`${enumValue} Months`}</Select.Option>
            );
        });
    };

    const currencyOptions = () => {
        return Object.keys(Currencies).map((k, i) => {
            const enumValue = Currencies[k];
            return (
                <Select.Option data-cy={`currency_option_${i}`} key={i} value={enumValue}>
                    {enumValue}
                </Select.Option>
            );
        });
    };

    const legalFormOptions = () => {
        return CompanyLegalForms.map((val, i) => {
            return (
                <Select.Option data-cy={`company_type_option_${i}`} key={i} value={val}>
                    {val}
                </Select.Option>
            );
        });
    };

    const companySizeOptions = () => {
        return CompanySizes.map((val, i) => {
            return (
                <Select.Option data-cy={`company_number_of_employees_option_${i}`} key={i} value={val}>
                    {val}
                </Select.Option>
            );
        });
    };

    const addressCountryOptions = () => {
        return CountryCodes.map((val, i) => {
            return (
                <Select.Option data-cy={`address_country_option_${i}`} key={i} value={val}>
                    {t(`countryCodes:${val}`)}
                </Select.Option>
            );
        });
    };

    return (
        <Modal width={1000}
            title={t('')}
            visible={props.modalOpen}
            onOk={handleOK}
            onCancel={() => props.setModalOpen(false)}
        >

            <Form name={"cpUpdateApplicationForm"}
                layout={"vertical"}
                form={form}
                onFinish={onFormFinish}
            >
                <h4 className={styles.bold}>{t('loanInformation')}</h4>
                {
                    props.application.type === ApplicationFlowType.Loan ?
                        <Row gutter={36}>
                            <Col span={12}>
                                <Form.Item name={"amount"} label={t('loanAmount')}>
                                    <LocalizedNumberInput
                                        data-cy="input_amount"
                                        value={form.getFieldValue("amount")}
                                        precision={2}
                                    />
                                </Form.Item>

                                <Form.Item name={"currency"} label={t('currency')}>
                                    <AnchoredSelect data-cy="select_currency"
                                    >
                                        {currencyOptions()}
                                    </AnchoredSelect>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={"duration"} label={t('loanDuration')}>
                                    <AnchoredSelect data-cy="select_duration"
                                    >
                                        {loanDurationOptions()}
                                    </AnchoredSelect>
                                </Form.Item>

                                <Form.Item name={"purpose_info"} label={t('loanPurpose')}>
                                    <Input data-cy={"input_purpose_info"}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        :
                        <>
                            <Col span={12}>
                                <Form.Item name={"amount"} label={t('factoringVolume')}>
                                    <LocalizedNumberInput
                                        data-cy="input_amount"
                                        value={form.getFieldValue("amount")}
                                        precision={2}
                                    />
                                </Form.Item>

                                <Form.Item name={"currency"} label={t('currency')}>
                                    <AnchoredSelect data-cy="select_currency"
                                    >
                                        {currencyOptions()}
                                    </AnchoredSelect>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={"largest_debitors_list"} label={t('largestDebitors2')}>
                                    <Input.TextArea data-cy={"largest_debitors_list"} rows={4} maxLength={500}/>
                                </Form.Item>
                            </Col>
                        </>
                }
                <Spacer/>

                <h4 className={styles.bold}>{t('sectionTitles:companyInfo')}</h4>
                <Row gutter={36}>
                    <Col span={12}>
                        <Form.Item name={"company_name"} label={t('company')}>
                            <Input data-cy={"input_company_name"}/>
                        </Form.Item>

                        <Form.Item name={"company_type"} label={t('company_legal_form')}>
                            <AnchoredSelect data-cy="select_company_type"
                            >
                                {legalFormOptions()}
                            </AnchoredSelect>
                        </Form.Item>

                        <Form.Item name={"company_number_of_employees"} label={t('number_of_employees')}>
                            <AnchoredSelect
                                data-cy="select_number_of_employees"
                            >
                                {companySizeOptions()}
                            </AnchoredSelect>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"tax_id"} label={t('taxIdNumberShort')}>
                            <Input data-cy={"input_tax_id"}/>
                        </Form.Item>

                        <Form.Item name={"company_register_number"} label={t('companyRegisterNumber')}>
                            <Input data-cy={"input_company_register_number"}/>
                        </Form.Item>

                        <Form.Item name={"company_purpose"} label={t('companyDesc')}>
                            <Input data-cy={"input_company_purpose"}/>
                        </Form.Item>
                    </Col>
                </Row>

                <h4 className={styles.bold}>{t("addressData")}</h4>
                <Row gutter={36}>
                    <Col span={12}>
                        <Row gutter={12}>
                            <Col span={16}>
                                <Form.Item name={"company_street"} label={t('street')}>
                                    <Input data-cy="input_company_street"/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={"company_house_number"} label={t('houseNumber')}>
                                    <Input data-cy="input_company_house_number" className={styles.shortInput}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={12}>
                            <Col span={8}>
                                <Form.Item name={"company_post_code"} label={t('postCode')}>
                                    <Input data-cy="input_company_post_code" className={styles.shortInput}/>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item name={"company_city"} label={t('city2')}>
                                    <Input data-cy="input_company_city"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"company_country"} label={t('country')}>
                            <AnchoredSelect data-cy="select_company_country">
                                {addressCountryOptions()}
                            </AnchoredSelect>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
