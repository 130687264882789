import { Button, Col, DatePicker, Form, Input, Radio, Row, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import { AnchoredSelect } from "components/common/presenters/anchored-select/AnchoredSelect";
import { ConfirmModalClose } from "components/common/presenters/confirm-modal-close/ConfirmModalClose";
import styles from "components/common/presenters/contact-persons-editor/ContactPersonsEditor.module.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { getPostCodeValidationPattern } from "validators/inputs";
import {DATE_FORMAT} from "../../../../api/apiConfig";
import {CountryCodes} from "../../../../types/countries";
import {useState} from "react";
const { Option } = Select;
export interface AddPersonModalProps {
    open: boolean
    editing: boolean
    loading: boolean
    error: string
    formData: any
    onOk: (values: any) => void
    onCancel: () => void
}

export const AddPersonModal = (props: AddPersonModalProps) => {
    const { t } = useTranslation();
	
    const [form] = Form.useForm();
    const [countryCode, setCountryCode] = useState<string>("");

    const [secondaryModalOpen, setSecondaryModalOpen] = React.useState(false);

    const handleOnFinish = (values) => {
        const data = {
            ...values
        };

        if (data.birth_date) {
            data.birth_date = moment(data.birth_date).format('YYYY-MM-DD');
        }

        props.onOk(data);
    };

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };

    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        form.resetFields();
        props.onCancel();
    };

    React.useEffect(() => {
        if (props.editing) {
            try {
                const data = {
                    ...props.formData
                };

                if (data.birth_date) {
                    // Important: DatePicker works with moment objects
                    data.birth_date = moment(data.birth_date);
                }

                form.setFieldsValue(data);
            } catch (error) {
                console.error(error);
            }
        } else {
            form.resetFields();
        }
    }, [props]);

    return (
        <React.Fragment>
            <ConfirmModalClose visible={secondaryModalOpen} onCancel={secondaryModalOnCancel} onOk={secondaryModalOnOk}/>

            <Modal className={styles.modal}
                title={`Kontaktperson Hinzufügen`}
                visible={props.open}
                centered
                destroyOnClose={true}
                maskClosable={false}
                keyboard={true}
                onCancel={(e: any) => {
                    if (e.code === "Escape") {
                        e.preventDefault();
                        e.stopPropagation();
                        setSecondaryModalOpen(true);
                    } else {
                        form.resetFields();
                        props.onCancel();
                    }
                }}
                footer={[
                    <div key="fr" className={styles.footerRow}>
                        <div key="footer_row_1" className={styles.error}>{props.error}</div>
                        <div key="footer_row_2" className={styles.rowCenterSpace}>
                            <Button form="addPerson" key="cancel" className={styles.buttonCancel} 
                                onClick={props.onCancel}>
                                { t("buttons:cancel") }
                            </Button>
                            <Button form="addPerson" key="submit" htmlType="submit" loading={props.loading}>
                                { t("buttons:submit") }
                            </Button>
                        </div>
                    </div>
                ]}>
                <div className={styles.inputsContainer}>
                    <Form id="addPerson" className={styles.form}
                        layout={"vertical"}
                        form={form}
                        onFinish={handleOnFinish}
                        scrollToFirstError
                    >
                        <Col>
                            <h3>Personendaten</h3>
                            <Spacer/>
                            <Form.Item name="salutation" label={t("salutation")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                <AnchoredSelect data-cy="salutation" className={styles.select} allowClear>
                                    <Option value="Herr">Herr</Option>
                                    <Option value="Frau">Frau</Option>
                                </AnchoredSelect>
                            </Form.Item>

                            <Form.Item name="title" label={t("personTitle")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                <AnchoredSelect data-cy="title" className={styles.select} allowClear>
                                    <Option value="Doktor">Doktor</Option>
                                    <Option value="Professor">Professor</Option>
                                    <Option value="Professor Doktor">Professor Doktor</Option>
                                    <Option value="Ingenieur">Ingenieur</Option>
                                    <Option value="Diplom-Ingenieur">Diplom-Ingenieur</Option>
                                    <Option value="Magister">Magister</Option>
                                </AnchoredSelect>
                            </Form.Item>

                            <Form.Item name="first_name" label={t("firstName")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                <Input data-cy="first_name"
                                    className={styles.input}
                                    placeholder={t("example") + " Hans Mustermann"}/>
                            </Form.Item>

                            <Form.Item name="last_name" label={t("lastName")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                <Input data-cy="last_name"
                                    className={styles.input}
                                    placeholder={t("example") + " Hans Mustermann"}/>
                            </Form.Item>

                            <Form.Item name="gender" label={t("gender")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                <AnchoredSelect data-cy="gender" className={styles.select} allowClear>
                                    <Option value="M">M</Option>
                                    <Option value="F">F</Option>
                                </AnchoredSelect>
                            </Form.Item>

                            <Form.Item name="nationality" label={t("nationality")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                <Input data-cy="nationality"
                                    className={styles.input}
                                    placeholder={t("example") + " Deutsch"}/>
                            </Form.Item>
                            <Spacer/>
                            <hr />
                            <Spacer/>

                            <h4>Addressdaten</h4>

                            <Form.Item name="address_country" label={t("country")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                <AnchoredSelect data-cy="address_country" className={styles.select} allowClear onChange={(e) => setCountryCode(e)}>
                                    {
                                        CountryCodes.map((val, i) => {
                                            return <Select.Option data-cy={`address_country_option_${i}`} key={i} value={val}>{t(`countryCodes:${val}`)}</Select.Option>;
                                        })
                                    }
                                </AnchoredSelect>
                            </Form.Item>

                            <Form.Item name="address_addition" label={t("addressAddition")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                <Input data-cy="address_addition"
                                    className={styles.input}
                                    placeholder={t("example") + " Mustertext"}/>
                            </Form.Item>

                            <Row>
                                <Form.Item name="address_street" label={t("streetName")} hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                    <Input data-cy="address_street"
                                        className={styles.input}
                                        placeholder={t("example") + " Musterstrasse"}/>
                                </Form.Item>
                                <Form.Item name="address_house_number" label={t("streetNumber")} hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                    <Input data-cy="address_house_number"
                                        className={styles.input}
                                        placeholder={t("example") + " 123"}/>
                                </Form.Item>
                            </Row>

                            <Row>
                                <Form.Item name="address_post_code" label={t("postCode")}
                                    rules={[{ required: false, message: t('formErrors:invalidPostCode'), pattern: getPostCodeValidationPattern()}]}>
                                    <Input data-cy="address_post_code"
                                        className={styles.input}
                                        placeholder={t("example") + " 12345"}/>
                                </Form.Item>
                                <Form.Item name="address_city" label={t("city")} hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                    <Input data-cy="address_city"
                                        className={styles.input}
                                        placeholder={t("example") + " Berlin"}/>
                                </Form.Item>
                            </Row>
                        </Col>

                        <Col>
                            <Form.Item name="email" label={t("email")} hasFeedback
                                rules={[{ required: true, message: t("errors:invalidData"), min: 5, max: 150 }]}>
                                <Input data-cy="email"
                                    className={styles.input}
                                    disabled={props.editing}
                                    placeholder={t("example") + " muster@example.com"}/>
                            </Form.Item>

                            <Form.Item name="newsletter_subscription" label={t("subscribeNewsletter2")}>
                                <Radio.Group data-cy={"radio_newsletter"}>
                                    <Radio value={true}>{ t("yes") }</Radio>
                                    <Radio value={false}>{ t("no") }</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item name="phone" label={t("phone")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                <Input data-cy="phone"
                                    className={styles.input}
                                    placeholder={t("example") + " " + t(`placeholders:phone:${countryCode}`)}/>
                            </Form.Item>

                            <Form.Item name="mobile_number" label={t("mobileNumber")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                <Input data-cy="mobile_number"
                                    className={styles.input}
                                    placeholder={t("example") + " " + t(`placeholders:mobileNumber:${countryCode}`)}/>
                            </Form.Item>

                            <Form.Item name="birth_date" label={t("dateOfBirth")} hasFeedback>
                                <DatePicker className={styles.datePicker} format={DATE_FORMAT.DE} allowClear={true}/>
                            </Form.Item>

                            <Form.Item name="birth_place" label={t("placeOfBirth")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                <Input data-cy="birth_place"
                                    className={styles.input}
                                    placeholder={t("example") + " " + t(`placeholders:city:${countryCode}`)}/>
                            </Form.Item>
                            <Spacer/>
                            <hr />
                            <Spacer/>

                            <h3>Firmendaten</h3>
                            <Spacer/>

                            <Form.Item name="is_signatory" label={t("signatories:legalSignatory")}
                                rules={[{ required: false, message: t("errors:invalidData")}]}>
                                <Radio.Group data-cy="is_signatory">
                                    <Radio value={true}>{ t("yes") }</Radio>
                                    <Radio value={false}>{ t("no") }</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item name="is_sole_signatory" label={t("signatories:soleSignatory")}
                                rules={[{ required: false, message: t("errors:invalidData")}]}>
                                <Radio.Group data-cy="is_sole_signatory">
                                    <Radio value={true}>{ t("yes") }</Radio>
                                    <Radio value={false}>{ t("no") }</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item name="is_beneficial_owner" label={t("signatories:doesOwnMoreThan25")}
                                rules={[{ required: false, message: t("errors:invalidData")}]}>
                                <Radio.Group data-cy="is_beneficial_owner">
                                    <Radio value={true}>{ t("yes") }</Radio>
                                    <Radio value={false}>{ t("no") }</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item name="pct_owned_shares" label={t("pctShares")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 0, max: 100}]}>
                                <Input data-cy="pct_owned_shares"
                                    type={"number"}
                                    className={styles.input}
                                    placeholder={t("example") + " 25%"}/>
                            </Form.Item>

                        </Col>
                    </Form>
                </div>
            </Modal>
        </React.Fragment>
    );
};
