import {ProgressBarStepsItem} from "../components/common/presenters/custom-page-header/CustomPageHeader";
import i18n from 'i18n/i18n';
import { RouteStrings } from "Routes";

const stepName = () => i18n.t("applProcess:step");

export const defaultFlowSteps: ProgressBarStepsItem[] = [
    {
        title: `${i18n.t("applProcess:step")} 1`,
        subtitle: "",
        description: i18n.t("applProcess:stepCompanyName")
    },
    {
        title: `${i18n.t("applProcess:step")} 2`,
        subtitle: "",
        description: i18n.t("applProcess:stepLoanType")
    },
    {
        title: `${i18n.t("applProcess:step")} 3`,
        subtitle: "",
        description: i18n.t("applProcess:stepLoanDetails")
    },
    {
        title: `${i18n.t("applProcess:step")} 4`,
        subtitle: "",
        description: i18n.t("applProcess:stepCompanyInfo")
    },
    {
        title: `${i18n.t("applProcess:step")} 5`,
        subtitle: "",
        description: i18n.t("applProcess:stepContactInfo")
    },
    {
        title: `${i18n.t("applProcess:step")} 6`,
        subtitle: "",
        description: i18n.t("applProcess:stepRegistration")
    },
    {
        title: `${i18n.t("applProcess:step")} 7`,
        subtitle: "",
        description: i18n.t("applProcess:stepResult")
    }
];

const modifyStepsIndexes = (steps: ProgressBarStepsItem[]) => {
    for (let i = 0; i < steps.length; i++) {
        steps[i].title = `${stepName()} ${i + 1}`;
    }

    return steps;
};

/**
 * Orders the steps based on certain criteria.
 */
const modifyFlowPathsOrders = () => {
    let processFlowPaths = [
        ...RouteStrings.DEFAULT_PROCESS_FLOW_PATHS
    ];

    // Skip loan type check is set to true when starting a loan application from 
    // the page header as a factoring onboarding user.
    // if (getSkipLoanTypeCheckStatus()) {
    //     processFlowPaths = processFlowPaths.filter((value) => {
    //         return value !== RouteStrings.ApplicationFlowStepTwo;
    //     });
    // }

    // console.log(processFlowPaths);

    // if (getUserHasAnAccount()) {
    //     processFlowPaths = processFlowPaths.filter((value) => {
    //         return value !== RouteStrings.ApplicationFlowStepCreateAccount;
    //     });
    // }

    return processFlowPaths;
};

export const getCurrentPageStepNumber = () => {
    const path = window.location.pathname;

    const processFlowPaths = modifyFlowPathsOrders();

    // search current page in array, containing pathnames of application flow steps
    const stepNumber = processFlowPaths.findIndex((stepName) => {
        return stepName === path;
    });

    return stepNumber;
};

export const getFlowSteps = () => {
    let steps = [
        ...defaultFlowSteps
    ];

    // TODO: figure out how to dynamically update steps
    // if (getSkipLoanTypeCheckStatus()) {
    //     steps = steps.filter((value, index, arr) => {
    //         return value.description !== StepNames.LoanDetails;
    //     });
    // }

    // if (getUserHasAnAccount()) {
    //     steps = steps.filter((value, index, arr) => {
    //         return value.description !== StepNames.Account;
    //     });
    // }

    steps = modifyStepsIndexes(steps);

    return steps;
};
