import { Button, Col, Row, Spin } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import styles from './SveaLandingPage.module.scss';
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { ArrowRightOutlined } from "@ant-design/icons";
import { RouteStrings } from "Routes";
import { Spacer } from "components/common/presenters/spacer/Spacer";

export const SveaLandingPage = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 250);
    }, []);

    return (
        <div className={styles.page}>
            {
                isLoading ?
                    <div className={styles.spinnerContainer}>
                        <Spin className={styles.spinner} />
                    </div>
                    :
                    null
            }

            <ApplicationProcessHeader/>

            <div className={styles.header}>

            </div>

            <div className={styles.header}>
                <img className={styles.headerImg} alt="header_img_businessman" src="https://www.svea.com/globalassets/germany/startseite/adobestock_365070618_1920x680-hero.jpg"></img>

                <div className={styles.overlay}></div>

                <div className={styles.headerContent}>
                    <h1 className={styles.headerTitle}>Ihr Partner für KMU-Finanzierungen</h1>
                    <p className={styles.headerText}>
                        Mit über 20 Jahren Erfahrung im Factoring-Geschäft ist Svea ihr zuverlässiger Partner beim Forderungsverkauf. Mit unseren vielfältigen Finanzierungsdienstleistungen unterstützen wir kleine und mittlere Unternehmen einfach und günstig an Liquidität zu kommen.
                    </p>
                </div>
            </div>
            <Spacer/>

            <div className={styles.sectionOne}>
                <Col className={styles.colInfo}>
                    <h2>Factoring</h2>
                    <Spacer/>
                    <p>Mit Factoring werden Ihre Kundenrechnungen innerhalb 24 Stunden bis zu 100% des Rechnungsbetrages bezahlt</p>
                    <a href="https://www.svea.com/de/de/start/factoring/" target="_blank" rel="noopener noreferrer" className={styles.link}>Mehr erfahren <ArrowRightOutlined/> </a>
                </Col>
                <Col className={styles.colInfo}>
                    <h2>Einkaufsfinanzierung mit Factoring</h2>
                    <Spacer/>
                    <p>Mit Einkaufsfinanzierung Ihrer Auftrags- und Projektbezogenen Einkäufe, überbrücken Sie finanzielle Engpässe.</p>
                    <a href="https://www.svea.com/de/de/start/einkaufsfinanzierung/" target="_blank" rel="noopener noreferrer" className={styles.link}>Mehr erfahren <ArrowRightOutlined/> </a>
                </Col>
                <Col className={styles.colInfo}>
                    <h2>KMU-Darlehen</h2>
                    <Spacer/>
                    <p>Unkompliziertes und schnelles Darlehen für Ihren mittelfristigen Finanzierungsbedarf von geschäftlichen Investitionen.</p>
                    <a href="https://www.svea.com/de/de/start/kmu-darlehen/" target="_blank" rel="noopener noreferrer" className={styles.link}>Mehr erfahren <ArrowRightOutlined/> </a>
                </Col>
            </div>

            <Spacer hx={2}/>
            <div className={styles.sectionTwo}>
                <Col className={styles.colInfoXL}>
                    <h2>Ihre Ziele sind unsere Ziele</h2>
                    <Spacer/>
                    <p>
                        Wir bieten Finanzierungslösungen von Factoring in unterschiedlichen Varianten, bis hin zu KMU Darlehen. Svea bietet individuelle Finanzierungskonzepte um das Wachstum Ihres Geschäftes voranzukurbeln.
                    </p>
                    <Spacer/>
                    <Button>
                        <a href={RouteStrings.ApplicationFlowStepOne} className={styles.link}>Antrag Starten &nbsp;<ArrowRightOutlined/></a>
                    </Button>
                </Col>
            </div>

            <div className={styles.footer}>
                <Row className={styles.rowValign}>
                    <div className={styles.logoLight}></div>
                    <div className={styles.footerText}>The finest way to cash...</div>
                </Row>
                <Button className={styles.footerButton}>
                    <a href={RouteStrings.AdminLogin} className={styles.link}>Admin Login &nbsp;<ArrowRightOutlined/></a>
                </Button>
            </div>
            <div className={styles.footer2}>
            </div>
        </div>
    );
};
