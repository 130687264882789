import React from 'react';
import classnames from "classnames";
import styles from "components/common/presenters/icon-button/IconButton.module.scss";
import { Component } from 'types/types';

export enum IconButtonType {
    Loan,
    Factoring
}

export interface IconButtonProps {
    className?: string
    icon: IconButtonType
    dataCy: string
    children?: Component
    onClick?: () => void
}

export const IconButton = (props: IconButtonProps) => {
    let dynamicClasses = classnames(styles.icon, { 
        [styles.iconLoan]: props.icon === IconButtonType.Loan,
        [styles.iconFactoring]: props.icon === IconButtonType.Factoring,
    });

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };
	
    return (
        <div data-cy={props.dataCy} className={`${styles.iconButton} ${props.className}`} onClick={handleClick}>
            <div className={dynamicClasses}></div>
            { props.children }
        </div>
    );
};

