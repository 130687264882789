import * as React from "react";
import {useEffect, useState} from "react";
import styles from '../ClientDrilldownPage.module.scss';
import {useTranslation} from 'react-i18next';
import {GetClientResponse} from "../../../../../api/types";
import {Button, Form, Input, message, Modal, Select, Space} from "antd";
import {adminUpdateClientStatus} from "../../../../../api/clientService";
import {AnchoredSelect} from "../../../../common/presenters/anchored-select/AnchoredSelect";
import {LeadStatus} from "../../../../../types/types";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import {ConfirmModalClose} from "components/common/presenters/confirm-modal-close/ConfirmModalClose";

interface LeadStatusModalProps {
    client: GetClientResponse
    onDataChanged: () => void
    modalOpen: boolean
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const LeadStatusModal = (props: LeadStatusModalProps) => {
    const {t} = useTranslation();

    const [form] = Form.useForm();
    const [secondaryModalOpen, setSecondaryModalOpen] = React.useState(false);

    const [newStatus, setNewStatus] = useState(props.client.lead_status);
    const [reasonFieldRule, setReasonFieldRule] = useState({});

    useEffect(() => {
        if (props.modalOpen) {
            form.resetFields();
        }
    }, [props.modalOpen]);

    useEffect(() => {
        if ([LeadStatus.Lost, LeadStatus.Disqualified].includes(form.getFieldValue("new_status"))) {
            setReasonFieldRule({
                required: true,
                message: t('formErrors:pleaseSetReason'),
                min: 1,
                max: 255,
            });
        } else {
            setReasonFieldRule({
                required: false,
                min: 0,
                max: 255,
            });
        }
    }, [newStatus]);

    const leadStatusOptions = () => {
        return Object.keys(LeadStatus).filter((k) => LeadStatus[k] !== LeadStatus.Archived)
            .map((k, i) => {
                const enumValue = LeadStatus[k];
                return <Select.Option data-cy={`lead_status_option_${i}`} key={i}
                    value={enumValue}>{t(`leadStatuses:${enumValue}`)}</Select.Option>;
            });
    };

    const onFormFinish = async (values: any) => {
        try {
            await adminUpdateClientStatus(props.client.client_id, {...values});
            props.onDataChanged();
            props.setModalOpen(false);
            message.success(t('messages:dataSaved'), 2);
        } catch (e) {
            message.error(t('messages:couldNotSave'), 2);
            console.error(e);
        }
    };

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };

    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        props.setModalOpen(false);
    };

    return (
        <React.Fragment>
            <ConfirmModalClose visible={secondaryModalOpen} onCancel={secondaryModalOnCancel}
                onOk={secondaryModalOnOk}/>

            <Modal title={t('modals:editLeadStatus')}
                width={800}
                centered
                visible={props.modalOpen}
                destroyOnClose={true}
                maskClosable={false}
                okText={t('buttons:ok')}
                cancelText={t('buttons:cancel')}
                onCancel={(e: any) => {
                    if (e.code === "Escape") {
                        e.preventDefault();
                        e.stopPropagation();
                        setSecondaryModalOpen(true);
                    } else {
                        props.setModalOpen(false);
                    }
                }}
                footer={[
                    <div key="fr" className={styles.footerFlexRow}>
                        <Button className={styles.buttonCancel} key="cancel"
                            onClick={(e: any) => {
                                props.setModalOpen(false);
                            }}>
                            {t("buttons:cancel")}
                        </Button>
                        <Button className={styles.buttonOk} key="submit" onClick={() => {
                            form.submit();
                        }}>
                            {t("buttons:submit")}
                        </Button>
                    </div>
                ]}>
                <Space direction={"horizontal"}
                    size={"large"}
                >
                    <h4>{t('currentleadStatus')}:</h4>
                    <h4 className={styles.bold}>{t(`leadStatuses:${props.client.lead_status}`)}</h4>

                </Space>
                <Spacer/>

                <Form id={"leadStatusModal"}
                    form={form}
                    layout={"vertical"}
                    onFinish={onFormFinish}
                    scrollToFirstError
                >
                    <Form.Item name={"new_status"}
                        label={t("leadStatus")}
                        initialValue={props.client.lead_status}
                    >
                        <AnchoredSelect data-cy="select_lead_status"
                            onChange={(e) => setNewStatus(e)}
                        >
                            {leadStatusOptions()}
                        </AnchoredSelect>
                    </Form.Item>

                    <Form.Item name={"reason"}
                        label={t("reason")}
                        rules={[reasonFieldRule]}
                    >
                        <Input.TextArea data-cy="input_lead_notes"
                            autoSize={{minRows: 2, maxRows: 4}}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};
