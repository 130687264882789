import LocalStorageManager, { ApplicationFlowType } from "./LocalAppDataManager";

export const userHasCompletedFactoringOnboarding = (): boolean => {
    const state = LocalStorageManager.get();

    return state.userIsFactoringOnboarding;
};
export const setUserFactoringOnboardingStatus = (status:  boolean) => {
    const state = LocalStorageManager.get();
    state.userIsFactoringOnboarding = status;

    LocalStorageManager.update(state);
};

export const getActiveFlowType = (): ApplicationFlowType => {
    const state = LocalStorageManager.get();

    return state.activeFlowType;
};
export const setActiveFlowType = (type: ApplicationFlowType) => {
    const state = LocalStorageManager.get();
    state.activeFlowType = type;

    LocalStorageManager.update(state);
};

export const getSkipLoanTypeCheckStatus = (): boolean => {
    const state = LocalStorageManager.get();

    return state.skipLoanTypeQuestion;
};
export const setSkipLoanTypeCheckStatus = (status: boolean) => {
    const state = LocalStorageManager.get();
    state.skipLoanTypeQuestion = status;

    LocalStorageManager.update(state);
};

export const getUserHasAnAccount = (): boolean => {
    const state = LocalStorageManager.get();

    return state.userHasAnAccount;
};
export const setUserHasAnAccount = (status: boolean) => {
    const state = LocalStorageManager.get();
    state.userHasAnAccount = status;

    LocalStorageManager.update(state);
};

