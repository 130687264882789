import {message, Tabs} from "antd";
import {
    adminGetApplicationById,
    adminGetApplicationReview,
    adminGetApplicationScore, adminGetLatestApplicationArchiveURL,
    adminPostApplicationReview,
    adminPostApplicationScore,
    adminPutApplicationBankInfo,
    adminPutApplicationReview,
    adminPutApplicationScore,
    putApplicationKYCConfirmAdmin,
    putApplicationStatusAdmin
} from "api/applicationService";
import {adminGetAllContactPersonsForClient} from "api/clientContactPersonService";
import {
    ApplicationReview,
    ApplicationScore,
    PutApplicationBankInfoReq,
    PutApplicationKYCConfirmReq,
    ScoreChecklistItem
} from "api/types";
import {ApplicationStateButtons} from "components/admin/presenters/application-state-buttons/ApplicationStateButtons";
import {ApplicationReviewTab} from "components/admin/presenters/applications-drilldown-tabs/application-review-tab/ApplicationReviewTab";
import {
    ApplicationBankInfo,
    CompanyTab
} from "components/admin/presenters/applications-drilldown-tabs/company-tab/CompanyTab";
import {NotesTab, NotesTabType} from "components/admin/presenters/applications-drilldown-tabs/notes-tab/NotesTab";
import {ScoringTab} from "components/admin/presenters/applications-drilldown-tabs/scoring-tab/ScoringTab";
import {ContactPersonsEditor} from "components/common/presenters/contact-persons-editor/ContactPersonsEditor";
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from 'react-router-dom';
import {Application, ApplicationStates} from "types/types";
import {deleteObjNullProps, parseToFloatObjNumProps} from "../../../../storage/genericHelpers";
import {TopInfoSection, TopInfoSectionType} from "../../../common/presenters/top-info-section/TopInfoSection";
import {DocumentsTab} from "../../presenters/applications-drilldown-tabs/documents-tab/DocumentsTab";
import styles from './ApplicationDrilldownPage.module.scss';
import "./TabMenu.scss";
import {Spacer} from "../../../common/presenters/spacer/Spacer";
import {DrilldownActionsMenu} from "../../presenters/drilldown-actions-menu/DrilldownActionsMenu";
import {ApplicationLogsTab} from "../../presenters/applications-drilldown-tabs/logs-tab/ApplicationLogsTab";
import {clearURLQueryString, openUrlInNewTab} from "../../../../helpers/uiHelpers";
import {useQuery} from "../../../../hooks/QueryParams";

const {TabPane} = Tabs;

export const ApplicationDrilldownPage = () => {
    const {t} = useTranslation();
    const queryParams = useQuery();

    let {applicationId} = useParams();
    const [appl, setAppl] = useState({} as Application);
    const [cpList, setCpList] = useState([] as any);
    const [applScore, setApplScore] = useState({} as ApplicationScore);
    const [applReview, setApplReview] = useState({} as ApplicationReview);
    const [, setError] = useState("");
    const [loading, setLoading] = useState(false);

    // const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

    const tabTitles = [
        t("companyInfo"),
        t("contactPersons"),
        t("documents"),
        t("scoring"),
        t("review"),
        t("notes"),
        t("logs"),
    ];

    const [activeTab, setActiveTab] = useState("0");

    const onTabChange = (key) => {
        setActiveTab(key);
    };

    const getDataAndSetState = async (): Promise<any> => {
        // separate try-catch block for each req group, because otherwise if 1 fails, the rest don't get executed
        try {
            const applReq = await adminGetApplicationById(applicationId);
            setAppl(applReq.data);

            const cpListReq = await adminGetAllContactPersonsForClient(applReq.data.client_id);
            setCpList(cpListReq.data);
        } catch (error) {
            console.error(error);
        }
        try {
            const applScoreReq = await adminGetApplicationScore(applicationId);
            setApplScore(applScoreReq.data);
    
        } catch (error) {
            console.error(error);
        }
        try {
            const applReviewReq = await adminGetApplicationReview(applicationId);
            setApplReview(applReviewReq.data);
        } catch (error) {
            console.error(error);
        }
    };

    const saveBankInfo = async (info: ApplicationBankInfo) => {
        const data: PutApplicationBankInfoReq = {
            ...info,
            client_id: appl.client_id
        };

        await adminPutApplicationBankInfo(appl.id, data);

        getDataAndSetState();
    };


    const handleKYCConfirm = async (data: PutApplicationKYCConfirmReq) => {
        try {
            await putApplicationKYCConfirmAdmin(applicationId, data);

            await putApplicationStatusAdmin(applicationId, {
                client_id: appl.client_id,
                status: ApplicationStates.Signature_KYC
            });

            getDataAndSetState();
        } catch (err) {
            console.error(err);
        }
    };

    const handleApplicationStateChange = async (newState: ApplicationStates) => {
        try {
            await putApplicationStatusAdmin(applicationId, {
                client_id: appl.client_id,
                status: newState
            });
            getDataAndSetState();
        } catch (err) {
            console.error(err);
        }
    };

    const handleKPIsSave = async (values: any, checklistItems: ScoreChecklistItem[]) => {
        await setError("");
        await setLoading(true);

        let score = {
            ...applScore,
            ...values,
            checklist: {
                ...applScore.checklist,
                items: [
                    ...checklistItems
                ]		
            }
        };

        
        score = deleteObjNullProps(score);
        score = parseToFloatObjNumProps(score);

        try {
            if (applScore == null || applScore.score_id == null || applScore.score_id == "") {
                const postKpisReq = await adminPostApplicationScore(applicationId, score);

                if (postKpisReq && postKpisReq.status >= 300) {
                    setError(t("errors:postApplicationScore"));
                } else {
                    // a short timeout, so we can show the loader for some time
                    setTimeout(() => {
                        getDataAndSetState();
                    }, 250);
                }
            } else {
                const putKpisReq = await adminPutApplicationScore(applicationId, score);

                if (putKpisReq && putKpisReq.status >= 300) {
                    setError(t("errors:putApplicationScore"));
                } else {
                    // a short timeout, so we can show the loader for some time
                    setTimeout(() => {
                        getDataAndSetState();
                    }, 250);
                }
            }
        } catch (error) {
            console.error(error);
            setError("Error updating KPIs");
        }

        setLoading(false);
    };

    const handleApplReviewSave = async (values: any) => {
        await setError("");
        await setLoading(true);

        let review = {
            ...values
        };

        review = deleteObjNullProps(review);

        try {
            if (applReview == null || applReview.review_id == null) {
                const postReviewReq = await adminPostApplicationReview(applicationId, review);

                if (postReviewReq && postReviewReq.status >= 300) {
                    setError(t("errors:postApplicationReview"));
                } else {
                    // a short timeout, so we can show the loader for some time
                    setTimeout(() => {
                        getDataAndSetState();
                    }, 250);
                }
            } else {
                const postReviewReq = await adminPutApplicationReview(applicationId, review);

                if (postReviewReq && postReviewReq.status >= 300) {
                    setError(t("errors:putApplicationReview"));
                } else {
                    // a short timeout, so we can show the loader for some time
                    setTimeout(() => {
                        getDataAndSetState();
                    }, 250);
                }
            }
        } catch (error) {
            console.error(error);
            setError("Error updating application review");
        }

        setLoading(false);
    };

    const handleDeepLinkForArchive = async () => {
        const isDownloadArchive: string = queryParams.get("download_archive");
        if (isDownloadArchive === "true") {
            try {
                const zipFileURL: string = (await adminGetLatestApplicationArchiveURL(applicationId)).data;

                openUrlInNewTab(zipFileURL);
                clearURLQueryString();
            } catch (e) {
                console.error(e);
                message.error(t('messages:errorEncountered'), 2);
            }
        }
    };

    useEffect(() => {
        try {
            handleDeepLinkForArchive();
            getDataAndSetState();
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <div className={styles.page}>
            <SideMenu isLoggedIn={true}/>

            <div className={styles.sectionRight}>
                <div className={styles.header}>
                    <TopInfoSection type={TopInfoSectionType.TypeApplication}
                        applicationData={appl}
                    />

                    <DrilldownActionsMenu application={appl} onDataChanged={getDataAndSetState}/>
                </div>
                <Spacer hx={3}/>

                <div className="tab-menu">
                    <Tabs className="tab-menu" defaultActiveKey="0" activeKey={activeTab}
                        onChange={onTabChange}>
                        <TabPane tab={tabTitles[0]} key={`0`}>
                            <CompanyTab application={appl} onBankInfoSave={saveBankInfo}/>
                        </TabPane>

                        <TabPane tab={tabTitles[1]} key={`1`}>
                            <ContactPersonsEditor isAdmin={true} clientId={appl.client_id} contactPersons={cpList}
                                onDataChanged={() => getDataAndSetState()}/>
                        </TabPane>

                        <TabPane tab={tabTitles[2]} key={`2`}>
                            <DocumentsTab key="dt" applicationId={applicationId}/>
                        </TabPane>

                        <TabPane tab={tabTitles[3]} key={`3`}>
                            <ScoringTab key="st" loading={loading} error={""}
                                score={applScore}
                                onKPIsSave={handleKPIsSave}/>
                        </TabPane>

                        <TabPane tab={tabTitles[4]} key={`4`}>
                            <ApplicationReviewTab key="ar" applicationId={applicationId} loading={loading} error={""}
                                score={applScore}
                                data={applReview}
                                onSave={handleApplReviewSave}/>
                        </TabPane>

                        <TabPane tab={tabTitles[5]} key={`5`}>
                            <NotesTab key="nt" entityId={applicationId} type={NotesTabType.Application}/>
                        </TabPane>

                        <TabPane tab={tabTitles[6]} key={`6`}>
                            <ApplicationLogsTab key="logst" application={appl}/>
                        </TabPane>
                    </Tabs>
                </div>

                <ApplicationStateButtons application={appl}
                    onKYCConfirm={handleKYCConfirm}
                    onStateChange={handleApplicationStateChange}/>
            </div>
        </div>
    );
};
