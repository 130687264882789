import { getRandom10InRange, getRandomIntInRange } from "helpers/numbersHelper";
import { ApplicationFlowType } from "storage/LocalAppDataManager";
import { LocalApplicationEntity } from "storage/LocalFinancingApplicationDataManager";
import { ApplicationStates } from "types/types";
import { v4 as uuidv4 } from 'uuid';

export const applicationEntityFactory = (): LocalApplicationEntity  => {
    return {
        applicationNumber: uuidv4(),
        company: "Muster Firma GmbH",
        email: "muster@muster.mail",
        created: "22.09.2020",
        loanAmount: getRandom10InRange(2000, 25000),
        currency: "EUR",
        loanDuration: getRandomIntInRange(6, 60),
        interestRate: getRandomIntInRange(1, 8),
        status: ApplicationStates.Open,
        type: ApplicationFlowType.Loan,
        legalForm: null,
        postCode: null,
        principalBusiness: null,
        loanPurpose: null,
        detailedLoanPurpose: null,
        taxIdNumber: null,
        oneTimeFees: null,
        monthlyFees: null,
        factoringLimit: null
    };
};

const createSeedApplications = (limit: number) => {
    const seedApplications: any = {

    };

    // Loan applications
    for (let i = 0; i < limit / 2; i++) {
        const appl = applicationEntityFactory();

        seedApplications[appl.applicationNumber] = appl;
    }

    return seedApplications;
};

export const getNewApplication = () => {
    return applicationEntityFactory();
};

export const seedApplications = createSeedApplications(25);
