import {PlusOutlined, RightOutlined} from '@ant-design/icons';
import {Button, Table} from 'antd';
import {userGetAllApplications} from 'api/applicationService';
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import {Spacer} from 'components/common/presenters/spacer/Spacer';
import * as React from "react";
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {RouteStrings} from 'Routes';
import {setSkipLoanTypeCheckStatus} from 'storage/applicationFlowHelpers';
import styles from './ApplicationsPage.module.scss';
import {Application, ApplicationStates} from "../../../../types/types";

export const ApplicationsPage = () => {
    const {t} = useTranslation();

    const [loading, setLoading] = React.useState(false);
    const [isEmpty, setIsEmpty] = React.useState(false);
    const [appl, setAppl] = React.useState([] as Application[]);

    const setSkipLoanTypeCheck = () => {
        setSkipLoanTypeCheckStatus(true);
    };

    const getApplications = async (page: number) => {
        await setLoading(true);

        const getApplReq = await userGetAllApplications(page);

        if (getApplReq && getApplReq.status == 200) {
            // A little timeout, so the loader doesn't just flash if the backend returns quickly
            setTimeout(() => {
                setIsEmpty(false);
                setLoading(false);
                setAppl(getApplReq.data);
            }, 250);
        } else {
            // A little timeout, so the loader doesn't just flash if the backend returns quickly
            setTimeout(() => {
                setIsEmpty(true);
                setLoading(false);
            }, 250);
        }
    };

    React.useEffect(() => {
        getApplications(1);
    }, []);

    const getApplicationStatusLabel = (status: ApplicationStates): string => {
        switch (status) {
        case ApplicationStates.Paid_Out:
            return t(`applicationStates:${ApplicationStates.Paid_Out}`);
        case ApplicationStates.Approved_Awaiting_Payout:
            return t('approved');
        case ApplicationStates.Open:
            return t('incomplete');
        case ApplicationStates.In_Review_Risk:
        case ApplicationStates.Signature_KYC:
        case ApplicationStates.Final_Review_Operations:
            return t('inReview');
        default:
            return "-";
        }
    };

    const getApplicationShortID = (a: Application): string => {
        return a.company_country ? `${a.company_country.toUpperCase()}-${a.short_id?.toUpperCase()}` : `ID-${a.short_id?.toUpperCase()}`;
    };

    const columns: any = [
        {
            title: 'Antrags Nr.',
            dataIndex: 'short_id',
            render: (value, record) => getApplicationShortID(record)
        },
        {
            title: 'Firma',
            dataIndex: 'company_name',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.company_name?.localeCompare(b.company_name),
        },
        {
            title: 'Erstellt',
            dataIndex: 'created_at',
        },
        {
            title: 'Betrag',
            dataIndex: 'amount',
            sorter: (a, b) => Number.parseInt(a.amount) - Number.parseInt(b.amount),
        },
        {
            title: 'Laufzeit',
            dataIndex: 'duration',
        },
        {
            title: 'Typ',
            dataIndex: 'type',
            render: value => value ? t(`applicationTypesCP:${value}`) : "-",
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: value => value ? getApplicationStatusLabel(value) : "-",
        },
        {
            title: '',
            dataIndex: 'lastCol',
            render: (text, record) => {
                let link = RouteStrings.UserApplicationDrilldownBase + record.id;

                return <Link to={link}>{t('view')}<RightOutlined/></Link>;
            }
        },
    ];

    return (
        <div className={styles.page}>
            <SideMenu isLoggedIn={true}/>

            <div className={styles.sectionRight}>
                <h2>{t("userHomeTitle")}</h2>
                <Spacer/>
                <Spacer/>

                {
                    isEmpty ?
                        <div className={styles.empty}>
                            <h3>You have no loans, apply for one now:</h3> &nbsp; &nbsp;
                            <Button className={styles.sideMenuButton} key="3" onClick={setSkipLoanTypeCheck}>
                                <Link to={RouteStrings.ApplicationFlowStepOne}>
                                    <PlusOutlined/>&nbsp;Kredit Beantragen
                                </Link>
                            </Button>
                        </div>
                        :
                        <Table className={styles.table}
                            rowKey={record => record.id}
                            loading={loading}
                            pagination={{position: ["bottomCenter"]}}
                            columns={columns}
                            dataSource={appl}
                        />
                }
            </div>
        </div>
    );
};
