import { CalendarOutlined, DownOutlined, MailOutlined, MobileOutlined, PhoneOutlined, RightOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { ContactPersonClient } from "api/types";
import styles from "components/common/presenters/contact-persons-editor/ContactPersonsEditor.module.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { renderString } from "helpers/stringHelpers";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ContactPersonCardProps {
    person: ContactPersonClient
    onEdit: (contact_person_id: string) => void
}

export const ContactPersonCard = (props: ContactPersonCardProps) => {
    const { t } = useTranslation();

    const [moreInfoOpen, setMoreInfoOpen] = React.useState(false);
    const [birthDate, setBirthDate] = React.useState("");

    const person = {
        ...props.person
    };

    for (const prop in person) {
        if (person.hasOwnProperty(prop) && person[prop] == null) {
            person[prop] = "";
        }
    }

    React.useEffect(() => {
        try {
            if (props.person.birth_date) {
                const birthDate = moment(props.person.birth_date).format('DD.MM.YYYY');
                setBirthDate(birthDate);
            }
        } catch (error) {
            console.error(error);
        }
    }, [props.person]);
    
    return (
        <div className={styles.card}>
            <div className={styles.buttonAnchor} onClick={() => {
                props.onEdit(person.contact_person_id); 
            }}>
                { t("buttons:edit") }
            </div>

            <div className={styles.header}>
                <h4>{ person.title + " " + person.first_name + " " + person.last_name } </h4>
            </div>

            <hr />

            <div className={styles.info}>
                <Row className={styles.cardRow}><PhoneOutlined className={styles.icon} /> &nbsp; 
                    <div className={styles.cardContent}>
                        { renderString(person.phone) }
                    </div>
                </Row>
                <Row className={styles.cardRow}><MobileOutlined className={styles.icon} /> &nbsp; 
                    <div className={styles.cardContent}>
                        { renderString(person.mobile_number) }
                    </div>
                </Row>
                <Row className={styles.cardRow}><MailOutlined className={styles.icon} /> &nbsp;
                    <div className={styles.cardContent}>
                        { renderString(person.email) }
                    </div>
                </Row>
                <Row className={styles.cardRow}><CalendarOutlined className={styles.icon} /> &nbsp; 
                    <div className={styles.cardContent}>
                        { renderString(birthDate) }
                    </div>
                </Row>

                <Spacer/>

                <Row className={styles.cardRow}><b>{ t("address") }:</b></Row>
                <Row className={styles.cardRow}>
                    <div className={styles.cardContent}>
                        { renderString(person.address_street) } { person.address_house_number}
                    </div>
                </Row>
                <Row className={styles.cardRow}>
                    <div className={styles.cardContent}>
                        { renderString(person.address_addition) }
                    </div>
                </Row>
                <Row className={styles.cardRow}>
                    <div className={styles.cardContent}>
                        { renderString(person.address_city) } { person.address_post_code }
                    </div>
                </Row>
                <Row className={styles.cardRow}>
                    <div className={styles.cardContent}>
                        { renderString(person.address_country) }
                    </div>
                </Row>
            </div>
            <Spacer/>

            <div className={styles.moreInfoHeader} onClick={ () => {
                setMoreInfoOpen(!moreInfoOpen); 
            }}>
                {
                    moreInfoOpen ?
                        <DownOutlined />
                        :
                        <RightOutlined />
                }

				&nbsp;&nbsp;
                {t("additionalInfo")}
            </div>

            {
                moreInfoOpen ?
                    <div className={styles.moreInfo}>
                        <div className={styles.infoItem}>
                            <div>{t("gender")}:&nbsp;</div>
                            <div>{ renderString(person.gender) }</div>
                        </div>
                        <div className={styles.infoItem}>
                            <div>{t("placeOfBirth")}:&nbsp;</div>
                            <div className={styles.cardContent}>{ renderString(person.birth_place) }</div>
                        </div>
                        <div className={styles.infoItem}>
                            <div>{t("nationality")}:&nbsp;</div>
                            <div className={styles.cardContent}>{ renderString(person.nationality) }</div>
                        </div>
                        <Spacer/>

                        <div className={styles.infoItem}>
                            <div>{t("isSignatory")}:&nbsp;</div>
                            <div>{ person.is_signatory ? t("yes") : t("no") }</div>
                        </div>
                        <div className={styles.infoItem}>
                            <div>{t("isSoleSignatory")}:&nbsp;</div>
                            <div>{ person.is_sole_signatory ? t("yes") : t("no")}</div>
                        </div>
                        <div className={styles.infoItem}>
                            <div>{t("isBeneficialOwner")}:&nbsp;</div>
                            <div>{ person.is_beneficial_owner ? t("yes") : t("no")}</div>
                        </div>
                        <Spacer/>

                        <div className={styles.infoItem}>
                            <div>{t("newsletterSubscription")}:&nbsp;</div>
                            <div>{ person.newsletter_subscription ? t("yes") : t("no")}</div>
                        </div>
                        {
                            person.is_beneficial_owner ?
                                <div className={styles.infoItem}>
                                    <div>{t("pctShares")}:&nbsp;</div>
                                    <div>{ person.pct_owned_shares }</div>
                                </div>
                                :
                                null
                        }
                    </div>
                    :
                    null
            }

        </div>
    );
};
