import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import styles from 'components/common/pages/application-flow/ApplicationFlow.module.scss';
import { ApplicationProcessHeader } from 'components/common/presenters/application-process-header/ApplicationProcessHeader';
import { CustomPageHeader } from 'components/common/presenters/custom-page-header/CustomPageHeader';
import * as React from "react";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { RouteStrings } from "Routes";
import { LocalApplicationFlowDataManager } from 'storage/LocalApplicationFlowDataManager';
import { goTo } from 'navigation/navigationHelpers';
import { refreshSession } from 'api/clientContactPersonService';
import { useHistory } from "react-router-dom";
import { Spacer } from 'components/common/presenters/spacer/Spacer';

export const ApplicationStepResultPage = () => {
    const { t } = useTranslation();

    const history = useHistory();

    const [ isSuccess, setSuccess ] = useState(null);
    const [ isLoggedIn, setIsLoggedIn ] = useState(false);
    const [applId, setApplId ] = useState("");

    const renderSuccess = () => {
        if (isLoggedIn) {
            return (
                <>
                    <h1 className={styles.processTitle}>
                        { t("headings:processResultLoggedIn") }
                    </h1>
                    <Spacer/>
                    <CheckCircleOutlined className={styles.iconSuccess}/>
                    <Spacer/>
                    <Spacer/>
                    <p>{ t("headings:processSuccess1LoggedIn") }</p>
                </>
            );
        } else {
            return (
                <>
                    <h1 className={styles.processTitle}>
                        { t("headings:processResult") }
                    </h1>
                    <Spacer/>
                    <CheckCircleOutlined className={styles.iconSuccess}/>
                    <Spacer/>
                    <Spacer/>
                    <p>{ t("headings:processSuccess1") }</p>
                    <Spacer/>
                    <p>{ t("headings:processSuccess2") }</p>
                </>
            );
        }
    };

    const renderFailure = () => {
        return (
            <>
                <h1 className={styles.processTitle}>
                    { t("headings:processResultRejected") }
                </h1>
                <Spacer/>
                <InfoCircleOutlined className={styles.iconReject}/>
                <Spacer/>
                <Spacer/>
            </ >
        );
    };

    const renderLoader = () => {
        return (
            <>
                <h1 className={styles.processTitle}>Creating an application</h1>
                <Spacer/>
                <Spin size="large" />
            </ >
        );
    };

    const renderContent = () => {
        if (isSuccess != null) {
            if (isSuccess) {
                return renderSuccess();
            }
            return renderFailure();
        }

        return renderLoader();
    };

    const navigate = async () => {
        try {
            if (isSuccess) {
                const refreshSessionReq = await refreshSession(true);

                if (refreshSessionReq?.status === 200) {
                    history.push(RouteStrings.UserApplicationDrilldownBase + `${applId}`);
                } else {
                    goTo(RouteStrings.Login);
                }
            }
        } catch (error) {
            console.error(error);
            goTo(RouteStrings.Login);
        }
    };

    const prepareDisplayContent = () => {
        setTimeout(() => {
            const applDataManager = new LocalApplicationFlowDataManager();

            const appl = applDataManager.get();

            if (appl.id != null) {
                setSuccess(true);
                setApplId(appl.id);
            } else {
                setSuccess(false);
            }

        }, 1000);
    };

    const loggedInCheck = async () => {
        setIsLoggedIn(false);

        try {
            const refreshSessionReq = await refreshSession(true);

            if (refreshSessionReq?.status === 200) {
                setIsLoggedIn(true);
                return;
            }

        } catch (error) {
            // We don't care about the error here
            return;
        }
    };

    const preparePage = async () => {
        await loggedInCheck();
        prepareDisplayContent();
    };

    React.useEffect(() => {
        preparePage();
    }, []);

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus/>
            <CustomPageHeader/>
            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    {
                        renderContent()
                    }

                    <Spacer/>
                    <Spacer/>

                    { isSuccess === null ?
                        null
                        :
                        <Button data-cy="button_continue"
                            className={styles.buttonCenter}
                            type="primary"
                            size="large"
                            onClick={() => {
                                navigate(); 
                            }}>
                            { t("buttons:continue") }
                        </Button>
					 }
                    <Spacer/>
                </div>
            </div>
        </div>
    );
};
