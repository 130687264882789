import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    FileAddOutlined,
    FileOutlined
} from "@ant-design/icons";
import {Checkbox, Col, Input, message, Popconfirm, Row, Space, Spin, Table} from 'antd';
import styles from './DocumentsSection.module.scss';
import * as React from "react";
import {useState} from "react";
import Dropzone from 'react-dropzone';
import {P} from "../paragraph/P";
import {useTranslation} from "react-i18next";
import {GetDocumentsListResponse, RetrieveDocumentUploadLinkResponse} from "../../../../api/types";
import {DocumentCategorySelector} from "./DocumentCategorySelector";
import {DocumentCategory, DocumentType} from "../../../../types/types";
import {openUrlInNewTab} from "../../../../helpers/uiHelpers";
import {
    adminDeleteClientDocument,
    adminGetClientDocumentURL,
    adminRetrieveClientDocumentUploadLink,
    adminSetClientDocumentUploadedSuccessfully,
    adminUpdateClientDocument
} from "../../../../api/document-service/adminOnClientDocuments";
import {uploadFileToURL} from "../../../../api/document-service/upload";
import {
    adminDeleteApplicationDocument,
    adminGetApplicationDocumentURL,
    adminRetrieveApplicationDocumentUploadLink,
    adminSetApplicationDocumentUploadedSuccessfully,
    adminUpdateApplicationDocument
} from "../../../../api/document-service/adminOnApplicationDocuments";
import {
    cpDeleteApplicationDocument,
    cpGetApplicationDocumentURL,
    cpRetrieveApplicationDocumentUploadLink,
    cpSetApplicationDocumentUploadedSuccessfully
} from "../../../../api/document-service/contactPersonOnApplicationDocuments";
import {
    cpDeleteClientDocument,
    cpGetClientDocumentURL,
    cpRetrieveClientDocumentUploadLink,
    cpSetClientDocumentUploadedSuccessfully
} from "../../../../api/document-service/contactPersonOnClientDocuments";
import {SectionTitle} from "../section-title/SectionTitle";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import {DocumentsChecklist} from "components/common/presenters/documents-section/DocumentsChecklist";

export enum DocumentSectionActor {
    Admin,
    ContactPerson,
}

export interface DocumentsSectionProps { // TODO mandatory params
    showDocList?: boolean
    companyCountry?: string
    noDivider?: boolean
    actor: DocumentSectionActor
    entityType: DocumentType
    entityId: string // clientId, applicationId, ...
    documents: GetDocumentsListResponse[]
    onDataChanged: () => void
}

export const DocumentsSection = (props: DocumentsSectionProps) => {
    const {t} = useTranslation();

    const [isUploading, setIsUploading] = useState(false);

    const [editingId, setEditingId] = useState(null as string);
    const [editingName, setEditingName] = useState(null as string);
    const [editingCategory, setEditingCategory] = useState(null as DocumentCategory);
    const [editingClientVisible, setEditingClientVisible] = useState(null as boolean);

    const handleEditDocument = async () => {
        try {
            if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Client) {
                await adminUpdateClientDocument(props.entityId, editingId, editingName, editingCategory, editingClientVisible);
            } else if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Application) {
                await adminUpdateApplicationDocument(props.entityId, editingId, editingName, editingCategory, editingClientVisible);
            } else {
                return;
            }

            setEditingId(null);
            setEditingName(null);
            setEditingCategory(null);
            setEditingClientVisible(null);
            props.onDataChanged();
            message.success(t('messages:dataSaved'), 2);
        } catch (e) {
            console.error(e);
            message.error(t('messages:couldNotSave'), 2);
        }
    };

    const handleOpenEditing = (record: GetDocumentsListResponse) => {
        setEditingId(record.document_id);
        setEditingName(record.document_name);
        setEditingCategory(record.document_category);
        setEditingClientVisible(record.is_client_visible);
    };

    const handleCloseEditing = () => {
        setEditingId(null);
        setEditingName(null);
        setEditingCategory(null);
        setEditingClientVisible(null);
    };

    const handleViewDocument = async (documentId: string) => {
        try {
            let documentUrl: string;

            if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Client) {
                documentUrl = (await adminGetClientDocumentURL(props.entityId, documentId)).data;
            } else if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Application) {
                documentUrl = (await adminGetApplicationDocumentURL(props.entityId, documentId)).data;
            } else if (props.actor === DocumentSectionActor.ContactPerson && props.entityType === DocumentType.Client) {
                documentUrl = (await cpGetClientDocumentURL(props.entityId, documentId)).data;
            } else if (props.actor === DocumentSectionActor.ContactPerson && props.entityType === DocumentType.Application) {
                documentUrl = (await cpGetApplicationDocumentURL(props.entityId, documentId)).data;
            } else {
                return;
            }

            openUrlInNewTab(documentUrl);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    const handleDeleteDocument = async (documentId: string) => {
        try {
            if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Client) {
                await adminDeleteClientDocument(props.entityId, documentId);
            } else if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Application) {
                await adminDeleteApplicationDocument(props.entityId, documentId);
            } else if (props.actor === DocumentSectionActor.ContactPerson && props.entityType === DocumentType.Client) {
                await cpDeleteClientDocument(props.entityId, documentId);
            } else if (props.actor === DocumentSectionActor.ContactPerson && props.entityType === DocumentType.Application) {
                await cpDeleteApplicationDocument(props.entityId, documentId);
            } else {
                return;
            }

            props.onDataChanged();
            message.success(t('messages:documentDeleted'), 2);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    const columns = [
        {
            render: () => <FileOutlined className={styles.iconInRow}/>,
        },
        {
            title: t('name'),
            width: 300,
            dataIndex: 'document_name',
            render: (value, record) => renderDocumentName(record),
        },
        props.actor === DocumentSectionActor.Admin ? {
            title: t('type'),
            width: 300,
            dataIndex: 'document_category',
            render: (value, record) => renderDocumentCategory(record),
        } : {
            render: () => null,
        },
        props.actor === DocumentSectionActor.Admin ? {
            title: t('visibleToClient'),
            dataIndex: 'is_client_visible',
            render: (value, record) => renderVisibilityToggle(record),
        } : {
            render: () => null,
        },
        {
            title: t('action'),
            dataIndex: 'document_id',
            render: (value, record) => props.actor === DocumentSectionActor.Admin ?
                renderAdminActionIcons(record)
                : renderClientActionIcons(record),
        },
    ];

    const renderDocumentName = (record: GetDocumentsListResponse) => {
        return editingId === record.document_id ?
            <Input defaultValue={record.document_name}
                minLength={1}
                maxLength={255}
                onChange={(e) => setEditingName(e.target.value)}
            />
            :
            <p>{record.document_name}</p>;
    };

    const renderDocumentCategory = (record: GetDocumentsListResponse) => {
        return editingId === record.document_id ?
            <DocumentCategorySelector category={record.document_category}
                onChange={(newCategory) => {
                    setEditingCategory(newCategory);
                }}/>
            :
            <p>{t(`documentCategories:${record.document_category}`)}</p>;
    };

    const renderVisibilityToggle = (record: GetDocumentsListResponse) => {
        return (
            <Checkbox disabled={editingId !== record.document_id}
                checked={editingId === record.document_id ? editingClientVisible : record.is_client_visible}
                onChange={(e) => {
                    setEditingClientVisible(e.target.checked);
                }}
            />
        );
    };

    const renderClientActionIcons = (record: GetDocumentsListResponse) => {
        return (
            <Space direction={"horizontal"} align={"start"} size={12}>
                <EyeOutlined className={styles.iconInRow} onClick={() => handleViewDocument(record.document_id)}/>
                <Popconfirm title={t('confirmDocumentDelete')}
                    onConfirm={() => handleDeleteDocument(record.document_id)}
                    okText={t('yes')}
                    cancelText={t('no')}
                >
                    <DeleteOutlined className={styles.iconInRow}/>
                </Popconfirm>
            </Space>
        );
    };

    const renderAdminActionIcons = (record: GetDocumentsListResponse) => {
        return editingId === record.document_id ?
            <Space direction={"horizontal"} align={"start"} size={12}>
                <CheckOutlined className={styles.iconInRow} onClick={handleEditDocument}/>
                <CloseOutlined className={styles.iconInRow} onClick={handleCloseEditing}/>
            </Space>
            :
            <Space direction={"horizontal"} align={"start"} size={12}>
                {
                    editingId ?
                        <EditOutlined className={styles.inactiveIconInRow}/>
                        :
                        <EditOutlined className={styles.iconInRow} onClick={() => handleOpenEditing(record)}/>
                }
                <EyeOutlined className={styles.iconInRow} onClick={() => handleViewDocument(record.document_id)}/>
                <Popconfirm title={t('confirmDocumentDelete')}
                    onConfirm={() => handleDeleteDocument(record.document_id)}
                    okText={t('yes')}
                    cancelText={t('no')}
                >
                    <DeleteOutlined className={styles.iconInRow}/>
                </Popconfirm>
            </Space>;
    };

    const onDropFiles = async (acceptedFiles: any) => {
        setIsUploading(true);

        await Promise.all(acceptedFiles?.map(async (file) => {
            try {
                let retrieveLinkResponse: RetrieveDocumentUploadLinkResponse;

                if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Client) {
                    retrieveLinkResponse = (await adminRetrieveClientDocumentUploadLink(props.entityId, file.name, DocumentCategory.Other))?.data;
                    await uploadFileToURL(file, retrieveLinkResponse.upload_url);
                    return adminSetClientDocumentUploadedSuccessfully(props.entityId, retrieveLinkResponse.document_id);
                } else if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Application) {
                    retrieveLinkResponse = (await adminRetrieveApplicationDocumentUploadLink(props.entityId, file.name, DocumentCategory.Other))?.data;
                    await uploadFileToURL(file, retrieveLinkResponse.upload_url);
                    return adminSetApplicationDocumentUploadedSuccessfully(props.entityId, retrieveLinkResponse.document_id);
                } else if (props.actor === DocumentSectionActor.ContactPerson && props.entityType === DocumentType.Client) {
                    retrieveLinkResponse = (await cpRetrieveClientDocumentUploadLink(props.entityId, file.name))?.data;
                    await uploadFileToURL(file, retrieveLinkResponse.upload_url);
                    return cpSetClientDocumentUploadedSuccessfully(props.entityId, retrieveLinkResponse.document_id);
                } else if (props.actor === DocumentSectionActor.ContactPerson && props.entityType === DocumentType.Application) {
                    retrieveLinkResponse = (await cpRetrieveApplicationDocumentUploadLink(props.entityId, file.name))?.data;
                    await uploadFileToURL(file, retrieveLinkResponse.upload_url);
                    return cpSetApplicationDocumentUploadedSuccessfully(props.entityId, retrieveLinkResponse.document_id);
                } else {
                    return null;
                }
            } catch (e) {
                message.error(t('messages:errorEncountered'), 2);
                console.error("failed to upload file:", file?.name, e);
            }

            return null;
        }));

        setIsUploading(false);
        props.onDataChanged();
    };

    return (
        <div className={styles.tab}>
            {
                props.noDivider ?
                    null
                    :
                    <SectionTitle text={t('documents')}/>
            }

            {
                props.showDocList ?
                    <DocumentsChecklist companyCountry={props.companyCountry}/>
                    :
                    null
            }

            <Spacer hx={2}/>

            <Row className={styles.wide}
                gutter={36}
                justify={"start"}
            >
                <Col>
                    <h4 className={styles.bold}>{t('uploadDocuments')}</h4>
                    <Spacer/>
                    {
                        isUploading ?
                            <section>
                                <Spin className={styles.dropzone} size={"large"}/>
                            </section>
                            :
                            <Dropzone onDrop={onDropFiles}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div className={styles.dropzone} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <FileAddOutlined className={styles.iconAdd}/>
                                            <Spacer/>
                                            <P>{ t("dragAndDrop") } <Spacer/> { t("clickHere") }.</P>
                                            <Spacer/>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                    }
                </Col>

                <Col>
                    <h4 className={styles.bold}>{t('uploadedDocuments')}</h4>
                    <Spacer/>
                    <Table rowKey={record => record.document_id}
                        columns={columns}
                        dataSource={props.documents}
                        pagination={{position: ["bottomCenter"], pageSize: 10, total: props.documents?.length}}/>
                </Col>
            </Row>
        </div>
    );
};
