import * as React from "react";
import {useState} from "react";
import styles from "./TableSearchInputs.module.scss";
import {Button, DatePicker, Space} from "antd";
import {RedoOutlined, SearchOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {Moment} from "moment";
import {DATE_FORMAT} from "../../../../api/apiConfig";

export interface IntervalSearchProps {
    confirm: () => void
    onSearch: (from: Moment, to: Moment) => void
}

export const TimeRangeSearch = (props: IntervalSearchProps) => {
    const {t} = useTranslation();
    const [from, setFrom] = useState<Moment>();
    const [to, setTo] = useState<Moment>();

    const onFinishSearch = () => {
        props.onSearch(from, to);
        props.confirm();
    };

    const onReset = () => {
        setFrom(null);
        setTo(null);
        props.onSearch(null, null);
        props.confirm();
    };

    return (
        <Space className={styles.container}
            direction={"vertical"}
        >
            <DatePicker.RangePicker format={DATE_FORMAT.DE}
                allowEmpty={[true, true]}
                onChange={(e) => {
                    const [from, to] = e ? e : [null, null];
                    setFrom(from);
                    setTo(to);
                }}
            />

            <Space>
                <Button type={"primary"}
                    icon={<SearchOutlined />}
                    onClick={onFinishSearch}
                >
                    {t('search')}
                </Button>

                <Button type={"primary"}
                    icon={<RedoOutlined />}
                    onClick={onReset}
                >
                    {t('buttons:reset')}
                </Button>
            </Space>
        </Space>
    );
};
