import * as React from "react";
import styles from './SubmitTab.module.scss';
import { Button} from "antd";
import { Spacer } from "components/common/presenters/spacer/Spacer";

export interface ContinueTabProps {
    title: string
    error: string
    loading: boolean
    handleContinue: () => any
}

export const SubmitTab = (props: ContinueTabProps) => {
    return (
        <div className={styles.tabXL}>
            <h2>{props.title}</h2>
            <Spacer/>
            <p>Mit dem Abschicken des Antrags stimmen Sie den <a>Allgemeinen Geschäftsbedingungen</a> zu.</p>

            <Spacer/>
            <div className="custom-error"></div>

            <Button type="primary" loading={props.loading}
                onClick={() => props.handleContinue()}>
                  Weiter
            </Button>
        </div>
    );
};
