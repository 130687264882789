import {Button, Col, DatePicker, Input, Row, Select} from "antd";
import {CountriesDropdown} from "components/common/presenters/countries-dropdown/CountriesDropdown";
import {CurrenciesDropdown} from "components/common/presenters/currencies-dropdown/CurrenciesDropdown";
import {SideMenu} from "components/common/presenters/side-menu/SideMenu";
import {goTo} from "navigation/navigationHelpers";
import * as React from "react";
import {useTranslation} from 'react-i18next';
import {RouteStrings} from "Routes";
import styles from './PaymentMenuPage.module.scss';
import {SectionTitle} from "../../../common/presenters/section-title/SectionTitle";
import { Spacer } from "components/common/presenters/spacer/Spacer";


export const PaymentMenuPage = () => {
    const { t } = useTranslation();

    // let { applicationId } = useParams();

    return (
        <div className={styles.page}>
            <SideMenu isLoggedIn={true}/>

            <div className={styles.sectionRight}>
                {/* <h2>{t("paymentMenu")}</h2> */}
                <Spacer hx={2}/>


                <div className={styles.tab}>
                    <SectionTitle text={t("createPayment")}/>

                    <Row>
                        <Col>
                            <h4>{t("labels:paymentType")}</h4>
                            <Select
                                data-cy="dropdown_payment_type"
                                defaultValue="1"
                                onChange={() => { }}>
                                <Select.Option value="1">Kreditrückzahlung</Select.Option>
                                <Select.Option value="2">Lorem Ipsum</Select.Option>
                                <Select.Option value="3">Lorem Ipsum</Select.Option>
                            </Select>
                        </Col>
                    </Row>
                    
                    <Spacer hx={1}/>

                    <Row gutter={24}>
                        <Col>
                            <CountriesDropdown />
                        </Col>

                        <Col>
                            <CurrenciesDropdown />
                        </Col>

                        <Col>
                            <h4>{t("currency")}</h4>
                            <Select
                                data-cy="dropdown_transaction_code"
                                defaultValue="10"
                                onChange={() => { }}>
                                <Select.Option value="1">1 - Lorem Ipsum</Select.Option>
                                <Select.Option value="2">2 - Lorem Ipsum</Select.Option>
                                <Select.Option value="10">10 - Manueller Eingang</Select.Option>
                            </Select>
                        </Col>

                        <Col>
                            <h4>{t("labels:loanAccountNumber")}</h4>
                            <Input
                                data-cy="input_invoice_number"
                                // value={state.phone}
                                onChange={() => {
                                    // setState({...state, phone: e.target.value})
                                }}
                            />
                        </Col>

                        <Col>
                            <h4>{t("labels:dateOfReceipt")}</h4>
                            <DatePicker
                                data-cy="input_dateOfReceipt"
                                className={styles.date}
                                format={'DD/MM/YYYY'}
                                allowClear={false}
                                // value={moment(applicationData.repaymentDeadline)}
                                onChange={() => {
                                    // 	moment(applicationData.repaymentDeadline).format('DD/MM/YYYY'),
                                    // 	e.format('DD/MM/YYYY')
                                    // )
                                }}
                            />
                        </Col>
                    </Row>

                    <Spacer hx={1}/>

                    <Row gutter={24}>
                        <Col>
                            <h4>{t("labels:bankAccount")}</h4>
                            <Select
                                data-cy="dropdown_bank_account"
                                defaultValue="1"
                                onChange={() => { }}>
                                <Select.Option value="1">DE12 1234 1243 1234 1234 - USD - Deutschland</Select.Option>
                                <Select.Option value="2">DE12 1231 1231 1231 1231 - EUR - Deutschland</Select.Option>
                                <Select.Option value="3">DE12 1232 1232 1232 1232 - CHF - Deutschland</Select.Option>
                            </Select>
                        </Col>

                        <Col>
                            <h4>{t("labels:amount")}</h4>
                            <Input
                                data-cy="input_amount"
                                // value={state.phone}
                                onChange={() => {
                                    // setState({...state, phone: e.target.value})
                                }}
                            />
                        </Col>

                        <Col>
                            <h4 className={styles.emptyTitleVAlign}>{" "}</h4>
                            <Button className={styles.button} type="primary" size="large"
                                onClick={() => {
                                    goTo(RouteStrings.AdminPaymentMenu); 
                                }}>
                                {t("buttons:addTransaction")}
                            </Button>
                        </Col>
                    </Row>

                    <Spacer hx={2}/>

                    <Row gutter={24}>
                        <Col span={12}>
                            <div><b>Rechnungsnummer:</b></div>
                            <div>Rechnungsbetrag:</div>
                            <div>Einzahlung:</div>
                            <div>Differenz:</div>
                            <div>Status nach Erfassung:</div>
                            <div>Transaktionsnummer:</div>
                        </Col>
                        <Col span={12}>
                            <div>8812888382882</div>
                            <div>€1.000,00</div>
                            <div>€900,00</div>
                            <div>€100,00</div>
                            <div>1 - Partially Paid</div>
                            <div>8812888382882</div>
                            <div>abc-123-123</div>
                        </Col>
                    </Row>
                </div>

            </div>
        </div>
    );
};
