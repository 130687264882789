import {InfoCircleOutlined} from '@ant-design/icons';
import {Button, Checkbox, Col, Form, Input, Popover, Row} from 'antd';
import {ApplicationScore, ClientScore, ClientOrApplicationScoreChecklist, ScoreChecklistItem} from 'api/types';
import styles from 'components/admin/pages/application-drilldown/ApplicationDrilldownPage.module.scss';
import * as React from "react";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {SectionTitle} from "../../../../common/presenters/section-title/SectionTitle";
import { Spacer } from 'components/common/presenters/spacer/Spacer';
import { roundTo2DecimalPlacesWithNaNReplace } from 'formatters/numberFormatters';

export interface ScoringTabProps {
    isClientScore?: boolean
    score: ApplicationScore | ClientScore
    loading: boolean
    error: string
    onKPIsSave: (values: any, checklistItems?: ScoreChecklistItem[]) => void
}

export const ScoringTab = (props: ScoringTabProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const valCheck = (val: any) => {
        return val != null && !isNaN(val);
    };
    let equityCss = classnames(styles.red, { 
        [styles.green]: valCheck(props.score.equity_ratio_previous_year) && props.score.equity_ratio_previous_year >= 0.1
    });
    let currentRatioCss = classnames(styles.red, { 
        [styles.green]: valCheck(props.score.current_ratio_previous_year) && props.score.current_ratio_previous_year > 1
    });
    let debtToEbitdaRatioCss = classnames(styles.red, { 
        [styles.green]: valCheck(props.score.debt_to_ebitda_ratio_previous_year) && props.score.debt_to_ebitda_ratio_previous_year < 7
    });
    let debtToEquityRatioCss = classnames(styles.red, { 
        [styles.green]: valCheck(props.score.debt_to_equity_ratio_previous_year) && props.score.debt_to_equity_ratio_previous_year < 5
    });

    let equityCssCy = classnames(styles.red, { 
        [styles.green]: valCheck(props.score.equity_ratio_current_year) && props.score.equity_ratio_current_year >= 0.1
    });
    let currentRatioCssCy = classnames(styles.red, { 
        [styles.green]: valCheck(props.score.current_ratio_current_year) && props.score.current_ratio_current_year > 1
    });
    let debtToEbitdaRatioCssCy = classnames(styles.red, { 
        [styles.green]: valCheck(props.score.debt_to_ebitda_ratio_current_year) && props.score.debt_to_ebitda_ratio_current_year < 7
    });
    let debtToEquityRatioCssCy = classnames(styles.red, { 
        [styles.green]: valCheck(props.score.debt_to_equity_ratio_current_year) && props.score.debt_to_equity_ratio_current_year < 5
    });
    let revenueGrowthCss = classnames(styles.red, { 
        [styles.green]: valCheck(props.score.revenue_growth) && props.score.revenue_growth > 0
    });
    // let netIncomeCss = classnames(styles.red, { 
    //     [styles.green]: props.score.netIncome != null && !isNaN(props.score.netIncome) && props.score.netIncome > 0
    // });

    const tooltipOne = <div>
		• Paid-in Capital
        <Spacer/>
		• Capital Increase
        <Spacer/>
		• Retained Earnings
        <Spacer/>
		• Net profit/ Net loss of FY
    </div>;

    const tooltipTwo = <div>
		Assets <i>(e.g. lending)</i> or Investments
        <Spacer/>
		which are not related to the operational purpose
        <Spacer/>
		need to be deducted from the economic 
        <Spacer/>
		equity <i>(Firmenwert/Goodwill, aktivierte Eigenleistungen,</i>
        <Spacer/> 
        <i>selbst geschaffene immat. Vermögensgegenstände)</i>
    </div>;

    const tooltipThree = <div>
		• Work in progress and unfinished services 
        <Spacer/>
		• Trade accounts receivable 
        <Spacer/>
		• Checks, Bank account, etc. 
        <Spacer/>
		• Other current assets 
    </div>;

    const tooltipFour = <div>
		• Trade accounts payable
        <Spacer/>
		• Short term <i>(&lt; 1 year)</i> loan installments
        <Spacer/>
		• Other current liabilities 
        <Spacer/>
    </div>;

    const tooltipFive = <div>
		• Operating Revenue - COGS - SG&amp;A 
        <Spacer/>
		• No “aktivierte Eigenleistung” or “non- operating revenues“
        <Spacer/>
    </div>;

    const tooltipSix = <div>
		All interest bearing debt <i>(bank loans, shareholder loans etc.)</i>
    </div>;

    // TODO: The Scoring Section of the Admin Panel is ONLY able to be edited in the “In Review - Risk” and “In Review - Operations”.

    let formData = {
        ...props.score
    };
    
    try {
        const checklistItems: any = {};
        (props.score as any)?.checklist?.items?.map((item: ScoreChecklistItem) => {
            checklistItems[item.name] = item.is_checked;
            return null;
        });

        formData = {
            ...props.score,
            ...checklistItems
        };
    } catch (error) {
        console.error(error);
    }


    const handleOnKPIsSave = (values) => {
        const checkListItems = [];
        (props.score as any).checklist.items.map(item => {
            checkListItems.push({
                name: item.name,
                is_checked: !!values[item.name] // cast to boolean
            });
            delete values[item.name]; // this object is sent to the BE - we don't want to polute it

            return null;
        });

        props.onKPIsSave(values, checkListItems);
    };

    const renderChecklist = () => {
        const checklist = (props.score as any)?.checklist as ClientOrApplicationScoreChecklist;

        const items = checklist?.items.map(item => {
            return (
                <Form.Item key={item.name} name={item.name}
                    valuePropName="checked" label={""}>
                    <Checkbox>
                        { t(`checklistItems:${item.name}`) }
                    </Checkbox>
                </Form.Item>
            );
        });

        return items;
    };

    return (
        <div className={styles.tabXL}>
            <SectionTitle text={"Risikoprüfung"}/>

            <div className={styles.container}>

                <Form id="applScore"
                    className={styles.form}
                    layout={"vertical"}
                    form={form}
                    initialValues={formData}
                    onFinish={handleOnKPIsSave}
                    scrollToFirstError
                >
                    {
                        renderChecklist()
                    }
                  
                    <Spacer/>

                    <SectionTitle text={"KPI Check"}/>
            
                    <Row gutter={24}>
                        <Col>
                            <Form.Item name="previous_year" label={t("prevYear")} hasFeedback
                                rules={[{ required: false, message: 'Please input a valid year.', min: 4, max: 4 }]}>
                                <Input data-cy="previous_year"
                                    className={styles.input} placeholder={t("example") + ": 2019"}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name="current_year" label={t("currentYear")} hasFeedback
                                rules={[{ required: false, message: 'Please input a valid year.', min: 4, max: 4 }]}>
                                <Input data-cy="current_year"
                                    className={styles.input} placeholder={t("example") + ": 2020"}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Spacer hx={2}/>

                    <h3>Revenue</h3>
                    <Row gutter={24}>
                        <Col>
                            <Form.Item name="revenue_previous_year" label={t("prevYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="revenue_previous_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name="revenue_current_year" label={t("currentYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="revenue_current_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Popover content={tooltipOne} title="Info">
                                <InfoCircleOutlined className={styles.iconInfo} />
                            </Popover>
                        </Col>
                    </Row>

                    <Spacer hx={2}/>

                    <h3>Equity</h3>
                    <Row gutter={24}>
                        <Col>
                            <Form.Item name="equity_previous_year" label={t("prevYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="equity_previous_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name="equity_current_year" label={t("currentYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="equity_current_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Popover content={tooltipOne} title="Info">
                                <InfoCircleOutlined className={styles.iconInfo} />
                            </Popover>
                        </Col>
                    </Row>
                    
                    <Spacer hx={2}/>

                    <h3>Total Assets</h3>
                    <Row gutter={24}>
                        <Col>
                            <Form.Item name="total_assets_previous_year" label={t("prevYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="total_assets_previous_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name="total_assets_current_year" label={t("currentYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="total_assets_current_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Popover content={tooltipTwo} title="Info">
                                <InfoCircleOutlined className={styles.iconInfo} />
                            </Popover>
                        </Col>
                    </Row>

                    <Spacer hx={2}/>

                    <h3>Current Assets</h3>
                    <Row gutter={24}>
                        <Col>
                            <Form.Item name="current_assets_previous_year" label={t("prevYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="current_assets_previous_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name="current_assets_current_year" label={t("currentYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="current_assets_current_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Popover content={tooltipThree} title="Info">
                                <InfoCircleOutlined className={styles.iconInfo} />
                            </Popover>
                        </Col>
                    </Row>

                    <Spacer hx={2}/>

                    <h3>Current Liabilities</h3>
                    <Row gutter={24}>
                        <Col>
                            <Form.Item name="current_liabilities_previous_year" label={t("prevYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="current_liabilities_previous_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name="current_liabilities_current_year" label={t("currentYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="current_liabilities_current_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Popover content={tooltipFour} title="Info">
                                <InfoCircleOutlined className={styles.iconInfo} />
                            </Popover>
                        </Col>
                    </Row>
                    
                    <Spacer hx={2}/>

                    <h3>EBITDA</h3>
                    <Row gutter={24}>
                        <Col>
                            <Form.Item name="ebitda_previous_year" label={t("prevYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="ebitda_previous_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name="ebitda_current_year" label={t("currentYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="ebitda_current_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Popover content={tooltipFive} title="Info">
                                <InfoCircleOutlined className={styles.iconInfo} />
                            </Popover>
                        </Col>
                    </Row>

                    <Spacer hx={2}/>

                    <h3>Total Debt</h3>
                    <Row gutter={24}>
                        <Col>
                            <Form.Item name="total_debt_previous_year" label={t("prevYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="total_debt_previous_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name="total_debt_current_year" label={t("currentYear")} hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                                <Input data-cy="total_debt_current_year"
                                    type={"number"}
                                    className={styles.input}/>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Popover content={tooltipSix} title="Info">
                                <InfoCircleOutlined className={styles.iconInfo} />
                            </Popover>
                        </Col>
                    </Row>
                    <Spacer hx={2}/>

                    <Row gutter={24}>
                        <Col span={8}>
                            <h2>KPIs - { props.score.previous_year }</h2>
                            <Row>
                                <Col span={12}>
                                    <h4>Equity Ratio</h4>
                                </Col>
                                <Col span={8}>
                                    <h4 className={equityCss}>{roundTo2DecimalPlacesWithNaNReplace(props.score.equity_ratio_previous_year)}</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <h4>Current Ratio</h4>
                                </Col>
                                <Col span={8}>
                                    <h4 className={currentRatioCss}>{roundTo2DecimalPlacesWithNaNReplace(props.score.current_ratio_previous_year)}</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <h4>Debt to EBITDA Ratio</h4>
                                </Col>
                                <Col span={8}>
                                    <h4 className={debtToEbitdaRatioCss}>{roundTo2DecimalPlacesWithNaNReplace(props.score.debt_to_ebitda_ratio_previous_year)}</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <h4>Debt to Equity Ratio</h4>
                                </Col>
                                <Col span={8}>
                                    <h4 className={debtToEquityRatioCss}>{roundTo2DecimalPlacesWithNaNReplace(props.score.debt_to_equity_ratio_previous_year)}</h4>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={8}>
                            <h2>KPIs - { props.score.current_year }</h2>

                            <Row>
                                <Col span={12}>
                                    <h4>Equity Ratio</h4>
                                </Col>
                                <Col span={8}>
                                    <h4 className={equityCssCy}>{roundTo2DecimalPlacesWithNaNReplace(props.score.equity_ratio_current_year)}</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <h4>Current Ratio</h4>
                                </Col>
                                <Col span={8}>
                                    <h4 className={currentRatioCssCy}>{roundTo2DecimalPlacesWithNaNReplace(props.score.current_ratio_current_year)}</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <h4>Debt to EBITDA Ratio</h4>
                                </Col>
                                <Col span={8}>
                                    <h4 className={debtToEbitdaRatioCssCy}>{roundTo2DecimalPlacesWithNaNReplace(props.score.debt_to_ebitda_ratio_current_year)}</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <h4>Debt to Equity Ratio</h4>
                                </Col>
                                <Col span={8}>
                                    <h4 className={debtToEquityRatioCssCy}>{roundTo2DecimalPlacesWithNaNReplace(props.score.debt_to_equity_ratio_current_year)}</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <h4>Revenue Growth</h4>
                                </Col>
                                <Col span={8}>
                                    <div className={revenueGrowthCss}>{roundTo2DecimalPlacesWithNaNReplace(props.score.revenue_growth)}</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Spacer/>

                    <SectionTitle text={"Analysis"}/>
                    <Form.Item name="analysis_note" label={""} hasFeedback
                        rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100000000 }]}>
                        <Input.TextArea className={styles.textarea} style={{ minHeight: "200px" }}/>
                    </Form.Item>

                    {/* {
                        isApproved ? 
                            <>
                                <label>Interest Rate</label>
                                <Input className={styles.input} placeholder="" maxLength={50} onChange={() => { }} />
                                <Spacer/>

                                <label>Processing Fee</label>
                                <Input className={styles.input} placeholder="" maxLength={50} onChange={() => { }} />
                                <Spacer/>
                            </>
                            :
                            null
                    } */}

                    <Spacer/>

                    <div className={styles.error}>{props.error}</div>
                    <Spacer/>

                    <Form.Item>
                        <Row className={styles.rowCenterX}>
                            <Button type="primary" htmlType="submit" loading={props.loading}>{t("buttons:save")}</Button>
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
