import * as React from "react";
import {useState} from "react";
import styles from './UserApplicationDrilldownPage.module.scss';
import {CPGetApplicationResponse} from "../../../../api/types";
import {DataItemsColumn} from "../../presenters/data-items-column/DataItemsColumn";
import {Button, Space, Typography} from "antd";
import {SectionTitle} from "../../presenters/section-title/SectionTitle";
import {useTranslation} from "react-i18next";
import {MailOutlined, PhoneOutlined} from "@ant-design/icons";
import {Spacer} from "../../presenters/spacer/Spacer";
import {ApplicationFlowType} from "../../../../storage/LocalAppDataManager";
import {currencyLocaleFormatter} from "../../../../formatters/currencyFormatters";
import {UpdateApplicationModal} from "./UpdateApplicationModal";

export interface NextStepsSectionProps {
    application: CPGetApplicationResponse
    onDataChanged: () => void
}

export const NextStepsSection = (props: NextStepsSectionProps) => {
    const {t} = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    const getFullAddress = (): string => {
        let result: string = "";

        if (props.application.company_house_number && props.application.company_street) {
            result += `${props.application.company_house_number} ${props.application.company_street}\n`;
        }

        if (props.application.company_post_code) {
            result += `${props.application.company_post_code} `;
        }

        if (props.application.company_city) {
            result += `${props.application.company_city}`;
        }

        if (props.application.company_country) {
            result += `\n${t(`countryCodes:${props.application.company_country}`)}`;
        }

        return result;
    };

    return (
        <div className={styles.card}>
            <SectionTitle text={t('sectionTitles:nextSteps')}/>
            <Typography.Text className={styles.nextStepsText}>
                {t('cpApplicationNextSteps')}
            </Typography.Text>
            <Space>
                <PhoneOutlined/>
                <Typography.Text strong>{t('sveaContactPhoneNumber')}</Typography.Text>
            </Space>
            <Space>
                <MailOutlined/>
                <Typography.Text strong>{t('sveaContactEmail')}</Typography.Text>
            </Space>

            <Spacer hx={2}/>

            <SectionTitle text={t('loanInformation')}/>
            {
                props.application.type === ApplicationFlowType.Loan ?
                    <DataItemsColumn dataItems={[
                        {
                            itemName: t("loanAmount"), itemValue: props.application.amount ?
                                `${t(`currencySymbols:${props.application.currency}`)} ${currencyLocaleFormatter(props.application.amount)}` : "-"
                        },
                        {
                            itemName: t('loanDuration'),
                            itemValue: props.application.duration ? `${props.application.duration} ${t('months')}` : "-"
                        },
                        {itemName: t('currency'), itemValue: props.application.currency},
                        {itemName: t('loanPurpose'), itemValue: props.application.purpose_info},
                    ]}/>
                    :
                    <DataItemsColumn dataItems={[
                        {
                            itemName: t("factoringVolume"), itemValue: props.application.amount ?
                                `${t(`currencySymbols:${props.application.currency}`)} ${currencyLocaleFormatter(props.application.amount)}` : "-"
                        },
                        {itemName: t('currency'), itemValue: props.application.currency},
                        {itemName: t('largestDebitors2'), itemValue: props.application.largest_debitors_list},
                    ]}/>
            }

            <SectionTitle text={t('sectionTitles:companyInfo')}/>
            <DataItemsColumn dataItems={[
                {itemName: t("company"), itemValue: props.application.company_name},
                {itemName: t("company_legal_form"), itemValue: props.application.company_type},
                {itemName: t("number_of_employees"), itemValue: props.application.company_number_of_employees},
                {itemName: t("taxIdNumberShort"), itemValue: props.application.tax_id},
                {itemName: t("companyRegisterNumber"), itemValue: props.application.company_register_number},
                {itemName: t("companyDesc"), itemValue: props.application.company_purpose},
                {itemName: t("companyAddress"), itemValue: getFullAddress()},
            ]}/>
            <Spacer/>

            <Button type={"primary"}
                onClick={() => setModalOpen(true)}
            >
                {t('buttons:adjustData')}
            </Button>

            <UpdateApplicationModal application={props.application}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onDataChanged={props.onDataChanged}
            />
        </div>
    );
};
