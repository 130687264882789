import {ApplicationFlowType} from "storage/LocalAppDataManager";
import {
    NoteCategory,
    ApplicationStates,
    BoUserRole,
    ClientFinancingType,
    ClientType,
    Currencies,
    DocumentCategory,
    LeadStatus,
    TodoEntityKind,
    TodoKind,
    TodoStatus
} from "types/types";

export interface PaginationResponse {
    total_rows: number
    current_page: number
    items_per_page: number
}

export interface FirmenWissenCompanyData {
    crefonummer: string
    identnummer: string
    name: string
    handelsName: string
    land: string
    bundesland: string
    plz: string
    ort: string
    strasseHausnummer: string
}

export interface CrefoResult {
    companyNameSuggestions: FirmenWissenCompanyData[]
}

export interface GetNotesListResponse {
    note_id?: string
    author_id?: string
    author_name?: string
    category?: NoteCategory
    note_text?: string
    created_at?: string // timestamp
}

export interface ApplicationReview {
    review_id: string
    created_at: string
    factoring_contract_settled: boolean
    global_cession: boolean
    svea_terms_accepted: boolean
    security_guarantee: boolean
    bank_negative_guarantee: boolean
    signature_list: boolean
    id_copy_of_representative: boolean
    company_registry_statement: boolean
    current_debitors_creditors_list: boolean
    last_annual_statement: boolean
    bwa_list: boolean
    confidential_questionary: boolean
    cash_note: boolean
    self_declaration: boolean
    owners_verification_with_info: boolean
}

export interface PostOrPutApplicationReview {
    factoring_contract_settled: boolean
    global_cession: boolean
    svea_terms_accepted: boolean
    security_guarantee: boolean
    bank_negative_guarantee: boolean
    signature_list: boolean
    id_copy_of_representative: boolean
    company_registry_statement: boolean
    current_debitors_creditors_list: boolean
    last_annual_statement: boolean
    bwa_list: boolean
    confidential_questionary: boolean
    cash_note: boolean
    self_declaration: boolean
    owners_verification_with_info: boolean
}

export interface ApplicationFilter {
    status?: ApplicationStates
    type?: ApplicationFlowType
    source?: string
    id?: string
}

export interface PostOrPutApplicationScore {
    checklist: ClientOrApplicationScoreChecklist
    currency: string
    previous_year: number
    current_year: number
    revenue_previous_year: number
    revenue_current_year: number
    equity_previous_year: number
    equity_current_year: number
    total_assets_previous_year: number
    total_assets_current_year: number
    current_assets_previous_year: number
    current_assets_current_year: number
    current_liabilities_previous_year: number
    current_liabilities_current_year: number
    ebitda_previous_year: number
    ebitda_current_year: number
    total_debt_previous_year: number
    total_debt_current_year: number
    analysis_note: string
}

export interface ApplicationScore {
    score_id: string
    application_id: string
    created_at: string
    checklist: ClientOrApplicationScoreChecklist
    currency: string
    previous_year: number
    current_year: number
    revenue_previous_year: number
    revenue_current_year: number
    equity_previous_year: number
    equity_current_year: number
    total_assets_previous_year: number
    total_assets_current_year: number
    current_assets_previous_year: number
    current_assets_current_year: number
    current_liabilities_previous_year: number
    current_liabilities_current_year: number
    ebitda_previous_year: number
    ebitda_current_year: number
    total_debt_previous_year: number
    total_debt_current_year: number
    equity_ratio_previous_year: number
    equity_ratio_current_year: number
    current_ratio_previous_year: number
    current_ratio_current_year: number
    debt_to_ebitda_ratio_previous_year: number
    debt_to_ebitda_ratio_current_year: number
    debt_to_equity_ratio_previous_year: number
    debt_to_equity_ratio_current_year: number
    revenue_growth: number
    analysis_note: string
}

export enum ScoreChecklistTypes {
    Loan_Checklist = "loan_checklist",
    Factoring_Checklist = "factoring_checklist",
    Client_Checklist = "client_checklist"
}

export interface ScoreChecklistItem {
    name: string
    is_checked: boolean
}

export interface ClientOrApplicationScoreChecklist {
    checklist_type: ScoreChecklistTypes
    version: number
    valid_from?: string // timestamp
    items: ScoreChecklistItem[]
}

export interface PostOrPutClientScore {
    checklist: ClientOrApplicationScoreChecklist
    currency: number
    previous_year: number
    current_year: number
    revenue_previous_year: number
    revenue_current_year: number
    equity_previous_year: number
    equity_current_year: number
    total_assets_previous_year: number
    total_assets_current_year: number
    current_assets_previous_year: number
    current_assets_current_year: number
    current_liabilities_previous_year: number
    current_liabilities_current_year: number
    ebitda_previous_year: number
    ebitda_current_year: number
    total_debt_previous_year: number
    total_debt_current_year: number
    analysis_note: string
}

export interface ClientScore {
    score_id: string
    client_id: string
    created_at: string
    checklist: ClientOrApplicationScoreChecklist
    currency: number
    previous_year: number
    current_year: number
    revenue_previous_year: number
    revenue_current_year: number
    equity_previous_year: number
    equity_current_year: number
    total_assets_previous_year: number
    total_assets_current_year: number
    current_assets_previous_year: number
    current_assets_current_year: number
    current_liabilities_previous_year: number
    current_liabilities_current_year: number
    ebitda_previous_year: number
    ebitda_current_year: number
    total_debt_previous_year: number
    total_debt_current_year: number
    equity_ratio_previous_year: number
    equity_ratio_current_year: number
    current_ratio_previous_year: number
    current_ratio_current_year: number
    debt_to_ebitda_ratio_previous_year: number
    debt_to_ebitda_ratio_current_year: number
    debt_to_equity_ratio_previous_year: number
    debt_to_equity_ratio_current_year: number
    revenue_growth: number
    analysis_note: string
}

export interface PutApplicationBankInfoReq {
    client_id: string
    bank_name: string
    iban: string
}

export interface PutApplicationStatusReq {
    client_id: string
    status: ApplicationStates
}

export interface PutApplicationKYCConfirmReq {
    client_id: string
    amount: number
    duration: number
    interest_rate: number
    required_signature: string
}

export interface CPCreateApplicationRequest {
    client: CPPostClient
    application: CPPostApplication
}

export interface CPPostClient {
    client_type: string
    contact_person_id?: string
    contact_person_email?: string
    company_name?: string
    company_legal_form?: string
    company_register_number?: string
    company_industry?: string
    company_product?: string
    company_number_of_employees?: string
    address_addition?: string
    address_street?: string
    address_house_number?: string
    address_post_code?: string
    address_city?: string
    address_region?: string
    address_country?: string
    salutation?: string
    title?: string
    first_name?: string
    last_name?: string
    phone?: string
    mobile_number?: string
    newsletter_subscription?: boolean

    credentials?: {
        email: string
        password: string
        is_email_confirmed: boolean
        is_enabled: boolean
    }
}

export interface CPPostApplication {
    type: ApplicationFlowType
    currency: string
    amount: number
    duration: number
    company_purpose: string
    purpose_info: string
    largest_debitors_list: string
    tax_id: string
}

export interface PostContactPerson {
    client_id: string
    email: string
    salutation: string
    title: string
    gender: string
    first_name: string
    last_name: string
    birth_date: string
    birth_place: string
    nationality: string
    phone: string
    mobile_number: string
    passport_number: string
    address_country: string
    address_addition: string
    address_street: string
    address_house_number: string
    address_post_code: string
    address_city: string
    newsletter_subscription: boolean
    created_at: string
    is_deleted: boolean
}

export interface UpdateContactPersonProfileRequest {
    email: string
    salutation?: string
    title?: string
    gender?: string
    first_name?: string
    last_name?: string
    birth_date?: string
    birth_place?: string
    nationality?: string
    phone?: string
    mobile_number?: string
    passport_number?: string
    address_country?: string
    address_addition?: string
    address_street?: string
    address_house_number?: string
    address_post_code?: string
    address_city?: string
    newsletter_subscription?: boolean
}

export interface ContactPerson {
    contact_person_id: string
    email: string
    salutation: string
    title: string
    gender: string
    first_name: string
    last_name: string
    birth_date: string
    birth_place: string
    nationality: string
    phone: string
    mobile_number: string
    passport_number: string
    address_country: string
    address_addition: string
    address_street: string
    address_house_number: string
    address_post_code: string
    address_city: string
    newsletter_subscription: boolean
    created_at: string
    is_deleted: boolean
}

export interface ContactPersonClient extends ContactPerson {
    is_signatory: boolean
    is_sole_signatory: boolean
    is_beneficial_owner: boolean
    pct_owned_shares: string
}

export enum ClientTypes {
    Lead = "lead",
    Client = "client"
}

export interface LoginRequest {
    email: string
    password: string
}

export interface LogoutRequest {
    email: string
    password: string
}

export interface ApiResponse<T> {
    data?: T
    status: number
}

export interface PostClientResponse {
    client_id: string
    contact_person_id: string
}

export interface PutClientRequest { // POST and PATCH endpoints accept this
    client_type: string
    company_name?: string
    company_legal_form?: string
    company_register_number?: string
    company_industry?: string
    company_product?: string
    company_number_of_employees?: string
    address_addition?: string
    address_street?: string
    address_house_number?: string
    address_post_code?: string
    address_city?: string
    address_region?: string
    address_country?: string
    language?: string
    company_website?: string
    tax_number?: string
    lead_status?: string
    lead_source?: string
    financing_type?: string
    lead_currency?: string
    lead_expected_volume?: number
    lead_expected_duration?: number
    bo_contact_name?: string
    broker_name?: string
    note?: string
}

export interface GetClientsListResponse {
    client_id?: string
    short_id?: string
    company_name?: string
    company_legal_form?: string
    bo_contact_name?: string
    address_country?: string
    address_region?: string
    lead_status?: LeadStatus
    lead_status_updated_at?: string // timestamp
    financing_type?: ClientFinancingType
    lead_currency?: Currencies
    lead_expected_volume?: number
    created_at?: string // timestamp
}

export interface GetClientResponse {
    client_id?: string
    short_id?: string
    client_type?: ClientType
    company_name?: string
    company_legal_form?: string
    company_register_number?: string
    company_industry?: string
    company_product?: string
    company_number_of_employees?: string
    address_addition?: string
    address_street?: string
    address_house_number?: string
    address_post_code?: string
    address_city?: string
    address_region?: string
    address_country?: string
    language?: string
    company_website?: string
    tax_number?: string
    lead_status?: LeadStatus
    lead_source?: string
    financing_type?: ClientFinancingType
    lead_currency?: string
    lead_expected_volume?: number
    lead_expected_duration?: number
    bo_contact_name?: string
    broker_name?: string
    created_at?: string // timestamp
    is_deleted?: boolean
    lead_status_updated_at?: string // timestamp
    archive_initiated_at?: string // timestamp

    bank_name?: string
    iban?: string

    // joint entities
    has_application?: boolean
    is_account_created?: boolean
    contact_persons?: ContactPersonClient[]
}

export interface PatchClientStatusRequest {
    new_status: string
    reason: string
}

export interface PatchClientBankDetailsRequest {
    bank_name: string
    iban: string
}

export interface GetContactPersonResponse { // not final
    contact_person_id?: string
    email?: string
    salutation?: string
    title?: string
    gender?: string
    first_name?: string
    last_name?: string
    birth_date?: string // date 2020-01-30
    birth_place?: string
    nationality?: string
    phone?: string
    mobile_phone?: string
    passport_number?: string
    address_country?: string
    address_addition?: string
    address_street?: string
    address_house_number?: string
    address_post_code?: string
    address_city?: string
    created_at?: string // timestamp
    is_deleted?: boolean
}

export interface CPGetApplicationResponse { // not final
    id: string
    short_id: string
    client_id: string
    bo_assignee_id: string
    contact_person_id: string
    type: ApplicationFlowType
    currency: Currencies
    amount: string // Decimal Number!!
    duration: number
    interest_rate: string // Decimal Number!!
    status: ApplicationStates
    company_name: string
    company_type: string
    company_country: string
    company_post_code: string
    company_number_of_employees: string
    company_street: string
    company_house_number: string
    company_city: string
    company_purpose: string
    company_register_number: string
    purpose: string
    purpose_info: string
    largest_debitors_list: string
    tax_id: string
    bank_name: string
    iban: string
    contract_id: string
    created_at: string // timestamp
    paid_out_on: string // timestamp
}

export interface CPPutApplicationDetails {
    amount: number
    duration: number
    currency: string
    purpose_info: string
    largest_debitors_list: string
    company_name: string
    company_type: string
    company_number_of_employees: string
    tax_id: string
    company_register_number: string
    company_purpose: string
    company_country: string
    company_street: string
    company_house_number: string
    company_post_code: string
    company_city: string
}

export interface GetDocumentsListResponse {
    document_id?: string
    document_name?: string
    document_category?: DocumentCategory
    is_client_visible?: boolean // always undefined for client api response
    created_at?: string // timestamp
}

export interface RetrieveDocumentUploadLinkResponse {
    document_id: string
    upload_url: string
}

export interface GetBoUsersListItem {
    bo_user_id?: string
    bo_user_name?: string
    email?: string
    role?: BoUserRole
    is_email_confirmed?: boolean
    is_deleted?: boolean
}

export interface GetAdminProfileResponse {
    bo_user_id?: string
    bo_user_name?: string
    email?: string
    role?: BoUserRole
}

export interface PostGenerateOfferForClientRequest {
    contact_person_id: string
    interest_rate_30_days: number
    interest_rate_60_days: number
    admin_fee_per_invoice: number
    max_advance_payment_percent: number
    is_with_global_assignment: boolean
    is_selective_factoring: boolean
    is_risk_assessment_completed: boolean
}

export interface PostBOUserRequest {
    name: string
    email: string
    role: BoUserRole
}

export interface GetTodosResponse {
    result: GetTodosResponseItem[]
    pagination: PaginationResponse
}

export interface GetTodosResponseItem {
    todo_id: string
    entity_id: string
    entity_kind: TodoEntityKind
    created_by: string
    assignee_id: string
    kind: TodoKind
    status: TodoStatus
    details?: string
    due_at: string // timestamp
    send_email: boolean
    created_at: string
    company_name: string
}

export interface GetContractListItem {
    contract_id: string
    created_at: string // timestamp
}

export interface GetContractResponse {
    contract_id: string
    is_standard_general_assignment?: boolean
    is_standard_negative_declaration?: boolean
    financing_type?: string
    company_name?: string
    company_legal_form?: string
    company_address_street?: string
    company_address_house_number?: string
    company_address_post_code?: string
    company_address_city?: string
    company_address_country?: string
    is_global_session?: boolean
    expected_volume?: number
    factoring_fee_30?: number
    factoring_fee_45?: number
    factoring_fee_60?: number
    factoring_fee_90?: number
    factoring_fee_120?: number
    min_factoring_fee?: number
    admin_fee?: number
    debitor_limit_fee_amount?: number
    max_invoice_payout?: number
    max_advance_payout?: number
    loan_amount?: number
    loan_amount_words?: string
    loan_usage?: string
    interest_rate?: number
    min_interest_rate?: number
    first_rate_amount?: number
    installment_rate?: number
    minimum_repayment_amount?: number
    early_repayment_months?: number
    repayment_fee?: number
    late_payment_fee?: number
    loan_duration?: number
    first_rate_days?: number
    repayment_type?: string
    repayment_day?: number
    final_repayment?: number
    final_rate_date?: string // date
    final_rate_days?: number
    single_repayment_date?: string // date
    repayment_bank_name?: string
    repayment_bank_iban?: string
    repayment_bank_bic?: string
    liability_limit?: number
    currency_type?: string
    admin_fee_per_rate?: number
    first_rate_date?: string // date
    paid_out_date?: string // date
    guarantor_name?: string
    guarantor_date_of_birth?: string // date
    guarantor_postcode_city?: string
    guarantee_currency?: string
    guarantee_amount?: number
    guarantee_amount_words?: string
    guarantee_end?: string // date
    created_at?: string // timestamp
}

export interface PutContractRequest {
    is_standard_general_assignment?: boolean
    is_standard_negative_declaration?: boolean
    is_global_session?: boolean
    expected_volume?: number
    factoring_fee_30?: number
    factoring_fee_45?: number
    factoring_fee_60?: number
    factoring_fee_90?: number
    factoring_fee_120?: number
    min_factoring_fee?: number
    admin_fee?: number
    debitor_limit_fee_amount?: number
    max_invoice_payout?: number
    max_advance_payout?: number
    loan_amount?: number
    loan_amount_words?: string
    loan_usage?: string
    interest_rate?: number
    min_interest_rate?: number
    first_rate_amount?: number
    minimum_repayment_amount?: number
    early_repayment_months?: number
    repayment_fee?: number
    late_payment_fee?: number
    loan_duration?: number
    first_rate_days?: number
    repayment_type?: string
    repayment_day?: number
    final_repayment?: number
    final_rate_date?: string // date
    final_rate_days?: number
    single_repayment_date?: string // date
    repayment_bank_name?: string
    repayment_bank_iban?: string
    repayment_bank_bic?: string
    liability_limit?: number
    currency_type?: string
    admin_fee_per_rate?: number
    first_rate_date?: string // date
    paid_out_date?: string // date
    guarantor_name?: string
    guarantor_date_of_birth?: string // date
    guarantor_postcode_city?: string
    guarantee_currency?: string
    guarantee_amount?: number
    guarantee_amount_words?: string
    guarantee_end?: string // date
}

export interface AdminGetEventsResponse {
    result: AdminGetEventsResponseItem[]
    pagination: PaginationResponse
}

export interface AdminGetEventsResponseItem {
    event_id: string
    target_type: string
    target_id: string
    actor_type: string
    actor_id: string
    actor_name?: string
    event_action: string
    event_description: string
    event_data?: string
    event_time: string // timestamp
    created_at: string // timestamp
}
