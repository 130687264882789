import { Loading3QuartersOutlined, ShopOutlined } from '@ant-design/icons';
import { Button, Form, Input, List, Row, Select, Spin } from 'antd';
import { COMPANY_SEARCH } from 'api/apiConfig';
import { getCompanySearchResults } from 'api/applicationService';
import styles from 'components/common/pages/application-flow/ApplicationFlow.module.scss';
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { Spacer } from 'components/common/presenters/spacer/Spacer';
import { goToFlowStepTwo } from "navigation/navigationHelpers";
import * as React from "react";
import { useTranslation } from 'react-i18next';
import { LocalApplicationFlowDataManager } from "storage/LocalApplicationFlowDataManager";
import { FirmenWissenCompanyData } from "../../../../../api/types";
import { CountryCodes } from "../../../../../types/countries";

export const ApplicationStepOnePage = () => {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [searchResults, setSearchResults] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    let timeout;

    const persistData = (values: any) => {
        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();

        appl.company_country = values.company_country;
        appl.company_name = values.company_name;

        applDataManager.update(appl);
    };

    // on finish is fired after succesful validation of ALL form fields
    const handleOnFinish = (values: any) => {
        persistData(values);
        
        goToFlowStepTwo();
    };

    const doSearch = async (input: string, country: string) => {
        try {
            setLoading(true);
            const companySearchReq = await getCompanySearchResults(input, country);
            setSearchResults(companySearchReq.data.companyNameSuggestions);
        } catch (error) {
            console.error(error);
        }
        timeout = null; // reset
        setLoading(false);
    };

    const handleInput = (input: string) => {
        const country = form.getFieldValue("company_country");
        
        if (input && input.length >= 3 && country) {
            clearTimeout(timeout);
            timeout = setTimeout(doSearch.bind(this, input, country), COMPANY_SEARCH.SEARCH_DELAY_MS);
        }
    };

    const handleListItemClick = (company: FirmenWissenCompanyData) => {
        form.setFieldsValue({
            company_name: company.name
        });

        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();

        appl.company_country = form.getFieldValue("company_country");
        appl.company_name = company.name;
        appl.company_post_code = company.plz;
        appl.company_city = company.ort;
        appl.company_street = company?.strasseHausnummer?.split(" ")[0] || "";
        appl.company_house_number = company?.strasseHausnummer?.split(" ")[1] || "";

        applDataManager.update(appl);

        setSearchResults([]);
    };

    const handleSearchResultsClose = () => {
        setSearchResults([]);
        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();
        appl.company_name = "";
        appl.company_post_code = "";
        applDataManager.update(appl);
    };

    React.useEffect(() => {
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();

        if (appl == null) {
            return;
        }

        form.setFieldsValue({
            company_country: appl.company_country,
            company_name: appl.company_name
        });

    }, []);

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus/>
            <CustomPageHeader />
            <div className={styles.container}>
                <h2 className={styles.processTitle}>{t("applProcess:titleStepOne")}</h2>
                <div className={styles.innerContainer}>

                    <Form
                        layout={"vertical"}
                        form={form}
                        onFinish={handleOnFinish}
                        scrollToFirstError
                    >
                        <Form.Item name="company_country" label={t("country")}
                            rules={[{ required: true, message: t('formErrors:pleaseSelectOption')}]}>
                            <Select placeholder={t('pleaseSelectOption')} allowClear>
                                {
                                    CountryCodes.map((val, i) => {
                                        return <Select.Option data-cy={`company_country_option_${i}`} key={i} value={val}>{t(`countryCodes:${val}`)}</Select.Option>;
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name="company_name" label={t("company")}
                            rules={[{ required: true, message: 'Please input a company name', min: 2, max: 250 }]}>
                            <Input data-cy="input_company_name"
                                onChange={(e) => {
                                    handleInput(e.target.value);
                                }}
                                className={styles.input}
                                placeholder="Muster GmbH"/>
                        </Form.Item>

                        {
                            loading ? 
                                <div className={styles.loaderContainer}>
                                    <Spin className={styles.spin} indicator={<Loading3QuartersOutlined style={{ fontSize: 34 }} spin />}/>
                                    <Spacer/>
                                </div>
                                :
                                null
                        }

                        {
                            searchResults.length > 0 ?
                                <React.Fragment>
                                    <div className={styles.searchResults}>
                                        <List
                                            bordered={true}
                                            itemLayout="horizontal"
                                            dataSource={searchResults}
                                            renderItem={(company: any)=> (
                                                <List.Item className={styles.listItem} onClick={handleListItemClick.bind(this, company)}>
                                                    <List.Item.Meta
                                                        className={styles.listItemMeta}
                                                        avatar={<ShopOutlined className={styles.iconResults} />}
                                                        title={company.name}
                                                        description={`${company.ort} - ${company.strasseHausnummer}`}
                                                    />
                                                </List.Item>
                                            )}/>
                                    </div>
                                    <Spacer/>
                                    <p className={styles.link} onClick={handleSearchResultsClose}>{t("companySearchNotFoundInfo")}</p>
                                    <Spacer/>
                                </React.Fragment>
                                :
                                null
                        }

                        <Spacer/>

                        <Form.Item>
                            <Row className={styles.rowCenterX}>
                                <Button disabled={loading} type="primary" htmlType="submit">{t("buttons:continue")}</Button>
                            </Row>
                        </Form.Item>
                    </Form>

                </div>
            </div >
        </div>
    );
};
