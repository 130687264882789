import * as React from "react";
import {useState} from "react";
import styles from '../ClientDrilldownPage.module.scss';
import {useTranslation} from 'react-i18next';
import {GetClientResponse} from "../../../../../api/types";
import {Button, Col, Row, Space, message, Modal, Typography} from "antd";
import {DataItemsColumn} from "../../../../common/presenters/data-items-column/DataItemsColumn";
import {currencyLocaleFormatter} from "../../../../../formatters/currencyFormatters";
import {LeadModal} from "../../../presenters/lead-modal/LeadModal";
import {LeadStatusModal} from "./LeadStatusModal";
import {SectionTitle} from "../../../../common/presenters/section-title/SectionTitle";
import {adminCreateApplicationForClient, adminGetLastApplicationOfClient} from "../../../../../api/applicationService";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import {Application} from "../../../../../types/types";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {goTo} from "../../../../../navigation/navigationHelpers";
import {RouteStrings} from "../../../../../Routes";

interface LeadInfoSectionProps {
    client: GetClientResponse
    onDataChanged: () => void
}

export const LeadInfoSection = (props: LeadInfoSectionProps) => {
    const {t} = useTranslation();
    const client = props.client;

    const [editLeadModalOpen, setEditLeadModalOpen] = useState(false);
    const [editLeadStatusModalOpen, setEditLeadStatusModalOpen] = useState(false);

    const handleCreateApplication = async () => {
        try {
            const newApplId: string = (await adminCreateApplicationForClient(props.client.client_id)).data;
            props.onDataChanged();
            message.success(t('messages:dataSaved'), 2);

            goTo(RouteStrings.AdminApplicationDrilldown.replace(":applicationId", newApplId));
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    const confirmCreateApplication = async () => {
        if (!props.client.has_application) {
            handleCreateApplication();
            return;
        }

        let lastApplication: Application;
        try {
            lastApplication  = (await adminGetLastApplicationOfClient(props.client.client_id)).data;
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
            return;
        }

        Modal.confirm({
            width: 800,
            title: t('modals:clientAlreadyHasApplication'),
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <Typography.Text>{t('createApplicationConfirm1')}</Typography.Text>
                    <Spacer hx={2}/>

                    <DataItemsColumn dataItems={[
                        {itemName: t('product'), itemValue: lastApplication.type ? t(`financingTypes:${lastApplication.type}`) : "-"},
                        {itemName: t('amount'), itemValue: (lastApplication.currency && lastApplication.amount) ? `${t(`currencySymbols:${lastApplication.currency}`)} ${currencyLocaleFormatter(lastApplication.amount)}` : "-"},
                        {itemName: t('loanDuration'), itemValue: lastApplication.duration ? `${lastApplication.duration} ${t('months')}` : "-"},
                    ]}/>
                    <Spacer/>

                    <Typography.Text>{t('createApplicationConfirm2')}</Typography.Text>
                </>
            ),
            okText: t('buttons:createApplication'),
            cancelText: t('buttons:back'),
            onOk: handleCreateApplication,
        });
    };

    return (
        <>
            <SectionTitle text={t('sectionTitles:leadInfo')}/>

            <div className={styles.container}>
                <Row gutter={36}>
                    <Col>
                        <DataItemsColumn dataItems={[
                            {itemName: t('uniqueId'), itemValue: client.client_id},
                            {itemName: t('company'), itemValue: client.company_name},
                            {itemName: t('street'), itemValue: client.address_street},
                            {itemName: t('houseNumber'), itemValue: client.address_house_number},
                            {itemName: t('postCode'), itemValue: client.address_post_code},
                            {itemName: t('city2'), itemValue: client.address_city},
                            {itemName: t('region'), itemValue: client.address_region},
                            {itemName: t('country'), itemValue: client.address_country ? t(`countryCodes:${client.address_country.toLowerCase()}`) : "-"},
                        ]}/>
                    </Col>
                    <Col>
                        <DataItemsColumn dataItems={[
                            {itemName: t('leadType'), itemValue: client.financing_type ? t(`financingTypes:${client.financing_type}`) : "-"},
                            {itemName: t('expectedVolume'), itemValue: client.lead_expected_volume ? currencyLocaleFormatter(client.lead_expected_volume) : "-"},
                            {itemName: t('currency'), itemValue: client.lead_currency},
                            {itemName: t('loanDuration'), itemValue: client.lead_expected_duration ? `${client.lead_expected_duration} ${t('months')}` : "-"},
                            {itemName: t('brokerName'), itemValue: client.broker_name},
                            {isEmptyLine: true},
                            {itemName: t('sveaContact'), itemValue: client.bo_contact_name},
                            {itemName: t('leadStatus'), itemValue: client.lead_status ? t(`leadStatuses:${client.lead_status}`) : "-", isBoldValue: true},
                        ]}/>
                    </Col>
                </Row>
                
                <Spacer hx={2}/>

                <Space size={50}>
                    <Button data-cy="button_edit_lead"
                        type={"primary"}
                        onClick={() => {
                            setEditLeadModalOpen(true);
                        }}
                    >
                        {t('buttons:editData')}
                    </Button>

                    <Button data-cy="button_edit_lead_status"
                        type={"primary"}
                        onClick={() => {
                            setEditLeadStatusModalOpen(true);
                        }}
                    >
                        {t('buttons:editLeadStatus')}
                    </Button>

                    <Button data-cy="button_create_application"
                        type={"primary"}
                        onClick={confirmCreateApplication}
                    >
                        {t('buttons:createApplication')}
                    </Button>
                </Space>
            </div>

            <LeadModal existingLeadData={props.client}
					   modalOpen={editLeadModalOpen}
					   setModalOpen={setEditLeadModalOpen}
					   onDataChanged={() => props.onDataChanged()}
            />

            <LeadStatusModal client={props.client}
							 modalOpen={editLeadStatusModalOpen}
							 setModalOpen={setEditLeadStatusModalOpen}
							 onDataChanged={() => props.onDataChanged()}
            />
        </>
    );
};
