import * as React from "react";
import {useEffect, useState} from "react";
import styles from "./PreviewPane.module.scss";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {ContactPerson, ContactPersonClient, GetClientResponse, GetNotesListResponse} from "../../../../api/types";
import {adminGetClient, adminGetClientNotes} from "../../../../api/clientService";
import {adminGetApplicationById, adminGetApplicationNotes} from "../../../../api/applicationService";
import {adminGetAllContactPersonsForClient} from "../../../../api/clientContactPersonService";
import {Application} from "../../../../types/types";
import {Button, Empty, List, Space, Typography} from "antd";
import {RouteStrings} from "../../../../Routes";
import {DataItemsColumn} from "../../../common/presenters/data-items-column/DataItemsColumn";
import {currencyLocaleFormatter} from "../../../../formatters/currencyFormatters";
import {Spacer} from "../../../common/presenters/spacer/Spacer";
import moment from "moment";
import {DATE_FORMAT} from "../../../../api/apiConfig";

export enum PreviewPaneType {
    Application = "application",
    Client = "client",
    Lead = "lead",
}

export interface PreviewPaneProps {
    paneType: PreviewPaneType

    applicationId?: string
    clientId?: string
}

interface PreviewPaneData {
    shortId?: string
    companyFullName?: string
    street?: string
    houseNumber?: string
    postCode?: string
    city?: string
    region?: string
    country?: string
    financingType?: string
    volume?: number
    currency?: string
    durationMonths?: number
    brokerName?: string
    sveaContact?: string
    status?: string
}

export const PreviewPane = (props: PreviewPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();

    const [isEmpty, setIsEmpty] = useState<boolean>(true);
    const [data, setData] = useState<PreviewPaneData>({});
    const [contactPersons, setContactPersons] = useState<ContactPerson[]>([]);
    const [notes, setNotes] = useState<GetNotesListResponse[]>([]);

    const fetchContactPersons = async (clientId: string) => {
        const result: ContactPersonClient[] = (await adminGetAllContactPersonsForClient(clientId)).data;

        setContactPersons(result || []);
    };

    const fetchNotes = async () => {
        let result: GetNotesListResponse[];

        switch (props.paneType) {
        case PreviewPaneType.Application:
            result = (await adminGetApplicationNotes(props.applicationId)).data;
            break;
        case PreviewPaneType.Client:
            result = (await adminGetClientNotes(props.clientId)).data;
            break;
        case PreviewPaneType.Lead:
            result = (await adminGetClientNotes(props.clientId)).data;
            break;
        default:
            return;
        }

        setNotes(result || []);
    };

    const fetchApplicationData = async () => {
        const application: Application = (await adminGetApplicationById(props.applicationId)).data;
        const client: GetClientResponse = (await adminGetClient(application.client_id)).data;

        setData({
            shortId: verboseShortId(application.company_country, application.short_id),
            companyFullName: companyFullName(application.company_name, application.company_type),
            street: application.company_street,
            houseNumber: application.company_house_number,
            postCode: application.company_post_code,
            city: application.company_city,
            region: client.address_region,
            country: application.company_country ? t(`countryCodes:${application.company_country}`) : "-",
            financingType: application.type ? t(`financingTypes:${application.type}`) : "-",
            volume: Number.parseFloat(application.amount?.toString()),
            currency: application.currency,
            durationMonths: application.duration,
            brokerName: client.broker_name,
            sveaContact: client.bo_contact_name,
            status: application.status ? t(`applicationStates:${application.status}`) : "-",
        });

        setIsEmpty(false);

        fetchNotes();
        fetchContactPersons(application.client_id);
    };

    const fetchClientData = async () => {
        const result: GetClientResponse = (await adminGetClient(props.clientId)).data;

        setData({
            shortId: verboseShortId(result.address_country, result.short_id),
            companyFullName: companyFullName(result.company_name, result.company_legal_form),
            street: result.address_street,
            houseNumber: result.address_house_number,
            postCode: result.address_post_code,
            city: result.address_city,
            region: result.address_region,
            country: result.address_country ? t(`countryCodes:${result.address_country}`) : "-",
            financingType: result.financing_type ? t(`financingTypes:${result.financing_type}`) : "-",
            volume: Number.parseFloat(result.lead_expected_volume?.toString()),
            currency: result.lead_currency,
            durationMonths: result.lead_expected_duration,
            brokerName: result.broker_name,
            sveaContact: result.bo_contact_name,
            status: result.lead_status ? t(`leadStatuses:${result.lead_status}`) : "-",
        });

        setIsEmpty(false);

        fetchNotes();
        fetchContactPersons(result.client_id);
    };

    const fetchData = async () => {
        try {
            switch (props.paneType) {
            case PreviewPaneType.Application:
                fetchApplicationData();
                break;
            case PreviewPaneType.Client:
            case PreviewPaneType.Lead:
                fetchClientData();
                break;
            default:
                return;
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleOnView = async () => {
        switch (props.paneType) {
        case PreviewPaneType.Application:
            history.push(RouteStrings.AdminApplicationDrilldown.replace(":applicationId", props.applicationId));
            break;
        case PreviewPaneType.Client:
        case PreviewPaneType.Lead:
            history.push(RouteStrings.AdminClientDrilldown.replace(":clientId", props.clientId));
            break;
        default:
            return;
        }
    };

    useEffect(() => {
        if (!props.applicationId && !props.clientId) {
            setIsEmpty(true);
        } else {
            fetchData();
        }
    }, [props]);

    const getShortIdName = () => {
        switch (props.paneType) {
        case PreviewPaneType.Application:
            return "Antrags ID";
        case PreviewPaneType.Client:
            return "Klient ID";
        case PreviewPaneType.Lead:
            return "Lead ID";
        }
    };

    const renderDataItems = () => {
        return (
            <DataItemsColumn spaceHx={0.1}
                dataItems={[
                    {itemName: getShortIdName(), itemValue: data.shortId},
                    {itemName: t('company'), itemValue: data.companyFullName},
                    {itemName: t('street'), itemValue: data.street},
                    {itemName: t('houseNumber'), itemValue: data.houseNumber},
                    {itemName: t('postCode'), itemValue: data.postCode},
                    {itemName: t('city'), itemValue: data.city},
                    {itemName: t('region'), itemValue: data.region},
                    {itemName: t('country'), itemValue: data.country},
                    {isEmptyLine: true},
                    {itemName: t('product'), itemValue: data.financingType},
                    {itemName: t('expectedVolume'), itemValue: data.volume ? currencyLocaleFormatter(data.volume) : "-"},
                    {itemName: t('currency'), itemValue: data.currency},
                    {itemName: t('loanDuration'), itemValue: data.durationMonths ? `${data.durationMonths?.toFixed(0)} ${t('months')}` : "-"},
                    {itemName: t('brokerName'), itemValue: data.brokerName},
                    {isEmptyLine: true},
                    {itemName: t('contactName'), itemValue: data.sveaContact},
                    {itemName: t('status'), itemValue: data.status, isBoldValue: true},
                ]}/>
        );
    };

    const renderContactPersons = () => {
        return (
            <>
                <Typography.Title level={5}>{t('contactPersons')}</Typography.Title>
                <List size={"small"}
                    itemLayout="vertical"
                    dataSource={contactPersons.slice(0, 3)}
                    renderItem={(cp: ContactPerson) => {
                        return (
                            <div key={cp.contact_person_id} >
                                <Space className={styles.contactPersonItem} direction={"vertical"} size={0}>
                                    <Typography.Text className={styles.opaqueText}>{`${cp.first_name} ${cp.last_name}`}</Typography.Text>
                                    <Typography.Text className={styles.opaqueText}>{cp.mobile_number ? cp.mobile_number : cp.phone}</Typography.Text>
                                    <Typography.Text className={styles.opaqueText}>{cp.email}</Typography.Text>
                                </Space>
                                <Spacer/>
                            </div>
                        );
                    }}
                />
            </>
        );
    };

    const renderNotes = () => {
        return (
            <>
                <Typography.Title level={5}>{t('notes')}</Typography.Title>
                <List size={"small"}
                    itemLayout="vertical"
                    dataSource={notes.slice(0, 3)}
                    renderItem={(note: GetNotesListResponse) => {
                        return (
                            <div key={note.note_id}>
                                <Space align={"start"}>
                                    <Typography.Text className={styles.opaqueText}>{note.created_at ? moment(note.created_at).format(DATE_FORMAT.DE) : "-"}</Typography.Text>
                                    <Typography.Paragraph className={styles.opaqueText} ellipsis={{rows: 3}}>{note.note_text}</Typography.Paragraph>
                                </Space>
                            </div>
                        );
                    }}
                />
            </>
        );
    };

    return (
        <div className={styles.paneBody}>
            <Typography.Title level={4}>
                {t('previewPaneHeader', {entityType: t(`previewPaneTypes:${props.paneType.toString()}`)})}
            </Typography.Title>

            {
                !isEmpty ?
                    <div className={styles.paneBodyFlex}>
                        <div>
                            {renderDataItems()}
                            <Spacer/>

                            {contactPersons?.length > 0 ? renderContactPersons() : null}
                            <Spacer/>

                            {notes?.length > 0 ? renderNotes() : null}
                        </div>

                        <Button type={"primary"}
                            onClick={handleOnView}
                        >
                            {t('view')}
                        </Button>
                    </div>
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t('emptyPreviewPane')}
                    />
            }
        </div>
    );
};

const verboseShortId = (countryCode: string, shortId: string): string => {
    return countryCode ? `${countryCode.toUpperCase()}-${shortId?.toUpperCase()}` : `ID-${shortId?.toUpperCase()}`;
};

const companyFullName = (companyName: string, companyLegalForm: string): string => {
    let result = "";

    if (companyName) {
        result += companyName;
    } else {
        return "";
    }

    if (companyLegalForm) {
        result += ` ${companyLegalForm}`;
    }

    return result;
};
