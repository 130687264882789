import * as React from "react";
// import styles from "../../ClientDrilldownPage.module.scss";
import {useTranslation} from 'react-i18next';
import {GetClientResponse} from "../../../../../../api/types";
import {DataItemsColumn} from "../../../../../common/presenters/data-items-column/DataItemsColumn";
import {Button, message} from "antd";
import {getQuestionnaireURL} from "../../../../../../api/document-service/questionnaires";
import {openUrlInNewTab} from "../../../../../../helpers/uiHelpers";

interface NextStepsContactedProps {
    client: GetClientResponse
    onDataChanged: () => void
}

export const NextStepsContacted = (props: NextStepsContactedProps) => {
    const {t} = useTranslation();

    const openQuestionnaireDocument = async () => {
        try {
            const downloadURL = (await getQuestionnaireURL(props.client.address_country, props.client.financing_type?.toString())).data;

            openUrlInNewTab(downloadURL); // download doesn't work :(
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }
    };

    return (
        <>
            <p>{t('leadNextSteps:labelStepContacted')}</p>

            <DataItemsColumn dataItems={[
                {itemName: t('country'), itemValue: props.client.address_country ? t(`countryCodes:${props.client.address_country.toLowerCase()}`) : "-"},
                {
                    itemName: t('product'),
                    itemValue: props.client.financing_type ? t(`financingTypes:${props.client.financing_type}`) : "-"
                },
            ]}/>

            <Button data-cy={"button_download_questionnaire"}
                onClick={() => {
                    openQuestionnaireDocument();
                }}
            >
                {t('buttons:downloadQuestionnaire')}
            </Button>
        </>
    );
};
