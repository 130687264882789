import i18n from "i18n/i18n";
import { RouteStrings } from "Routes";

export const goTo = (pageName: string) => {
    window.location.href = window.location.origin + `${pageName}`;
};

export const goToExternal = (url: string) => {
    window.location.href = url;
};

export const goToUserDashboardHome = () => {
    window.location.href = window.location.origin + `${RouteStrings.ApplicationsOverview}`;
};

export const goToFlowStepTwo = () => {
    goTo(RouteStrings.ApplicationFlowStepTwo);
};

export const goToFlowStepRegistration = () => {
    goTo(RouteStrings.ApplicationFlowStepCreateAccount);
};

export const goToFlowStepResult = () => {
    goTo(RouteStrings.ApplicationFlowStepResult);
};

export const goToAdminClientDrilldown = (clientId: string) => {
    goTo(RouteStrings.AdminClientDrilldown.replace(":clientId", clientId));
};

export const isLoanDrilldownPage = () => {
	
};

export const getAdminMenuItems = () => {
    return [
        i18n.t("applications"),
        i18n.t("clients"),
        i18n.t("settings"),
        // "Statistics",
        i18n.t("dashboard"),
        "Leads",
        "Lead Pipeline",
    ];
};
