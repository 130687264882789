import styles from "components/admin/pages/admin-home/AdminHomePage.module.scss";
import { ApplicationsTab } from "components/admin/presenters/admin-home-tabs/applications-tab/ApplicationsTab";
import { ClientsTab } from "components/admin/presenters/admin-home-tabs/clients-tab/ClientsTab";
import { SettingsTab } from "components/admin/presenters/admin-home-tabs/settings-tab/SettingsTab";
import { SideMenu } from "components/common/presenters/side-menu/SideMenu";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { getAdminMenuItems } from "navigation/navigationHelpers";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { LeadsTab } from "../../presenters/admin-home-tabs/leads-tab/LeadsTab";
import { PipelineTab } from "../../presenters/admin-home-tabs/pipeline-tab/PipelineTab";
import {DashboardTab} from "../../presenters/admin-home-tabs/dashboard-tab/DashboardTab";

export const AdminHomePage = () => {
    const { t } = useTranslation();

    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [activeHash, setActiveHash] = useState(null);

    const hashes = [
        "#applications",
        "#clients",
        "#settings",
        // "#statistics",
        "#dashboard",
        "#leads",
        "#pipeline",
    ];

    React.useEffect(() => {
        const hash = window.location.hash;

        if (hash == null || hash == "") {
            return;
        }

        if (activeHash == null) {
            setActiveHash(hash);
        }

        if (activeTabIndex !== hashes.indexOf(hash)) {
            setActiveTabIndex(hashes.indexOf(hash));
        }
    }, []);

    const tabsContent = [
        <ApplicationsTab />,
        <ClientsTab />,
        <SettingsTab />,
        // <StatisticsTab />,
        <DashboardTab />,
        <LeadsTab />,
        <PipelineTab />,
    ];

    const adminMenuItems = getAdminMenuItems();

    return (
        <div className={styles.adminPage}>

            <SideMenu isLoggedIn={true}
                onItemClick={(index) => {
                    setActiveTabIndex(index);

                    window.location.hash = hashes[index];
                }} />

            <div className={styles.sectionRight}>

                <div className={styles.headerRow}>
                    <h2>{t("adminHomeTitle")} - {adminMenuItems[activeTabIndex]}</h2>
                </div>
                <Spacer/>

                {tabsContent[activeTabIndex]}
            </div>
        </div>
    );
};
