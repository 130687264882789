import * as React from "react";

export interface PProps {
    className?: string
    children?: any
}

export const P = (props: PProps) => {
    return (
        <div className={`svea-p ${props.className ? props.className : ""}`}>
            { props.children }
        </div>
    );
};
