import {
    DashboardOutlined,
    FileDoneOutlined,
    FilePdfOutlined, FunnelPlotOutlined,
    LeftOutlined,
    LogoutOutlined, PartitionOutlined,
    PlusOutlined,
    RightOutlined,
    SettingOutlined,
    UserOutlined
} from "@ant-design/icons";
import { Button } from "antd";
import { logoutContactPerson } from "api/clientContactPersonService";
import classnames from "classnames";
import styles from 'components/common/presenters/side-menu/SideMenu.module.scss';
import { getAdminMenuItems, goTo } from "navigation/navigationHelpers";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { RouteStrings } from "Routes";
import {
    setSkipLoanTypeCheckStatus,
    setUserFactoringOnboardingStatus,
    userHasCompletedFactoringOnboarding
} from "storage/applicationFlowHelpers";
import {logoutBoUser} from "../../../../api/boUserService";

export interface SideMenuProps {
    isLoggedIn: boolean
    onItemClick?: (itemIndex: number) => void
}

export const SideMenu = (props: SideMenuProps) => {
    const {t} = useTranslation();

    const [isCollapsed, setCollapsed] = useState(false);
    const [wasToggled, setWasToggled] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [activePageIndex, setActivePageIndex] = useState(null);

    let dynamicClasses = classnames(styles.menu, {
        [styles.menuCollapsed]: isCollapsed,
        [styles.menuToggled]: wasToggled,
        [styles.menuFirstRender]: isFirstRender,
        [styles.active_3]: activePageIndex === 3,
    });

    let textClasses = classnames(styles.text, {
        [styles.textDisappear]: isCollapsed
    });

    const toggleMenu = () => {
        setCollapsed(!isCollapsed);
        setIsFirstRender(false);
    };

    const adminMenuItemsOrder = [
        {
            hash: "#applications",
            index: 0,
        },
        {
            hash: "#clients",
            index: 1,
        },
        {
            hash: "#settings",
            index: 2,
        },
        {
            hash: "#dashboard",
            index: 3,
        },
        {
            hash: "#leads",
            index: 4,
        },
        {
            hash: "#pipeline",
            index: 5,
        },
    ];

    const userMenuItemsOrder = [
        {
            page: RouteStrings.ApplicationsOverview,
            index: 0,
        },
        {
            page: RouteStrings.UserSettings,
            index: 1,
        },
    ];

    // Called on every re-render, including the first one.
    useEffect(() => {
        if (!isCollapsed && !wasToggled) {
            setWasToggled(true);
        }

        if (isCollapsed && wasToggled) {
            setWasToggled(false);
        }

        const activePage = window.location.pathname;

        switch (activePage) {
        case "/admin/people":
            setActivePageIndex(1);
            break;
        case "/admin/applications":
            setActivePageIndex(3);
            break;
        default:
            break;
        }

    }, [isCollapsed]);

    // Mark active tab on first render before any side menu item is clicked.
    useEffect(() => {
        const activeTab = window.location.hash;
        const pathname = window.location.pathname;

        if (pathname?.indexOf("admin") != -1) {
            for (let i = 0; i < adminMenuItemsOrder.length; i++) {
                if (activeTab === adminMenuItemsOrder[i].hash) {
                    setActivePageIndex(adminMenuItemsOrder[i].index);
                }
            }
        } else {
            for (let i = 0; i < userMenuItemsOrder.length; i++) {
                if (pathname === userMenuItemsOrder[i].page) {
                    setActivePageIndex(userMenuItemsOrder[i].index);
                }
            }
        }
    }, []);

    const handleLogout = async () => {
        try {
            if (isAdminPage()) {
                await logoutBoUser();
                goTo(RouteStrings.AdminLogin);
            } else {
                await logoutContactPerson();
                goTo(RouteStrings.Login);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const goToAdminHome = (hash) => {
        window.location.href = window.location.origin + RouteStrings.AdminHome + `/${hash}`;
    };

    const goToUserApplications = () => {
        window.location.href = window.location.origin + RouteStrings.ApplicationsOverview;
    };

    const goToUserContracts = () => {
        window.location.href = window.location.origin + RouteStrings.MyContracts;
    };

    const goToUserSettings = () => {
        window.location.href = window.location.origin + RouteStrings.UserSettings;
    };

    const isAdminPage = () => {
        return window.location.pathname.includes("admin");
    };

    const renderMenuItems = (menuItems: string[], icons?: JSX.Element[], navActions?: any[], renderExtraItems?: boolean) => {
        const items = [];

        for (let i = 0; i < menuItems.length; i++) {
            items.push(
                <div key={menuItems[i]} className={styles.menuItem} data-active={activePageIndex === i}
                    onClick={() => {
                        setActivePageIndex(i);

                        if (props.onItemClick) {
                            props.onItemClick(i);
                        } else {
                            if (navActions) {
                                navActions[i]();
                            }
                        }
                    }}>
                    {
                        icons ?
                            icons[i]
                            :
                            <div className={styles.iconApplications}></div>
                    }
                    <div className={textClasses}>{menuItems[i]}</div>
                </div>
            );
        }

        return items;
    };

    const adminMenuIcons = [
        <FileDoneOutlined className={styles.adminIcon}/>,
        <UserOutlined className={styles.adminIcon}/>,
        <SettingOutlined className={styles.adminIcon}/>,
        // <BarChartOutlined className={styles.adminIcon}/>,
        <DashboardOutlined className={styles.adminIcon}/>,
        <FunnelPlotOutlined className={styles.adminIcon}/>,
        <PartitionOutlined className={styles.adminIcon}/>
    ];

    const adminMenuNavActions = [
        goToAdminHome.bind(this, "#applications"),
        goToAdminHome.bind(this, "#clients"),
        goToAdminHome.bind(this, "#settings"),
        // goToAdminHome.bind(this, "#statistics"),
        goToAdminHome.bind(this, "#dashboard"),
        goToAdminHome.bind(this, "#leads"),
        goToAdminHome.bind(this, "#pipeline"),
    ];

    const renderAdminMenu = () => {
        const menuItems = getAdminMenuItems();

        return renderMenuItems(menuItems, adminMenuIcons, adminMenuNavActions);
    };

    let userMenuIcons = [
        <FileDoneOutlined className={styles.adminIcon}/>,
        <SettingOutlined className={styles.adminIcon}/>,
    ];

    if (userHasCompletedFactoringOnboarding()) {
        userMenuIcons = [
            <FileDoneOutlined className={styles.adminIcon}/>,
            <FilePdfOutlined className={styles.adminIcon}/>,
            <SettingOutlined className={styles.adminIcon}/>,
        ];
    }

    let userMenuNavActions = [
        goToUserApplications,
        goToUserSettings,
    ];

    if (userHasCompletedFactoringOnboarding()) {
        userMenuNavActions = [
            goToUserApplications,
            goToUserContracts,
            goToUserSettings,
        ];
    }

    const renderUserMenu = () => {
        let menuItems = [
            t("loans"),
            t("settings"),
        ];

        if (userHasCompletedFactoringOnboarding()) {
            menuItems = [
                t("loans"),
                t("contracts"),
                t("settings"),
            ];
        }

        return renderMenuItems(menuItems, userMenuIcons, userMenuNavActions, true);
    };

    return (
        <div className={dynamicClasses}>
            <div className={styles.containerLogo}>
                <div className={styles.logo}></div>
            </div>

            {
                props.isLoggedIn ?
                    <div className={styles.containerMid}>

                        {
                            isAdminPage() ?
                                renderAdminMenu()
                                :
                                renderUserMenu()
                        }
                        {
                            isCollapsed ?
                                <Button className={styles.sideMenuButtonCompact} key="toggled-btn">
                                    <Link to={RouteStrings.ApplicationFlowStepOne}>
                                        <PlusOutlined id={styles.iconPlus} />
                                    </Link>
                                </Button>
                                :
                                <Button className={styles.sideMenuButton} key="untoggled-btn" onClick={() => {
                                    // If the user sees this button, they still haven't completed the factoring onboarding, reset the flags just in case.
                                    setSkipLoanTypeCheckStatus(false);
                                    setUserFactoringOnboardingStatus(false);
                                }}>
                                    <Link to={RouteStrings.ApplicationFlowStepOne}>
                                        <PlusOutlined id={styles.iconPlus} />&nbsp;Neuer Antrag
                                    </Link>
                                </Button>
                        }
                    </div>
                    : null
            }

            <div className={styles.containerBot}>
                {/* <Select className={styles.langSelector} disabled={false}
                    value={i18n.language}
                    onChange={(value) => {
                        i18n.changeLanguage(value);
                    }}>
                    <Select.Option value={"en"}>English</Select.Option>
                    <Select.Option value={"de"}>Deutsch</Select.Option>
                </Select> */}
                <div className={styles.menuItem} onClick={toggleMenu}>
                    {isCollapsed ? <RightOutlined className={styles.adminIcon}/> :
                        <LeftOutlined className={styles.adminIcon}/>}
                    <div className={textClasses}>
                        Toggle
                    </div>
                </div>

                {
                    props.isLoggedIn ?
                        <div data-cy="button_logout" className={styles.menuItem} onClick={handleLogout}>
                            <LogoutOutlined className={styles.adminIcon}/>
                            <div className={textClasses}>{t('signOut')}</div>
                        </div>
                        : null
                }
            </div>
        </div>
    );
};
