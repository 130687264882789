import * as React from "react";
import styles from '../ClientDrilldownPage.module.scss';
import {GetClientResponse} from "../../../../../api/types";
import {BankInfoSection} from "./BankInfoSection";
import {ClientInfoSection} from "./ClientInfoSection";
import {ClientStatusSection} from "./ClientStatusSection";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import {LeadStatus} from "../../../../../types/types";
import {Alert} from "antd";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {DATE_FORMAT} from "../../../../../api/apiConfig";

export interface CompanyDetailsTabProps {
    clientData: GetClientResponse
    onDataChanged: () => void
}

export const CompanyDetailsTab = (props: CompanyDetailsTabProps) => {
    const {t} = useTranslation();

    return (
        <div className={styles.tab}>
            {
                props.clientData.lead_status === LeadStatus.Archived ?
                    <Alert type="error"
                        closable={false}
                        message={t('archivedBanner', {
                            entityType: "Klient",
                            archivedAt: props.clientData.archive_initiated_at ? moment(props.clientData.archive_initiated_at).format(DATE_FORMAT.DE) : "-"
                        })}
                    /> : null
            }

            <ClientStatusSection client={props.clientData}
                onDataChanged={() => props.onDataChanged()}
            />
            <Spacer/>
            <ClientInfoSection client={props.clientData}
                onDataChanged={() => props.onDataChanged()}
            />
            <Spacer/>
            <BankInfoSection client={props.clientData}
                onDataChanged={() => props.onDataChanged()}
            />
        </div>
    );
};
