import {Input, message, Space, Table} from 'antd';
import {adminGetAllApplications, adminSearchApplications} from 'api/applicationService';
import styles from "components/admin/presenters/admin-home-tabs/AdminHomeTabs.module.scss";
import {Link} from "react-router-dom";
import * as React from "react";
import {useState} from "react";
import {useTranslation} from 'react-i18next';
import {RouteStrings} from "../../../../../Routes";
import {RightOutlined} from "@ant-design/icons";
import {tableColumnListSearchProps, tableColumnSearchProps} from "../../../../../misc/antdTableSearch";
import moment from "moment";
import {DATE_FORMAT} from "../../../../../api/apiConfig";
import {useDateRangeFilterOptions} from "../../../../../hooks/TableFilterOptions";
import {Application, ApplicationStates} from "../../../../../types/types";
import {ApplicationFlowType} from "../../../../../storage/LocalAppDataManager";
import {GetBoUsersListItem} from "../../../../../api/types";
import {adminGetBOUsers} from "../../../../../api/boUserService";
import {Spacer} from "../../../../common/presenters/spacer/Spacer";
import debounce from 'lodash/debounce';
import {PreviewPane, PreviewPaneType} from "../../preview-pane/PreviewPane";

/**
 * Only used by the admin. It's actually more like its own page, so we should rename it.
 * It should also manage its data alone.
 */
export const ApplicationsTab = () => {
    const { t } = useTranslation();

    const [applications, setApplications] = React.useState([] as Application[]);
    const [loading, setLoading] = useState(false);
    const [boUsers, setBOUsers] = useState([] as GetBoUsersListItem[]);
    const [selectedApplicationId, setSelectedApplicationId] = useState("");

    const fetchApplications = async () => {
        try {
            const response = await adminGetAllApplications();
            setApplications(response.data);
        } catch (e) {
            console.error(e);
        }
    };

    React.useEffect(() => {
        fetchApplications();
        fetchSveaContactNames();
    }, []);

    const fetchSveaContactNames = async () => {
        try {
            const result = await adminGetBOUsers();
            setBOUsers(result.data);
        } catch (e) {
            console.error(e);
            setBOUsers([]);
        }
    };

    const handleOnTextSearch = async (e: any) => {
        const text = e.target.value;

        if (!text) {
            fetchApplications();
            return;
        }

        setLoading(true);

        try {
            const result = (await adminSearchApplications(text)).data;
            setApplications(result);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }

        setLoading(false);
    };

    const handleRowClicked = (appl: Application) => {
        setSelectedApplicationId(appl.id);
    };

    const boUserNameById = (boUserId: string): string => {
        const foundBoUser = boUsers.find(bo => bo.bo_user_id === boUserId);

        return foundBoUser?.bo_user_name || "-";
    };

    const withVerboseShortIDs = (applications: Application[]): Application[] => {
        return applications.map(a => ({
            ...a,
            short_id: a.company_country ? `${a.company_country.toUpperCase()}-${a.short_id?.toUpperCase()}` : `ID-${a.short_id?.toUpperCase()}`
        }));
    };

    const columns: any = [
        {
            title: t('applicationId'),
            dataIndex: 'short_id',
            ...tableColumnSearchProps("short_id"),
        },
        {
            title: t('assignee2'),
            dataIndex: 'bo_assignee_id',
            ...tableColumnListSearchProps("bo_assignee_id", boUsers.map(bo => ({
                label: bo.bo_user_name,
                value: bo.bo_user_id,
            }))),
            render: (value) => value ? boUserNameById(value) : "-",
        },
        {
            title: t('company'),
            dataIndex: 'company_name',
            ...tableColumnSearchProps("company_name"),
        },
        {
            title: t('created'),
            dataIndex: 'created_at',
            render: (value) => value ? moment(value).format(DATE_FORMAT.DE) : "-",
            ...useDateRangeFilterOptions('created_at'),
        },
        {
            title: t('loanAmount'),
            dataIndex: 'amount',
            sorter: (a, b) => Number.parseInt(a.amount) - Number.parseInt(b.amount),
        },
        {
            title: t('loanDuration'),
            dataIndex: 'duration',
        },
        {
            title: t('type'),
            dataIndex: 'type',
            render: (value) => value ? t(`applicationTypes:${value}`) : "-",
            filters: Object.keys(ApplicationFlowType).map(k => {
                const statusValue = ApplicationFlowType[k];
                const statusText = t(`applicationTypes:${statusValue}`);
                return {
                    text: statusText,
                    value: statusValue,
                };
            }),
            filterMultiple: true,
            onFilter: (value, record) => record.type?.valueOf() === value.valueOf(),
        },
        {
            title: t('status'),
            dataIndex: 'status',
            render: (value) => value ? t(`applicationStates:${value}`) : "-",
            filters: Object.keys(ApplicationStates).map(k => {
                const statusValue = ApplicationStates[k];
                const statusText = t(`applicationStates:${statusValue}`);
                return {
                    text: statusText,
                    value: statusValue,
                };
            }),
            filterMultiple: true,
            defaultFilteredValue: Object.keys(ApplicationStates)
                .filter(k => ![ApplicationStates.Rejected, ApplicationStates.Archived].includes(ApplicationStates[k]))
                .map(k => ApplicationStates[k]),
            onFilter: (value, record) => record.status?.valueOf() === value.valueOf(),
        },
        {
            title: t('applicationSource'),
            dataIndex: 'source',
            render: (value) => value ? value : "-",
        },
        {
            title: '',
            dataIndex: 'lastCol',
            key: 'lastCol',
            render: (text, record) => {
                let link = RouteStrings.AdminApplicationBase + record.id;

                return <Link to={link}>{t('view')}<RightOutlined /></Link>;
            }
        },
    ];

    return (
        <div className="admin-home-tab">
            <Input placeholder={t('placeholders:freeTextSearch')}
                allowClear
                onChange={debounce(handleOnTextSearch, 500)}
            />
            <Spacer/>

            <Space align={"start"}>
                <Table className={styles.smallTable}
                    rowKey={record => record.id}
                    pagination={{position: ["bottomCenter"], pageSize: 20, total: applications?.length}}
                    columns={columns}
                    dataSource={withVerboseShortIDs(applications)}
                    scroll={{x: true}}
                    loading={loading}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: evt => handleRowClicked(record),
                        };
                    }}
                />
                
                <PreviewPane paneType={PreviewPaneType.Application}
                    applicationId={selectedApplicationId}
                />
            </Space>

        </div>
    );
};
