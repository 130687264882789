import * as React from "react";
import styles from './DataItemsColumn.module.scss';
import {Col, Row, Typography} from "antd";
import {Spacer} from "../spacer/Spacer";

export interface DataItemProps {
    dataItems: ItemNameValue[]
    spaceHx?: number
}

export interface ItemNameValue {
    itemName?: string
    itemValue?: string
    isEmptyLine?: boolean
    isBoldValue?: boolean
}

export const DataItemsColumn = (props: DataItemProps) => {
    const spaceHx: number = props.spaceHx ? props.spaceHx : 1;

    return (
        <Row gutter={12}>
            <Col>
                {
                    props.dataItems?.map((item, i) => {
                        return (
                            <>
                                {
                                    item.isEmptyLine || item.itemName?.length === 0 ?
                                        <p key={i} className={styles.emptyLine}/> :
                                        <Typography.Text key={i} strong>{item.itemName}:</Typography.Text>
                                }
                                <Spacer hx={spaceHx}/>
                            </>
                        );
                    })
                }
            </Col>
            <Col>
                {
                    props.dataItems?.map((item, i) => {
                        return (
                            <>
                                {
                                    item.isEmptyLine ?
                                        <p key={i} className={styles.emptyLine}/> :
                                        <Typography.Text key={i} className={styles.dataItemValue} strong={item.isBoldValue}>{item.itemValue ? item.itemValue : "-"}</Typography.Text>
                                }
                                <Spacer hx={spaceHx}/>
                            </>
                        );
                    })
                }
            </Col>
        </Row>
    );
};
