import { FileOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import * as React from "react";
import styles from './PaidOutTab.module.scss';
import { downloadDummyPdf } from "storage/genericHelpers";
import { Spacer } from "components/common/presenters/spacer/Spacer";

export interface HProps {
    className?: string
    children?: any
    isInvoice?: boolean
}

export const PaidOutTab = (props: HProps) => {
    return (
        <div className={styles.tab}>
            <h2>Paid Out</h2>
            <Spacer/>
            <Row className={styles.topRow}>
                {
                    props.isInvoice ? 
                        <p>Congratulations! Your invoice amount has been paid out. The money should be on your account in 2 business days.
						If it doesn't arrive by then, please contact our support team.
                        </p>
                        :	
                        <p>Congratulations! Your loan has been paid out. The money should be on your account in 2 business days.
					If it doesn't arrive by then, please contact our support team.
                        </p>
                }
            </Row>
            <Spacer/>
            <Spacer/>
            <Row className={styles.row}>
                <Col span={10} className={styles.colLeft}>
                    <div>
                        <FileOutlined className={styles.iconCheckbox} />
                    </div>
                    <Spacer/>
                    <Spacer/>
                    {
                        props.isInvoice ? 
                            <>
                                <Button className={styles.button} type="primary" size="large">
								Download Invoice
                                </Button>
                            </>
                            :
                            <>
                                <Button className={styles.button} type="primary" size="large"
                                    onClick={downloadDummyPdf}>
								Download Contract
                                </Button>
                                <Spacer/>
                                <Button className={styles.button} type="primary" size="large"
                                    onClick={downloadDummyPdf}>
								Download Repayment Plan
                                </Button>
                            </>
                    }
                </Col>
				&nbsp;&nbsp;&nbsp;
                <Col span={12}>
                    {
                        props.isInvoice ? 
                            <>
                                <p>
								You can download the paid out invoice by clicking the button on the left side.
                                    <Spacer/>
                                    <Spacer/>
								You can contact us at +4188878923.
                                </p>
                                <hr />
                                <p>Invoice Status: <b>OK</b></p>
                                <p>Monthly Rate: <b>€2,237.45</b></p>
                                <p>Outstanding Amount: <b>€25,000.00</b></p>
                            </>
                            :	
                            <>
                                <p>
								Please download the contract and contact us at +4188878923 to organise an appointment for a staff member to come to you do to KYC and pick up your signed contract.
                                    <Spacer/>
                                    <Spacer/>
								When we receive the contract from our staff member or by post, your application will be completed.
                                </p>
                                <hr />
                                <p>Next repayment date: <b>15.08.2020</b></p>
                                <p>Monthly Rate: <b>€2,500.00</b></p>
                                <p>Outstanding Amount: <b>€125,000.00</b></p>
                            </>
                    }
					
                </Col>
            </Row>
        </div>
    );
};
