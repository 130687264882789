import * as React from "react";
import {useEffect, useState} from "react";
import styles from 'components/admin/pages/application-drilldown/ApplicationDrilldownPage.module.scss';
import {useTranslation} from "react-i18next";
import {
    AdminGetEventsResponse,
    AdminGetEventsResponseItem,
    GetClientResponse,
    PaginationResponse
} from "../../../../../api/types";
import {message, Table} from "antd";
import moment from "moment";
import {DATE_FORMAT} from "../../../../../api/apiConfig";
import {adminGetClientEvents} from "../../../../../api/clientService";

export interface ClientLogsTabProps {
    client: GetClientResponse
}

export const ClientLogsTab = (props: ClientLogsTabProps) => {
    const defaultPagination: PaginationResponse = {
        current_page: 1,
        items_per_page: 10,
        total_rows: 0,
    };

    const {t} = useTranslation();
    const [events, setEvents] = useState<AdminGetEventsResponseItem[]>([]);
    const [pagination, setPagination] = useState<PaginationResponse>(defaultPagination);

    const fetchEvents = async (page: number, pageSize: number) => {
        try {
            const response: AdminGetEventsResponse = (await adminGetClientEvents(props.client.client_id, page, pageSize)).data;
            setEvents(response.result);
            setPagination(response.pagination);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    useEffect(() => {
        setPagination(defaultPagination);
        fetchEvents(defaultPagination.current_page, defaultPagination.items_per_page);
    }, [props.client.client_id]);

    const onPaginationChange = (newPage: number, newPageSize: number) => {
        setPagination(prevState => ({
            ...prevState,
            current_page: newPage,
            items_per_page: newPageSize,
        }));

        fetchEvents(newPage, newPageSize);
    };

    const columns = [
        {
            title: t('createdAt'),
            dataIndex: 'event_time',
            render: (value) => value ? moment(value).format(DATE_FORMAT.DE) : "-",
        },
        {
            title: t('actorName'),
            dataIndex: 'actor_name',
        },
        {
            title: t('eventDescription'),
            dataIndex: 'event_description',
        },
        {
            title: t('data'),
            dataIndex: 'event_data',
        },
    ];

    return (
        <div className={styles.tabXL}>
            <Table rowKey={record => record.event_id}
                pagination={{
                    position: ["bottomCenter"],
                    pageSize: pagination.items_per_page,
                    current: pagination.current_page,
                    total: pagination.total_rows,
                    onChange: onPaginationChange,
                }}
                columns={columns}
                dataSource={events}
                scroll={{x: true}}
            />
        </div>
    );
};
