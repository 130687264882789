import * as React from "react";
import {useEffect, useState} from "react";
import {GetBoUsersListItem, GetTodosResponseItem} from "../../../../api/types";
import {TodoEntityKind, TodoKind} from "../../../../types/types";
import {Checkbox, DatePicker, Form, Input, message, Modal, Select, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {ConfirmModalClose} from "../../../common/presenters/confirm-modal-close/ConfirmModalClose";
import {Spacer} from "../../../common/presenters/spacer/Spacer";
import {DATE_FORMAT} from "../../../../api/apiConfig";
import moment, {Moment} from "moment";
import {adminCreateTodo, adminUpdateTodo} from "../../../../api/todoService";

export interface TodoModalProps {
    existingTodo?: GetTodosResponseItem
    entityKind?: TodoEntityKind
    entityId?: string

    currentBoUserId: string
    boUsers: GetBoUsersListItem[]

    modalOpen: boolean
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    onDataChanged: () => void
}

export const TodoModal = (props: TodoModalProps) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [secondaryModalOpen, setSecondaryModalOpen] = useState(false);

    useEffect(() => {
        if (props.modalOpen) {
            if (props.existingTodo) {
                form.setFieldsValue({
                    details: props.existingTodo.details,
                    assignee_id: props.existingTodo.assignee_id,
                    due_at: moment(props.existingTodo.due_at),
                    send_email: props.existingTodo.send_email,
                });
            } else {
                form.setFieldsValue({
                    assignee_id: props.currentBoUserId,
                    details: null,
                    due_at: null,
                    send_email: null,
                });
            }
        }
    }, [props]);

    const handleFormSubmit = async (values: any) => {
        try {
            if (props.existingTodo) {
                await adminUpdateTodo(
                    props.existingTodo.todo_id,
                    values.assignee_id,
                    values.details,
                    values.due_at,
                    values.send_email,
                );
            } else {
                await adminCreateTodo(
                    values.assignee_id,
                    TodoKind.Todo.toString(),
                    props.entityKind.toString(),
                    props.entityId,
                    values.details,
                    values.due_at,
                    values.send_email,
                );
            }
            message.success(t('messages:dataSaved'), 2);
            props.onDataChanged();
            props.setModalOpen(false);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    const getModalTitle = (): string => {
        if (props.existingTodo) {
            return t(`modals:updateTodo`);
        } else {
            return t(`modals:createTodo`);
        }
    };

    const getOkTitle = (): string => {
        if (props.existingTodo) {
            return t(`buttons:updateTodo`);
        } else {
            return t(`buttons:createTodo`);
        }
    };

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };

    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        props.setModalOpen(false);
    };

    return (
        <>
            <ConfirmModalClose visible={secondaryModalOpen} onCancel={secondaryModalOnCancel}
                onOk={secondaryModalOnOk}/>

            <Modal width={600}
                centered
                title={getModalTitle()}
                visible={props.modalOpen}
                destroyOnClose={true}
                maskClosable={false}
                okText={getOkTitle()}
                cancelText={t('buttons:cancel')}
                onCancel={(e: any) => {
                    if (e.code === "Escape") {
                        e.preventDefault();
                        e.stopPropagation();
                        setSecondaryModalOpen(true);
                    } else {
                        props.setModalOpen(false);
                    }
                }}
                onOk={form.submit}
            >
                <Typography.Text>{t("modals:todoInfo")}</Typography.Text>
                <Spacer/>

                <Form form={form}
                    layout={"vertical"}
                    onFinish={handleFormSubmit}
                >
                    <Form.Item name="details"
                        label={t('details')}
                    >
                        <Input.TextArea placeholder={t('reminderText')}/>
                    </Form.Item>

                    <Form.Item name="assignee_id"
                        label={t('assignee3')}
                    >
                        <Select>
                            {
                                props.boUsers.map(bou => (
                                    <Select.Option key={bou.bo_user_id}
                                        value={bou.bo_user_id}>{bou.bo_user_name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item name="due_at"
                        label={t('date')}
                        rules={[
                            {
                                required: true,
                                validator: (_, value) =>
                                    (value as Moment)?.isAfter(moment().endOf("day")) ? Promise.resolve() : Promise.reject(t('formErrors:pickFutureDate')),
                            },
                        ]}
                    >
                        <DatePicker format={DATE_FORMAT.DE}/>
                    </Form.Item>

                    <Form.Item name="send_email"
                        label={t('remindByEmail')}
                        valuePropName="checked"
                    >
                        <Checkbox/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
