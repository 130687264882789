import {ApiWrapper} from "api/ApiWrapper";
import {
    ApiResponse,
    ClientScore,
    ContactPerson,
    ContactPersonClient,
    PostContactPerson,
    PostOrPutClientScore,
    UpdateContactPersonProfileRequest
} from "api/types";

// ADMIN
export const adminGetAllContactPersonsForClient = async (clientId: string): Promise<ApiResponse<ContactPersonClient[]>> => {
    const path = `/admin/clients/${clientId}/contact_persons`;
  
    return ApiWrapper.get(path);
};
export const adminPostContactPerson = async (data: PostContactPerson): Promise<any> => {
    const path = `/admin/contact_persons`;

    return ApiWrapper.post(path, data);
};
export const adminPutContactPerson = async (id: string, data: PostContactPerson): Promise<any> => {
    const path = `/admin/contact_persons/${id}`;

    return ApiWrapper.put(path, data);
};

export const adminPostClientScore = async (clientId: string, data: PostOrPutClientScore): Promise<any> => {
    const path = `/admin/clients/${clientId}/scores`;

    return ApiWrapper.post(path, data) as Promise<any>;
};
export const adminPutClientScore = async (clientId: string, data: PostOrPutClientScore): Promise<any> => {
    const path = `/admin/clients/${clientId}/scores`;

    return ApiWrapper.put(path, data) as Promise<any>;
};
export const adminGetClientScore = async (clientId: string): Promise<ApiResponse<ClientScore>> => {
    const path = `/admin/clients/${clientId}/scores`;

    return ApiWrapper.get(path) as Promise<ApiResponse<ClientScore>>;
};


// CONTACT_PERSONS
export const getContactPersonSelfProfile = async (): Promise<ApiResponse<ContactPerson>> => {
    const path = `/contact_persons/profile`;

    return ApiWrapper.get(path);
};
export const getContactPerson = async (id): Promise<any> => {
    const path = `/contact_persons`;

    return ApiWrapper.get(path);
};
export const postContactPerson = async (data: PostContactPerson): Promise<any> => {
    const path = `/contact_persons`;

    return ApiWrapper.post(path, data);
};
export const putContactPerson = async (id: string, data: PostContactPerson): Promise<any> => {
    const path = `/contact_persons/${id}`;

    return ApiWrapper.put(path, data);
};
export const updateContactPersonProfile = async (data: UpdateContactPersonProfileRequest): Promise<any> => {
    const path = `/contact_persons/update_profile`;

    return ApiWrapper.put(path, data);
};

export const contactPersonCheckRegisterTokenValid = async (registerToken: string): Promise<ApiResponse<void>> => {
    const path = `/contact_persons/account/invites/${registerToken}`;

    return ApiWrapper.get(path, true);
};

export const contactPersonCheckResetTokenValid = async (registerToken: string): Promise<ApiResponse<void>> => {
    const path = `/contact_persons/account/resets/${registerToken}`;

    return ApiWrapper.get(path, true);
};

export const contactPersonRegister = async (registerToken: string, newPassword: string): Promise<ApiResponse<void>> => {
    const path = `/contact_persons/account/register`;

    const body = {
        password: newPassword,
        token: registerToken,
    };

    return ApiWrapper.post(path, body, true);
};

export const contactPersonRecoverPassword = async (resetToken: string, newPassword: string): Promise<ApiResponse<void>> => {
    const path = `/contact_persons/account/recover`;

    const body = {
        password: newPassword,
        token: resetToken,
    };

    return ApiWrapper.post(path, body, true);
};

export const contactPersonConfirmEmail = async (confirmEmailToken: string): Promise<ApiResponse<void>> => {
    const path = `/contact_persons/account/confirm_email`;

    const body = {
        token: confirmEmailToken,
    };

    return ApiWrapper.post(path, body, true);
};

// CLIENTS
export const getClient = async (): Promise<any> => {
    const path = `/clients`;

    return ApiWrapper.get(path);
};

export const getAllContactPersonsForClient = async (clientId: string): Promise<ApiResponse<ContactPersonClient>> => {
    const path = `/contact_persons/clients/${clientId}/list`;
  
    return ApiWrapper.get(path);
};

// OTHER
/**
 * Call this to refresh the session and to check if the user's logged in.
 */
export const refreshSession= async (manuallyHandleError?: boolean) => {
    const path = `/contact_persons/account/refresh_session`;
  
    return ApiWrapper.post(path, {}, manuallyHandleError);
};
export const logoutContactPerson = async (): Promise<any> => {
    const path = `/contact_persons/account/logout`;

    return ApiWrapper.post(path, {});
};
