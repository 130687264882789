
import { NETWORK_REQUEST_TIMEOUT_MS } from "./apiConfig";

function createCustomError(errorCode: string) {
    const customError = new Error();
    (customError as any).code = errorCode;

    return customError;
}

export default class ApiManager {
    public static readonly NETWORK_REQUEST_TIMEOUT_MS = NETWORK_REQUEST_TIMEOUT_MS;
    public static readonly NetworkTimeoutException = createCustomError("NetworkRequestTimeoutException");

    /**
     * Returns the timeout for network calls
     */
    public static async generateTimeout(networkTimeout?: number) {
        return new Promise(async (resolve) => {
            setTimeout(resolve, networkTimeout || ApiManager.NETWORK_REQUEST_TIMEOUT_MS, null);
        });
    }

    public static handleNetworkCallResult(networkCallResult: any): any {
        if (networkCallResult === null) {
            throw ApiManager.NetworkTimeoutException;
        }
		
        return networkCallResult;
    }
}
