import {Button, Form, Input, message, Row} from "antd";
import styles from "components/admin/pages/settings-page/SettingsPage.module.scss";
import {SideMenu} from 'components/common/presenters/side-menu/SideMenu';
import {Spacer} from 'components/common/presenters/spacer/Spacer';
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {SectionTitle} from "../../../common/presenters/section-title/SectionTitle";
import {ContactPerson} from "../../../../api/types";
import {getContactPersonSelfProfile, updateContactPersonProfile} from "../../../../api/clientContactPersonService";
import {DataItemsColumn} from "../../../common/presenters/data-items-column/DataItemsColumn";
import {contactPersonChangePassword} from "../../../../api/loginService";
import {isValidPassword} from "../../../../helpers/inputValidationHelpers";

export const SettingsPage = () => {
    const {t} = useTranslation();
    const [profileData, setProfileData] = useState({} as ContactPerson);

    const [pwForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const fetchProfileData = async () => {
        try {
            const result = await getContactPersonSelfProfile();
            setProfileData(result.data);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchProfileData();
    }, []);

    const handleChangePassword = async (password) => {
        setError("");
        setLoading(true);
        try {
            await contactPersonChangePassword(password);
            message.success(t("messages:dataSaved"));
        } catch (error) {
            if (error.response?.status === 400 && error.response?.data === "password too simple") {
                setError(t('errors:passwordInvalid'));
            } else {
                message.error(t("messages:couldNotSave"));
            }
            console.error();
        } finally {
            setLoading(false);
        }
    };

    const handleOnFinish = (values: any) => {
        if (values.password !== values.password_repeat) {
            message.error(t('errors:passwordsDontMatch'));
        } else {
            handleChangePassword(values.password);
        }
    };

    const handleNewsletterChange = async () => {
        try {
            await updateContactPersonProfile({...profileData, newsletter_subscription: !profileData.newsletter_subscription});
            message.success(t("messages:dataSaved"), 2);
            fetchProfileData();
        } catch (e) {
            console.error(e);
            message.error(t("messages:couldNotSave"), 2);
        }
    };

    return (
        <div className={styles.page}>

            <div className={styles.page}>

                <SideMenu isLoggedIn={true}/>

                <div className={styles.sectionRight}>
                    <h2>{t("settings")}</h2>
                    <Spacer/>

                    <div className={styles.tab}>
                        <SectionTitle text={"User Info"}/>
                        <DataItemsColumn dataItems={[
                            {itemName: t('email2'), itemValue: profileData.email},
                            {
                                itemName: t('newsletterSubscription'),
                                itemValue: profileData.newsletter_subscription ? t('yes') : t('no')
                            },
                        ]}/>

                        <Spacer/>

                        <Button data-cy={"button_change_newsletter_subscription"}
                            onClick={handleNewsletterChange}
                        >
                            {
                                profileData.newsletter_subscription ?
                                    t('unsubscribeNewsletter')
                                    : t('subscribeNewsletter')
                            }
                        </Button>


                        <Spacer/>

                        <SectionTitle text={"Reset Your Password"}/>
                        <Form
                            className={styles.passwordForm}
                            layout={"vertical"}
                            form={pwForm}
                            onFinish={handleOnFinish}
                            scrollToFirstError
                        >
                            <Form.Item name="password" label={t("password")} hasFeedback
                                rules={[({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (value && !isValidPassword(value)) {
                                            return Promise.reject(t('errors:passwordInvalid'));
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <Input.Password data-cy="input_password"
                                    className={styles.input}/>
                            </Form.Item>

                            <Form.Item name="password_repeat"
                                label={t("passwordRepeat")}
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('The two passwords that you entered do not match!');
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password data-cy="input_password_repeat"
                                    className={styles.input}/>
                            </Form.Item>

                            <div className="custom-error">{error}</div>
                            <Spacer/>

                            <Form.Item>
                                <Row className={styles.rowCenterX}>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        {t("buttons:changePassword")}
                                    </Button>
                                </Row>
                            </Form.Item>
                        </Form>

                        <p>*If you want to delete your account, please contact us.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
