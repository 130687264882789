import {Button, Input, message} from 'antd';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {adminUpdateClientBankDetails} from '../../../../../api/clientService';
import {GetClientResponse, PatchClientBankDetailsRequest} from '../../../../../api/types';
import styles from '../ClientDrilldownPage.module.scss';
import {SectionTitle} from "../../../../common/presenters/section-title/SectionTitle";
import { Spacer } from 'components/common/presenters/spacer/Spacer';

interface BankInfoSectionProps {
    client: GetClientResponse
    onDataChanged: () => void
}

export const BankInfoSection = (props: BankInfoSectionProps) => {
    const {t} = useTranslation();

    const [data, setData] = useState({} as PatchClientBankDetailsRequest);

    useEffect(() => {
        setData({
            bank_name: props.client.bank_name,
            iban: props.client.iban,
        });
    }, [props.client]);

    const handleSave = async () => {
        try {
            await adminUpdateClientBankDetails(props.client.client_id, {...data});
            props.onDataChanged();
            message.success(t('messages:dataSaved'), 2);
        } catch (e) {
            message.error(t('messages:couldNotSave'), 2);
            console.error(e);
        }
    };

    return (
        <>
            <SectionTitle text={t('sectionTitles:bankInfo')}/>

            <div className={styles.container}>
                <h4>Bank Name:</h4>
                <Input data-cy="input_bank_name"
                    placeholder="Svea Bank"
                    maxLength={150}
                    value={data.bank_name}
                    onChange={(e) => {
                        setData(prevState => {
                            return {...prevState, bank_name: e.target.value};
                        });
                    }}
                />
                
                <Spacer hx={2}/>

                <h4>IBAN:</h4>
                <Input data-cy="input_iban"
                    placeholder="DE1232439359439"
                    maxLength={100}
                    value={data.iban}
                    onChange={(e) => {
                        setData(prevState => {
                            return {...prevState, iban: e.target.value};
                        });
                    }}
                />

                <Spacer hx={2}/>

                <Button data-cy="button_bank_save"
                    type="primary"
                    onClick={() => handleSave()}
                >
                    {t('save')}
                </Button>
            </div>
        </>
    );
};
