import {Button, Form, Input, message, Popconfirm, Row} from 'antd';
import {loginAdmin, loginContactPerson, passwordResetAdmin, passwordResetContactPerson} from "api/loginService";
import styles from "components/common/pages/authentication-page/AuthenticationPage.module.scss";
import {ApplicationProcessHeader} from 'components/common/presenters/application-process-header/ApplicationProcessHeader';
import {Spacer} from 'components/common/presenters/spacer/Spacer';
import * as React from "react";
import {useState} from "react";
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import {RouteStrings} from "../../../../Routes";
import {useQuery} from "../../../../hooks/QueryParams";

export interface AuthenticationPageProps {
    isAdmin: boolean
}

export const AuthenticationPage = (props: AuthenticationPageProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const queryParams = useQuery();

    const [form] = Form.useForm();

    const [error, setError] = useState(null);

    const handleOnFinish = async (values) => {
        const { email, password } = values;

        if (email == null || email.length < 5 || password == null || password.length < 5) {
            return;
        }

        try {
            await setError("");

            const redirect: string = queryParams.get("redirect");

            if (props.isAdmin) {
                await loginAdmin(email, password);
                if (redirect) {
                    history.push(redirect);
                } else {
                    history.push(RouteStrings.AdminHome);
                }
            } else {
                await loginContactPerson(email, password);
                if (redirect) {
                    history.push(redirect);
                } else {
                    history.push(RouteStrings.ApplicationsOverview);
                }
            }
        } catch (e) {
            if (e.response?.status === 401){
                if (e.response?.data === "ErrEmailNotConfirmed") {
                    setError(t("errors:emailNotConfirmed"));
                } else {
                    setError(t("errors:incorrectCredentials"));
                }
                return;
            }
            console.error(e);
            setError(t("errors:loginError"));
        }
    };

    const handleForgotPassword = async () => {
        const email: string = form.getFieldValue("email");
        try {
            if (props.isAdmin) {
                await passwordResetAdmin(email);
            } else {
                await passwordResetContactPerson(email);
            }

            message.success(t("messages:emailSent"), 2); // even is no such email existed
        } catch (e) {
            console.error(e);
            message.error(t("messages:errorEncountered"), 2);
        }
    };

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader hideNav={true} logoLink="https://www.svea.com/ch/de/start/"/>
            <div className={styles.innerContainer}>
                <Form layout="vertical"
                    form={form}
                    onFinish={handleOnFinish}
                    scrollToFirstError
                >
                    <div className="auth-page__logo"></div>

                    <Form.Item>
                        <h2 className={"forgot"}>
                            {props.isAdmin ? 'Admin Login' : 'Login'}
                        </h2>
                    </Form.Item>

                    <Form.Item className={styles.formItem}
                        label={t("email2")}
                        name="email"
                        rules={[{required: true, type: 'email', message: t('errors:emailInvalid')}]}
                    >
                        <Input data-cy="input_email" />
                    </Form.Item>

                    <Form.Item className={styles.formItem}
                        label={t("password")}
                        name="password"
                        rules={[{ required: true, message: 'Please enter your password!' }]}
                    >
                        <Input.Password data-cy="input_password"/>
                    </Form.Item>

                    <Form.Item>
                        <div className={styles.error}>{error}</div>
                    </Form.Item>

                    <Form.Item>
                        <Popconfirm title={t('forgotPasswordPopover')} onConfirm={handleForgotPassword}>
                            <a className="forgot">{ t("forgotPassword") }</a>
                        </Popconfirm>
                    </Form.Item>

                    <Form.Item>
                        <Row className={styles.rowCenterX}>
                            <Button type="primary" htmlType="submit">{t("buttons:submit")}</Button>
                        </Row>
                    </Form.Item>
                </Form>
                <Spacer hx={2}/>
                <div className={styles.flowLink}>
                    { t("hasNoAccountPart1") }
                </div>
                <div className={styles.flowLink}>
                    <a href={RouteStrings.ApplicationFlowStepOne}>{ t("clickHereToCreateApplication") }</a>
                </div>
                <Spacer hx={2}/>

                <div className={styles.footer}>
                    <div className={styles.flowLink}>
                        <a href="https://www.svea.com/ch/de/start/">Zurück zu svea.com</a>
                    </div>
                </div>
            </div>
        </div>
    );
};
