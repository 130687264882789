import * as React from "react";
import styles from './SectionTitle.module.scss';
import {Divider} from "antd";

export interface SectionTitleProps {
    text?: string
}

export const SectionTitle = (props: SectionTitleProps) => {

    return (
        <Divider className={styles.divider} orientation="left">
            {
                props.text ?
                    <p className={styles.text}>{props.text}</p>
                    : null
            }
        </Divider>
    );
};
