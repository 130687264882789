import {ApiWrapper} from "api/ApiWrapper";
import {
    AdminGetEventsResponse,
    ApiResponse,
    ApplicationReview,
    ApplicationScore,
    CPCreateApplicationRequest,
    CPGetApplicationResponse,
    CPPutApplicationDetails,
    CrefoResult,
    GetNotesListResponse,
    PostOrPutApplicationReview,
    PostOrPutApplicationScore,
    PutApplicationBankInfoReq,
    PutApplicationKYCConfirmReq,
    PutApplicationStatusReq
} from "api/types";
import {Application, NoteCategory} from "types/types";

// ADMIN 
export const adminGetAllApplications = async (): Promise<ApiResponse<Application[]>> => {
    const path = `/admin/applications`;

    return ApiWrapper.get(path);
};
export const adminGetApplicationById = async (id: string): Promise<any> => {
    const path = `/admin/applications/${id}`;

    return ApiWrapper.get(path) as Promise<Application>;
};
export const adminPutApplicationBankInfo = async (id: string, data: PutApplicationBankInfoReq): Promise<any> => {
    const path = `/admin/applications/${id}/bank_info`;

    return ApiWrapper.put(path, data) as Promise<Application>;
};

export const adminPostApplicationScore = async (applicationId: string, data: PostOrPutApplicationScore): Promise<any> => {
    const path = `/admin/applications/${applicationId}/scores`;

    return ApiWrapper.post(path, data) as Promise<any>;
};
export const adminPutApplicationScore = async (id: string, data: PostOrPutApplicationScore): Promise<any> => {
    const path = `/admin/applications/${id}/scores`;

    return ApiWrapper.put(path, data) as Promise<any>;
};
export const adminGetApplicationScore = async (id: string): Promise<ApiResponse<ApplicationScore>> => {
    const path = `/admin/applications/${id}/scores`;

    return ApiWrapper.get(path) as Promise<ApiResponse<ApplicationScore>>;
};


export const adminPostApplicationReview = async (applicationId: string, data: PostOrPutApplicationReview): Promise<any> => {
    const path = `/admin/applications/${applicationId}/reviews`;

    return ApiWrapper.post(path, data) as Promise<any>;
};
export const adminPutApplicationReview = async (id: string, data: PostOrPutApplicationReview): Promise<any> => {
    const path = `/admin/applications/${id}/reviews`;

    return ApiWrapper.put(path, data) as Promise<any>;
};
export const adminGetApplicationReview = async (id: string): Promise<ApiResponse<ApplicationReview>> => {
    const path = `/admin/applications/${id}/reviews`;

    return ApiWrapper.get(path) as Promise<ApiResponse<ApplicationReview>>;
};

export const adminGetApplicationNotes = async (applicationId: string): Promise<ApiResponse<GetNotesListResponse[]>> => {
    const path = `/admin/applications/${applicationId}/notes`;

    return ApiWrapper.get(path);
};

export const adminPostApplicationNote = async (applicationId: string, note: string, category: NoteCategory): Promise<void> => {
    const path = `/admin/applications/${applicationId}/notes`;

    const body = {
        note: note,
        category: category.toString(),
    };

    return ApiWrapper.post(path, body);
};

export const adminPutApplicationNote = async (applicationId: string, noteId: string, note: string, category: NoteCategory): Promise<void> => {
    const path = `/admin/applications/${applicationId}/notes/${noteId}`;
    const body = {
        note: note,
        category: category.toString(),
    };

    return ApiWrapper.put(path, body);
};

export const adminDeleteApplicationNote = async (applicationId: string, noteId: string): Promise<void> => {
    const path = `/admin/applications/${applicationId}/notes/${noteId}`;

    return ApiWrapper.del(path, {});
};

export const adminGetApplicationEvents = async (applicationId: string, page: number, pageSize: number): Promise<ApiResponse<AdminGetEventsResponse>> => {
    const path = `/admin/applications/${applicationId}/events?page=${page}&limit=${pageSize}`;

    return ApiWrapper.get(path);
};

// USER
export const getCompanySearchResults = async (companyName: string, countryCode: string): Promise<ApiResponse<CrefoResult>> => {
    const path = `/company/${encodeURIComponent(companyName)}?country=${countryCode}`;

    return ApiWrapper.get(path);
};

export const userGetAllApplications = async (page: number): Promise<ApiResponse<Application[]>> => {
    const path = `/contact_persons/applications?page=${page}`;

    return ApiWrapper.get(path);
};

export const getApplicationById = async (id: string): Promise<ApiResponse<CPGetApplicationResponse>> => {
    const path = `/contact_persons/applications/${id}`;

    return ApiWrapper.get(path);
};

export const putApplicationBankInfo = async (id: string, data: PutApplicationBankInfoReq): Promise<any> => {
    const path = `/contact_persons/applications/${id}/bank_info`;

    return ApiWrapper.put(path, data) as Promise<Application>;
};

export const putApplicationStatus = async (id: string, data: PutApplicationStatusReq): Promise<any> => {
    const path = `/contact_persons/applications/${id}/status`;

    return ApiWrapper.put(path, data) as Promise<any>;
};

export const putApplicationStatusAdmin = async (id: string, data: PutApplicationStatusReq): Promise<any> => {
    const path = `/admin/applications/${id}/status`;

    return ApiWrapper.put(path, data) as Promise<any>;
};

export const putApplicationKYCConfirmAdmin = async (id: string, data: PutApplicationKYCConfirmReq): Promise<any> => {
    const path = `/admin/applications/${id}/confirm_kyc`;

    return ApiWrapper.put(path, data) as Promise<any>;
};

export const putApplicationAssigneeId = async (id: string, assigneeId?: string): Promise<any> => {
    const path = `/admin/applications/${id}/assignee`;
    const body = {
        bo_assignee_id: assigneeId,
    };

    return ApiWrapper.put(path, body) as Promise<any>;
};

export const cpPutApplicationDetails = async (applicationId: string, appl?: CPPutApplicationDetails): Promise<void> => {
    const path = `/contact_persons/applications/${applicationId}`;
    const body = {
        amount: appl.amount,
        duration: appl.duration,
        currency: appl.currency,
        purpose_info: appl.purpose_info,
        largest_debitors_list: appl.largest_debitors_list,
        company_name: appl.company_name,
        company_type: appl.company_type,
        company_number_of_employees: appl.company_number_of_employees,
        tax_id: appl.tax_id,
        company_register_number: appl.company_register_number,
        company_purpose: appl.company_purpose,
        company_country: appl.company_country,
        company_street: appl.company_street,
        company_house_number: appl.company_house_number,
        company_post_code: appl.company_post_code,
        company_city: appl.company_city,
    };

    return ApiWrapper.put(path, body) as Promise<any>;
};

export const postApplication = async (application: CPCreateApplicationRequest): Promise<ApiResponse<string>> => {
    const path = `/contact_persons/new/applications`;

    return ApiWrapper.post(path, application);
};
export const postApplicationAuthed = async (application: CPCreateApplicationRequest): Promise<ApiResponse<string>> => {
    const path = `/contact_persons/applications`;

    return ApiWrapper.post(path, application);
};

export const adminCreateApplicationForClient = async (clientID: string): Promise<ApiResponse<string>> => {
    const path = `/admin/applications`;
    const body = {
        client_id: clientID,
    };

    return ApiWrapper.post(path, body);
};

export const adminGetLastApplicationOfClient = async (clientID: string): Promise<ApiResponse<Application>> => {
    const path = `/admin/clients/${clientID}/last_application`;

    return ApiWrapper.get(path);
};

export const adminSearchApplications = async (text: string): Promise<ApiResponse<Application[]>> => {
    const path = `/admin/applications/search?text=${text}`;

    return ApiWrapper.get(path);
};

export const adminExportApplication = async (applicationId: string): Promise<ApiResponse<string>> => {
    const path = `/admin/applications/${applicationId}/export`;

    return ApiWrapper.post(path, {});
};

export const adminGetLatestApplicationArchiveURL = async (applicationId: string): Promise<ApiResponse<string>> => {
    const path = `/admin/applications/${applicationId}/latest_archive`;

    return ApiWrapper.get(path);
};

export const adminSoftDeleteApplication = async (applicationId: string): Promise<ApiResponse<void>> => {
    const path = `/admin/applications/${applicationId}/soft_delete`;

    return ApiWrapper.del(path, {});
};

export const adminCancelSoftDeleteApplication = async (applicationId: string): Promise<ApiResponse<void>> => {
    const path = `/admin/applications/${applicationId}/cancel_soft_delete`;

    return ApiWrapper.post(path, {});
};
