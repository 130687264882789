import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {GetClientResponse, PostGenerateOfferForClientRequest} from "../../../../api/types";
import {AnchoredSelect} from "../../../common/presenters/anchored-select/AnchoredSelect";
import {Spacer} from "../../../common/presenters/spacer/Spacer";
import {Button, Checkbox, Col, message, Row, Select, Space} from "antd";
import {LocalizedNumberInput} from "../../../common/presenters/localized-number-input/LocalizedNumberInput";
import {adminGenerateOfferForClient} from "../../../../api/clientService";
import {openUrlInNewTab} from "../../../../helpers/uiHelpers";
import {DataItemsColumn} from "../../../common/presenters/data-items-column/DataItemsColumn";

export interface GenerateOfferFormProps {
    client: GetClientResponse
    onSuccess?: () => void
    onCancel?: () => void
}

export const GenerateOfferForm = (props: GenerateOfferFormProps) => {
    const {t} = useTranslation();
    const [data, setData] = useState({
        contact_person_id: "",
        interest_rate_30_days: 4.1,
        interest_rate_60_days: 4.1,
        admin_fee_per_invoice: 0,
        max_advance_payment_percent: 0,
        is_with_global_assignment: false,
        is_selective_factoring: false,
        is_risk_assessment_completed: false,
    } as PostGenerateOfferForClientRequest);

    const handleGenerateOffer = async () => {
        try {
            const downloadURL: string = (await adminGenerateOfferForClient(props.client.client_id, {...data})).data;
            openUrlInNewTab(downloadURL);

            if (props.onSuccess) {
                props.onSuccess();
            }
            message.success(t('messages:dataSaved'), 2);
        } catch (e) {
            console.error(e);
            message.error(t('messages:couldNotSave'), 2);
        }
    };

    const contactPersonOptions = () => {
        return props.client.contact_persons?.map(cp => {
            return <Select.Option key={cp.contact_person_id} value={cp.contact_person_id}>{`${cp.salutation} ${cp.first_name} ${cp.last_name}`}</Select.Option>;
        });
    };

    return (
        <>
            <DataItemsColumn dataItems={[
                {itemName: t('country'), itemValue: props.client.address_country ? t(`countryCodes:${props.client.address_country.toLowerCase()}`) : "-"},
                {
                    itemName: t('product'),
                    itemValue: props.client.financing_type ? t(`financingTypes:${props.client.financing_type}`) : "-"
                },
            ]}/>

            <p>{t('contactPerson')}</p>
            <AnchoredSelect data-cy="select_contact_person"
                onChange={(e) => {
                    setData(prevState => {
                        return {...prevState, contact_person_id: e};
                    });
                }}
            >
                {contactPersonOptions()}
            </AnchoredSelect>
            <Spacer />

            <Row gutter={36}>
                <Col>
                    <p>{t('factoringFee30days')}</p>
                    <LocalizedNumberInput data-cy={"input_factoring_fee_30"}
                        suffix={"%"}
                        precision={2}
                        value={data.interest_rate_30_days}
                        onChange={(e) => {
                            setData({...data, interest_rate_30_days: e});
                        }}
                    />
                    <Spacer/>

                    <p>{t('adminFeePerInvoice')}</p>
                    <LocalizedNumberInput data-cy={"input_admin_fee"}
                        suffix={props.client.lead_currency}
                        precision={2}
                        value={data.admin_fee_per_invoice}
                        onChange={(e) => {
                            setData({...data, admin_fee_per_invoice: e});
                        }}
                    />
                </Col>
                <Col>
                    <p>{t('factoringFee60days')}</p>
                    <LocalizedNumberInput data-cy={"input_factoring_fee_60"}
                        suffix={"%"}
                        precision={2}
                        value={data.interest_rate_60_days}
                        onChange={(e) => {
                            setData({...data, interest_rate_60_days: e});
                        }}
                    />
                    <Spacer/>

                    <p>{t('maxAdvancePayment')}</p>
                    <LocalizedNumberInput data-cy={"input_advance_payment"}
                        suffix={"%"}
                        precision={0}
                        value={data.max_advance_payment_percent}
                        onChange={(e) => {
                            setData({...data, max_advance_payment_percent: e});
                        }}
                    />
                </Col>
            </Row>

            <Spacer />

            <Checkbox data-cy={"check_is_global"}
                checked={data.is_with_global_assignment}
                onChange={(e) => {
                    setData({...data, is_with_global_assignment: e.target.checked});
                }}
            >
                {t('withGlobalAssignment')}
            </Checkbox>

            <Spacer />

            <Checkbox data-cy={"check_is_selective"}
                checked={data.is_selective_factoring}
                onChange={(e) => {
                    setData({...data, is_selective_factoring: e.target.checked});
                }}
            >
                {t('selectiveFactoring')}
            </Checkbox>

            <Spacer />

            <Checkbox data-cy={"check_is_risk_completed"}
                checked={data.is_risk_assessment_completed}
                onChange={(e) => {
                    setData({...data, is_risk_assessment_completed: e.target.checked});
                }}
            >
                {t('riskAssessmentCompleted')}
            </Checkbox>

            <Spacer hx={2}/>

            <Space>
                {
                    props.onCancel ?
                        <Button data-cy={"button_generate_offer"}
                            onClick={() => {
                                props.onCancel();
                            }}
                        >
                            {t('buttons:cancel')}
                        </Button>
                        : null
                }
                <Button data-cy={"button_generate_offer"}
                    type={"primary"}
                    onClick={() => {
                        handleGenerateOffer();
                    }}
                >
                    {t('buttons:createOffer')}
                </Button>
            </Space>

        </>
    );
};
