import * as React from "react";
import {useEffect, useState} from "react";
import styles from "./DrilldownActionsMenu.module.scss";
import {Menu, message, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {TodoModal} from "../todo-modal/TodoModal";
import {GetAdminProfileResponse, GetBoUsersListItem, GetClientResponse} from "../../../../api/types";
import {adminGetBOUsers, adminGetSelfProfile} from "../../../../api/boUserService";
import {Application, TodoEntityKind} from "../../../../types/types";
import {getFlyerDocumentURL, getQuestionnaireURL} from "../../../../api/document-service/questionnaires";
import {openUrlInNewTab} from "../../../../helpers/uiHelpers";
import {GenerateOfferModal} from "../generate-offer-form/GenerateOfferModal";
import {
    adminCancelSoftDeleteClient,
    adminGetClient,
    adminHardDeleteClient,
    adminSoftDeleteClient
} from "../../../../api/clientService";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {adminCancelSoftDeleteApplication, adminSoftDeleteApplication} from "../../../../api/applicationService";

export interface DrilldownActionsMenuProps {
    application?: Application
    client?: GetClientResponse
    onDataChanged: () => void
}

export const DrilldownActionsMenu = (props: DrilldownActionsMenuProps) => {
    const {t} = useTranslation();

    const [adminProfile, setAdminProfile] = useState<GetAdminProfileResponse>({}); // TODO store it in global store
    const [boUsers, setBoUsers] = useState<GetBoUsersListItem[]>([]);

    const [offerModalOpen, setOfferModalOpen] = useState(false);
    const [todoModalOpen, setTodoModalOpen] = useState(false);

    const [clientForOffer, setClientForOffer] = useState<GetClientResponse>();

    const fetchBoUsersAndProfile = async () => {
        try {
            const profileResponse: GetAdminProfileResponse = (await adminGetSelfProfile()).data;
            const boUsersResponse: GetBoUsersListItem[] = (await adminGetBOUsers()).data;

            setAdminProfile(profileResponse);
            setBoUsers(boUsersResponse);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchBoUsersAndProfile();
    }, []);

    const handleNewTodo = () => {
        setTodoModalOpen(true);
    };

    const openQuestionnaireDocument = async () => {
        try {
            const country = props.client?.address_country || props.application?.company_country || "de";
            const financingType = props.client?.financing_type || props.application?.type || "loan";

            const downloadURL = (await getQuestionnaireURL(country, financingType.toString())).data;

            openUrlInNewTab(downloadURL);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }
    };

    const handleGenerateOffer = async () => {
        let clientForOffer: GetClientResponse = null;

        if (props.client) {
            clientForOffer = props.client;
        } else {
            try {
                const clientResult = (await adminGetClient(props.application.client_id)).data;
                clientForOffer = clientResult;
            } catch (e) {
                console.error(e);
                message.error(t('messages:errorEncountered'));
                return;
            }
        }

        setClientForOffer(clientForOffer);
        setOfferModalOpen(true);
    };

    const handleGetFlyer = async () => {
        try {
            const downloadURL: string = (await getFlyerDocumentURL()).data;
            openUrlInNewTab(downloadURL);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }
    };

    const handleConfirmSoftDeleteClient = () => {
        Modal.confirm({
            width: 800,
            title: t('confirmSoftDeleteClient'),
            icon: <ExclamationCircleOutlined />,
            okText: t('buttons:ok'),
            cancelText: t('buttons:cancel'),
            onOk: () => handleSoftDeleteClient(),
        });
    };
    const handleConfirmHardDeleteClient = () => {
        Modal.confirm({
            width: 800,
            title: t('confirmHardDeleteClient'),
            icon: <ExclamationCircleOutlined />,
            okText: t('buttons:ok'),
            cancelText: t('buttons:cancel'),
            onOk: () => handleHardDeleteClient(),
        });
    };

    const handleConfirmSoftDeleteApplication = () => {
        Modal.confirm({
            width: 800,
            title: t('confirmSoftDeleteApplication'),
            icon: <ExclamationCircleOutlined />,
            okText: t('buttons:ok'),
            cancelText: t('buttons:cancel'),
            onOk: () => handleSoftDeleteApplication(),
        });
    };

    const handleSoftDeleteClient = async () => {
        try {
            await adminSoftDeleteClient(props.client?.client_id);
            message.success(t('messages:scheduledForArchiving'), 2);
            props.onDataChanged();
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }
    };

    const handleSoftDeleteApplication = async () => {
        try {
            await adminSoftDeleteApplication(props.application?.id);
            message.success(t('messages:scheduledForArchiving'), 2);
            props.onDataChanged();
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }
    };

    const handleCancelSoftDeleteClient = async () => {
        try {
            await adminCancelSoftDeleteClient(props.client?.client_id);
            message.success(t('messages:success'), 2);
            props.onDataChanged();
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }
    };

    const handleCancelSoftDeleteApplication = async () => {
        try {
            await adminCancelSoftDeleteApplication(props.application?.id);
            message.success(t('messages:success'), 2);
            props.onDataChanged();
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }
    };

    const handleHardDeleteClient = async () => {
        try {
            await adminHardDeleteClient(props.client?.client_id);
            message.success(t('messages:dataDeleted'), 2);
            props.onDataChanged();
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }
    };

    const renderDeleteMenuItems = () => {
        if (props.client) {
            return (
                <>
                    {
                        props.client.archive_initiated_at ?
                            <Menu.Item className={styles.menuItem} key="cancelSoftDeleteClient" onClick={handleCancelSoftDeleteClient}>{t('cancelSoftDelete')}</Menu.Item>
                            : <Menu.Item className={styles.menuItem} key="softDeleteClient" onClick={handleConfirmSoftDeleteClient}>{t('softDelete')}</Menu.Item>
                    }

                    <Menu.Item className={styles.menuItem} key="hardDeleteClient" onClick={handleConfirmHardDeleteClient}>{t('hardDelete')}</Menu.Item>
                </>
            );
        } else if (props.application) {
            return (
                <>
                    {
                        props.application.archive_initiated_at ?
                            <Menu.Item className={styles.menuItem} key="cancelSoftDeleteApplication" onClick={handleCancelSoftDeleteApplication}>{t('cancelSoftDelete')}</Menu.Item>
                            : <Menu.Item className={styles.menuItem} key="softDeleteApplication" onClick={handleConfirmSoftDeleteApplication}>{t('softDelete')}</Menu.Item>
                    }
                </>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <Menu className={styles.menu} mode="vertical">
                <Menu.SubMenu className={styles.blueMenuItem} key="actions" title={t('actions')}>
                    <Menu.Item className={styles.menuItem} key="createTodo"
                        onClick={() => handleNewTodo()}>{t('createTodo')}</Menu.Item>
                    <Menu.SubMenu key="documents" title={t('documents')}>
                        <Menu.Item className={styles.menuItem} key="questionnaire"
                            onClick={openQuestionnaireDocument}>{t('questionnaire')}</Menu.Item>
                        <Menu.Item className={styles.menuItem} key="offer"
                            onClick={handleGenerateOffer}>{t('newOffer')}</Menu.Item>
                        <Menu.Item className={styles.menuItem} key="flyer" onClick={handleGetFlyer}>{t('sveaFlyer')}</Menu.Item>
                    </Menu.SubMenu>

                    {renderDeleteMenuItems()}
                </Menu.SubMenu>
            </Menu>

            <TodoModal currentBoUserId={adminProfile.bo_user_id}
                boUsers={boUsers}
                modalOpen={todoModalOpen}
                setModalOpen={setTodoModalOpen}
                onDataChanged={() => {}}
                entityKind={props.application ? TodoEntityKind.Application : TodoEntityKind.Client}
                entityId={props.application?.id || props.client?.client_id}
            />

            <GenerateOfferModal modalOpen={offerModalOpen}
                setModalOpen={setOfferModalOpen}
                client={clientForOffer}
            />
        </>
    );
};
