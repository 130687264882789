import * as React from "react";
import {useEffect, useState} from "react";
import styles from "./PipelineTab.module.scss";
import {useTranslation} from "react-i18next";
import {GetBoUsersListItem, GetClientsListResponse} from "../../../../../api/types";
import {adminGetLeads} from "../../../../../api/clientService";
import {Button, Select, Space, Typography} from "antd";
import {Currencies, LeadStatus} from "../../../../../types/types";
import {currencyLocaleFormatter} from "../../../../../formatters/currencyFormatters";
import {ClientCard} from "./ClientCard";
import {CountryCodes} from "../../../../../types/countries";
import {adminGetBOUsers} from "../../../../../api/boUserService";
import {AnchoredSelect} from "../../../../common/presenters/anchored-select/AnchoredSelect";

const {Text} = Typography;

export interface PipelineTabProps {

}

interface Filter {
    leadStatuses: LeadStatus[]
    countries: string[]
    boContactNames: string[]
}

export const PipelineTab = (props: PipelineTabProps) => {
    const {t} = useTranslation();
    const [clients, setClients] = useState([] as GetClientsListResponse[]);
    const [boUsers, setBoUsers] = useState([] as GetBoUsersListItem[]);

    const defaultFilter: Filter = {
        leadStatuses: [],
        countries: [],
        boContactNames: [],
    };

    const [filter, setFilter] = useState(defaultFilter);

    const filteredClients = clients
        .filter(c => filter.countries?.length > 0 ? filter.countries.includes(c.address_country) : true)
        .filter(c => filter.leadStatuses?.length > 0 ? filter.leadStatuses.includes(c.lead_status) : true)
        .filter(c => filter.boContactNames?.length > 0 ? filter.boContactNames.includes(c.bo_contact_name) : true);

    useEffect(() => {
        fetchLeads();
        fetchBOUsers();
    }, []);

    const fetchLeads = async () => {
        try {
            const result = (await adminGetLeads(1, 9999))?.data; // until pagination is implemented

            setClients(result);
        } catch (e) {
            console.error(e);
        }
    };

    const fetchBOUsers = async () => {
        try {
            const result = (await adminGetBOUsers()).data;
            setBoUsers(result); // don't filter deleted ones, show them for filtering
        } catch (e) {
            console.error(e);
        }
    };

    const renderColumns = () => {
        return filter.leadStatuses?.length > 0 ?
            filter.leadStatuses?.map(status => renderColumn(status))
            :
            Object.keys(LeadStatus).map((k, i) => {
                const enumValue = LeadStatus[k];
                return renderColumn(enumValue);
            });
    };

    const renderColumn = (leadStatus: LeadStatus) => {
        const columnClients = filteredClients.filter(c => c.lead_status === leadStatus);

        const sumEUR: number = columnClients.filter(c => c.lead_currency === Currencies.EUR)
            .reduce((sum, client) => sum + Number(client.lead_expected_volume), 0);

        const sumCHF: number = columnClients.filter(c => c.lead_currency === Currencies.CHF)
            .reduce((sum, client) => sum + Number(client.lead_expected_volume), 0);

        const sumUSD: number = columnClients.filter(c => c.lead_currency === Currencies.USD)
            .reduce((sum, client) => sum + Number(client.lead_expected_volume), 0);

        return (
            <Space key={leadStatus.toString()}
                className={styles.column}
                direction={"vertical"}
                size={"large"}
            >
                <div>
                    <Text className={styles.columnTitleText}>{t(`leadStatuses:${leadStatus}`)}</Text>
                    <br/>

                    <Text className={styles.columnSumText}>{`CHF ${currencyLocaleFormatter(sumCHF, 2)}`}</Text>
                    <br/>

                    <Text className={styles.columnSumText}>{`€ ${currencyLocaleFormatter(sumEUR, 2)}`}</Text>
                    <br/>

                    <Text className={styles.columnSumText}>{`$ ${currencyLocaleFormatter(sumUSD, 2)}`}</Text>
                    <br/>
                </div>

                {columnClients.map((c, i) => <ClientCard data-cy={`card_client_${i}`} key={c.client_id} client={c}/>)}
            </Space>
        );
    };

    return (
        <>
            <Space align={"start"} size={"large"}>
                <Button data-cy="button_reset_filters"
                    onClick={() => setFilter(defaultFilter)}
                >
                    {t('buttons:resetFilter')}
                </Button>

                <AnchoredSelect mode="multiple"
                    allowClear
                    value={filter.countries}
                    placeholder={t(`placeholders:filterByCountry`)}
                    onChange={(items) => {
                        setFilter(prevState => {
                            return {
                                ...prevState,
                                countries: [...items],
                            };
                        });
                    }}
                >
                    {CountryCodes.map(c => <Select.Option key={c} value={c}>{t(`countryCodes:${c}`)}</Select.Option>)}
                </AnchoredSelect>

                <AnchoredSelect mode="multiple"
                    allowClear
                    value={filter.boContactNames}
                    placeholder={t(`placeholders:filterByContactName`)}
                    onChange={(items) => {
                        setFilter(prevState => {
                            return {
                                ...prevState,
                                boContactNames: [...items]
                            };
                        });
                    }}
                >
                    {boUsers.map(bou => <Select.Option key={bou.bo_user_id}
                        value={bou.bo_user_name}>{bou.bo_user_name}</Select.Option>)}
                </AnchoredSelect>

                <AnchoredSelect mode="multiple"
                    allowClear
                    value={filter.leadStatuses}
                    placeholder={t(`placeholders:filterByStatus`)}
                    onChange={(items) => {
                        setFilter(prevState => {
                            return {
                                ...prevState,
                                leadStatuses: [...items]
                            };
                        });
                    }}
                >
                    {
                        Object.keys(LeadStatus).map((k, i) => {
                            const status = LeadStatus[k];
                            return <Select.Option key={status.toString()}
                                value={status}>{t(`leadStatuses:${status}`)}</Select.Option>;
                        })
                    }
                </AnchoredSelect>
            </Space>
            <br/>
            <br/>

            <Space size={32}
                wrap={false}
                align={"start"}
            >
                {renderColumns()}
            </Space>
        </>
    );
};
