import {Button, Checkbox, Form, Input, Row} from 'antd';
import {postApplication, postApplicationAuthed} from 'api/applicationService';
import {loginContactPerson} from 'api/loginService';
import {ClientTypes, CPPostApplication, CPPostClient} from "api/types";
import styles from 'components/common/pages/application-flow/ApplicationFlow.module.scss';
import {ApplicationProcessHeader} from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { BackButton } from 'components/common/presenters/back-button/BackButton';
import {CustomPageHeader} from "components/common/presenters/custom-page-header/CustomPageHeader";
import {Spacer} from 'components/common/presenters/spacer/Spacer';
import {goTo} from 'navigation/navigationHelpers';
import * as React from "react";
import {useTranslation} from 'react-i18next';
import {RouteStrings} from 'Routes';
import {LocalApplicationFlowDataManager} from "storage/LocalApplicationFlowDataManager";
import {isValidPassword} from "../../../../../helpers/inputValidationHelpers";

export const ApplicationStepCreateAccountPage = () => {
    const {t} = useTranslation();

    const [form] = Form.useForm();

    const [loading, setLoading] = React.useState(false);
    const [generalError, setGeneralError] = React.useState("");
    const [isLogin, setIsLogin] = React.useState(false);

    const [state, setState] = React.useState({
        email: "",
        password: "",
        repeatPassword: ""
    });


    const handleContinue = async (values: { email: string; password: string; newsletter_subscription: boolean }) => {
        if (loading) {
            return;
        }
        setGeneralError("");

        if (!isLogin && state.password != state.repeatPassword) {
            setGeneralError(t("errors:passwordsDoNotMatch"));
            return;
        }

        await setLoading(true);

        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();

        const newClient: CPPostClient = {
            client_type: ClientTypes.Client,
            company_name: appl.company_name,
            company_legal_form: appl.company_type,
            company_number_of_employees: appl.company_number_of_employees,
            address_post_code: appl.company_post_code,
            address_city: appl.company_city,
            address_country: appl.company_country,
            address_street: appl.company_street,
            address_house_number: appl.company_house_number,
            salutation: appl.salutation,
            title: appl.title,
            first_name: appl.first_name,
            last_name: appl.last_name,
            phone: appl.phone,
            mobile_number: appl.mobile_number,
            newsletter_subscription: values.newsletter_subscription ? values.newsletter_subscription : false,
        };

        const newAppl: CPPostApplication = {
            type: appl.type,
            currency: appl.currency,
            amount: appl.amount,
            duration: appl.duration,
            company_purpose: appl.company_purpose,
            purpose_info: appl.purpose_info,
            largest_debitors_list: appl.largest_debitors_list,
            tax_id: appl.tax_id,
        };

        if (isLogin) {
            try {
                await loginContactPerson(values.email, values.password);

                const createApplReq = await postApplicationAuthed({
                    client: newClient,
                    application: newAppl
                });

                if (createApplReq && createApplReq.status == 200) {
                    appl.id = createApplReq.data;
                    applDataManager.update(appl);
                } else {
                    appl.id = null;
                }

                goTo(RouteStrings.ApplicationFlowStepResult);
            } catch (error) {
                console.error(error);

                if (error?.response?.status === 409) {
                    setGeneralError(t("errors:cantProceed"));
                } else if (error?.response?.status === 401 && error.response?.data === "ErrEmailNotConfirmed") {
                    setGeneralError(t("errors:emailNotConfirmed"));
                } else {
                    setGeneralError(t("errors:generalError"));
                }
            }

        } else {
            try {
                const newClientWithCredentials = {
                    ...newClient,
                    credentials: {
                        email: values.email,
                        password: values.password,
                        is_email_confirmed: false,
                        is_enabled: true
                    }
                };

                const createApplReq = await postApplication({
                    client: newClientWithCredentials,
                    application: newAppl
                });

                if (createApplReq && createApplReq.status == 200) {
                    appl.id = createApplReq.data;
                    applDataManager.update(appl);
                } else {
                    appl.id = null;
                }

                goTo(RouteStrings.ApplicationFlowStepResult);
            } catch (error) {
                console.error(error);

                if (error?.response?.status === 409) {
                    setGeneralError(t("errors:cantProceed"));

                } else {
                    setGeneralError(t("errors:generalError"));
                }
            }
        }

        setLoading(false);
    };

    const handleOnFinish = (values: any) => {
        handleContinue(values);
    };

    const getPasswordFeedback = () => {
        if (isLogin) {
            return state.password.length >= 6;
        }

        return state.password.length >= 6 && state.password === state.repeatPassword;
    };

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus/>
            <CustomPageHeader/>
            <div className={styles.container}>
                <BackButton route={RouteStrings.ApplicationFlowStepContactInfo} />

                <div className={styles.innerContainer}>
                    <h2 className={styles.processTitle}>{t("headings:processCreateAccount")}</h2>
                    <Spacer/>

                    <Form
                        layout={"vertical"}
                        form={form}
                        onFinish={handleOnFinish}
                        scrollToFirstError
                    >
                        <Form.Item name="email" label={t("email")} hasFeedback={state.email.length >= 5}
                            rules={[{required: true, message: t('errors:emailInvalid'), type: 'email'}]}>

                            <Input data-cy="email"
                                className={styles.input}
                                placeholder={t("example") + " example@example.com"}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        email: e.target.value
                                    });
                                }}
                            />
                        </Form.Item>

                        <Form.Item name="password" label={t("password")}
                            hasFeedback={getPasswordFeedback()}
                            rules={[({getFieldValue}) => ({
                                validator(_, value) {
                                    if (value && !isValidPassword(value)) {
                                        return Promise.reject(t('errors:passwordInvalid'));
                                    }
                                    return Promise.resolve();
                                },
                            })]}>
                            <Input.Password data-cy="input_company_name"
                                className={styles.input}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        password: e.target.value
                                    });
                                }}/>
                        </Form.Item>

                        {
                            isLogin ?
                                null
                                :
                                <Form.Item name="passwordRepeat" label={t("passwordRepeat")}
                                    hasFeedback={state.repeatPassword.length >= 6 && state.password === state.repeatPassword}
                                    rules={[{
                                        required: true,
                                        message: t("errors:passwordInvalid"),
                                        min: 6,
                                        max: 100
                                    }]}>
                                    <Input.Password data-cy="input_company_name"
                                        className={styles.input}
                                        onChange={(e) => {
                                            setState({
                                                ...state,
                                                repeatPassword: e.target.value
                                            });
                                        }}/>
                                </Form.Item>
                        }

                        <Spacer/>


                        {
                            isLogin ?
                                null
                                :
                                <Form.Item name="tc_accepted" valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject('Please accept the Terms & Conditions'),
                                        },
                                    ]}>
                                    <Checkbox>
                                        Ich akzeptiere die <a
                                            href="https://www.svea.com/ch/de/uber-uns/cookie-policy/haftungsablehnung/"
                                            target="_blank" rel="noopener noreferrer">Allgemeinen
                                        Geschäftsbedingungen</a> und <a
                                            href="https://www.svea.com/ch/de/uber-uns/cookie-policy/datenschutzerklarung/"
                                            target="_blank" rel="noopener noreferrer">Datenschutzbedingungen</a>
                                    </Checkbox>
                                </Form.Item>
                        }

                        {
                            isLogin ?
                                null
                                :
                                <Form.Item name="newsletter_subscription" valuePropName="checked">
                                    <Checkbox>
                                        {t('acceptNewsletterCheckbox')}
                                    </Checkbox>
                                </Form.Item>
                        }

                        <div className={styles.error}>{generalError}</div>
                        <Spacer/>
                        <Spacer/>

                        <Form.Item>
                            <Row className={styles.rowCenterX}>
                                <Button type="primary" htmlType="submit"
                                    loading={loading}>{t("buttons:continue")}</Button>
                            </Row>
                        </Form.Item>

                        <Spacer/>

                        <Form.Item className={styles.itemCenter}>
                            <a className={styles.link} onClick={() => {
                                setIsLogin(!isLogin);
                            }}>
                                {isLogin ?
                                    t("hasNoAccount")
                                    :
                                    t("hasAccount")
                                }
                            </a>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};
