import * as React from "react";
import styles from "./PipelineTab.module.scss";
import {goToAdminClientDrilldown} from "../../../../../navigation/navigationHelpers";
import {Avatar, Card, Typography} from "antd";
import {GetClientsListResponse} from "../../../../../api/types";
import {currencyLocaleFormatter} from "../../../../../formatters/currencyFormatters";
import moment from "moment";
import {DATE_FORMAT} from "../../../../../api/apiConfig";
import {UserOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const { Text } = Typography;

export interface ClientCardProps {
    client: GetClientsListResponse
}

export const ClientCard = (props: ClientCardProps) => {
    const {t} = useTranslation();

    return (
        <Card className={styles.card}
            hoverable
            onClick={() => goToAdminClientDrilldown(props.client.client_id)}
        >
            <Text className={styles.expectedVolumeText}>
                {`${t(`currencySymbols:${props.client.lead_currency}`)} ${props.client.lead_expected_volume ? currencyLocaleFormatter(props.client.lead_expected_volume) : 0}`}
            </Text>
            <br/>

            <Text className={styles.companyNameText}>{`${props.client.company_name} ${props.client.company_legal_form}`}</Text>
            <br/>

            <Text className={styles.createdDateText}>{`Created: ${props.client.created_at ? moment(props.client.created_at).format(DATE_FORMAT.DE) : ""}`}</Text>
            <br/>

            <Avatar className={styles.userIcon} size="large" icon={<UserOutlined />} />
        </Card>
    );
};
