import * as React from "react";
import {useState} from "react";
import styles from "./TableSearchInputs.module.scss";
import {Button, Checkbox, List, Space} from "antd";
import {RedoOutlined, SearchOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
const { Set } = require('immutable');

export interface ListSearchItem {
    value: string
    label: string
}

export interface ListSearchProps {
    items: ListSearchItem[]
    confirm: () => void
    onSearch: (selectedValues: string[]) => void
}

export const ListSearch = (props: ListSearchProps) => {
    const {t} = useTranslation();
    const [data, setData] = useState(Set());

    const onFinishSearch = () => {
        props.onSearch(data.toArray());
        props.confirm();
    };

    const onReset = () => {
        setData(Set());
        props.onSearch([]);
        props.confirm();
    };

    const renderListItem = (item: ListSearchItem) => {
        return (
            <List.Item actions={[
                <Checkbox checked={data.includes(item.value)}
                    onChange={(e) => {
                        const isChecked = e.target.checked;
                        setData(isChecked ? data.add(item.value) : data.delete(item.value));
                    }}
                >
                </Checkbox>
            ]}>
                {item.label}
            </List.Item>
        );
    };

    return (
        <Space className={styles.container}
            direction={"vertical"}
        >
            <List itemLayout={"horizontal"}
                dataSource={props.items}
                renderItem={(item) => renderListItem(item)}
            />

            <Space>
                <Button type={"primary"}
                    icon={<SearchOutlined/>}
                    onClick={onFinishSearch}
                >
                    {t('search')}
                </Button>

                <Button type={"primary"}
                    icon={<RedoOutlined/>}
                    onClick={onReset}
                >
                    {t('buttons:reset')}
                </Button>
            </Space>
        </Space>
    );
};
