import * as React from "react";
import {GetClientResponse} from "../../../../../api/types";
import {LeadInfoSection} from "./LeadInfoSection";
import {LeadStatus} from "../../../../../types/types";
import {NextStepsContacted} from "./next-steps/NextStepsContacted";
import styles from "../ClientDrilldownPage.module.scss";
import {useTranslation} from "react-i18next";
import {NextStepsQuestionnaireSent} from "./next-steps/NextStepsQuestionnaireSent";
import {NextStepsQuestionnaireReturned} from "./next-steps/NextStepsQuestionnaireReturned";
import {NextStepsProposalSent} from "./next-steps/NextStepsProposalSent";
import {NextStepsContractSent} from "./next-steps/NextStepsContractSent";
import {NextStepsContractSignedOrWon} from "./next-steps/NextStepsContractSignedOrWon";
import {NextStepsHasApplication} from "./next-steps/NextStepsHasApplication";
import {SectionTitle} from "../../../../common/presenters/section-title/SectionTitle";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import {Alert} from "antd";
import moment from "moment";
import {DATE_FORMAT} from "../../../../../api/apiConfig";

export interface LeadDetailsTabProps {
    clientData: GetClientResponse
    onDataChanged: () => void
}

export const LeadDetailsTab = (props: LeadDetailsTabProps) => {
    const {t} = useTranslation();


    const renderNextStepsSection = () => {
        let innerComponent;

        switch (props.clientData.lead_status) {
        case LeadStatus.LeadContacted:
            innerComponent = (
                <NextStepsContacted client={props.clientData} onDataChanged={() => props.onDataChanged()}/>);
            break;
        case LeadStatus.ProjectIdentifiedQuestionnaireSent:
            innerComponent = (
                <NextStepsQuestionnaireSent client={props.clientData}
                    onDataChanged={() => props.onDataChanged()}/>);
            break;
        case LeadStatus.QuestionnaireReturned:
            innerComponent = (
                <NextStepsQuestionnaireReturned client={props.clientData}
                    onDataChanged={() => props.onDataChanged()}/>);
            break;
        case LeadStatus.ProposalSent:
            innerComponent = (
                <NextStepsProposalSent client={props.clientData} onDataChanged={() => props.onDataChanged()}/>);
            break;
        case LeadStatus.ContractSent:
            innerComponent = (
                <NextStepsContractSent client={props.clientData} onDataChanged={() => props.onDataChanged()}/>);
            break;
        case LeadStatus.ContractSigned:
        case LeadStatus.Won:
            innerComponent = props.clientData.has_application ?
                <NextStepsHasApplication client={props.clientData} onDataChanged={() => props.onDataChanged()}/>
                :
                <NextStepsContractSignedOrWon client={props.clientData}
                    onDataChanged={() => props.onDataChanged()}/>;
            break;
        default:
            return null;
        }

        return (
            <div className={styles.tab}>
                <SectionTitle text={t('sectionTitles:leadStatusNextSteps')}/>

                <div className={styles.container}>
                    {innerComponent}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className={styles.tab}>
                {
                    props.clientData.lead_status === LeadStatus.Archived ?
                        <Alert type="error"
                            closable={false}
                            message={t('archivedBanner', {
                                entityType: "Lead",
                                archivedAt: props.clientData.archive_initiated_at ? moment(props.clientData.archive_initiated_at).format(DATE_FORMAT.DE) : "-"
                            })}
                        /> : null
                }
                
                <LeadInfoSection client={props.clientData}
                    onDataChanged={() => props.onDataChanged()}
                />
            </div>
            
            <Spacer hx={2}/>

            {renderNextStepsSection()}
        </>
    );
};
