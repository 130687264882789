export enum BoUserRole {
    ADMIN = "admin",
    SALES_REP = "sales_rep",
    NO_PERMISSION = "no_permission",
}

export enum Currencies {
    EUR = "EUR",
    USD = "USD",
    CHF = "CHF",
}

export const LoanDurations = {
    Months6: 6,
    Months12: 12,
    Months24: 24,
    Months36: 36,
};

export enum ApplicationStates {
    Open = "open",
    In_Review_Risk = "in_review_risk",
    Signature_KYC = "signature_kyc",
    Final_Review_Operations = "final_review_operations",
    Approved_Awaiting_Payout = "approved_awaiting_payout",
    Paid_Out = "paid_out",
    Rejected = "rejected",
    Archived = "archived",
}

export enum ApplicationFailedStates {
    General
}

export enum InvoiceStatuses {
    Ok = "ok",
    Repaid = "repaid",
    Overdue = "overdue"
}

export enum LeadTaskStatus {
    None = "None",
    Pre_Check = "Pre_Check",
    Risk_Review = "Risk_Review",
    Request_Docs_Sales = "Request_Docs_Sales",
    Request_Docs_Risk = "Request_Docs_Risk",
    Reject_User_Sales = "Reject_User_Sales",
    Reject_User_Risk = "Reject_User_Risk",
    Pending_User_Feedback = "Pending_User_Feedback",
    Follow_Up_Sales = "Follow_Up_Sales",
    Follow_Up_Risk = "Follow_Up_Risk",
    Setup_Link = "Setup_Link",
    Monitor = "Monitor",
}

export enum UserFlags {
    Unset = "-",
    None = "none",
    FraudRisk = "fraud_risk",
    MonitorRisk = "monitor_risk",
    MonitorLegal = "monitor_legal",
    InactiveUser = "inactive_user",
}

export type Application = {
    id: string
    short_id: string
    client_id: string
    bo_assignee_id?: string
    source?: string
    type: string
    currency: string
    amount: number
    duration: number
    interest_rate: number
    status: ApplicationStates
    company_name: string
    company_type: string
    company_country: string
    company_post_code: string
    company_purpose: string
    company_street: string
    company_house_number: string
    company_city: string
    purpose: string
    purpose_info: string
    largest_debitors_list: string
    tax_id: string
    bank_name: string
    iban: string
    contract_id: string
    created_at: string
    paid_out_on: string
    archive_initiated_at: string
};

export type Signatory = {
    id: string
    application_id: string
    first_name: string
    last_name: string
    date_of_birth: string
    street_name: string
    street_number: string
    city: string
    post_code: string
    phone: string
    passport_number: string
    is_signatory: boolean
    is_sole_signatory: boolean
    is_contract_signatory: boolean
    is_beneficial_owner: boolean
    is_deleted: boolean
    created_at: string
};

export type Component = JSX.Element | JSX.Element[] | HTMLElement | HTMLElement[] | string;

export enum ClientType {
    Client = "client",
    Lead = "lead",
}

export enum LeadStatus {
    LeadBacklog = "backlog",
    InBearbeitung = "in_bearbeitung",
    LeadContacted = "lead_contacted",
    ProjectIdentifiedQuestionnaireSent = "project_identified_questionnaire_sent",
    QuestionnaireReturned = "questionnaire_returned",
    ProposalSent = "proposal_sent",
    ContractSent = "contract_sent",
    ContractSigned = "contract_signed",
    Won = "won",
    Lost = "lost",
    Disqualified = "disqualified",
    Archived = "archived",
}

export const LeadSources = [ // TODO list by svea
    "Telefon (Inbound)",
    "E-Mail (Inbound)",
    "Google Ads",
    "Broker",
    "Cold Calling",
    "LinkedIn InMail Kampagne",
    "LinkedIn Cold Mailing Social Selling",
    "Chat",
    "Webseite Callback-Anfrage",
    "Persönliches Netzwerk",
    "Empfehlung",
    "Sonstige",
];

export const CompanyIndustries = [ // TODO list by svea
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U"
];

export const CompanyLegalForms = [ // this list is also maintained on the backend, because we validate the input there too
    "AG",
    "AG / börsennotiert",
    "AG & Co. KG",
    "AG & Co. oHG",
    "BGB-Gesellschaft ( z.B. GbR )",
    "Einzelfirma",
    "e.G.",
    "e.V.",
    "Freiberufler",
    "Gewerbebetrieb",
    "GmbH",
    "GmbH & Co KG",
    "GmbH & Co. KGaA",
    "GmbH & Co oHG",
    "INC. & Co. KG",
    "KG",
    "KGaA",
    "Körperschaft d. öffentl. Rechts",
    "Körperschaft d. öffentl. Rechts / Bundesland",
    "Körperschaft d. öffentl. Rechts / Regierung",
    "Körperschaft d. öffentl. Rechts / Zentralregierung",
    "Limited",
    "Limited & Co. KG",
    "Limited Liability Partnership (LLP)",
    "OHG",
    "Partnerschaftsgesellschaft",
    "Partnergs. mbB",
    "Privatperson",
    "SE",
    "SE & Co. KG",
    "SE & Co. KGaA",
    "S.a.r.l. & Co. KG",
    "Stiftung",
    "UG (haftungsbeschränkt)",
    "UG & Co. KG",
];

export const CompanySizes = [ // TODO list by svea
    "1-10",
    "11-100",
    "101-500",
    "501-1000",
];

export enum ClientFinancingType {
    Loan = "loan",
    Factoring = "factoring",
}

export enum DocumentType {
    Client,
    Application,
}

export enum DocumentCategory {
    Other = "other",
    AnnualAccount = "annual_accounts",
    BWA = "bwa",
    SUSA = "susa",
    PropertyDocuments = "property_documents",
    DebitorCreditorList = "debitor_creditor_list",
    NegativeStatement = "negative_statement",
    TaxOfficeExcerpt = "tax_office_excerpt",
    HealthInsureExtract = "health_insure_extract",
    IDCard = "id_card",
    Passport = "passport",
    ProofOfOwnership = "proof_of_ownership",
    ListOfSignatures = "list_of_signatures",
    CommercialRegisterExtract = "commercial_register_extract",
    CompanyBookExtract = "company_book_extract",
    BusinessRegistration = "business_registration",
    OtherKYC = "other_kyc",
    Schufa = "schufa",
    Creditreform = "creditreform",
    Bisnode = "bisnode",
    DebitorRegisterExcerpt = "debitor_register_excerpt",
    Email = "email",
    Letter = "letter",
    Fax = "fax",
    Offer = "offer",
    Contract = "contract",
    OtherCorrespondence = "other_correspondence",
    Unclassified = "unclassified",
    Questionnaire = "questionnaire",
}

export interface Todo {

}

export enum TodoStatus {
    Open = "open",
    Complete = "complete",
}

export enum TodoKind {
    Todo = "todo",
    Reminder = "reminder",
}

export enum TodoEntityKind {
    Application = "application",
    Client = "client",
}

export enum RepaymentType {
    Monthly = "monthly",
    Quarterly = "quarterly",
    Yearly = "yearly",
    Once = "once",
}

export enum ContractDocument {
    DocumentTemplate_CH_Contract_1 = "DocumentTemplate_CH_Contract_1",
    DocumentTemplate_CH_Contract_2_a = "DocumentTemplate_CH_Contract_2_a",
    DocumentTemplate_CH_Contract_2_b = "DocumentTemplate_CH_Contract_2_b",
    DocumentTemplate_CH_Contract_3 = "DocumentTemplate_CH_Contract_3",
    DocumentTemplate_CH_Contract_5 = "DocumentTemplate_CH_Contract_5",
    DocumentTemplate_CH_Contract_6_a = "DocumentTemplate_CH_Contract_6_a",
    DocumentTemplate_CH_Contract_6_b = "DocumentTemplate_CH_Contract_6_b",
    DocumentTemplate_CH_Contract_7 = "DocumentTemplate_CH_Contract_7",
    DocumentTemplate_CH_Contract_14 = "DocumentTemplate_CH_Contract_14",
    DocumentTemplate_CH_Contract_15 = "DocumentTemplate_CH_Contract_15",
    DocumentTemplate_CH_Contract_16 = "DocumentTemplate_CH_Contract_16",
    DocumentTemplate_CH_Contract_17 = "DocumentTemplate_CH_Contract_17",

    DocumentTemplate_DE_Contract_1 = "DocumentTemplate_DE_Contract_1",
    DocumentTemplate_DE_Contract_2_a = "DocumentTemplate_DE_Contract_2_a",
    DocumentTemplate_DE_Contract_2_b = "DocumentTemplate_DE_Contract_2_b",
    DocumentTemplate_DE_Contract_3 = "DocumentTemplate_DE_Contract_3",
    DocumentTemplate_DE_Contract_5 = "DocumentTemplate_DE_Contract_5",
    DocumentTemplate_DE_Contract_6 = "DocumentTemplate_DE_Contract_6",
    DocumentTemplate_DE_Contract_7 = "DocumentTemplate_DE_Contract_7",
    DocumentTemplate_DE_Contract_15 = "DocumentTemplate_DE_Contract_15",
    DocumentTemplate_DE_Contract_16 = "DocumentTemplate_DE_Contract_16",
    DocumentTemplate_DE_Contract_17 = "DocumentTemplate_DE_Contract_17",

    DocumentTemplate_Repayment_Plan = "Repayment_Plan",
}

export enum NoteCategory {
    Phone = "phone",
    Email = "email",
    Conversation = "conversation",
    Note = "note",
    Other = "other",
}
