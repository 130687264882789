import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from 'components/common/pages/404-page/NotFoundPage.module.scss';
import "./additional.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";

export const NotFoundPage = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.page}>
            <section className="error-container">
                <span className="four"><span className="screen-reader-text">4</span></span>
                <span className="zero"><span className="screen-reader-text">0</span></span>
                <span className="four"><span className="screen-reader-text">4</span></span>
            </section>
            <div>
                <p className={styles.text}>{ t("notFoundPage:text")}</p>
            </div>
            <Spacer/>
            <div>
                <a className={styles.link} href="/">{ t("buttons:toHome") }</a>
            </div>
        </div>
    );
};
