import * as React from "react";
import styles from './DocumentCategorySelector.module.scss';
import {v4 as uuidv4} from 'uuid';
import {Cascader} from "antd";
import {DocumentCategory} from "../../../../types/types";
import {useTranslation} from "react-i18next";

export interface DocumentCategorySelectorProps {
    category?: DocumentCategory
    onChange: (category: DocumentCategory) => void
}

export const DocumentCategorySelector = (props: DocumentCategorySelectorProps) => {
    const {t} = useTranslation();

    const options = [
        {
            label: t(`documentCategories:${DocumentCategory.Other}`),
            value: DocumentCategory.Other,
        },
        {
            label: t('documentCategoryGroups:finances'),
            value: 'finances',
            children: [
                {
                    label: t(`documentCategories:${DocumentCategory.AnnualAccount}`),
                    value: DocumentCategory.AnnualAccount
                },
                {label: t(`documentCategories:${DocumentCategory.BWA}`), value: DocumentCategory.BWA},
                {label: t(`documentCategories:${DocumentCategory.SUSA}`), value: DocumentCategory.SUSA},
                {
                    label: t(`documentCategories:${DocumentCategory.PropertyDocuments}`),
                    value: DocumentCategory.PropertyDocuments
                },
                {
                    label: t(`documentCategories:${DocumentCategory.DebitorCreditorList}`),
                    value: DocumentCategory.DebitorCreditorList
                },
                {
                    label: t(`documentCategories:${DocumentCategory.NegativeStatement}`),
                    value: DocumentCategory.NegativeStatement
                },
                {
                    label: t(`documentCategories:${DocumentCategory.TaxOfficeExcerpt}`),
                    value: DocumentCategory.TaxOfficeExcerpt
                },
                {
                    label: t(`documentCategories:${DocumentCategory.HealthInsureExtract}`),
                    value: DocumentCategory.HealthInsureExtract
                },
            ],
        },
        {
            label: t('documentCategoryGroups:legitimation'),
            value: 'legitimation',
            children: [
                {label: t(`documentCategories:${DocumentCategory.IDCard}`), value: DocumentCategory.IDCard},
                {label: t(`documentCategories:${DocumentCategory.Passport}`), value: DocumentCategory.Passport},
                {
                    label: t(`documentCategories:${DocumentCategory.ProofOfOwnership}`),
                    value: DocumentCategory.ProofOfOwnership
                },
                {
                    label: t(`documentCategories:${DocumentCategory.ListOfSignatures}`),
                    value: DocumentCategory.ListOfSignatures
                },
                {
                    label: t(`documentCategories:${DocumentCategory.CommercialRegisterExtract}`),
                    value: DocumentCategory.CommercialRegisterExtract
                },
                {
                    label: t(`documentCategories:${DocumentCategory.CompanyBookExtract}`),
                    value: DocumentCategory.CompanyBookExtract
                },
                {
                    label: t(`documentCategories:${DocumentCategory.BusinessRegistration}`),
                    value: DocumentCategory.BusinessRegistration
                },
                {label: t(`documentCategories:${DocumentCategory.OtherKYC}`), value: DocumentCategory.OtherKYC},
            ],
        },
        {
            label: t('documentCategoryGroups:information'),
            value: 'information',
            children: [
                {label: t(`documentCategories:${DocumentCategory.Schufa}`), value: DocumentCategory.Schufa},
                {label: t(`documentCategories:${DocumentCategory.Creditreform}`), value: DocumentCategory.Creditreform},
                {label: t(`documentCategories:${DocumentCategory.Bisnode}`), value: DocumentCategory.Bisnode},
                {
                    label: t(`documentCategories:${DocumentCategory.DebitorRegisterExcerpt}`),
                    value: DocumentCategory.DebitorRegisterExcerpt
                },
            ],
        },
        {
            label: t('documentCategoryGroups:correspondence'),
            value: 'correspondence',
            children: [
                {label: t(`documentCategories:${DocumentCategory.Email}`), value: DocumentCategory.Email},
                {label: t(`documentCategories:${DocumentCategory.Letter}`), value: DocumentCategory.Letter},
                {label: t(`documentCategories:${DocumentCategory.Fax}`), value: DocumentCategory.Fax},
                {label: t(`documentCategories:${DocumentCategory.Offer}`), value: DocumentCategory.Offer},
                {label: t(`documentCategories:${DocumentCategory.Contract}`), value: DocumentCategory.Contract},
                {
                    label: t(`documentCategories:${DocumentCategory.OtherCorrespondence}`),
                    value: DocumentCategory.OtherCorrespondence
                },
            ],
        },
        {
            label: t(`documentCategories:${DocumentCategory.Unclassified}`),
            value: DocumentCategory.Unclassified,
        },
        {
            label: t(`documentCategories:${DocumentCategory.Questionnaire}`),
            value: DocumentCategory.Questionnaire,
        },
    ];

    // Display only the last item in the tree.
    const displayRender = (label) => {
        return label[label.length - 1];
    };

    const dropdownRender = (menus) => {
        return (
            <div className={styles.cascaderMenu}>
                {menus}
            </div>
        );
    };

    const getDefaultValue = () => {
        switch (props.category) {
        case DocumentCategory.Other:
            return [t(`documentCategories:${DocumentCategory.Other}`)];
        case DocumentCategory.AnnualAccount:
            return ["finances", t(`documentCategories:${DocumentCategory.AnnualAccount}`)];
        case DocumentCategory.BWA:
            return ["finances", t(`documentCategories:${DocumentCategory.BWA}`)];
        case DocumentCategory.SUSA:
            return ["finances", t(`documentCategories:${DocumentCategory.SUSA}`)];
        case DocumentCategory.PropertyDocuments:
            return ["finances", t(`documentCategories:${DocumentCategory.PropertyDocuments}`)];
        case DocumentCategory.DebitorCreditorList:
            return ["finances", t(`documentCategories:${DocumentCategory.DebitorCreditorList}`)];
        case DocumentCategory.NegativeStatement:
            return ["finances", t(`documentCategories:${DocumentCategory.NegativeStatement}`)];
        case DocumentCategory.TaxOfficeExcerpt:
            return ["finances", t(`documentCategories:${DocumentCategory.TaxOfficeExcerpt}`)];
        case DocumentCategory.HealthInsureExtract:
            return ["finances", t(`documentCategories:${DocumentCategory.HealthInsureExtract}`)];
        case DocumentCategory.IDCard:
            return ["legitimation", t(`documentCategories:${DocumentCategory.IDCard}`)];
        case DocumentCategory.Passport:
            return ["legitimation", t(`documentCategories:${DocumentCategory.Passport}`)];
        case DocumentCategory.ProofOfOwnership:
            return ["legitimation", t(`documentCategories:${DocumentCategory.ProofOfOwnership}`)];
        case DocumentCategory.ListOfSignatures:
            return ["legitimation", t(`documentCategories:${DocumentCategory.ListOfSignatures}`)];
        case DocumentCategory.CommercialRegisterExtract:
            return ["legitimation", t(`documentCategories:${DocumentCategory.CommercialRegisterExtract}`)];
        case DocumentCategory.CompanyBookExtract:
            return ["legitimation", t(`documentCategories:${DocumentCategory.CompanyBookExtract}`)];
        case DocumentCategory.BusinessRegistration:
            return ["legitimation", t(`documentCategories:${DocumentCategory.BusinessRegistration}`)];
        case DocumentCategory.OtherKYC:
            return ["legitimation", t(`documentCategories:${DocumentCategory.OtherKYC}`)];
        case DocumentCategory.Schufa:
            return ["information", t(`documentCategories:${DocumentCategory.Schufa}`)];
        case DocumentCategory.Creditreform:
            return ["information", t(`documentCategories:${DocumentCategory.Creditreform}`)];
        case DocumentCategory.Bisnode:
            return ["information", t(`documentCategories:${DocumentCategory.Bisnode}`)];
        case DocumentCategory.DebitorRegisterExcerpt:
            return ["information", t(`documentCategories:${DocumentCategory.DebitorRegisterExcerpt}`)];
        case DocumentCategory.Email:
            return ["correspondence", t(`documentCategories:${DocumentCategory.Email}`)];
        case DocumentCategory.Letter:
            return ["correspondence", t(`documentCategories:${DocumentCategory.Letter}`)];
        case DocumentCategory.Fax:
            return ["correspondence", t(`documentCategories:${DocumentCategory.Fax}`)];
        case DocumentCategory.Offer:
            return ["correspondence", t(`documentCategories:${DocumentCategory.Offer}`)];
        case DocumentCategory.Contract:
            return ["correspondence", t(`documentCategories:${DocumentCategory.Contract}`)];
        case DocumentCategory.OtherCorrespondence:
            return ["correspondence", t(`documentCategories:${DocumentCategory.OtherCorrespondence}`)];
        case DocumentCategory.Unclassified:
            return [t(`documentCategories:${DocumentCategory.Unclassified}`)];
        case DocumentCategory.Questionnaire:
            return [t(`documentCategories:${DocumentCategory.Questionnaire}`)];
        }
    };

    const containerId = uuidv4();

    return (
        <div style={{position: "relative"}} id={containerId}>
            <Cascader getPopupContainer={() => document.getElementById(containerId)}
                defaultValue={getDefaultValue()}
                options={options}
                displayRender={displayRender}
                dropdownRender={dropdownRender}
                onChange={(e) => {
                    const docCategory = e[e.length - 1];
                    console.log(docCategory);
                    props.onChange(docCategory as DocumentCategory);
                }}
            />
        </div>
    );
};
