export const roundTo2DecimalPlaces = (num: number): number => {
    return Math.round(num * 100 + Number.EPSILON) / 100;
};

export const roundTo2DecimalPlacesWithNaNReplace = (num: number): number | string => {
    const roundedNum = Math.round(num * 100 + Number.EPSILON) / 100;

    if (!isNaN(roundedNum)) {
        return roundedNum;
    }

    return "-";
};
