import * as React from "react";
import {useTranslation} from "react-i18next";
import {Col, Row} from "antd";
import {DataItemsColumn} from "../data-items-column/DataItemsColumn";
import {GetClientResponse, GetContactPersonResponse} from "../../../../api/types";
import {ApplicationStates, ClientType} from "../../../../types/types";
import moment from "moment";

interface TopInfoSectionProps {
    type: TopInfoSectionType
    isContactPersonUI?: boolean

    clientData?: GetClientResponse
    applicationData?: {
        short_id: string
        status: ApplicationStates
        type: string
        company_name?: string
        company_country?: string
    }
}

export enum TopInfoSectionType {
    TypeClient,
    TypeApplication
}

export const TopInfoSection = (props: TopInfoSectionProps) => {
    const {t} = useTranslation();

    const getApplicationStatusLabel = (): string => {
        if (props.isContactPersonUI) {
            switch (props.applicationData.status) {
            case ApplicationStates.Paid_Out:
                return t(`applicationStates:${ApplicationStates.Paid_Out}`);
            case ApplicationStates.Approved_Awaiting_Payout:
                return t('approved');
            case ApplicationStates.Open:
                return t('incomplete');
            case ApplicationStates.In_Review_Risk:
            case ApplicationStates.Signature_KYC:
            case ApplicationStates.Final_Review_Operations:
                return t('inReview');
            case ApplicationStates.Rejected:
                return t(`applicationStates:${ApplicationStates.Rejected}`);
            default:
                return "-";
            }
        } else {
            return props.applicationData.status ? t(`applicationStates:${props.applicationData.status}`) : "-";
        }
    };

    const getApplicationTypeLabel = (applType): string => {
        if (props.isContactPersonUI) {
            return t(`applicationTypesCP:${applType}`);
        } else {
            return t(`applicationTypes:${applType}`);
        }
    };

    const getShortID = (): string => {
        if (props.clientData) {
            return props.clientData.address_country ? `${props.clientData.address_country.toUpperCase()}-${props.clientData.short_id?.toUpperCase()}` : `ID-${props.clientData.short_id?.toUpperCase()}`;
        }

        if (props.applicationData) {
            return props.applicationData.company_country ? `${props.applicationData.company_country.toUpperCase()}-${props.applicationData.short_id?.toUpperCase()}` : `ID-${props.applicationData.short_id?.toUpperCase()}`;
        }

        return "-";
    };

    const renderInfo = () => {
        if (props.type === TopInfoSectionType.TypeClient) {
            const client = props.clientData;
            const firstContactPerson: GetContactPersonResponse = client.contact_persons?.sort((cp1, cp2) => moment(cp1.created_at).isBefore(cp2.created_at) ? -1 : 0)[0];

            if (props.clientData.client_type === ClientType.Client) {
                return (
                    <Row gutter={36}>
                        <Col>
                            <DataItemsColumn dataItems={[
                                {itemName: t('company'), itemValue: client.company_name},
                                {itemName: t('clientId'), itemValue: getShortID()},
                                {itemName: "Has application", itemValue: client.has_application ? "TRUE" : "FALSE"},
                            ]}/>
                        </Col>
                        <Col>
                            <DataItemsColumn dataItems={[
                                {itemName: t('email2'), itemValue: firstContactPerson?.email},
                                {itemName: t('telNumber'), itemValue: firstContactPerson?.phone},
                                {itemName: t('country'), itemValue: client.address_country ? t(`countryCodes:${client.address_country.toLowerCase()}`) : "-"},
                                {
                                    itemName: t('type'),
                                    itemValue: client.financing_type ? t(`financingTypes:${client.financing_type}`) : "-"
                                },
                            ]}/>
                        </Col>
                    </Row>
                );
            } else if (props.clientData.client_type === ClientType.Lead) {
                return (
                    <Row gutter={36}>
                        <Col>
                            <DataItemsColumn dataItems={[
                                {itemName: t('company'), itemValue: client.company_name},
                                {itemName: t('leadId'), itemValue: getShortID()},
                                {
                                    itemName: t('leadStatus'),
                                    itemValue: client.lead_status ? t(`leadStatuses:${client.lead_status}`) : "-"
                                },
                                {
                                    itemName: t('type'),
                                    itemValue: client.financing_type ? t(`financingTypes:${client.financing_type}`) : "-"
                                },
                            ]}/>
                        </Col>
                        <Col>
                            <DataItemsColumn dataItems={[
                                {
                                    itemName: t('contactName'),
                                    itemValue: firstContactPerson ? `${firstContactPerson.first_name} ${firstContactPerson.last_name}` : "-"
                                },
                                {itemName: t('email2'), itemValue: firstContactPerson?.email},
                                {itemName: t('telNumber'), itemValue: firstContactPerson?.phone},
                                {itemName: t('sveaContact'), itemValue: client.bo_contact_name},
                            ]}/>
                        </Col>
                    </Row>
                );
            }
        }

        if (props.type === TopInfoSectionType.TypeApplication) { // todo
            return (
                <Row gutter={36}>
                    <Col>
                        <DataItemsColumn dataItems={[
                            {itemName: t('company'), itemValue: props.applicationData.company_name},
                            {itemName: t('applicationId'), itemValue: getShortID()},
                            {
                                itemName: t('applicationType'),
                                itemValue: props.applicationData.type ? getApplicationTypeLabel(props.applicationData.type) : "-"
                            },
                            {itemName: t('status'), itemValue: getApplicationStatusLabel()},
                        ]}/>
                    </Col>
                </Row>
            );
        }

        return null;
    };

    return renderInfo();
};
