import {ApiWrapper} from "./ApiWrapper";
import {
    AdminGetEventsResponse,
    ApiResponse,
    GetAdminProfileResponse,
    GetBoUsersListItem,
    PostBOUserRequest
} from "./types";

export const adminGetSelfProfile = async (): Promise<ApiResponse<GetAdminProfileResponse>> => {
    const path = `/admin/profile`;

    return ApiWrapper.get(path);
};

export const adminGetBOUsers = async (): Promise<ApiResponse<GetBoUsersListItem[]>> => {
    const path = `/admin/bo_users`;

    return ApiWrapper.get(path);
};


export const adminChangeBOUserPassword = async (password: string): Promise<ApiResponse<any>> => {
    const path = `/admin/account/change_password`;

    const body = {
        password
    };

    return ApiWrapper.post(path, body);
};

export const adminCreateBOUser = async (req: PostBOUserRequest): Promise<ApiResponse<void>> => {
    const path = `/admin/bo_users`;

    const body = {
        name: req.name,
        email: req.email,
        role: req.role,
    };

    return ApiWrapper.post(path, body);
};

export const adminCheckRegisterTokenValid = async (registerToken: string): Promise<ApiResponse<void>> => {
    const path = `/admin/account/invites/${registerToken}`;

    return ApiWrapper.get(path, true);
};

export const adminCheckResetTokenValid = async (registerToken: string): Promise<ApiResponse<void>> => {
    const path = `/admin/account/resets/${registerToken}`;

    return ApiWrapper.get(path, true);
};

export const adminRegister = async (registerToken: string, newPassword: string): Promise<ApiResponse<void>> => {
    const path = `/admin/account/register`;

    const body = {
        password: newPassword,
        token: registerToken,
    };

    return ApiWrapper.post(path, body, true);
};

export const adminRecoverPassword = async (resetToken: string, newPassword: string): Promise<ApiResponse<void>> => {
    const path = `/admin/account/recover`;

    const body = {
        password: newPassword,
        token: resetToken,
    };

    return ApiWrapper.post(path, body, true);
};

export const adminDisableBoUser = async (boUserId: string): Promise<ApiResponse<void>> => {
    const path = `/admin/bo_users/${boUserId}/disable`;

    return ApiWrapper.post(path, {});
};

export const adminEnableBoUser = async (boUserId: string): Promise<ApiResponse<void>> => {
    const path = `/admin/bo_users/${boUserId}/enable`;

    return ApiWrapper.post(path, {});
};

export const adminSendResetForBoUser = async (boUserId: string): Promise<ApiResponse<void>> => {
    const path = `/admin/bo_users/${boUserId}/reset`;

    return ApiWrapper.post(path, {});
};

export const adminGetBOUserEvents = async (boUserId: string, page: number, pageSize: number): Promise<ApiResponse<AdminGetEventsResponse>> => {
    const path = `/admin/bo_users/${boUserId}/events?page=${page}&limit=${pageSize}`;

    return ApiWrapper.get(path);
};

export const logoutBoUser = async (): Promise<ApiResponse<void>> => {
    const path = `/admin/account/logout`;

    return ApiWrapper.post(path, {});
};
