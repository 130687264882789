import {ApiWrapper} from "../ApiWrapper";
import {GetDocumentsListResponse, ApiResponse, RetrieveDocumentUploadLinkResponse} from "../types";
import {DocumentCategory} from "../../types/types";

export const adminGetApplicationDocumentURL = async (applicationId: string, documentId: string): Promise<ApiResponse<string>> => {
    const path = `/admin/applications/${applicationId}/documents/${documentId}`;

    return ApiWrapper.get(path);
};

export const adminGetApplicationDocuments = async (applicationId: string): Promise<ApiResponse<GetDocumentsListResponse[]>> => {
    const path = `/admin/applications/${applicationId}/documents`;

    return ApiWrapper.get(path);
};

export const adminUpdateApplicationDocument = async (applicationId: string, documentId: string, documentName: string, documentCategory: DocumentCategory, isClientVisible: boolean): Promise<ApiResponse<void>> => {
    const path = `/admin/applications/${applicationId}/documents/${documentId}`;
    const body = {
        document_name: documentName,
        document_category: documentCategory,
        is_client_visible: isClientVisible,
    };

    return ApiWrapper.patch(path, body);
};

export const adminRetrieveApplicationDocumentUploadLink = async (applicationId: string, fileName: string, documentCategory: DocumentCategory): Promise<ApiResponse<RetrieveDocumentUploadLinkResponse>> => {
    const path = `/admin/applications/${applicationId}/documents`;
    const body = {
        file_name: fileName,
        document_category: documentCategory,
    };

    return ApiWrapper.post(path, body);
};

export const adminSetApplicationDocumentUploadedSuccessfully = async (applicationId: string, documentId: string): Promise<ApiResponse<void>> => {
    const path = `/admin/applications/${applicationId}/documents/${documentId}/upload_success`;

    return ApiWrapper.patch(path, {});
};

export const adminDeleteApplicationDocument = async (applicationId: string, documentId: string): Promise<ApiResponse<void>> => {
    const path = `/admin/applications/${applicationId}/documents/${documentId}`;

    return ApiWrapper.del(path, {});
};
