export const openUrlInNewTab = (url: string) => {
    try {
        const fileTab = window.open(url, '_blank');
        fileTab.focus();
    } catch (e) {
        console.error(e);
    }
};

export const clearURLQueryString = () => {
    window.history.replaceState(
        {pageID: getRandomInt(0, Number.MAX_SAFE_INTEGER).toString()},
        'Factoring und Forderungsverkauf in der Schweiz',
        window.location.origin + window.location.pathname + window.location.hash
    );
};

export const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};
