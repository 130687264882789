import {ApiWrapper} from "./ApiWrapper";
import {ApiResponse, GetContractListItem, GetContractResponse, PutContractRequest} from "./types";

export const adminGetContractsForClient = async (clientId: string): Promise<ApiResponse<GetContractListItem[]>> => {
    const path = `/admin/clients/${clientId}/contracts`;

    return ApiWrapper.get(path);
};

export const adminCreateContractForClient = async (clientId: string, contractData: PutContractRequest): Promise<ApiResponse<string>> => {
    const path = `/admin/clients/${clientId}/contracts`;

    return ApiWrapper.post(path, contractData);
};

export const adminGetContract = async (contractId: string): Promise<ApiResponse<GetContractResponse>> => {
    const path = `/admin/contracts/${contractId}`;

    return ApiWrapper.get(path);
};

export const adminUpdateContract = async (contractId: string, contractData: PutContractRequest): Promise<ApiResponse<string>> => {
    const path = `/admin/contracts/${contractId}`;

    return ApiWrapper.put(path, contractData);
};

export const adminDeleteContract = async (contractId: string): Promise<ApiResponse<void>> => {
    const path = `/admin/contracts/${contractId}`;

    return ApiWrapper.del(path, {});
};

export const adminDownloadContractDocuments = async (contractId: string, documentList: string[]): Promise<any> => {
    let path = `/admin/contracts/${contractId}/documents`;

    for (let i = 0; i < documentList?.length; i++) {
        if (i === 0) {
            path += `?document=${documentList[i]}`;
        } else {
            path += `&document=${documentList[i]}`;
        }
    }

    return ApiWrapper.getBlob(path);
};

export const adminCalculateInstallmentRate = async (clientId: string, contractData: PutContractRequest): Promise<ApiResponse<string>> => {
    const path = `/admin/clients/${clientId}/calculate_contract_installment_rate`;

    return ApiWrapper.post(path, contractData);
};
