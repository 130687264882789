import { Button, Checkbox, Divider, Form, Row } from "antd";
import { ApplicationReview, ApplicationScore, ClientOrApplicationScoreChecklist, GetDocumentsListResponse, ScoreChecklistItem } from "api/types";
import {DocumentType} from "../../../../../types/types";
import * as React from "react";
import styles from 'components/admin/pages/application-drilldown/ApplicationDrilldownPage.module.scss';
import { adminGetApplicationDocuments } from "api/document-service/adminOnApplicationDocuments";
import { DocumentsSection, DocumentSectionActor } from "components/common/presenters/documents-section/DocumentsSection";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "components/common/presenters/section-title/SectionTitle";
import { Spacer } from "components/common/presenters/spacer/Spacer";

export interface ApplicationReviewTabProps {
    loading: boolean
    score: ApplicationScore
    error: string
    data: ApplicationReview
    onSave: (data) => void
    applicationId: string
}

export const ApplicationReviewTab = (props: ApplicationReviewTabProps) => {
    const [form] = Form.useForm();
    const [docs, setDocs] = React.useState([] as GetDocumentsListResponse[]);
    const [riskSectionExpanded, setRiskSectionExpanded] = React.useState(false);
    const { t } = useTranslation();
    
    const handleOnFinish = (values) => {
        console.log(values);
        props.onSave(values);
    };

    const fetchApplicationDocuments = async () => {
        try {
            const result = await adminGetApplicationDocuments(props.applicationId);
            setDocs(result.data);
        } catch (e) {
            console.error(e);
        }
    };

    const toggleRiskSection = () => {
        setRiskSectionExpanded(!riskSectionExpanded);
    };

    React.useEffect(() => {
        fetchApplicationDocuments();
    }, [props.applicationId]);


    const formData = {
        ...props.data
    };

    const renderChecklist = () => {
        const checklist = (props.score as any)?.checklist as ClientOrApplicationScoreChecklist;

        const items = checklist?.items.map((item: ScoreChecklistItem) => {
            return (
                <React.Fragment>
                    <Checkbox checked={item.is_checked} disabled={true}>
                        { t(`checklistItems:${item.name}`) }
                    </Checkbox>
                    <Spacer />
                </React.Fragment>
            );
        });

        return items;
    };

    return (
        <div className={styles.tabXL}>

            <SectionTitle text={"Risikoprüfung"}/>

            <div className={styles.container}>

                <Row className={styles.rowCenterX}>
                    <Button type="primary" onClick={toggleRiskSection}>
                        { riskSectionExpanded ? t("buttons:collapse") : t("buttons:expand")}
                    </Button>
                </Row>

                <Spacer hx={2}/>

                {
                    riskSectionExpanded ?
                        renderChecklist()
                        :
                        null
                }

                <Spacer/>
                <Divider className={styles.dividerNormal} orientation="left" style={{ color: '#333', fontWeight: 'normal' }}>
                    <h2>Vertrag</h2>
                </Divider>

                <DocumentsSection actor={DocumentSectionActor.Admin}
                    noDivider={true}
                    entityType={DocumentType.Application}
                    entityId={props.applicationId}
                    documents={docs}
                    onDataChanged={() => fetchApplicationDocuments()}
                />
                <Spacer/>

                <Divider className={styles.dividerNormal} orientation="left" style={{ color: '#333', fontWeight: 'normal' }}>
                    <h2>Operations Review</h2>
                </Divider>
            
                <Form id="applReview"
                    className={styles.form}
                    layout={"vertical"}
                    form={form}
                    initialValues={formData}
                    onFinish={handleOnFinish}
                    scrollToFirstError
                >
                    <Form.Item name="factoring_contract_settled" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Vereinbarung Factoringvertrag</Checkbox></Form.Item>
                    <Form.Item name="global_cession" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Globalzession</Checkbox></Form.Item>
                    <Form.Item name="svea_terms_accepted" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>AGB SVea Ekonomi AB</Checkbox></Form.Item>
                    <Form.Item name="security_guarantee" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Garantieerklärung</Checkbox></Form.Item>
                    <Form.Item name="bank_negative_guarantee" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Negativerklräung der Bank(en)</Checkbox></Form.Item>
                    <Form.Item name="signature_list" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Unterschriftenverzeichnis</Checkbox></Form.Item>
                    <Form.Item name="id_copy_of_representative" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Kopie eines Lichtbildausweises sämtlicher Bevollmächtigten</Checkbox></Form.Item>
                    <Form.Item name="company_registry_statement" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Handelsregisterauszug </Checkbox></Form.Item>
                    <Form.Item name="current_debitors_creditors_list" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Aktuelle Debitoren- und Kreditorenliste mit Fälligkeitsdatum</Checkbox></Form.Item>
                    <Form.Item name="last_annual_statement" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Letzter Jahresabschluss (Bilanz/Erfolgsrechnung)</Checkbox></Form.Item>
                    <Form.Item name="bwa_list" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>BWA / Saldenliste</Checkbox></Form.Item>
                    <Form.Item name="confidential_questionary" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Vertraulicher Fragebogen</Checkbox></Form.Item>
                    <Form.Item name="cash_note" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Auszahlungsanweisung</Checkbox></Form.Item>
                    <Form.Item name="self_declaration" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Selbstauskunft </Checkbox></Form.Item>
                    <Form.Item name="owners_verification_with_info" valuePropName="checked" label={""} hasFeedback>
                        <Checkbox>Nachweis der wirtschaftlichen Eigentümer mit Privatadresse und Firmenanteile inkl. Kopie der ID-Karte</Checkbox>
                    </Form.Item>
                    <Spacer/>
                    <Form.Item>
                        <Row className={styles.rowCenterX}>
                            <Button type="primary" htmlType="submit">{t("buttons:save")}</Button>
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
