export const getContactPersonModalForm = () => {
    return {
        email: "",
        salutation: "",
        title: "",
        gender: "",
        first_name: "",
        last_name: "",
        birth_date: "",
        birth_place: "",
        nationality: "",
        phone: "",
        mobile_number: "",
        passport_number: "",
        address_country: "",
        address_addition: "",
        address_street: "",
        address_house_number: "",
        address_post_code: "",
        address_city: "",
        is_signatory: false,
        is_sole_signatory: false,
        is_beneficial_owner: false,
        pct_owned_shares: "",
    };
};
