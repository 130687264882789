import styles from "components/common/presenters/custom-page-header/CustomPageHeader.module.scss";
import {getCurrentPageStepNumber, getFlowSteps} from 'navigation/applicationSteps';
import React, {useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Steps} from 'antd';

export interface ProgressBarStepsItem {
    title: string
    subtitle: string
    description: string
}

export const CustomPageHeader = () => {
    const [activeStep, setActiveStep] = React.useState(null);

    const isVerticalLabel = useMediaQuery({query: '(max-width: 1320px)'});
    const isVerticalDirection = useMediaQuery({query: '(max-width: 1024px)'});

    // The header should know which step it's on automatically instead of manually passing
    // the active step in each place where CustomPageHeader is used.
    // That way we can reorder steps and won't need to update each step individually.
    useEffect(() => {
        let stepNumber = getCurrentPageStepNumber();
        setActiveStep(stepNumber);
    });

    const renderSteps = () => {
        return getFlowSteps().map((step) => {
            return <Steps.Step key={step.title} title={step.title} subTitle={step.subtitle} description={step.description}/>;
        });
    };

    // This component is not responsive out of the box when used with <Steps/>: https://github.com/ant-design/ant-design/issues/14463
    return (
        <div className={styles.strip}>
            <div className={styles.stepsContainer}>
                <Steps current={activeStep}
                    labelPlacement={(isVerticalLabel && !isVerticalDirection) ? "vertical" : "horizontal"}
                    direction={isVerticalDirection ? "vertical" : "horizontal"}
                >
                    {renderSteps()}
                </Steps>
            </div>
        </div>
    );
};
