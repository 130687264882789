import * as React from "react";
import {useState} from "react";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import {ConfirmModalClose} from "../../../common/presenters/confirm-modal-close/ConfirmModalClose";
import {GenerateOfferForm} from "./GenerateOfferForm";
import {GetClientResponse} from "../../../../api/types";

export interface GenerateOfferModalProps {
    modalOpen: boolean
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>

    client: GetClientResponse
}

export const GenerateOfferModal = (props: GenerateOfferModalProps) => {
    const {t} = useTranslation();
    const [secondaryModalOpen, setSecondaryModalOpen] = useState(false);

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };

    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        props.setModalOpen(false);
    };

    return (
        <>
            <Modal width={800}
                centered
                title={t('modals:generateOffer')}
                visible={props.modalOpen}
                destroyOnClose={true}
                footer={null}
                onCancel={(e: any) => {
                    if (e.code === "Escape") {
                        e.preventDefault();
                        e.stopPropagation();
                        setSecondaryModalOpen(true);
                    } else {
                        props.setModalOpen(false);
                    }
                }}
            >

                <GenerateOfferForm client={props.client}
                    onSuccess={() => props.setModalOpen(false)}
                    onCancel={() => props.setModalOpen(false)}
                />
            </Modal>

            <ConfirmModalClose visible={secondaryModalOpen}
                onCancel={secondaryModalOnCancel}
                onOk={secondaryModalOnOk}
            />
        </>
    );
};
