import path from "path";

export const fileNameExtension = (originalFilename: string): string => {
    if (!originalFilename) {
        return originalFilename;
    }

    const onlyFileName: string = path.basename(originalFilename);

    const parts = onlyFileName.split(".");
    if (parts.length === 1 || (parts[0] === "" && parts.length === 2)) {
        return ""; // for files without extension, or files like .bashrc
    }

    return parts.pop().toLowerCase();
};

export const fileNameWithoutExtension = (originalFilename: string): string => {
    if (!originalFilename) {
        return originalFilename;
    }

    const onlyFileName: string = path.basename(originalFilename);

    const extension: string = fileNameExtension(onlyFileName);

    if (!extension) {
        return onlyFileName;
    }

    return onlyFileName.substring(0, onlyFileName.lastIndexOf(extension) - 1);
};

export const renderString = (str: string): string => {
    if (str == null || str === "") {
        return "-";
    }
    
    return str;
};
