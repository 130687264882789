import * as React from "react";
import {useTranslation} from 'react-i18next';
import {GetClientResponse} from "../../../../../../api/types";
import {Button, message, Space} from "antd";
import {adminCreateApplicationForClient} from "../../../../../../api/applicationService";
import {goTo} from "../../../../../../navigation/navigationHelpers";
import {RouteStrings} from "../../../../../../Routes";
import { Spacer } from "components/common/presenters/spacer/Spacer";

interface NextStepsHasApplicationProps {
    client: GetClientResponse
    onDataChanged: () => void
}

export const NextStepsHasApplication = (props: NextStepsHasApplicationProps) => {
    const {t} = useTranslation();

    const handleCreateApplication = async () => {
        try {
            await adminCreateApplicationForClient(props.client.client_id);
            message.success(t('messages:dataSaved'), 2);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    return (
        <>
            <p>{t('leadNextSteps:labelStepHasApplication')}</p>

            <Spacer />

            <Space direction={"horizontal"}
                size={"large"}
            >
                <Button data-cy={"button_generate_offer"}
                    type={"primary"}
                    onClick={() => {
                        goTo(RouteStrings.AdminHome + "#applications");
                    }}
                >
                    {t('buttons:goToApplications')}
                </Button>

                <Button data-cy={"button_create_application"}
                    type={"primary"}
                    onClick={() => {
                        handleCreateApplication();
                    }}
                >
                    {t('buttons:createAnotherApplication')}
                </Button>
            </Space>
        </>
    );
};
