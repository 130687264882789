import * as React from "react";
import styles from "components/common/presenters/back-button/BackButton.module.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { goTo } from "navigation/navigationHelpers";

export interface BackButtonProps {
    route: string
}

export const BackButton = (props: BackButtonProps) => {
    const { t } = useTranslation();

    const handleOnClick = () => {
        goTo(props.route);
    };
    
    return (
        <div className={styles.backButton} onClick={handleOnClick}>
            <ArrowLeftOutlined/> { t("buttons:back")}
        </div>
    );
};
