import * as React from "react";
// import styles from "../../ClientDrilldownPage.module.scss";
import {useTranslation} from 'react-i18next';
import {GetClientResponse} from "../../../../../../api/types";
import {Button, message} from "antd";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import {adminExportClient} from "../../../../../../api/clientService";

interface NextStepsContractSignedOrWonProps {
    client: GetClientResponse
    onDataChanged: () => void
}

export const NextStepsContractSignedOrWon = (props: NextStepsContractSignedOrWonProps) => {
    const {t} = useTranslation();

    const handleExportClientData = async () => {
        try {
            await adminExportClient(props.client.client_id);
            props.onDataChanged();
            message.success(t('messages:archiveDataSent'), 2);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    return (
        <>
            <p>{t('leadNextSteps:labelStepSignedOrWon')}</p>
            
            <Spacer />

            <Button data-cy={"button_export_client"}
                type={"primary"}
                onClick={() => {
                    handleExportClientData();
                }}
            >
                {t('buttons:exportData')}
            </Button>
        </>
    );
};
