import { seedApplications } from "mock-data/localApplicationsSeed";
import { ApplicationFlowType } from "storage/LocalAppDataManager";
import { ApplicationStates } from "types/types";
import * as uuid from 'uuid';

export interface LocalApplicationEntity {
    applicationNumber: string
    company: string
    email: string
    created: string
    loanAmount: number
    currency: string
    loanDuration: number
    interestRate: number
    status: ApplicationStates
    type: ApplicationFlowType
    legalForm: string
    postCode: string
    principalBusiness: string
    loanPurpose: string
    detailedLoanPurpose: string
    taxIdNumber: string
    // confirmation modal data
    oneTimeFees: string
    monthlyFees: string
    factoringLimit: string
}

export interface LocalFinancingApplicationData {
    applications: {
        [key: string]: LocalApplicationEntity
    }
    // factoringOnboardingApplications: {
    // 	[key: string]: LocalApplicationEntity;
    // };
    // factoringApplications: {
    // 	[key: string]: LocalApplicationEntity;
    // };
    lastCreatedApplicationId: string
}

/**
 * Will be used to store localy application data,
 * so the click demo is functional without a backend.
 */
export class LocalFinancingApplicationDataManager {
    // 1. Insert 25 applications on init. 
    // 2. Reset newly stored applications. On reset, keep the 25 applications.
    // 3. Lookup and return applications by application id. (write a utility method to generate application UUIDs)
    // 4. Update applications by application id.

    private readonly LOCAL_STORAGE_KEY: string = "financingApplicationsLocalData";

    public constructor() {
        this.init();
    }

    public get(): LocalFinancingApplicationData {
        try {
            const localData = localStorage.getItem(this.LOCAL_STORAGE_KEY);
            return JSON.parse(localData);
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public update(data): void {
        try {
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(data));
        } catch (error) {
            console.error(error);
        }
    }

    public reset(): void {
        try {
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.getInitialStorageState()));
        } catch (error) {
            console.error(error);
        }
    }

    public addNewLoanApplication(data: LocalApplicationEntity): void {
        try {
            const localData = this.get();

            localData.applications[data.applicationNumber] = data;

            this.update(localData);
        } catch (error) {
            console.error(error);
        }
    }

    public getNumberOfLoanApplications = (): number => {
        const localData = this.get().applications;

        let size = 0, key;

        for (key in localData) {
            if (localData.hasOwnProperty(key) && uuid.validate(key)) {
                size++;
            }
        }

        return size;
    };

    public getLastCreatedApplicationId = (): string => {
        return this.get().lastCreatedApplicationId;
    };
    public setLastCreatedApplicationId = (id: string): void => {
        try {
            const localData = this.get();

            localData.lastCreatedApplicationId = id;

            this.update(localData);
        } catch (error) {
            console.error(error);
        }
    };

    public getLastCreatedApplication = (): LocalApplicationEntity => {
        try {
            const localData = this.get();

            return localData.applications[localData.lastCreatedApplicationId];
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    public updateLastCreatedApplication = (data: LocalApplicationEntity): void => {
        try {
            const localData = this.get();

            localData.applications[localData.lastCreatedApplicationId] = data;

            this.update(localData);
        } catch (error) {
            console.error(error);
        }
    };

    public getApplicationById = (id: string): LocalApplicationEntity => {
        try {
            const localData = this.get();

            return localData.applications[id];
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    public updateApplicationById = (id: string, data: LocalApplicationEntity): void => {
        try {
            const localData = this.get();

            localData.applications[id] = data;

            this.update(localData);
        } catch (error) {
            console.error(error);
        }
    };

    public getInitialStorageState(): LocalFinancingApplicationData {
        return {
            applications: seedApplications,
            lastCreatedApplicationId: null
        };
    }

    private init(): void {
        try {
            const localDataInitalized: LocalFinancingApplicationData = this.getInitialStorageState();

            const localData = localStorage.getItem(this.LOCAL_STORAGE_KEY);

            if (localData == null) {
                localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(localDataInitalized));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

