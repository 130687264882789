import * as React from "react";
import {useEffect, useState} from "react";
import {AutoComplete, Button, Col, Divider, Form, Input, message, Modal, Row, Select} from "antd";
import {
    ClientType,
    CompanyIndustries,
    CompanyLegalForms,
    CompanySizes,
    Currencies,
    LeadSources
} from "../../../../types/types";
import styles from "./ClientModal.module.scss";
import {useTranslation} from "react-i18next";
import {CountryCodes, LanguageCodes, RegionList} from "types/countries";
import {AnchoredSelect} from "../../../common/presenters/anchored-select/AnchoredSelect";
import {adminCreateClient, adminGetCompanyProducts, adminUpdateClient} from "../../../../api/clientService";
import {GetBoUsersListItem, GetClientResponse, PutClientRequest} from "../../../../api/types";
import {adminGetBOUsers} from "../../../../api/boUserService";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import {getPostCodeValidationPattern} from "validators/inputs";
import { ConfirmModalClose } from "components/common/presenters/confirm-modal-close/ConfirmModalClose";

interface ClientModalProps {
    modalOpen: boolean
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    existingClientData: GetClientResponse
    onDataChanged: () => void
}

export const ClientModal = (props: ClientModalProps) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();

    const [secondaryModalOpen, setSecondaryModalOpen] = React.useState(false);

    const defaultState = {
        lead_currency: Currencies.EUR,
    } as PutClientRequest;

    const [data, setData] = useState(defaultState);
    const [note, setNote] = useState(null as string);
    const [boUsers, setBOUsers] = useState([] as GetBoUsersListItem[]);

    const [companyProducts, setCompanyProducts] = useState([] as string[]);
    const [companyProductOptions, setCompanyProductOptions] = useState([] as {key: string; value: string}[]);

    const isUpdateOperation = props.existingClientData ? true : false;

    const fetchSveaContactNames = async () => {
        try {
            const result = await adminGetBOUsers();
            setBOUsers(result.data?.filter(bo => !bo.is_deleted));
        } catch (e) {
            console.error(e);
            setBOUsers([]);
        }
    };

    const fetchCompanyProductOptions = async () => {
        try {
            const result = (await adminGetCompanyProducts()).data;
            setCompanyProducts(result);

            setCompanyProductOptions(
                result?.map(p => ({key: p, value: p})) || []
            );
        } catch (e) {
            console.error(e);
            setCompanyProducts([]);
            setCompanyProductOptions([]);
        }
    };

    useEffect(() => {
        if (props.modalOpen) {
            fetchSveaContactNames();
            fetchCompanyProductOptions();

            if (isUpdateOperation) {
                setData({
                    client_type: props.existingClientData.client_type,
                    company_name: props.existingClientData.company_name,
                    company_legal_form: props.existingClientData.company_legal_form,
                    company_register_number: props.existingClientData.company_register_number,
                    company_industry: props.existingClientData.company_industry,
                    company_product: props.existingClientData.company_product,
                    company_number_of_employees: props.existingClientData.company_number_of_employees,
                    address_addition: props.existingClientData.address_addition,
                    address_street: props.existingClientData.address_street,
                    address_house_number: props.existingClientData.address_house_number,
                    address_post_code: props.existingClientData.address_post_code,
                    address_city: props.existingClientData.address_city,
                    address_region: props.existingClientData.address_region,
                    address_country: props.existingClientData.address_country,
                    language: props.existingClientData.language,
                    company_website: props.existingClientData.company_website,
                    tax_number: props.existingClientData.tax_number,
                    lead_status: props.existingClientData.lead_status,
                    lead_source: props.existingClientData.lead_source,
                    financing_type: props.existingClientData.financing_type,
                    lead_currency: props.existingClientData.lead_currency,
                    lead_expected_volume: props.existingClientData.lead_expected_volume,
                    lead_expected_duration: props.existingClientData.lead_expected_duration,
                    bo_contact_name: props.existingClientData.bo_contact_name,
                    broker_name: props.existingClientData.broker_name,
                } as PutClientRequest);
            } else {
                setData(defaultState);
            }
        }
    }, [props.modalOpen]);

    const handleSave = async () => {
        try {
            if (isUpdateOperation) {
                await adminUpdateClient(props.existingClientData.client_id, {
                    ...data,
                    client_type: ClientType.Client,
                });
            } else {
                await adminCreateClient({
                    ...data,
                    client_type: ClientType.Client,
                    note: note,
                });
            }
            props.onDataChanged();
            props.setModalOpen(false);
            message.success(t('messages:dataSaved'), 2);
        } catch (e) {
            message.error(t('messages:couldNotSave'), 2);
            console.error(e);
        }
    };
    const legalFormOptions = () => {
        return CompanyLegalForms.map((val, i) => {
            return <Select.Option data-cy={`company_legal_form_option_${i}`} key={i} value={val}>{val}</Select.Option>;
        });
    };

    const companySizeOptions = () => {
        return CompanySizes.map((val, i) => {
            return <Select.Option data-cy={`company_number_of_employees_option_${i}`} key={i}
                value={val}>{val}</Select.Option>;
        });
    };

    const companyIndustryOptions = () => {
        return CompanyIndustries.map((val, i) => {
            return <Select.Option data-cy={`company_industry_option_${i}`} key={i} value={val}>{t(`company_industries:${val}`)}</Select.Option>;
        });
    };

    const leadSourceOptions = () => {
        return LeadSources.map((val, i) => {
            return <Select.Option data-cy={`lead_source_option_${i}`} key={i} value={val}>{val}</Select.Option>;
        });
    };

    const addressCountryOptions = () => {
        return CountryCodes.map((val, i) => {
            return <Select.Option data-cy={`address_country_option_${i}`} key={i} value={val}>{t(`countryCodes:${val}`)}</Select.Option>;
        });
    };

    const regionOptions = () => {
        const country: string = data.address_country;
        const regions: string[] = RegionList[country] || [];
        return regions.map((val, i) => {
            return <Select.Option data-cy={`address_region_option_${i}`} key={i} value={val}>{val}</Select.Option>;
        });
    };

    const languageOptions = () => {
        return LanguageCodes.map((val, i) => { // TODO use different list?
            return <Select.Option data-cy={`language_option_${i}`} key={i} value={val}>{t(`languageCodes:${val}`)}</Select.Option>;
        });
    };

    const boUsersOptions = () => {
        return boUsers.map((val, i) => {
            return (
                <Select.Option data-cy={`bo_contact_name_option_${i}`} key={i}
                    value={val.bo_user_name}>{val.bo_user_name}
                </Select.Option>
            );
        });
    };

    const handleSearchCompanyProduct = (searchText: string) => {
        if (!searchText) {
            setCompanyProductOptions(
                companyProducts?.map(p => ({key: p, value: p})) || []
            );
        } else {
            setCompanyProductOptions(
                companyProducts
                    ?.filter(p => p.toLowerCase().includes(searchText))
                    ?.map(p => ({key: p, value: p}))
            );
        }
    };

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };

    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        props.setModalOpen(false); 
    };

    return (
        <React.Fragment>
            <ConfirmModalClose visible={secondaryModalOpen} onCancel={secondaryModalOnCancel} onOk={secondaryModalOnOk}/>

            <Modal width={1000}
                centered
                title={isUpdateOperation ? t('modals:editClient') : t('modals:addClient')}
                visible={props.modalOpen}
                destroyOnClose={true}
                maskClosable={false}
                okText={t('buttons:ok')}
                cancelText={t('buttons:cancel')}
                onCancel={(e: any) => {
                    if (e.code === "Escape") {
                        e.preventDefault();
                        e.stopPropagation();
                        setSecondaryModalOpen(true);
                    } else {
                        props.setModalOpen(false); 
                    }
                }}
                footer={[
                    <div key="fr" className={styles.footerRow}>
                        <Button className={styles.buttonCancel} key="cancel"
                            onClick={(e: any) => {
                                props.setModalOpen(false); 
                            }}>
                            { t("buttons:cancel") }
                        </Button>
                        <Button className={styles.buttonOk} key="submit" onClick={() => {
                            handleSave();
                        }}>
                            { t("buttons:submit") }
                        </Button>
                    </div>
                ]}>
                <Form
                    layout={"vertical"}
                    form={form}
                >
                    <div className={styles.inputsContainer}>
                        <h4 className={styles.boldText}>{t('companyData')}</h4>
                        <Row gutter={36}>
                            <Col span={12}>
                                <h4>{t('company')}</h4>
                                <Input data-cy="input_company_name"
                                    value={data.company_name}
                                    onChange={(e) => {
                                        setData(prevState => {
                                            return {...prevState, company_name: e.target.value};
                                        });
                                    }}
                                />
                                <Spacer/>

                                <h4>{t('company_legal_form')}</h4>
                                <AnchoredSelect data-cy="select_company_legal_form"
                                    value={data.company_legal_form}
                                    onChange={(e) => {
                                        setData(prevState => {
                                            return {...prevState, company_legal_form: e};
                                        });
                                    }}
                                >
                                    {legalFormOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t('number_of_employees')}</h4>
                                <AnchoredSelect data-cy="select_number_of_employees"
                                    value={data.company_number_of_employees}
                                    onChange={(e) => {
                                        setData(prevState => {
                                            return {...prevState, company_number_of_employees: e};
                                        });
                                    }}
                                >
                                    {companySizeOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t('companyRegisterNumber')}</h4>
                                <Input data-cy="input_company_register_number"
                                    value={data.company_register_number}
                                    onChange={(e) => {
                                        setData(prevState => {
                                            return {...prevState, company_register_number: e.target.value};
                                        });
                                    }}
                                />
                            </Col>
                            <Col span={12}>
                                <h4>{t('industry_type')}</h4>
                                <AnchoredSelect data-cy="select_company_industry"
                                    value={data.company_industry}
                                    onChange={(e) => {
                                        setData(prevState => {
                                            return {...prevState, company_industry: e};
                                        });
                                    }}
                                >
                                    {companyIndustryOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t('company_product')}</h4>
                                <AutoComplete
                                    data-cy="select_company_product"
                                    options={companyProductOptions}
                                    onSearch={searchText => handleSearchCompanyProduct(searchText)}
                                    value={data.company_product}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, company_product: e };
                                        });
                                    }}
                                />
                                <Spacer/>

                                <h4>{t('source_of_leads')}</h4>
                                <AnchoredSelect data-cy="select_lead_source"
                                    value={data.lead_source}
                                    onChange={(e) => {
                                        setData(prevState => {
                                            return {...prevState, lead_source: e};
                                        });
                                    }}
                                >
                                    {leadSourceOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t('taxIdNumberShort')}</h4>
                                <Input data-cy="input_tax_number"
                                    value={data.tax_number}
                                    onChange={(e) => {
                                        setData((prevState => {
                                            return {...prevState, tax_number: e.target.value};
                                        }));
                                    }}
                                />
                                <Spacer/>
                                <Spacer/>
                            </Col>
                        </Row>
                        <Spacer/>

                        <h4 className={styles.boldText}>{t('addressData')}</h4>
                        <Row gutter={36}>
                            <Col span={12}>
                                <h4>{t('country')}</h4>
                                <AnchoredSelect data-cy="select_address_country"
                                    value={data.address_country}
                                    onChange={(e) => {
                                        setData(prevState => {
                                            return {...prevState, address_country: e, address_region: ""};
                                        });
                                    }}
                                >
                                    {addressCountryOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t('addition')}</h4>
                                <Input data-cy="input_address_addition"
                                    value={data.address_addition}
                                    onChange={(e) => {
                                        setData((prevState => {
                                            return {...prevState, address_addition: e.target.value};
                                        }));
                                    }}
                                />
                                <Spacer/>

                                <Row gutter={12}>
                                    <Col span={16}>
                                        <h4>{t('street')}</h4>
                                        <Input data-cy="input_address_street"
                                            className={styles.shortInput}
                                            value={data.address_street}
                                            onChange={(e) => {
                                                setData((prevState => {
                                                    return {...prevState, address_street: e.target.value};
                                                }));
                                            }}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <h4>{t('houseNumber')}</h4>
                                        <Input data-cy="input_address_house_number"
                                            className={styles.shortInput}
                                            value={data.address_house_number}
                                            onChange={(e) => {
                                                setData((prevState => {
                                                    return {...prevState, address_house_number: e.target.value};
                                                }));
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Spacer/>

                                <Row gutter={12}>
                                    <Col span={8}>
                                        <h4>{t('postCode')}</h4>
                                        <Form.Item name="company_post_code"
                                            rules={[{required: false, message: t('formErrors:invalidPostCode'), pattern: getPostCodeValidationPattern()}]}>
                                            <Input data-cy="input_address_post_code"
                                                className={styles.shortInput}
                                                value={data.address_post_code}
                                                onChange={(e) => {
                                                    setData((prevState => {
                                                        return {...prevState, address_post_code: e.target.value};
                                                    }));
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <h4>{t('city2')}</h4>
                                        <Input data-cy="input_address_city"
                                            className={styles.shortInput}
                                            value={data.address_city}
                                            onChange={(e) => {
                                                setData((prevState => {
                                                    return {...prevState, address_city: e.target.value};
                                                }));
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Spacer/>
                            </Col>
                            <Col span={12}>
                                <h4>{t('region')}</h4>
                                <AnchoredSelect data-cy="select_address_region"
                                    value={data.address_region}
                                    onChange={(e) => {
                                        setData(prevState => {
                                            return {...prevState, address_region: e};
                                        });
                                    }}
                                >
                                    {regionOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t('language')}</h4>
                                <AnchoredSelect data-cy="select_language"
                                    value={data.language}
                                    onChange={(e) => {
                                        setData(prevState => {
                                            return {...prevState, language: e};
                                        });
                                    }}
                                >
                                    {languageOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t('website')}</h4>
                                <Input data-cy="input_company_website"
                                    value={data.company_website}
                                    onChange={(e) => {
                                        setData((prevState => {
                                            return {...prevState, company_website: e.target.value};
                                        }));
                                    }}
                                />
                            </Col>
                        </Row>
                        <Spacer/>

                        <Divider/>

                        <Row gutter={36}>
                            <Col span={12}>
                                <h4>{t('brokerName')}</h4>
                                <Input data-cy="input_broker_name"
                                    value={data.broker_name}
                                    onChange={(e) => {
                                        setData((prevState => {
                                            return {...prevState, broker_name: e.target.value};
                                        }));
                                    }}
                                />
                            </Col>
                            <Col span={12}>
                                <h4>{t('sveaContact')}</h4>
                                <AnchoredSelect data-cy="select_bo_contact_name"
                                    value={data.bo_contact_name}
                                    onChange={(e) => {
                                        setData(prevState => {
                                            return {...prevState, bo_contact_name: e};
                                        });
                                    }}
                                >
                                    {boUsersOptions()}
                                </AnchoredSelect>
                            </Col>
                        </Row>
                        <Spacer/>

                        {
                            !isUpdateOperation ?
                                <>
                                    <Divider/>

                                    <Row gutter={36}>
                                        <Col span={12}>
                                            <h4>{t('notes')}</h4>
                                            <Input.TextArea data-cy="input_lead_notes"
                                                value={note}
                                                onChange={(e) => {
                                                    setNote(e.target.value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                : null
                        }

                    </div>
                </Form>
            </Modal>
        </React.Fragment>
    );
};
