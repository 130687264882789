import { contactPersonConfirmEmail } from "api/clientContactPersonService";
import styles from 'components/common/pages/confirm-email-page/ConfirmEmailPage.module.scss';
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';

export const ConfirmEmailPage = () => {
    const { t } = useTranslation();

    let {token} = useParams();

    React.useEffect(() => {
        try {
            if (token) {
                contactPersonConfirmEmail(token);
            } else {
                console.error("No token provided.");
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader hideNav={true}/>
            <div className={styles.info}>
                <h2 className={styles.title}>{ t("confirmEmail:title") }</h2>
                <Spacer/>
                <p className={styles.text}>{ t("confirmEmail:body") }</p>
            </div>    
        </div>
    );
};
