export const de = {
    "de": {
        "common": {
            "sveaContactPhoneNumber": "+41 43 321 25 02",
            "sveaContactEmail": "beratung@svea.com",
            "messages": {
                "success": "Erfolgreich",
                "dataSaved": "Daten gespeichert",
                "dataDeleted": "Daten gelöscht",
                "couldNotSave": "Konnte nicht speichern",
                "errorEncountered": "Fehler aufgetreten",
                "registrationSuccessful": "Registrierung erfolgreich",
                "passwordResetSuccessful": "Passwort zurücksetzen erfolgreich",
                "emailSent": "E-Mail gesendet",
                "userWithEmailAlreadyExists": "Benutzer mit E-Mail existiert bereits",
                "documentDeleted": "Dokument gelöscht",
                "archiveDataSent": "Archivdaten gesendet",
                "scheduledForArchiving": "zur Archivierung geplant",
            },
            "errors": {
                "generalError": "Something went wrong",
                "maxNumContactPersons": "Sie können mehr als 3 Kontaktpersonen nicht hinzufügen",
                "postContactPerson": "The operation cannot be executed",
                "bankInfo": "Bank Info kann nicht gespeichert werden",
                "bankInfoInvalidBankName": "Please enter a valid bank name.",
                "bankInfoInvalidIBAN": "Please enter a valid IBAN.",
                "submitApplication": "Der Antrag kann nicht abgeschickt werden",
                "postApplicationScore": "The application score cannot be created",
                "putApplicationScore": "The application score cannot be updated",
                "postClientScore": "The client score cannot be created",
                "putClientScore": "The client score cannot be updated",
                "postApplicationReview": "The application review cannot be created",
                "putApplicationReview": "The application review cannot be updated",
                "invalidToken": "Token ist ungültig",
                "invalidTokenDetails": "Das Token ist entweder ungültig oder abgelaufen.",
                "cantProceed": "Konto konnte nicht erstellt werden. Bitte überprüfen Sie die Daten oder loggen Sie sich ein.",
                "incorrectCredentials": "Falsche Anmeldeinformationen",
                "emailNotConfirmed": "E-Mail Adresse nicht bestätigt",
                "loginError": "Fehler beim Anmelden",
                "passwordsDoNotMatch": "The password confirmation does not match.",
                "emailInvalid": "Die Eingabe ist keine gültige E-Mail",
                "passwordsDontMatch": "Die Passwörter stimmen nicht überein!",
                "passwordInvalid": "Ihr Passwort muss mindestens 8 Zeichen lang sein, und mindestens 1 Buchstaben, 1 Zahl und 1 Sonderzeichen beinhalten",
                "invalidData": "Bitte gültige Daten eingeben.",
                "invalidIBAN": "Bitte gültige IBAN eingeben.",
            },
            "formErrors": {
                "required": "Erforderlich",
                "fullName": "Please enter full name!",
                "invalidPostCode": "Ungültige PLZ",
                "pleaseSelectOption": "Bitte wählen Sie eine Option",
                "pleaseSetReason": "Bitte schreiben Sie einen Grund",
                "pickFutureDate": "Bitte wählen Sie einen zukünftigen Termin",
                "mustBeAtLeast": "Muss mindestens {{amount}} sein",
            },
            "buttons": {
                "newLead": "Neuer Lead",
                "newClient": "Neuer Klient",
                "newNote": "Neue Notiz",
                "continue": "Weiter",
                "save": "Speichern",
                "download": "Herunterladen",
                "downloadPdf": "PDF herunterladen",
                "downloadDocuments": "Speichern und Dokumente herunterladen",
                "cancel": "Abbrechen",
                "ok": "OK",
                "login": "Anmelden",
                "edit": "Ändern",
                "view": "Bearbeiten",
                "customize": "Anpassen",
                "editData": "Daten bearbeiten",
                "editLeadStatus": "Lead Status bearbeiten",
                "resendEmail": "E-Mail zurücksenden",
                "goToApplications": "zu Anträge",
                "createApplication": "Antrag erstellen",
                "createAnotherApplication": "Ein Anderes Anträg erstellen",
                "addDebitor": "+ Debitor hinzufügen",
                "yes": "Ja",
                "no": "Nein",
                "submit": "Weiter",
                "addTransaction": "Add Transaction",
                "addPerson": "Person hinzufügen",
                "myLeads": "My Leads",
                "clear": "Clear",
                "toLogin": "Zum Login",
                "register": "Register",
                "reset": "Zurücksetzen",
                "resetPassword": "Passwort zurücksetzen",
                "resetFilter": "Filter Zurücksetzen",
                "downloadQuestionnaire": "Fragebogen herunterladen",
                "createOffer": "Offerte erstellen",
                "expand": "Expand",
                "collapse": "Collapse",
                "changePassword": "Passwort ändern",
                "addEmployee": "Mitarbeiter hinzufügen",
                "toHome": "Weiter zur Homepage",
                "toSveaClientPortal": "Zum SVEA Kundenportal",
                "back": "Zurück",
                "adjustData": "Daten Anpassen",
                "complete": "Erledigt",
                "delete": "Löschen",
                "generateOffer": "Angebot Erstellen",
                "createReminder": "Erinnerung erstellen",
                "createTodo": "To-Do erstellen",
                "updateReminder": "Erinnerung bearbeiten",
                "updateTodo": "To-Do bearbeiten",
                "exportApplicationAgain": "Antrag erneut exportieren",
                "exportData": "Daten exportieren",
                "calculate": "Berechnen",
            },
            "headings": {
                "processStepTwo": "Wofür möchten Sie einen Antrag stellen?",
                "processCreateAccount": "Bitte erstellen Sie ein Benutzerkonto um Ihren Antrag einzureichen",
                "processResult": "Ihr Konto wurde erfolgreich erstellt.",
                "processResultRejected": "Ihr Antrag konnte nicht erstellt werden.",
                "processResultLoggedIn": "Ihr Antrag wurde erfolgreich erstellt.",
                "processSuccess1": "Ihr Benutzerkonto wurde erfolgreich erstellt. Wir haben Ihnen eine E-Mail geschickt mit der Sie Ihre E-Mail Adresse bestätigen und Ihr Nutzerkonto aktivieren können. ",
                "processSuccess1LoggedIn": "Auf der nächsten Seiten können Sie Ihren Antrag weiter bearbeiten.",
                "processSuccess2": "Nachdem Sie Ihr Nutzerkonto aktiviert haben, können Sie sich einloggen und Ihren Antrag weiter bearbeiten.",
                "processRejection1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                "processRejection2": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
                "debitorCheck": "Debitor Prüfung Beantragen"
            },
            "placeholders": {
                "filterByCountry": "Filter by country...",
                "filterByContactName": "Filter by contact name...",
                "filterByStatus": "Filter by status...",
                "phone": {
                    "at": "+43 1 12345678",
                    "ch": "+41 43 12 34567",
                    "de": "+49 30 12345678",
                    "": "+49 30 12345678",
                    "undefined": "+49 30 12345678",
                },
                "mobileNumber": {
                    "at": "+43 676 1234567",
                    "ch": "+41 75 123 45 67",
                    "de": "+49 170 1234567",
                    "": "+49 170 1234567",
                    "undefined": "+49 170 1234567",
                },
                "city": {
                    "at": "Wien",
                    "ch": "Zürich",
                    "de": "Berlin",
                    "": "Berlin",
                    "undefined": "Berlin",
                },
                "freeTextSearch": "Suche nach Adresse oder Name...",
            },
            "applProcess": {
                "titleStepOne": "Für welches Unternehmen möchten Sie einen Antrag stellen?",
                "titleStepLoan": "Bitte einen Betrag und Laufzeit wählen",
                "titleStepCompany": "Bitte geben Sie die Angaben zu Ihrem Unternehmen ein",
                "titleStepContact": "Geben Sie bitte Ihre persönlichen Kontaktdaten ein",
                "step": "Schritt",
                "stepCompanyName": "Firmenname",
                "stepLoanType": "Antragsart",
                "stepLoanDetails": "Kredit Details",
                "stepCompanyInfo": "Firmeninformationen",
                "stepContactInfo": "Kontaktinformationen",
                "stepRegistration": "Registrierung",
                "stepResult": "Antrag abschicken",
            },
            "signatories": {
                "title": "Personen",
                "person": "Person",
                "removeSignatory": "Person entfernen",
                "addSignatory": "Weitere Person hinzufügen",
                "confirmAllSignatories": "Ich bestätige, dass jede Person, die den Vertrag unterschreibt und jede Person die über 25% des Unternehmens besitzt, hinzugefügt wurde.",
                "finalConfirmation": "Bitte stellen Sie sicher, dass genügend Personen hinzugefügt wurden, um den Kreditvertrag zu unterzeichnen.",
                "legalSignatory": "Ist diese Person zur Unterschrift berechtigt?",
                "soleSignatory": "Darf diese Person alleine für das Unternehmen unterschreiben?",
                "willSignLoanContract": "Wird diese Person den Kreditantrag unterschreiben?",
                "doesOwnMoreThan25": "Besitzt diese Person mindestens 25% des Unternehmens?",
            },
            "confirmEmail": {
                "title": "E-Mail-Adresse erfolgreich bestätigt.",
                "body": "Bitte kehren Sie auf die vorherige Seite zurück, um fortzufahren."
            },
            "docLists": {
                "heading": "Bitte laden Sie folgende Dokumente hoch:",
                "de": "1. Letzter Jahresabschluss \n 2. Aktuellen Handelsregisterauszug \n 3. Aktuelle Debitoren und Kreditorenliste mit Fälligkeitsdatum \n 4. Aktuelle BWA und Saldenliste \n 5. Kopie eines Lichtbildausweises sämtlicher Bevollmächtigten \n 6. Nachweis der wirtschaftlichen Eigentümer mit Privatadresse und Firmenanteile \n 7. Unterschriftenverzeichnis \n 8. Negativerklärung der Bank(en) \n",
                "at": "1. Letzter Jahresabschluss \n 2. Aktuelle Debitoren und Kreditorenliste im Excelformat \n 3. Aktuelle Saldenliste \n 4. Firmenbuchauszug und/oder Gewerbeanmeldung \n 5. Auszug Finanzamt \n 6. Auszug Gebietskrankenkasse \n 7. Kopie eines Lichtbildausweises / Reisepass sämtlicher Bevollmächtigten \n 8. Nachweis der wirtschaftlichen Eigentümer mit Privatadresse und Firmenanteile \n 9. Unterschriftenverzeichnis\n",
                "ch": "1. Letzter Jahresabschluss \n 2. Aktuellen Handelsregisterauszug \n 3. Aktuelle Debitoren und Kreditorenliste mit Fälligkeitsdatum \n 4. Betreibungsregisterauszug \n 5. Kopie eines Lichtbildausweises sämtlicher Bevollmächtigten \n 6. Nachweis der wirtschaftlichen Eigentümer mit Privatadresse und Firmenanteile \n"
            },
            "confirm:": "Factoring Betrag bestätigen",
            "confirmDocumentDelete": "Möchten Sie dieses Dokument löschen?",
            "modals": {
                "confirmFactoring:": "Factoring Betrag bestätigen",
                "addDebitor": "Neuer Debitor",
                "addLead": "Lead hinzufügen",
                "editLead": "Lead bearbeiten",
                "addClient": "Klient hinzufügen",
                "editClient": "Klient bearbeiten",
                "editLeadStatus": "Lead Status bearbeiten",
                "addNote": "Notiz hinzufügen",
                "addEmployee": "Mitarbeiter hinzufügen",
                "createApplForLeadConfirmationTitle:": "Antrag bestätigen",
                "confirmCloseTitle": "Prozess abbrechen?",
                "confirmCloseText": "Daten die Sie bereits eingegeben haben, gehen verloren",
                "createTodo": "Neues To-Do anlegen",
                "createReminder": "Neue Erinnerung anlegen",
                "updateTodo": "To-Do bearbeiten",
                "updateReminder": "Erinnerung bearbeiten",
                "todoInfo": "Geben Sie die folgenden Informationen an. Optional können Sie auch eine Erinnerung per E-Mail erhalten.",
                "reminderInfo": "Geben Sie die folgenden Informationen an und Sie erhalten eine E-Mail zur Erinnerung.",
                "generateOffer": "Angebot Erstellen",
                "downloadContractDocuments": "Vertragsdokumente herunterladen",
                "clientAlreadyHasApplication": "Dieser Klient hat bereits einen Antrag",
            },
            "invoiceListPage": {
                "sumOpen": "Offene Rechnungen:",
                "sumFinanced": "Finanzierte Rechnungen:",
                "sumInReview": "In Bearbeitung:",
                "sumNotFinanced": "Nicht Finanziert:",
                "sumDepot": "Depot:"
            },
            "userInvoiceInfoPage": {
                "debitor": "Debitor:",
                "applicationId": "Antrags ID:",
                "factoring": "Factoring:",
                "status": "Status:",
                "currency": "Währung:"
            },
            "userDebitorInfoPage": {
                "debitor": "Debitor:",
                "debitorId": "Debitor ID:",
                "invoices": "Rechnungen:",
                "saldo": "Saldo:"
            },
            "userApplPage": {
                "noContactPersons": "Sie haben keine Kontaktpersonen hinzugefügt.",
                "submitForReviewTitle": "Antrag abschicken",
                "submitForReviewText": ""
            },
            "leadNextSteps": {
                "labelStepContacted": "Der Kunde muss den Fragebogen ausfüllen. Sie können die neueste Version unten herunterladen.",
                "labelStepQuestionnaireSent": "Bitte laden Sie den unten stehenden Kundenfragebogen hoch.",
                "labelStepQuestionnaireReturned": "Bitte füllen Sie die folgenden Informationen aus, um ein Angebot zu erstellen:",
                "labelStepProposalSent": "Laden Sie unten die Vertragsvorlage für den Kunden herunter.",
                "labelStepContractSent": "Bitte laden Sie den unten unterzeichneten Kundenvertrag hoch.",
                "labelStepSignedOrWon": "Der Kunde hat den Vertrag unterschrieben. Sie können sie jetzt in eine Anwendung konvertieren und verwalten die weiteren Schritte auf der Registerkarte Anwendungsverwaltung.",
                "labelStepHasApplication": "Dieser Lead hat eine Anwendung. Sie können unten dorthin navigieren oder eine neue Anwendung starten.",
            },
            "admin": {
                "repaymentTab": {
                    "titleOne": "Rechnungszahlung eingeben",
                    "titleTwo": "Erfolgte Zahlungen",
                },
                "tabs": {
                    "companyInfo": "Firmendetails",
                    "contactPersons": "Kontaktpersonen",
                    "scoring": "Scoring",
                    "docs": "Dokumente",
                    "invoices": "Rechnungen",
                    "applications": "Anträge",
                    "debitors": "Debitoren",
                    "notes": "Notizen",
                    "payments": "Zahlungen",
                    "logs": "Logs",
                    "contracts": "Vertragsmenü",
                },
                "scoringTab": {
                    "hasIncomeStatementPY": "Bilanz & Erfolgsrechnung vom Vorjahr",
                    "hasIncomeStatementCurrentYear": "(Prov.) Bialanz & Erfolgsrechnung vom aktuellen Jahr",
                    "hasDebitorList": "Aktuelle offene Debitorenliste überprüft",
                    "hasCreditorList": "Aktuelle offene Kreditorenliste überprüft",
                    "hasContractDocuments": "Vertragsunterlagen überprüft",
                    "hasCompletedRiskCheck": "Risikoprüfung ist abgeschlossen und den Kreditgenehmigen"
                },
                "toEditGoToScoring": "Diese Liste können Sie im Scoring Bereich bearbeiten."
            },
            "labels": {
                "invoiceNumber": "Rechnungsnummer",
                "loanAccountNumber": "Kreditkontonummer",
                "dateOfReceipt": "Eingangsdatum",
                "bankAccount": "Bank Konto",
                "amount": "Betrag",
                "paymentType": "Art der Zahlung",
                "transactionCode": "Transaktionscode",
            },
            "notFoundPage": {
                "text": "Ooops... diese Seite existiert nicht!"
            },
            "checklistItems": {
                "LF_statement_and_income_check_previous_year": "Bilanz & Erfolgsrechnung vom Vorjahr",
                "LF_statement_and_income_check_current_year": "(Prov.) Bialanz & Erfolgsrechnung vom aktuellen Jahr",
                "LF_debitor_list_checked": "Aktuelle offene Debitorenliste überprüft",
                "LF_creditor_list_checked": "Aktuelle offene Kreditorenliste überprüft",
                "LF_contract_documents_checked": "Vertragsunterlagen überprüft",
                "CLI_statement_and_income_check_previous_year": "Bilanz & Erfolgsrechnung vom Vorjahr",
                "CLI_statement_and_income_check_current_year": "(Prov.) Bialanz & Erfolgsrechnung vom aktuellen Jahr",
                "CLI_debitor_list_checked": "Aktuelle offene Debitorenliste überprüft",
                "CLI_creditor_list_checked": "Aktuelle offene Kreditorenliste überprüft",
                "CLI_contract_documents_checked": "Vertragsunterlagen überprüft",
            },
            "dragAndDrop": "Dokumente zum Hochladen hierher ziehen oder",
            "clickHere": "hier klicken",
            "searchResults": "Suchergebnisse",
            "search": "Suchen",
            "companySearchNotFoundInfo": "Nicht gefunden? Kein Problem! Hier klicken und dann die Unternehmensdaten manuell eingeben.",
            "prevYear": "Vorjahr",
            "currentYear": "Jahr",
            "hasAccount": "Sie haben schon ein Konto? Klicken Sie hier um sich anzumelden.",
            "hasNoAccount": "Sie haben noch kein Konto? Klicken Sie hier um ein Konto zu erstellen.",
            "hasNoAccountPart1": "Sie haben noch kein Konto?",
            "hasNoAccountPart2": "Klicken Sie hier um ein Konto zu erstellen.",
            "clickHereToCreateApplication": "Klicken Sie hier um einen Antrag einzureichen.",
            "companyInfo": "Firmendetails",
            "contactPersons": "Kontaktpersonen",
            "gender": "Geschlecht",
            "placeOfBirth": "Geburtsort",
            "nationality": "Nationalität",
            "isSignatory": "Unterschriftenberechtigt",
            "isSoleSignatory": "Alleine Unterschriftenberechtigt",
            "isBeneficialOwner": "Besitzt mehr als 25%",
            "pctShares": "Anteile an dem Unternehmen",
            "contactPersonsTitle": "Kontaktpersonen",
            "additionalInfo": "Zusätzliche Informationen",
            "leadStatus": "Lead Status",
            "currentleadStatus": "Aktuell Lead Status",
            "leadStatuses": {
                "backlog": "Lead Backlog (0%)",
                "in_bearbeitung": "In Bearbeitung (2%)",
                "lead_contacted": "Lead kontaktiert (5%)",
                "project_identified_questionnaire_sent": "Lead identifiziert, Fragebogen gesendet (10%)",
                "questionnaire_returned": "Fragebogen zurückerhalten (25%)",
                "proposal_sent": "Angebot, Offerte gesendet (35%)",
                "contract_sent": "Vertrag gesendet, in Verhandlung (50%)",
                "contract_signed": "Vertrag unterschrieben (75%)",
                "won": "Gewonnen (100%)",
                "lost": "Verloren",
                "disqualified": "Lead disqualifiziert",
                "archived": "Archiviert",
            },
            "sectionTitles": {
                "companyInfo": "Firmeninformationen",
                "status": "Status",
                "leadInfo": "Lead Informationen",
                "bankInfo": "Bank Informationen",
                "leadStatusNextSteps": "Lead Status - Next Steps",
                "manageEmployees": "Mitarbeiter Verwalten",
                "finishRegistration": "Registrierung beenden",
                "createPassword": "SVEA Passwort erstellen",
                "resetPassword": "Passwort zurücksetzen",
                "nextSteps": "Die nächsten Schritte",
                "applicationInReview": "Ihr Antrag ist in Prüfung",
                "draftContractDocuments": "Erstellte Vertragsunterlagen",
                "contracts": "Vertragsmenü",
            },
            "createPasswordInfo": "Bitte geben Sie ein neues Passwort für ihr SVEA Konto ein.",
            "applInfo": "Antragsdaten",
            "applicationSource": "Antragsherkunft",
            "bankInfo": "Bank Informationen",
            "personTitle": "Titel",
            "data": "Daten",
            "message": "Nachricht",
            "paymentMenu": "Zahlungsmenü",
            "createPayment": "Zahlung Erfassen",
            "yes": "Ja",
            "no": "Nein",
            "view": "Bearbeiten",
            "username": "Benutzername",
            "actorName": "Nutzer",
            "newPassword": "Neues Passwort",
            "password": "Passwort",
            "passwordRepeat": "Passwort wiederholen",
            "forgotPassword": "Passwort vergessen?",
            "currency": "Währung",
            "months": "Monate",
            "loan": "Darlehen",
            "factoring": "Factoring",
            "expectedVolume": "Volumen",
            "adminHomeTitle": "Administrationsmenü",
            "userHomeTitle": "Kredite und Factoring",
            "userDebitorsTitle": "Debitoren",
            "userMyContractTitle": "Mein Vertrag",
            "applications": "Anträge",
            "clients": "Klienten",
            "clientId": "Klient ID",
            "leadId": "Lead ID",
            "uniqueId": "Unique ID",
            "settings": "Einstellungen",
            "dashboard": "Dashboard",
            "contracts": "Mein Vertrag",
            "debitors": "Debitoren",
            "debitorCheck": "Debitor Prüfung",
            "loans": "Anträge",
            "invoices": "Rechnungen",
            "applicationNumber": "Antrags-Nr.",
            "companyData": "Firmendaten",
            "company": "Firmenname",
            "email": "E-Mail-Addresse",
            "email2": "E-Mail",
            "website": "Website",
            "created": "Erstellungsdatum",
            "createdAt": "Erstellt am",
            "updated": "Aktualisierungsdatum",
            "loanAmount": "Kreditbetrag",
            "amount": "Betrag",
            "loanDuration": "Laufzeit",
            "interestRate": "Zinsrate",
            "adminFeePerRate": "Kontoführungsgebühren",
            "firstRateDate": "Erste Rate am",
            "enableFirstRateAmount": "Erste Rate weicht ab",
            "enableFinalRateAmount": "Letzte Rate weicht ab",
            "paidOutDate": "Ausbezahlt am",
            "status": "Status",
            "notes": "Notizen",
            "reason": "Grund",
            "sveaContact": "SVEA Contact",
            "logs": "Logs",
            "save": "Speichern",
            "assignee": "Bevollmächtigte(r)",
            "assignee2": "Verantwortlicher",
            "assignee3": "Verantwortliche(r)",
            "unassigned": "Nicht zugeordnet",
            "loanInformation": "Antragsinformationen",
            "loanPurpose": "Verwendungszweck",
            "largestDebitors": "Welches sind Ihre 3 größten Debitoren?",
            "largestDebitors2": "Grösste Debitoren",
            "legalForm": "Unternehmensform",
            "postCode": "PLZ",
            "foundationDate": "Gründungsdatum",
            "country": "Land",
            "numberOfEmployees": "Anzahl Mitarbeiter",
            "name": "Name",
            "bankName": "Bank Name",
            "iban": "IBAN",
            "brokerName": "Broker Name",
            "contactName": "Kontaktname",
            "applicationState": "Antragsstatus",
            "submitToSignature": "Einreichen zur Unterschrift/KYC",
            "setFinalReview": "Letzte Prüfung",
            "setPaidOut": "Auszahlung",
            "rejectUser": "Nutzer Ablehnen",
            "hideApplication": "Antrag Verstecken",
            "reopenApplication": "Antrag wiederöffnen",
            "owner": "Verantwortliche(r)",
            "signatoriesLabel": "Personen",
            "docs": "Unterlagen",
            "id": "ID",
            "salutation": "Anrede",
            "firstName": "Vorname",
            "lastName": "Nachname",
            "surname": "Nachname",
            "addition": "Zusatz",
            "street": "Strasse",
            "streetName": "Strasse",
            "streetNumber": "Hausnummer",
            "houseNumber": "Hausnummer",
            "dateOfBirth": "Geburtsdatum",
            "date": "Datum",
            "address": "Adresse",
            "addressData": "Adressdaten",
            "addressAddition": "Adresszusatz",
            "contactPerson": "Kontaktperson",
            "number": "Nr.",
            "city": "Ort",
            "city2": "Stadt",
            "region": "Region",
            "language": "Sprache",
            "telNumber": "Telefonnummer",
            "phone": "Telefonnummer",
            "mobileNumber": "Mobilnummer",
            "faxNumber": "Faxnummer",
            "vatNumber": "UID-Nr.",
            "documents": "Dokumente",
            "uploadDocuments": "Dokumente Hochladen",
            "uploadedDocuments": "Hochgeladene Dokumente",
            "paymentNotifications": "Mahnung",
            "sendPaymentNotifications": "Versendete Mahnungen",
            "scoring": "Scoring",
            "review": "Review",
            "analysis": "Analyse",
            "author": "Autor",
            "applicationId": "Antrags ID",
            "applicationType": "Antrag Typ",
            "purpose": "Verwendungszweck",
            "detailedPurpose": "Verwendungszweck für den Kredit",
            "detailedPurposeNoHint": "Genaue Verwendungszweck",
            "detailedPurposePlaceholder": "Wir brauchen ein Kredit damit wir....",
            "largestDebitorsPlaceholder": "Muster GmbH, Beispiel Firma AG, Muster Unternehmen KG",
            "companyDesc": "Unternehmenszweck",
            "hrNumber": "HR Nummer",
            "taxIdNumber": "Steuernummer (TIN bzw. SteuerIdNr.) (Optional)",
            "taxIdNumberShort": "Steuernummer",
            "idOrPassportNumber": "Ausweiss oder Pass Nummer",
            "companyAddress": "Firmenaddresse",
            "example": "Beispiel:",
            "adminFeePerInvoice": "Admin Gebühr pro Rechnung",
            "maxAdvancePayment": "Maximale Bevorschussung",
            "withGlobalAssignment": "mit Globalzession",
            "selectiveFactoring": "selektives Factoring",
            "riskAssessmentCompleted": "Risikoprüfung abgeschlossen",
            "role": "Rolle",
            "bo_user_roles": {
                "admin": "Admin",
                "sales_rep": "Sales Representative",
                "no_permission": "No Permission",
            },
            "applicationStates": {
                "open": "Offen",
                "in_review_risk": "In Bearbeitung – Risk",
                "signature_kyc": "Unterschrift/KYC",
                "final_review_operations": "Letzte Prüfung – Operations",
                "approved_awaiting_payout": "Antrag genehmigt",
                "paid_out": "Ausbezahlt",
                "rejected": "Abgelehnt",
                "archived": "Archiviert",
            },
            "toApplicationState": {
                "reopen": "Antrag wieder öffnen",
                "to_in_review_risk": "Zu in Bearbeitung - Risk",
                "back_to_in_review_risk": "Zurück zu in Bearbeitung - Risk",
                "to_signature_kyc": "Unterschrift / KYC",
                "back_to_signature_kyc": "Zurück zu Unterschrift / KYC",
                "to_final_review_operations": "Zur finalen Prüfung",
                "back_to_final_review_operations": "Zurück zur finalen Prüfung",
                "to_approved_awaiting_payout": "Antrag genehmigen",
                "back_to_approved_awaiting_payout": "Zurück zur Genehmigung",
                "to_paid_out": "Als Abgeschlossen markieren",
                "to_rejected": "Antrag Ablehnen",
            },
            "changeApplicationState": "Antragsstatus ändern",
            "inReview": "In Bearbeitung",
            "approved": "Genehmigt",
            "incomplete": "Unvollständig",
            "applicationTypes": {
                "loan": "Kredit",
                "factoring": "Factoring Onboarding",
                "factoring_onboarding": "Factoring Onboarding",
            },
            "applicationTypesCP": {
                "loan": "Darlehen",
                "factoring_onboarding": "Factoring",
            },
            "reopen": "Wieder Öffnen",
            "industry_type": "Branche",
            "leadType": "Lead Type",
            "type": "Typ",
            "product": "Produkt",
            "financingTypes": {
                "loan": "Darlehen",
                "factoring": "Factoring",
                "factoring_onboarding": "Factoring Onboarding",
            },
            "documentCategoryGroups": {
                "finances": "Finanzen",
                "legitimation": "Legitimation",
                "information": "Auskünfte",
                "correspondence": "Korrespondenz",
            },
            "documentCategories": {
                "other": "Sonstiges",
                "annual_accounts": "Jahresabschluss",
                "bwa": "BWA",
                "susa": "SuSa",
                "property_documents": "Vermögensunterlagen",
                "debitor_creditor_list": "Debitoren und Kreditorenliste",
                "negative_statement": "Negativerklärung der Bank(en)",
                "tax_office_excerpt": "Auszug Finanzamt",
                "health_insure_extract": "Auszug Gebietskrankenkasse",
                "id_card": "Ausweiskopie",
                "passport": "Reisepasskopie",
                "proof_of_ownership": "Nachweis der wirt. Eigentümer",
                "list_of_signatures": "Unterschriftenverzeichnis",
                "commercial_register_extract": "Handelsregisterauszug",
                "company_book_extract": "Firmebuchauszug",
                "business_registration": "Gewerbeanmeldung",
                "other_kyc": "Sonstige Dokumente",
                "schufa": "SCHUFA",
                "creditreform": "Creditreform",
                "bisnode": "Bisnode",
                "debitor_register_excerpt": "Betreibungsregisterauszug",
                "email": "E-Mail",
                "letter": "Brief",
                "fax": "Fax",
                "offer": "Angebot",
                "contract": "Vertrag",
                "other_correspondence": "Sonstige Korrespondenz",
                "unclassified": "Nicht klassifiziert",
                "questionnaire": "Fragebogen",
                "archive": "Archiv",
            },
            "company_industries": {
                "A": "Land- und Forstwirtschaft, Fischerei",
                "B": "Bergbau und Gewinnung von Steinen und Erden",
                "C": "Verarbeitendes Gewerbe",
                "D": "Energieversorgung",
                "E": "Wasserversorgung; Abwasser- und Abfallentsorgung und Beseitigung von Umweltverschmutzungen",
                "F": "Baugewerbe",
                "G": "Handel; Instandhaltung und Reparatur von Fahrzeugen",
                "H": "Verkehr und Lagerei",
                "I": "Gastgewerbe",
                "J": "Information und Kommunikation",
                "K": "Erbringung von Finanz- und Versicherungsdienstleistungen",
                "L": "Grundstücks- und Wohnungswesen",
                "M": "Erbringung von freiberuflichen, wissenschaftlichen und technischen Dienstleistungen",
                "N": "Erbringung von sonstigen wirtschaftlichen Dienstleistungen",
                "O": "Öffentliche Verwaltung, Verteidigung; Sozialversicherung",
                "P": "Erziehung und Unterricht",
                "Q": "Gesundheits- und Sozialwesen",
                "R": "Kunst, Unterhaltung und Erholung",
                "S": "Erbringung von sonstigen Dienstleistungen",
                "T": "Private Haushalte mit Hauspersonal; Herstellung von Waren und Erbringung von Dienstleistungen durch private Haushalte für den Eigenbedarf ohne ausgeprägten Schwerpunkt",
                "U": "Exterritoriale Organisationen und Körperschaften",
            },
            "currencySymbols": {
                "CHF": "CHF",
                "EUR": "€",
                "USD": "$",
                "archiviert": "",
            },
            "countryCodes": {
                "de": "Deutschland",
                "at": "Österreich",
                "ch": "Schweiz",
            },
            "languageCodes": {
                "de": "Deutsch",
                "en": "Englisch",
                "fr": "Französisch",
                "it": "Italienisch",
            },
            "number_of_employees": "Mitarbeiteranzahl",
            "company_legal_form": "Rechtsform",
            "companyRegisterNumber": "Handelsregisternummer",
            "company_product": "Produkt / Service",
            "source_of_leads": "Leadsquelle",
            "action": "Aktion",
            "actions": "Aktionen",
            "visibleToClient": "Kunden sichtbar",
            "factoringFee30days": "Bei 30 Tagen Zahlungsziel Factoringgebühr",
            "factoringFee60days": "Bei 60 Tagen Zahlungsziel Factoringgebühr",
            "resultApplicationReview": "In Review",
            "resultApplicationReviewDetails1": "Ihr Antrag wird gerade von unseren Mitarbeitern geprüft. \nEin SVEA Kundenberater wird sich in Kürze bei Ihnen melden. \nFalls Sie in der Zwischenzeit Fragen haben, erreichen Sie uns telefonisch unter",
            "resultApplicationReviewDetails2": "oder per E-Mail unter",
            "resultApplicationApproved": "Antrag genehmigt",
            "resultApplicationApprovedDetails1": `Ihr Antrag wurde genehmigt. Um Ihr SVEA Konto in Zukunft nutzen zu können, loggen Sie sich bitte in unser Kundenportal ein.`,
            "resultApplicationApprovedDetails2": `Falls Sie noch keine E-Mail mit den Zugangsdaten erhalten haben, sollte diese in Kürze bei Ihnen Eintreffen.`,
            "resultApplicationRejected": "Antrag abgelehnt",
            "resultApplicationRejectedDetails1": "Besten Dank für Ihr Interesse an unseren Finanzdienstleistungen.",
            "resultApplicationRejectedDetails2": "Nach Prüfung Ihres Antrages können wir Ihnen unsere Finanzdienstleistungen nicht anbieten.",
            "resultApplicationRejectedDetails3": "Freundliche Grüsse\nSvea Finans AG",
            "acceptNewsletterCheckbox": "Bitte halten Sie mich per E-Mail über Svea Dienstleistungen, Services und Branchen-Insights auf dem Laufenden. Diese Einwilligung kann ich jederzeit widerrufen.",
            "newsletterSubscription": "Newsletter abbonniert",
            "subscribeNewsletter": "Newsletter bestellen",
            "subscribeNewsletter2": "Newsletter abbonnieren?",
            "unsubscribeNewsletter": "Newsletter abbestellen",
            "forgotPasswordPopover": "Ein Link zum Zurücksetzen des Passworts wird an die oben angegebene E-Mail-Adresse gesendet. Möchten Sie fortfahren?",
            "pleaseSelectOption": "Bitte wählen Sie eine Option",
            "lockAccount": "Konto Sperren",
            "unlockAccount": "Konto Entsperren",
            "confirmLockAccount": "Sind Sie sicher dass Sie das Konto Sperren möchten?",
            "confirmUnlockAccount": "Sind Sie sicher dass Sie das Konto Entsperren möchten?",
            "sendResetPassword": "Passwort zurücksetzen",
            "confirmSendResetPassword": "Sind Sie sicher dass Sie das Passwort zurücksetzen möchten?",
            "accountNotVerified": "Konto nicht bestätigt oder inaktive",
            "showInactiveUsers": "Inaktive / gelöschte Benutzer anzeigen",
            "cpApplicationNextSteps": "1. Überprüfen Sie die Angaben zu Ihrem Unternehmen.\n2. Vervollständigen Sie die Kontaktpersonen und fügen Sie alle zur Unterschrift berechtigten Personen hinzu.\n3. Geben Sie Ihre Bank Informationen an.\n4. Laden Sie die benötigten Dokumente hoch\n5. Schicken Sie die Antrag zur finalen Prüfung ab\n\nBei Fragen stehen wir Ihnen jederzeit zur Verfügung:",
            "factoringVolume": "Factoringvolumen",
            "cpApplicationInReview": "Ihr Antrag wird von unseren Mitarbeitern geprüft.\nEin SVEA Kundenberater wird sich in Kürze bei Ihnen melden.\nBei Fragen stehen wir Ihnen jederzeit zur Verfügung:\n",
            "showCompleted": "Erledigte anzeigen",
            "showOnlyMine": "Nur meine anzeigen",
            "showOnlyToday": "Nur Heute anzeigen",
            "createdBy": "Erstellt von",
            "kind": "Art",
            "todoKinds": {
                "todo": "To-Do",
                "reminder": "Erinnerung",
            },
            "todoStatuses": {
                "open": "Offen",
                "complete": "Erledigt",
            },
            "details": "Details",
            "when": "Wann",
            "reminderText": "Erinnerungstext",
            "confirmDeleteEntry": "Möchten Sie diesen Eintrag Löschen?",
            "remindByEmail": "per E-Mail erinnern",
            "createTodo": "Neues To-Do",
            "createReminder": "Neue Erinnerung",
            "questionnaire": "Fragebogen",
            "newOffer": "Neues Angebot",
            "sveaFlyer": "SVEA Flyer",
            "exitingContractDrafts": "Bereits erstellte Vertragsunterlagen",
            "contractFormInfo": "Bitte füllen Sie die folgenden Informationen aus, um einen Vertrag zu erstellen",
            "factoringContract": "Factoring Vertrag",
            "loanContract": "Darlehensvertrag",
            "expectedFactoringVolume": "Erwartetes Factoringvolumen p.a.",
            "minFactoringFee": "Mindest-Factoring-Gebühr",
            "adminFee": "Administrationsgebühr",
            "debitorLimitFeeAmount": "Debitor Limitanfrage",
            "maxInvoicePayout": "Max. Auszahlung pro Rechnung",
            "maxAdvancePayout": "Max. Bevorschussungsrahmen",
            "isGlobalSession": "mit Globalzession",
            "personalGuarantee": "Bürgschaft",
            "withPersonalGuarantee": "mit einer persönlichen Bürgschaft",
            "guarantorName": "Name des Bürgen",
            "guarantorDateOfBirth": "Geburtsdatum des Bürgen",
            "guarantorPostcodeCity": "Postleitzahl  und Stadt",
            "guaranteeCurrency": "Währung",
            "guaranteeAmount": "Bürgschaftssumme",
            "guaranteeAmountWords": "Summe in Worten",
            "guaranteeEnd": "Ende der Bürgschaft",
            "guaranteeLetter": "Garantieerklärung",
            "liabilityLimit": "Höchstbetrag",
            "generalAssignment": "Globalzession",
            "generalAssignmentStandard": "standard",
            "generalAssignmentSpecial": "zu Sicherungszwecken",
            "negativeDeclaration": "Negativerklärung für Banken",
            "negativeDeclarationStandard": "standard",
            "negativeDeclarationSpecial": "für benannte Debitoren",

            "loanAmount2": "Darlehensbetrag",
            "loanAmountWords": "In Worten",
            "loanUsage": "Kreditnutzung",
            "interestRate2": "Zins",
            "minInterestRate": "min. Zins",
            "firstRateAmount": "Erste Rate",
            "firstRateDays": "zu zahlen am",
            "days": "Tage",
            "installmentRate": "Ratenzahlungen",
            "minimumRepaymentAmount": "Mindestrückzahlung",
            "earlyRepaymentMonths": "Vorzeite Rückzahlung bis",
            "repaymentFee": "Entschädigung",
            "latePaymentFee": "Verzugszins",
            "repaymentType": "Rückzahlungsart",
            "repaymentTypes": {
                "monthly": "Monatlich",
                "quarterly": "Quartal",
                "yearly": "Jährlich",
                "once": "Einmalrückzahlung",
            },
            "singleRepaymentDate": "Einmalrückzahlung am",
            "repaymentDay": "Rückzahlungstag",
            "finalRepayment": "Letzte Rate",
            "finalRateDate": "Letzte Rate Datum",
            "finalRateDays": "zu zahlen am",
            "repaymentBankName": "Bank Name",
            "repaymentBankIban": "IBAN",
            "repaymentBankBic": "BIC",
            "contractDownloadInfo": "Which documents do you want to download?",
            "contractDocuments": {
                "DocumentTemplate_CH_Contract_1": "Factoring Vertrag",
                "DocumentTemplate_CH_Contract_2_a": "Globalzession - Standard",
                "DocumentTemplate_CH_Contract_2_b": "Globalzession - zu Sicherungszwecken",
                "DocumentTemplate_CH_Contract_3": "Darlehensvertrag",
                "DocumentTemplate_CH_Contract_5": "Persönliche Erklärung",
                "DocumentTemplate_CH_Contract_6_a": "Negativerklärung für die Bank",
                "DocumentTemplate_CH_Contract_6_b": "Negativerklärung für benannte Debitoren",
                "DocumentTemplate_CH_Contract_7": "SVEA Depot MwSt.",
                "DocumentTemplate_CH_Contract_14": "Vorlage Auszahlungsanweisung",
                "DocumentTemplate_CH_Contract_15": "Nachweis wirtschaftliche Eigentümer",
                "DocumentTemplate_CH_Contract_16": "Sie möchten starten?",
                "DocumentTemplate_CH_Contract_17": "Bürgschaft",
                "DocumentTemplate_DE_Contract_1": "Factoring Vertragt",
                "DocumentTemplate_DE_Contract_2_a": "Einfache Globalzession",
                "DocumentTemplate_DE_Contract_2_b": "Starke Globalzession",
                "DocumentTemplate_DE_Contract_3": "Darlehensvertrag",
                "DocumentTemplate_DE_Contract_5": "Garantieerklärung",
                "DocumentTemplate_DE_Contract_6": "Negativerklärung für die Bank",
                "DocumentTemplate_DE_Contract_7": "Unterschriftenverzeichnis",
                "DocumentTemplate_DE_Contract_15": "Wirtschaftliche Auskunft",
                "DocumentTemplate_DE_Contract_16": "Nachweis der Wirtschaftlichen Eigentümer",
                "DocumentTemplate_DE_Contract_17": "Sie möchten starten?",
                "Repayment_Plan": "Zinsberechnung",
            },
            "softDelete": "Archivieren",
            "cancelSoftDelete": "Archivierung abbrechen",
            "hardDelete": "Löschen",
            "confirmSoftDeleteClient": "Möchten Sie wirklich diesen Klient, inklusive aller Anträge, Kontaktpersonen, Dokumente und Logins, anonymisieren? Dieser Vorgang ist unwiderruflich. Dies wird in 7 Tagen wirksam.",
            "confirmHardDeleteClient": "Möchten Sie wirklich diesen Klient, inklusive aller Anträge, Kontaktpersonen, Dokumente und Logins, löschen? Dieser Vorgang ist unwiderruflich.",
            "confirmSoftDeleteApplication": "Möchten Sie wirklich diesen Antrag anonymisieren? Dieser Vorgang ist unwiderruflich. Dies wird in 7 Tagen wirksam.",
            "createApplicationConfirm1": "Dieser Klient hat bereits einen aktiven Antrag mit folgenden Daten:",
            "createApplicationConfirm2": "Trotzdem einen neuen Antrag erstellen?",
            "eventDescription": "Eventbeschreibung",
            "signOut": "Abmelden",
            "archivedBanner": "Dieser {{entityType}} wurde am {{archivedAt}} archiviert. Die Daten wurden zur Archivierung an das Zürich Büro übersandt.",
            "noteCategory": "Eintragsart",
            "noteCategories": {
                "phone": "Telefonat",
                "email": "E-Mail",
                "conversation": "Gespräch",
                "note": "Notiz",
                "other": "Sonstiges",
            },
            "previewPaneHeader": "{{entityType}} Informationszusammenfassung",
            "previewPaneTypes": {
                "application": "Antrag",
                "client": "Klient",
                "lead": "Lead",
            },
            "emptyPreviewPane": "Bitte wählen Sie ein Element aus, um eine Vorschau der Informationen anzuzeigen",
            "thousand_separator": ".",
            "decimal_separator": ",",
        }
    }
};
