import { ApiWrapper } from "api/ApiWrapper";
import {ApiResponse} from "./types";


export const registerAdmin = async (username: string, password: string, role: string): Promise<any> => {
    const path = `/admin/account/register`;
    const body = {
        email: username,
        password: password,
        role: role
    };

    return ApiWrapper.post(path, body);
};

export const loginAdmin = async (username: string, password: string): Promise<any> => {
    const path = `/admin/account/login`;
    const body = {
        email: username,
        password: password
    };

    return ApiWrapper.post(path, body, true);
};

export const passwordResetAdmin = async (username: string): Promise<any> => {
    const path = `/admin/account/reset`;
    const body = {
        email: username,
    };

    return ApiWrapper.post(path, body, true);
};

export const loginContactPerson = async (username: string, password: string): Promise<any> => {
    const path = `/contact_persons/account/login`;
    const body = {
        email: username,
        password: password
    };

    return ApiWrapper.post(path, body, true);
};

export const passwordResetContactPerson = async (username: string): Promise<any> => {
    const path = `/contact_persons/account/reset`;
    const body = {
        email: username,
    };

    return ApiWrapper.post(path, body, true);
};

export const contactPersonChangePassword = async (password: string): Promise<ApiResponse<any>> => {
    const path = `/contact_persons/account/change_password`;

    const body = {
        password
    };

    return ApiWrapper.post(path, body);
};
