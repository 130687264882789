import styles from 'components/admin/pages/client-drilldown/ClientDrilldownPage.module.scss';
import * as React from "react";
import {useEffect, useState} from "react";
import {DocumentSectionActor, DocumentsSection} from "../../documents-section/DocumentsSection";
import {adminGetClientDocuments} from "../../../../../api/document-service/adminOnClientDocuments";
import {GetDocumentsListResponse} from "../../../../../api/types";
import {DocumentType} from "../../../../../types/types";

export interface UserDocsTabProps {
    clientId: string
}

export const UserDocsTab = (props: UserDocsTabProps) => {
    // const { t, i18n } = useTranslation();

    const [docs, setDocs] = useState([] as GetDocumentsListResponse[]);

    const fetchClientDocuments = async () => {
        try {
            const result = await adminGetClientDocuments(props.clientId);
            setDocs(result.data);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchClientDocuments();
    }, [props.clientId]);

    return (
        <div className={styles.tab}>
            <DocumentsSection actor={DocumentSectionActor.Admin}
                entityType={DocumentType.Client}
                entityId={props.clientId}
                documents={docs}
                onDataChanged={() => fetchClientDocuments()}
            />
        </div>
    );
};
