import { AdminHomePage } from "components/admin/pages/admin-home/AdminHomePage";
import { ApplicationDrilldownPage } from "components/admin/pages/application-drilldown/ApplicationDrilldownPage";
import { ClientDrilldownPage } from "components/admin/pages/client-drilldown/ClientDrilldownPage";
import React from "react";
import {
    BrowserRouter,
    Link, Route, Switch, Redirect
} from "react-router-dom";
import { ApplicationStepOnePage } from "components/common/pages/application-flow/step-1/ApplicationStepOnePage";
import { ApplicationStepTwoPage } from "components/common/pages/application-flow/step-2/ApplicationStepTwoPage";
import { ApplicationStepLoanPage } from "components/common/pages/application-flow/step-loan/ApplicationStepLoanPage";
import { ApplicationStepCompanyInfoPage } from "components/common/pages/application-flow/step-company-info/ApplicationStepCompanyInfo";
import { ApplicationStepCreateAccountPage } from "components/common/pages/application-flow/step-create-account/ApplicationStepCreateAccount";
import { ApplicationStepResultPage } from "components/common/pages/application-flow/step-result/ApplicationStepResult";
import { ApplicationsPage } from "components/common/pages/applications-page/ApplicationsPage";
import { UserApplicationDrilldownPage } from "components/common/pages/user-application-drilldown/UserApplicationDrilldownPage";
import { AuthenticationPage } from "components/common/pages/authentication-page/AuthenticationPage";
import { SveaLandingPage } from "components/common/pages/svea-landing-page/SveaLandingPage";
import { MyContractsPage } from "components/admin/pages/my-contracts/MyContractsPage";
import { PaymentMenuPage } from "components/admin/pages/payment-menu-page/PaymentMenuPage";
import { SettingsPage } from "components/admin/pages/settings-page/SettingsPage";
import { NotFoundPage } from "components/common/pages/404-page/NotFoundPage";
import { ConfirmEmailPage } from "components/common/pages/confirm-email-page/ConfirmEmailPage";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { ApplicationStepContactInfoPage } from "components/common/pages/application-flow/step-contact-info/ApplicationStepContactInfo";
import {ResetPasswordPage} from "./components/common/pages/reset-password-page/ResetPasswordPage";
import {AcceptInvitationPage} from "./components/common/pages/accept-invitaion-page/AcceptInvitationPage";

export class RouteStrings {
    // Demo
    public static DemoHome = '/demo-home';

    // Admin
    public static AdminRouteBase = '/admin';
    public static AdminLogin = RouteStrings.AdminRouteBase + '/login';
    public static AdminHome = RouteStrings.AdminRouteBase + '/home';
    public static AdminApplicationBase = RouteStrings.AdminRouteBase + "/application/";
    public static AdminInvoiceBase = RouteStrings.AdminRouteBase + "/invoice/";
    public static AdminApplicationDrilldown = RouteStrings.AdminRouteBase + "/application/:applicationId";
    public static AdminUserDrilldown = RouteStrings.AdminRouteBase + "/user/:userId";
    public static AdminLeadDrilldown = RouteStrings.AdminRouteBase + "/lead/:leadId";
    public static AdminClientDrilldown = RouteStrings.AdminRouteBase + "/client/:clientId";
    public static AdminPaymentMenu = RouteStrings.AdminRouteBase + "/payment-menu";
    public static AdminAcceptInvitation = RouteStrings.AdminRouteBase + "/accept-invitation/:registerToken";
    public static AdminResetPassword = RouteStrings.AdminRouteBase + "/reset/:resetToken";

    // User
    public static Index = '/';
    public static SignUp = '/signup';
    public static Login = '/login';
    public static SveaLandingPage = '/home';
	
    public static ApplicationFlowStepOne = "/application/1";
    public static ApplicationFlowStepTwo = "/application/2";
    public static ApplicationFlowStepLoan = "/application/loan";
    public static ApplicationFlowStepCompanyInfo = "/application/company-info";
    public static ApplicationFlowStepContactInfo = "/application/contact-info";
    public static ApplicationFlowStepCreateAccount = "/application/create-account";
    public static ApplicationFlowStepResult = "/application/result";

    // Logic for which step should be skipped is in /navigation/applicationSteps
    public static readonly DEFAULT_PROCESS_FLOW_PATHS = [
        RouteStrings.ApplicationFlowStepOne,
        RouteStrings.ApplicationFlowStepTwo,
        RouteStrings.ApplicationFlowStepLoan,
        RouteStrings.ApplicationFlowStepCompanyInfo,
        RouteStrings.ApplicationFlowStepContactInfo,
        RouteStrings.ApplicationFlowStepCreateAccount,
        RouteStrings.ApplicationFlowStepResult
    ];

    public static UserDashboardBase = '/dashboard';
    public static UserApplicationDrilldownBase = '/dashboard/application/';
    public static ApplicationsOverview = RouteStrings.UserDashboardBase + "/applications";
    public static ApplicationDrilldown = RouteStrings.UserDashboardBase + "/application/:applicationId";
    public static MyContracts = RouteStrings.UserDashboardBase + "/my-contracts";
    public static UserInvoiceInfo = RouteStrings.UserDashboardBase + "/invoice-info/:applicationId";
    public static UserDebitorInfo = RouteStrings.UserDashboardBase + "/debitor-info/:applicationId";
    public static UserSettings = RouteStrings.UserDashboardBase + "/settings";
    public static ConfirmEmail = "/contact_persons/confirm_email/:token";
    public static ResetPassword = "/contact_persons/reset/:resetToken";

}

// TODO: add authenticated routes
export const Routes: React.FC<any> = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={RouteStrings.AdminRouteBase}>
                    <Redirect to={RouteStrings.AdminLogin} />
                </Route>
                <Route path={RouteStrings.AdminLogin} render={(props) => <AuthenticationPage {...props} isAdmin={true}/>} />
                <Route path={RouteStrings.AdminHome} component={AdminHomePage}/>
                <Route path={RouteStrings.AdminApplicationDrilldown} component={ApplicationDrilldownPage}/>
                <Route path={RouteStrings.AdminClientDrilldown} component={ClientDrilldownPage}/>
                <Route path={RouteStrings.AdminPaymentMenu} component={PaymentMenuPage}/>
                <Route path={RouteStrings.AdminAcceptInvitation} component={AcceptInvitationPage}/>
                <Route path={RouteStrings.AdminResetPassword} component={ResetPasswordPage}/>

                <Route path={RouteStrings.SveaLandingPage} component={SveaLandingPage}/>

                <Route exact path={RouteStrings.Index}>
                    <Redirect to={RouteStrings.Login} />
                </Route>
                <Route exact path={RouteStrings.DemoHome}>
                    <div className="app-page">
                        <Spacer hx={2}/>

                        <div className="">
                            <Link to={RouteStrings.ApplicationFlowStepOne}>
                                <h2 style={{fontWeight: "normal", color: "#00aece"}}>
									Start the Application Process
                                </h2>
                            </Link>
                        </div>
                        <Spacer/>

                        <div className="">
                            <Link to={RouteStrings.AdminHome}>
                                <h2 style={{fontWeight: "normal", color: "#00aece"}}>
									Go to the Admin Panel
                                </h2>
                            </Link>
                        </div>
                    </div>
                </Route>

                <Route path={[RouteStrings.SignUp, RouteStrings.Login]} render={(props) => <AuthenticationPage {...props} isAdmin={false}/>} />

                <Route path={RouteStrings.ApplicationFlowStepOne} component={ApplicationStepOnePage}/>
                <Route path={RouteStrings.ApplicationFlowStepTwo} component={ApplicationStepTwoPage}/>
                <Route path={RouteStrings.ApplicationFlowStepLoan} component={ApplicationStepLoanPage}/>
                <Route path={RouteStrings.ApplicationFlowStepCompanyInfo} component={ApplicationStepCompanyInfoPage}/>
                <Route path={RouteStrings.ApplicationFlowStepContactInfo} component={ApplicationStepContactInfoPage}/>
                <Route path={RouteStrings.ApplicationFlowStepCreateAccount} component={ApplicationStepCreateAccountPage}/>

                <Route path={RouteStrings.ApplicationFlowStepResult} component={ApplicationStepResultPage}/>

                <Route path={RouteStrings.ApplicationsOverview} component={ApplicationsPage}/>
                <Route path={RouteStrings.ApplicationDrilldown} component={UserApplicationDrilldownPage}/>
                <Route path={RouteStrings.MyContracts} component={MyContractsPage}/>
                <Route path={RouteStrings.UserSettings} component={SettingsPage}/>
                <Route path={RouteStrings.ConfirmEmail} component={ConfirmEmailPage}/>
                <Route path={RouteStrings.ResetPassword} component={ResetPasswordPage}/>

                <Route path={"*"} component={NotFoundPage}/>
            </Switch>
        </BrowserRouter>
    );
};
