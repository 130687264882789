
export const isValidPassword = (password: string): boolean => {
    // This regex checks for letters, capitals, numbers, and non-character ASCII + unicode ranges
    if (
        password &&
        password.match(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u00FF\u0100-\uFFFF]).{8,254}$/
        )
    ) {
        return true;
    }

    return false;
};
