import {ApiWrapper} from "../ApiWrapper";
import {ApiResponse} from "../types";

export const getQuestionnaireURL = async (country: string, product: string): Promise<ApiResponse<string>> => {
    const path = `/admin/questionnaire/${country}/${product}`;

    return ApiWrapper.get(path);
};

export const getFlyerDocumentURL = async (): Promise<ApiResponse<string>> => {
    const path = `/admin/flyer`;

    return ApiWrapper.get(path);
};
