import { FileAddOutlined, FileOutlined } from "@ant-design/icons";
import { Col, message, Row, Spin, Table } from "antd";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from 'react-i18next';
import {
    adminGetClientDocuments,
    adminRetrieveClientDocumentUploadLink,
    adminSetClientDocumentUploadedSuccessfully
} from "../../../../../../api/document-service/adminOnClientDocuments";
import { uploadFileToURL } from "../../../../../../api/document-service/upload";
import {
    GetClientResponse,
    GetDocumentsListResponse,
    RetrieveDocumentUploadLinkResponse
} from "../../../../../../api/types";
import { DocumentCategory } from "../../../../../../types/types";
import { P } from "../../../../../common/presenters/paragraph/P";
import styles from "../../ClientDrilldownPage.module.scss";

interface NextStepsQuestionnaireSentProps {
    client: GetClientResponse
    onDataChanged: () => void
}

export const NextStepsQuestionnaireSent = (props: NextStepsQuestionnaireSentProps) => {
    const {t} = useTranslation();

    const [docs, setDocs] = useState([] as GetDocumentsListResponse[]);
    const [isUploading, setIsUploading] = useState(false);

    const fetchClientDocuments = async () => {
        try {
            const result = await adminGetClientDocuments(props.client.client_id);
            const allDocs: GetDocumentsListResponse[] = result.data;

            const questionnaireDocs = allDocs.filter(d => d.document_category === DocumentCategory.Questionnaire);
            setDocs(questionnaireDocs);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchClientDocuments();
    }, [props.client.client_id]);

    const onDropFiles = async (acceptedFiles: any) => {
        setIsUploading(true);

        await Promise.all(acceptedFiles?.map(async (file) => {
            try {
                const retrieveLinkResponse: RetrieveDocumentUploadLinkResponse = (
                    await adminRetrieveClientDocumentUploadLink(props.client.client_id, file.name, DocumentCategory.Questionnaire)
                )?.data;

                await uploadFileToURL(file, retrieveLinkResponse.upload_url);
                return adminSetClientDocumentUploadedSuccessfully(props.client.client_id, retrieveLinkResponse.document_id);
            } catch (e) {
                message.error(t('messages:errorEncountered'), 2);
                console.error("failed to upload file:", file?.name, e);
            }

            return null;
        }));

        setIsUploading(false);
        props.onDataChanged();
        fetchClientDocuments();
    };

    const columns = [
        {
            render: () => <FileOutlined className={styles.iconInRow}/>,
        },
        {
            title: t('name'),
            width: 500,
            dataIndex: 'document_name',
        },
    ];

    return (
        <>
            <p>{t('leadNextSteps:labelStepQuestionnaireSent')}</p>
            <Spacer/>

            <Row className={styles.wide}
                gutter={36}
                justify={"start"}
            >
                <Col>
                    <h4 className={styles.bold}>{t('uploadDocuments')}</h4>
                    <Spacer/>
                    {
                        isUploading ?
                            <section>
                                <Spin className={styles.dropzone} size={"large"}/>
                            </section>
                            :
                            <Dropzone onDrop={onDropFiles}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div className={styles.dropzone} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <FileAddOutlined className={styles.iconAdd}/>
                                            <Spacer/>
                                            <P>Drag and drop your documents or <Spacer/> click here.</P>
                                            <Spacer/>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                    }
                </Col>

                <Col>
                    <h4 className={styles.bold}>{t('uploadedDocuments')}</h4>
                    <Spacer/>
                    <Table rowKey={record => record.document_id}
                        columns={columns}
                        dataSource={docs}
                        pagination={{position: ["bottomCenter"], pageSize: 5, total: docs?.length}}/>
                </Col>
            </Row>
        </>
    );
};
