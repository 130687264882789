const axios = require('axios').default;

export const uploadFileToURL = async (file: any, uploadURL: string) => {
    return axios.put(uploadURL, file,
        {
            headers: {
                'Content-Type': file.type,
            }
        });
};
