import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button, Col, Input, message, Row, Space, Table} from 'antd';
import moment from "moment";
import {adminGetClients, adminSearchClients} from "../../../../../api/clientService";
import {CountryCodes, GetAllRegions} from "../../../../../types/countries";
import {DATE_FORMAT} from "../../../../../api/apiConfig";
import {useDateRangeFilterOptions} from "../../../../../hooks/TableFilterOptions";
import {RouteStrings} from "../../../../../Routes";
import {RightOutlined} from "@ant-design/icons";
import {ClientModal} from "../../client-modal/ClientModal";
import {GetBoUsersListItem, GetClientsListResponse} from "../../../../../api/types";
import {adminGetBOUsers} from "../../../../../api/boUserService";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import {tableColumnListSearchProps, tableColumnSearchProps} from "../../../../../misc/antdTableSearch";
import debounce from 'lodash/debounce';
import {PreviewPane, PreviewPaneType} from "../../preview-pane/PreviewPane";

export const ClientsTab = () => {
    const {t} = useTranslation();

    const [addClientModalOpen, setAddClientModalOpen] = useState(false);
    const [clients, setClients] = useState([] as GetClientsListResponse[]);
    const [loading, setLoading] = useState(false);
    const [boContacts, setBoContacts] = useState([] as GetBoUsersListItem[]);
    const [selectedClientId, setSelectedClientId] = useState("");

    const fetchClients = async () => {
        try {
            const result = await adminGetClients(1, 9999); // until pagination is implemented
            let clientsList: GetClientsListResponse[] = result.data;

            setClients(clientsList);
        } catch (e) {
            console.error(e);
        }
    };

    const fetchSveaContactNames = async () => {
        try {
            const result = (await adminGetBOUsers()).data;
            setBoContacts(result); // don't filter deleted ones, show them for filtering
        } catch (e) {
            console.error(e);
            setBoContacts([]);
        }
    };

    const withVerboseShortIDs = (clients: GetClientsListResponse[]): GetClientsListResponse[] => {
        return clients.map(c => ({
            ...c,
            short_id: c.address_country ? `${c.address_country.toUpperCase()}-${c.short_id?.toUpperCase()}` : `ID-${c.short_id?.toUpperCase()}`
        }));
    };

    const handleOnTextSearch = async (e: any) => {
        const text = e.target.value;

        if (!text) {
            fetchClients();
            return;
        }

        setLoading(true);

        try {
            const result = (await adminSearchClients(text)).data;
            setClients(result);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }

        setLoading(false);
    };

    const handleRowClicked = (client: GetClientsListResponse) => {
        setSelectedClientId(client.client_id);
    };

    useEffect(() => {
        fetchClients();
        fetchSveaContactNames();
    }, []);

    const columns = [
        {
            title: 'Client ID',
            dataIndex: 'short_id',
            ...tableColumnSearchProps("short_id"),
        },
        {
            title: t('company'),
            dataIndex: 'company_name',
            ...tableColumnSearchProps("company_name"),
        },
        {
            title: t('sveaContact'),
            dataIndex: 'bo_contact_name',
            ...tableColumnListSearchProps("bo_contact_name", boContacts.map(boUser => ({
                label: boUser.bo_user_name,
                value: boUser.bo_user_name,
            }))),
            render: (value) => value ? value : "-",
        },
        {
            title: t('country'),
            dataIndex: 'address_country',
            ...tableColumnListSearchProps("address_country", CountryCodes.map(country => ({
                label: t(`countryCodes:${country}`),
                value: country,
            }))),
            render: (value) => value ? t(`countryCodes:${value}`) : "-",
        },
        {
            title: t('region'),
            dataIndex: 'address_region',
            ...tableColumnListSearchProps("address_region", GetAllRegions().map(region => ({
                label: region,
                value: region,
            }))),
            render: (value) => value ? value : "-",
        },
        {
            title: t('created'),
            dataIndex: 'created_at',
            render: (value) => value ? moment(value).format(DATE_FORMAT.DE) : "-",
            ...useDateRangeFilterOptions('created_at'),
        },
        {
            title: '',
            dataIndex: 'client_id',
            render: (clientId) => <Link to={RouteStrings.AdminClientDrilldown.replace(':clientId', clientId)}>{t('view')} <RightOutlined/></Link>,
        },
    ];

    return (
        <div className="admin-home-tab">
            <Spacer/>
            <Row justify={"space-between"}
				 align={"bottom"}
            >
                <Col>
                    <Input placeholder={t('placeholders:freeTextSearch')}
                        allowClear
                        onChange={debounce(handleOnTextSearch, 500)}
                    />
                </Col>
                <Col>
                    <Button type="primary"
                        onClick={() => {
                            setAddClientModalOpen(true);
                        }}>
                        {t("buttons:newClient")}
                    </Button>
                </Col>
            </Row>
            <Spacer hx={2}/>

            <Space align={"start"}>
                <Table rowKey={record => record.client_id}
                    columns={columns}
                    loading={loading}
                    dataSource={withVerboseShortIDs(clients)}
                    scroll={{x: true}}
                    pagination={{position: ["bottomCenter"], pageSize: 20, total: clients?.length}}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: evt => handleRowClicked(record),
                        };
                    }}
                />

                <PreviewPane paneType={PreviewPaneType.Client}
                    clientId={selectedClientId}
                />

            </Space>

            <ClientModal existingClientData={null}
						 modalOpen={addClientModalOpen}
						 setModalOpen={setAddClientModalOpen}
						 onDataChanged={() => fetchClients()}
            />
        </div>
    );
};
