import { Button, Select, Slider } from 'antd';
import styles from 'components/common/pages/application-flow/ApplicationFlow.module.scss';
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { BackButton } from 'components/common/presenters/back-button/BackButton';
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { Spacer } from 'components/common/presenters/spacer/Spacer';
import { currencyLocaleFormatter } from "formatters/currencyFormatters";
import * as React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RouteStrings } from "Routes";
import { LocalApplicationFlowDataManager } from "storage/LocalApplicationFlowDataManager";
import { Currencies } from 'types/types';

const { Option } = Select;

export const ApplicationStepLoanPage = () => {
    const { t } = useTranslation();

    const LOAN_MIN_VALUE = 5000;
    const LOAN_MAX_VALUE = 100000;
    const LOAN_MIN_DURATION = 6;
    const LOAN_MAX_DURATION = 36;
    const LOAN_STEP_VALUE = 1000;
    const LOAN_STEP_DURATION = 1;

    const LOAN_DEFAULT_VALUE = 50000;
    const LOAN_DEFAULT_DURATION = 12;

    const [state, setState] = React.useState({
        amount: LOAN_DEFAULT_VALUE,
        duration: LOAN_DEFAULT_DURATION,
        currency: Currencies.EUR
    });

    const persistData = () => {
        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();

        appl.currency = state.currency;
        appl.amount = state.amount as number;
        appl.duration = state.duration as number;

        applDataManager.update(appl);
    };

    React.useEffect(() => {
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();

        const newState = {
            ...state
        };

        if (appl && appl.amount) {
            newState.amount = appl.amount;
        }
        if (appl && appl.duration) {
            newState.duration = appl.duration;
        }
        if (appl && appl.currency) {
            newState.currency = appl.currency as Currencies;
        }

        setState(newState);
    }, []);

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus/>
            <CustomPageHeader />
            <div className={styles.container}>
                <BackButton route={RouteStrings.ApplicationFlowStepTwo} />

                <h2 className={styles.processTitle}>{ t("applProcess:titleStepLoan") }</h2>

                <div className={styles.innerContainer}>

                    <div className={styles.labelContainer}>
                        <h4 className={styles.loanLabel}>{ t("loanAmount") }:</h4>
                        <h4 className={styles.loanHeading}>{t(`currencySymbols:${state.currency.toString()}`)} {currencyLocaleFormatter(state.amount as number)}</h4>
                    </div>

                    <Slider value={state.amount} min={LOAN_MIN_VALUE} max={LOAN_MAX_VALUE} step={LOAN_STEP_VALUE}
                        onChange={(value) => {
                            setState({
                                ...state,
                                amount: value
                            });
                        }} />
                    <Spacer/>

                    <div className={styles.labelContainer}>
                        <h4 className={styles.loanLabel}>{ t("loanDuration") }:</h4>
                        <h4 className={styles.loanHeading}>{state.duration} { t("months") }</h4>
                    </div>

                    <Slider value={state.duration} min={LOAN_MIN_DURATION} max={LOAN_MAX_DURATION} step={LOAN_STEP_DURATION}
                        onChange={(value) => {
                            setState({
                                ...state,
                                duration: value
                            });
                        }} />

                    <Spacer/>

                    <h4>{ t("currency") }:</h4>
                    <Select defaultValue={Currencies.EUR}
                        className={styles.select}
                        value={state.currency}
                        onChange={(val) => {
                            setState({
                                ...state,
                                currency: val
                            });
                        }}>
                        <Option value={Currencies.EUR}>EUR</Option>
                        <Option value={Currencies.CHF}>CHF</Option>
                        <Option value={Currencies.USD}>USD</Option>
                    </Select>

                    <Spacer/>
                    <Spacer/>
                    <Spacer/>

                    <Button className={styles.buttonCenter} type="primary" size="large"
                        onClick={() => {
                            persistData(); 
                        }}>
                        <Link to={RouteStrings.ApplicationFlowStepCompanyInfo}>
                            { t("buttons:continue") }
                        </Link>
                    </Button>
                    <Spacer/>
                </div>
            </div>
        </div>
    );
};
