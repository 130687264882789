import * as React from "react";
import {useEffect, useState} from "react";
import {useLocation, useParams} from 'react-router-dom';
import styles from "./ResetPasswordPage.module.scss";
import {Alert, Button, Form, Input, Row, Space, Spin} from "antd";
import {adminCheckResetTokenValid, adminRecoverPassword} from "../../../../api/boUserService";
import {useTranslation} from "react-i18next";
import {RouteStrings} from "../../../../Routes";
import {
    contactPersonCheckResetTokenValid,
    contactPersonRecoverPassword
} from "../../../../api/clientContactPersonService";
import {goTo} from "../../../../navigation/navigationHelpers";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import {ApplicationProcessHeader} from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import {isValidPassword} from "../../../../helpers/inputValidationHelpers";

export const ResetPasswordPage = () => {
    const {t} = useTranslation();
    const {resetToken} = useParams();
    const location = useLocation();
    const [form] = Form.useForm();

    const [isTokenValid, setIsTokenValid] = useState(null);
    const [resetLoading, setResetLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const isAdmin = location?.pathname?.includes(RouteStrings.AdminRouteBase);

    useEffect(() => {
        checkTokenValidity();
    }, [resetToken]);

    const onFormFinish = async (values: any) => {
        try {
            setResetLoading(true);

            await isAdmin ? adminRecoverPassword(resetToken, values.password) :
                contactPersonRecoverPassword(resetToken, values.password);

            setSuccess(true);
        } catch (e) {
            if (e.response?.status === 401) {
                setIsTokenValid(false);
                return;
            }
        } finally {
            setResetLoading(false);
        }
    };

    const checkTokenValidity = async () => {
        try {
            isAdmin ? await adminCheckResetTokenValid(resetToken)
                : await contactPersonCheckResetTokenValid(resetToken);

            setIsTokenValid(true);
        } catch (e) {
            if (e.response?.status === 401) {
                setIsTokenValid(false);
            }
        }
    };

    const renderLoading = () => {
        return (
            <Spin size={"large"} delay={200}/>
        );
    };

    const renderSetPassword = () => {
        return (
            <div className={styles.formContainer}>
                <h2> {t('sectionTitles:resetPassword')}</h2>

                <Spacer/>

                <h4>{t('createPasswordInfo')}</h4>

                <Spacer/>
                <Spacer/>

                <Form form={form}
                    className={styles.formCreatePassword}
                    name={"resetPassword"}
                    onFinish={onFormFinish}
                    layout={"vertical"}
                    scrollToFirstError
                >
                    <Form.Item
                        label={t('newPassword')}
                        name="password"
                        rules={[({getFieldValue}) => ({
                            validator(_, value) {
                                if (value && !isValidPassword(value)) {
                                    return Promise.reject(t('errors:passwordInvalid'));
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <Input.Password data-cy={"input_password"}/>
                    </Form.Item>

                    <Form.Item
                        label={t('passwordRepeat')}
                        name="password_repeat"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(t('errors:passwordsDontMatch'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password data-cy={"input_password_repeat"}/>
                    </Form.Item>
                    <Spacer/>

                    <Form.Item>
                        <Row className={styles.formButtonRow}>
                            <Button type="primary"
                                htmlType="submit"
                                loading={resetLoading}
                            >
                                {t('buttons:resetPassword')}
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        );
    };

    const renderTokenInvalid = () => {
        return (
            <Alert type="warning"
                showIcon
                message={t('errors:invalidToken')}
                description={t('errors:invalidTokenDetails')}
            />
        );
    };

    const renderSuccess = () => {
        return (
            <>
                <Alert type="success"
                    showIcon
                    message={t('messages:passwordResetSuccessful')}
                />
                <Spacer/>

                <Space direction={"horizontal"}>
                    <Button type={"primary"}
                        onClick={() => goTo(isAdmin ? RouteStrings.AdminLogin : RouteStrings.Login)}
                    >
                        {t('buttons:toLogin')}
                    </Button>
                </Space>
            </>
        );
    };

    const renderContent = () => {
        switch (isTokenValid) {
        case true:
            return success ? renderSuccess() :
                renderSetPassword();
        case false:
            return renderTokenInvalid();
        case null:
        default:
            return renderLoading();
        }
    };
    renderContent();

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader hideNav={true}/>
            <div className={styles.info}>
                {
                    renderSetPassword()
                }
            </div>
        </div>
    );
};
