import * as React from "react";
import {useEffect, useState} from "react";
import styles from './ClientContractsTab.module.scss';
import {Button, Card, Form, message, Popconfirm, Space, Table, Typography} from "antd";
import {SectionTitle} from "../../../../common/presenters/section-title/SectionTitle";
import {useTranslation} from "react-i18next";
import {Spacer} from "../../../../common/presenters/spacer/Spacer";
import {
    GetClientResponse,
    GetContractListItem,
    GetContractResponse,
    PutContractRequest
} from "../../../../../api/types";
import {
    adminCreateContractForClient,
    adminDeleteContract,
    adminGetContract,
    adminGetContractsForClient,
    adminUpdateContract
} from "../../../../../api/contractService";
import {ColumnsType} from "antd/es/table";
import moment from "moment";
import {DATE_FORMAT} from "../../../../../api/apiConfig";
import {DataItemsColumn} from "../../../../common/presenters/data-items-column/DataItemsColumn";
import {ClientFinancingType, Currencies, RepaymentType} from "../../../../../types/types";
import {FactoringContractInputs} from "./FactoringContractInputs";
import {LoanContractInputs} from "./LoanContractInputs";
import {DocumentListModal} from "./DocumentListModal";
import {RedoOutlined} from "@ant-design/icons";

export interface ClientContractsTabProps {
    client: GetClientResponse
}

export const ClientContractsTab = (props: ClientContractsTabProps) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();

    const [contractList, setContractList] = useState<GetContractListItem[]>([]);
    const [editingContractId, setEditingContractId] = useState<string>(null);

    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [downloadContract, setDownloadContract] = useState<GetContractResponse>();

    const getClientCountryCurrency = (): string => {
        return props.client.address_country === 'ch' ? Currencies.CHF.toString() : Currencies.EUR.toString();
    };

    const fetchExistingContracts = async () => {
        try {
            const result: GetContractListItem[] = (await adminGetContractsForClient(props.client.client_id)).data;
            setContractList(result);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        onReset();
        fetchExistingContracts();
    }, [props.client.client_id]);

    const versionOnSameDate = (record: GetContractListItem) => {
        const sameDay: GetContractListItem[] = contractList.filter(c => moment(c.created_at).isSame(record.created_at, "day"));
        const sameDayAsc: GetContractListItem[] = sameDay.sort((a, b) => moment(a.created_at).isBefore(b.created_at) ? -1 : 0);

        return (sameDayAsc.indexOf(record) || 0) + 1;
    };

    const handleEdit = async (contractId: string) => {
        try {
            const result: GetContractResponse = (await adminGetContract(contractId)).data;

            form.setFieldsValue({
                is_standard_general_assignment: result.is_standard_general_assignment,
                is_standard_negative_declaration: result.is_standard_negative_declaration,
                is_global_session: result.is_global_session,
                expected_volume: result.expected_volume,
                factoring_fee_30: result.factoring_fee_30,
                factoring_fee_45: result.factoring_fee_45,
                factoring_fee_60: result.factoring_fee_60,
                factoring_fee_90: result.factoring_fee_90,
                factoring_fee_120: result.factoring_fee_120,
                min_factoring_fee: result.min_factoring_fee,
                admin_fee: result.admin_fee,
                debitor_limit_fee_amount: result.debitor_limit_fee_amount,
                max_invoice_payout: result.max_invoice_payout,
                max_advance_payout: result.max_advance_payout,
                loan_amount: result.loan_amount,
                loan_amount_words: result.loan_amount_words,
                loan_usage: result.loan_usage,
                interest_rate: result.interest_rate,
                min_interest_rate: result.min_interest_rate,
                first_rate_amount: result.first_rate_amount,
                installment_rate: Number.parseFloat(result.installment_rate.toString()),
                minimum_repayment_amount: result.minimum_repayment_amount,
                early_repayment_months: result.early_repayment_months,
                repayment_fee: result.repayment_fee,
                late_payment_fee: result.late_payment_fee,
                loan_duration: result.loan_duration,
                first_rate_days: result.first_rate_days,
                repayment_type: result.repayment_type,
                repayment_day: result.repayment_day,
                final_repayment: result.final_repayment,
                final_rate_date: result.final_rate_date ? moment(result.final_rate_date) : null,
                final_rate_days: result.final_rate_days,
                single_repayment_date: result.single_repayment_date ? moment(result.single_repayment_date) : null,
                repayment_bank_name: result.repayment_bank_name,
                repayment_bank_iban: result.repayment_bank_iban,
                repayment_bank_bic: result.repayment_bank_bic,
                liability_limit: result.liability_limit,
                currency_type: result.currency_type,
                admin_fee_per_rate: result.admin_fee_per_rate,
                first_rate_date: result.first_rate_date ? moment(result.first_rate_date) : null,
                paid_out_date: result.paid_out_date ? moment(result.paid_out_date) : null,
                guarantor_name: result.guarantor_name,
                guarantor_date_of_birth: result.guarantor_date_of_birth ? moment(result.guarantor_date_of_birth) : null,
                guarantor_postcode_city: result.guarantor_postcode_city,
                guarantee_currency: result.guarantee_currency,
                guarantee_amount: result.guarantee_amount,
                guarantee_amount_words: result.guarantee_amount_words,
                guarantee_end: result.guarantee_end ? moment(result.guarantee_end) : null,
            });
            setEditingContractId(result.contract_id);
        } catch (e) {
            console.error(e);
            message.error(t('errors:generalError'), 2);
        }
    };

    const handleDownload = async (contractId: string) => {
        try {
            const foundContract: GetContractResponse = (await adminGetContract(contractId)).data;
            setDownloadContract(foundContract);
            setDownloadModalOpen(true);
        } catch (e) {
            console.error(e);
        }
    };

    const handleDelete = async (contractId: string) => {
        try {
            await adminDeleteContract(contractId);
            fetchExistingContracts();
            message.success(t('messages:dataSaved'), 2);
        } catch (e) {
            console.error(e);
            message.error(t('messages:couldNotSave'), 2);
        }
    };

    const handleSaveAndDownload = async () => {
        const contractId = await handleSave();
        handleDownload(contractId);
    };

    const handleSave = async (): Promise<string> => {
        await form.validateFields();

        const values = form.getFieldsValue();
        const contractData: PutContractRequest = {
            is_standard_general_assignment: values.is_standard_general_assignment,
            is_standard_negative_declaration: values.is_standard_negative_declaration,
            is_global_session: values.is_global_session,
            expected_volume: values.expected_volume,
            factoring_fee_30: values.factoring_fee_30,
            factoring_fee_45: values.factoring_fee_45,
            factoring_fee_60: values.factoring_fee_60,
            factoring_fee_90: values.factoring_fee_90,
            factoring_fee_120: values.factoring_fee_120,
            min_factoring_fee: values.min_factoring_fee,
            admin_fee: values.admin_fee,
            debitor_limit_fee_amount: values.debitor_limit_fee_amount,
            max_invoice_payout: values.max_invoice_payout,
            max_advance_payout: values.max_advance_payout,
            loan_amount: values.loan_amount,
            loan_amount_words: values.loan_amount_words,
            loan_usage: values.loan_usage,
            interest_rate: values.interest_rate,
            min_interest_rate: values.min_interest_rate,
            first_rate_amount: values.first_rate_amount,
            minimum_repayment_amount: values.minimum_repayment_amount,
            early_repayment_months: values.early_repayment_months,
            repayment_fee: values.repayment_fee,
            late_payment_fee: values.late_payment_fee,
            loan_duration: values.loan_duration,
            first_rate_days: values.first_rate_days,
            repayment_type: values.repayment_type,
            repayment_day: values.repayment_day,
            final_repayment: values.final_repayment,
            final_rate_date: values.final_rate_date ? moment(values.final_rate_date).format(DATE_FORMAT.API) : undefined,
            final_rate_days: values.final_rate_days,
            single_repayment_date: values.single_repayment_date ? moment(values.single_repayment_date).format(DATE_FORMAT.API) : undefined,
            repayment_bank_name: values.repayment_bank_name,
            repayment_bank_iban: values.repayment_bank_iban,
            repayment_bank_bic: values.repayment_bank_bic,
            liability_limit: values.liability_limit,
            currency_type: values.currency_type ? values.currency_type : getClientCountryCurrency(),
            admin_fee_per_rate: values.admin_fee_per_rate,
            first_rate_date: values.first_rate_date ? moment(values.first_rate_date).format(DATE_FORMAT.API) : undefined,
            paid_out_date: values.paid_out_date ? moment(values.paid_out_date).format(DATE_FORMAT.API) : undefined,
            guarantor_name: values.guarantor_name,
            guarantor_date_of_birth: values.guarantor_date_of_birth ? moment(values.guarantor_date_of_birth).format(DATE_FORMAT.API) : undefined,
            guarantor_postcode_city: values.guarantor_postcode_city,
            guarantee_currency: values.guarantee_currency,
            guarantee_amount: values.guarantee_amount,
            guarantee_amount_words: values.guarantee_amount_words,
            guarantee_end: values.guarantee_end ? moment(values.guarantee_end).format(DATE_FORMAT.API) : undefined,
        };

        try {
            if (editingContractId) {
                await adminUpdateContract(editingContractId, contractData);

                message.success(t('messages:dataSaved'), 2);
                return editingContractId;
            } else {
                const result: string = (await adminCreateContractForClient(props.client.client_id, contractData)).data;
                setEditingContractId(result);
                fetchExistingContracts();

                message.success(t('messages:dataSaved'), 2);
                return result;
            }
        } catch (e) {
            console.error(e);
            message.error(t('messages:couldNotSave'), 2);
        }

        return null;
    };

    const renderRowButtons = (record: GetContractListItem) => {
        return (
            <Space>
                {/* Sorry, <Button type="link"/> does not work due to sharedStyles.css */}
                <p className={styles.blueTextPointer}
                    onClick={() => handleEdit(record.contract_id)}
                >
                    {t(`buttons:customize`)}
                </p>
                <Typography.Text className={styles.blueText} strong>{" | "}</Typography.Text>

                <p className={styles.blueTextPointer}
                    onClick={() => handleDownload(record.contract_id)}
                >
                    {t(`buttons:download`)}
                </p>
                <Typography.Text className={styles.blueText} strong>{" | "}</Typography.Text>

                <Popconfirm title={t('confirmDeleteEntry')}
                    onConfirm={() => handleDelete(record.contract_id)}
                    okText={t('yes')}
                    cancelText={t('no')}
                >
                    <p className={styles.blueTextPointer}>
                        {t(`buttons:delete`)}
                    </p>
                </Popconfirm>
            </Space>
        );
    };

    const onReset = () => {
        setEditingContractId(null);
        form.resetFields();
        form.setFieldsValue({
            is_standard_general_assignment: true,
            is_standard_negative_declaration: true,
            currency_type: getClientCountryCurrency(),
            admin_fee_per_rate: 5.5,
            repayment_type: RepaymentType.Monthly,
            repayment_day: 1,
            loan_duration: props.client.lead_expected_duration,
            loan_amount: props.client.lead_expected_volume,
            expected_volume: props.client.lead_expected_volume,
        });
    };

    const columns: ColumnsType<GetContractListItem> = [
        {
            title: '',
            dataIndex: 'created_at',
            render: (value, record) => value ? `${moment(value).format(DATE_FORMAT.DE)} Version ${versionOnSameDate(record)}` : "-",
        },
        {
            title: '',
            render: (_, record) => renderRowButtons(record),
        },
    ];

    return (
        <div className={styles.container}>
            <Card>
                <SectionTitle text={t('sectionTitles:draftContractDocuments')}/>
                <Typography.Text>{`${t('exitingContractDrafts')}:`}</Typography.Text>
                <Table className={styles.contractList}
                    rowKey={record => record.contract_id}
                    columns={columns}
                    dataSource={contractList}
                    showHeader={false}
                />
            </Card>
            <Spacer hx={2}/>

            <Card>
                <SectionTitle text={t('sectionTitles:contracts')}/>
                <Typography.Text>{`${t('contractFormInfo')}:`}</Typography.Text>
                <Spacer/>

                <DataItemsColumn dataItems={[
                    {
                        itemName: t('country'),
                        itemValue: props.client.address_country ? t(`countryCodes:${props.client.address_country.toLowerCase()}`) : "-"
                    },
                    {
                        itemName: t('product'),
                        itemValue: props.client.financing_type ? t(`financingTypes:${props.client.financing_type}`) : "-"
                    },
                ]}/>
                <Spacer/>

                <Button type={"primary"}
                    icon={<RedoOutlined/>}
                    onClick={onReset}
                >
                    {t('buttons:reset')}
                </Button>
                <Spacer hx={2}/>

                <Form form={form}
                    layout={"vertical"}
                >
                    {
                        props.client.financing_type === ClientFinancingType.Factoring ?
                            <FactoringContractInputs client={props.client}
                                form={form}
                                contract_id={editingContractId}
                            /> : null
                    }

                    {
                        props.client.financing_type === ClientFinancingType.Loan ?
                            <LoanContractInputs client={props.client} form={form}/>
                            : null
                    }

                </Form>

                <Spacer hx={3}/>
                <Space direction={"vertical"}>
                    <Button type={"primary"}
                        onClick={handleSave}
                    >
                        {t('buttons:save')}
                    </Button>

                    <Button type={"primary"}
                        disabled={!["ch", "de"].includes(props.client?.address_country)}
                        onClick={handleSaveAndDownload}
                    >
                        {t('buttons:downloadDocuments')}
                    </Button>
                </Space>
            </Card>

            <DocumentListModal modalOpen={downloadModalOpen}
                setModalOpen={setDownloadModalOpen}
                contract={downloadContract}
            />
        </div>
    );
};
