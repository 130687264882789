import {Button, Form, Input, message, Row} from "antd";
import {adminChangeBOUserPassword, adminGetSelfProfile} from 'api/boUserService';
import {GetAdminProfileResponse} from 'api/types';
import styles from "components/admin/presenters/admin-home-tabs/AdminHomeTabs.module.scss";
import * as React from "react";
import {useState} from "react";
import {useTranslation} from 'react-i18next';
import {SectionTitle} from "../../../../common/presenters/section-title/SectionTitle";
import {BoUserRole} from "../../../../../types/types";
import {ManageBOUsersSection} from "./ManageBOUsersSection";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import {isValidPassword} from "../../../../../helpers/inputValidationHelpers";

export const SettingsTab = () => {
    const {t} = useTranslation();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState({} as GetAdminProfileResponse);
    const [error, setError] = useState("");

    const getData = async () => {
        try {
            const profileReq = await adminGetSelfProfile();

            setProfile(profileReq.data);
        } catch (error) {
            console.error(error);
            setError(t("messages:errorEncountered"));
        }
    };

    const changePassword = async (password) => {
        setError("");
        setLoading(true);
        try {
            await adminChangeBOUserPassword(password);
            message.success("Password successfully changed!");

        } catch (error) {
            message.error(t("messages:couldNotSave"));
            console.error();
        }
        setLoading(false);
    };

    const handleOnFinish = (values: any) => {
        if (values.password !== values.password_repeat) {
            message.error("Passwords do not match.");
        } else {
            changePassword(values.password);
        }
    };

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <div className={styles.adminUserTab}>
            <SectionTitle text={"User Info"}/>
            <Row>
                <b>E-Mail: &nbsp;</b>{profile ? profile.email : ""}
            </Row>
            <Spacer/>

            <SectionTitle text={"Reset Your Password"}/>
            <Form
                className={styles.passwordForm}
                layout={"vertical"}
                form={form}
                onFinish={handleOnFinish}
                scrollToFirstError
            >
                <Form.Item name="password" label={t("password")} hasFeedback
                    rules={[({getFieldValue}) => ({
                        validator(_, value) {
                            if (value && !isValidPassword(value)) {
                                return Promise.reject(t('errors:passwordInvalid'));
                            }
                            return Promise.resolve();
                        },
                    })]}
                >
                    <Input.Password data-cy="input_password"
                        className={styles.input}/>
                </Form.Item>

                <Form.Item name="password_repeat"
                    label={t("passwordRepeat")}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match!');
                            },
                        }),
                    ]}
                >
                    <Input.Password data-cy="input_password_repeat"
                        className={styles.input}/>
                </Form.Item>

                <div className="custom-error">{error}</div>
                <Spacer/>

                <Form.Item>
                    <Row className={styles.rowCenterX}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {t("buttons:changePassword")}
                        </Button>
                    </Row>
                </Form.Item>
            </Form>

            {
                profile.role === BoUserRole.ADMIN ?
                    <ManageBOUsersSection/>
                    : null
            }
        </div>
    );
};
