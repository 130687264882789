import * as React from "react";
import {useEffect, useState} from "react";
import {Input} from "antd";
import {currencyLocaleFormatter} from "../../../../formatters/currencyFormatters";
import {parseLocaleFormattedFloat} from "../../../../helpers/numbersHelper";

interface LocalizedNumberInputProps {
    className?: string
    value?: number
    onChange?: (value: number | null) => void
    prefix?: React.ReactNode
    suffix?: React.ReactNode
    placeholder?: string
    disabled?: boolean
    precision?: number
}

export const LocalizedNumberInput = (props: LocalizedNumberInputProps) => {
    const [precision, setPrecision] = useState(0);
    const [internal, setInternal] = useState('');

    useEffect(() => {
        const prec = props.precision ? props.precision : 0;
        setPrecision(prec);

        setInternal(props.value != null ? currencyLocaleFormatter(props.value, prec) : '');
    }, [props.value, props.precision]);

    return (
        <Input className={props.className}
			   prefix={props.prefix}
			   suffix={props.suffix}
			   placeholder={props.placeholder}
			   disabled={props.disabled}
			   value={internal}

			   onChange={(e) => {
				   setInternal(e.target.value);
			   }}

			   onBlur={(e) => {
			   	if (e.target?.value) {
                    const parsed = parseLocaleFormattedFloat(e.target.value);
                    const internalWithPrecision = currencyLocaleFormatter(parsed, precision);
                    const parsedWithPrecision = parseFloat(parsed.toFixed(precision));

                    setInternal(internalWithPrecision);
                    if(props.onChange) {
                        props.onChange(parsedWithPrecision); 
                    }
                } else {
                    setInternal(null);
                    if(props.onChange) {
                        props.onChange(null); 
                    }
                }
			   }}
        />
    );
};
