import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button, Checkbox, Col, Input, message, Row, Space, Table} from 'antd';
import {LeadModal} from "../../lead-modal/LeadModal";
import {adminGetLeads, adminSearchLeads} from "../../../../../api/clientService";
import {RouteStrings} from "../../../../../Routes";
import {RightOutlined} from "@ant-design/icons";
import moment from "moment";
import {DATE_FORMAT} from "../../../../../api/apiConfig";
import {CountryCodes, GetAllRegions} from "../../../../../types/countries";
import {useDateRangeFilterOptions} from "../../../../../hooks/TableFilterOptions";
import {GetAdminProfileResponse, GetBoUsersListItem, GetClientsListResponse} from "../../../../../api/types";
import {LeadStatus} from "../../../../../types/types";
import {adminGetBOUsers, adminGetSelfProfile} from "../../../../../api/boUserService";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import {tableColumnListSearchProps, tableColumnSearchProps} from "../../../../../misc/antdTableSearch";
import {currencyLocaleFormatter} from "../../../../../formatters/currencyFormatters";
import debounce from 'lodash/debounce';
import {PreviewPane, PreviewPaneType} from "../../preview-pane/PreviewPane";

export const LeadsTab = () => {
    const {t} = useTranslation();

    const [addLeadModalOpen, setAddLeadModalOpen] = useState(false);

    const [clients, setClients] = useState([] as GetClientsListResponse[]);
    const [loading, setLoading] = useState(false);
    const [boContacts, setBoContacts] = useState([] as GetBoUsersListItem[]);
    const [adminProfile, setAdminProfile] = useState({} as GetAdminProfileResponse); // TODO store it in global store
    const [filterSelf, setFilterSelf] = useState(true);
    const [selectedClientId, setSelectedClientId] = useState("");

    const dataSource = filterSelf ?
        clients.filter(c => c.bo_contact_name === adminProfile.bo_user_name)
        : clients;

    const fetchLeads = async () => {
        try {
            const result = await adminGetLeads(1, 9999); // until pagination is implemented
            let clientsList: GetClientsListResponse[] = result.data;

            setClients(clientsList);
        } catch (e) {
            console.error(e);
        }
    };

    const fetchSveaContactNames = async () => {
        try {
            const result = (await adminGetBOUsers()).data;
            setBoContacts(result); // don't filter deleted ones, show them for filtering
        } catch (e) {
            console.error(e);
            setBoContacts([]);
        }
    };

    const fetchAdminProfile = async () => {
        try {
            const result = (await adminGetSelfProfile()).data;
            setAdminProfile(result);
        } catch (e) {
            console.error(e);
            setAdminProfile({});
        }
    };

    const handleOnTextSearch = async (e: any) => {
        const text = e.target.value;

        if (!text) {
            fetchLeads();
            return;
        }

        setLoading(true);

        try {
            const result = (await adminSearchLeads(text)).data;
            setClients(result);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'));
        }

        setLoading(false);
    };

    const withVerboseShortIDs = (clients: GetClientsListResponse[]): GetClientsListResponse[] => {
        return clients.map(c => ({
            ...c,
            short_id: c.address_country ? `${c.address_country.toUpperCase()}-${c.short_id?.toUpperCase()}` : `ID-${c.short_id?.toUpperCase()}`
        }));
    };

    const handleRowClicked = (client: GetClientsListResponse) => {
        setSelectedClientId(client.client_id);
    };

    useEffect(() => {
        fetchLeads();
        fetchSveaContactNames();
        fetchAdminProfile();
    }, []);
  
    const columns = [
        {
            title: 'Lead ID',
            width: 150,
            dataIndex: 'short_id',
            ...tableColumnSearchProps("short_id"),
        },
        {
            title: t('company'),
            dataIndex: 'company_name',
            ...tableColumnSearchProps("company_name"),
        },
        {
            title: t('sveaContact'),
            dataIndex: 'bo_contact_name',
            ...tableColumnListSearchProps("bo_contact_name", boContacts.map(boUser => ({
                label: boUser.bo_user_name,
                value: boUser.bo_user_name,
            }))),
            render: (value) => value ? value : "-",
        },
        {
            title: t('country'),
            dataIndex: 'address_country',
            ...tableColumnListSearchProps("address_country", CountryCodes.map(country => ({
                label: t(`countryCodes:${country}`),
                value: country,
            }))),
            render: (value) => value ? t(`countryCodes:${value}`) : "-",
        },
        {
            title: t('region'),
            dataIndex: 'address_region',
            ...tableColumnListSearchProps("address_region", GetAllRegions().map(region => ({
                label: region,
                value: region,
            }))),
            render: (value) => value ? value : "-",
        },
        {
            title: t('status'),
            dataIndex: 'lead_status',
            ...tableColumnListSearchProps("lead_status", Object.keys(LeadStatus).map(k => ({
                label: t(`leadStatuses:${LeadStatus[k]}`),
                value: LeadStatus[k],
            }))),
            render: (value) => value ? t(`leadStatuses:${value}`) : "-",
            filters: Object.keys(LeadStatus).map(k => {
                const statusValue = LeadStatus[k];
                const statusText = t(`leadStatuses:${statusValue}`);
                return {
                    text: statusText,
                    value: statusValue,
                };
            }),
            filterMultiple: true,
            defaultFilteredValue: Object.keys(LeadStatus)
                .filter(k => ![LeadStatus.Lost, LeadStatus.Disqualified, LeadStatus.Archived].includes(LeadStatus[k]))
                .map(k => LeadStatus[k]),
            onFilter: (value, record) => record.lead_status?.valueOf() === value.valueOf(),
        },
        {
            title: t('product'),
            dataIndex: 'financing_type',
            render: (value) => value ? t(`financingTypes:${value}`) : "-",
        },
        {
            title: t('expectedVolume'),
            dataIndex: 'lead_expected_volume',
            render: (value, record) => `${t(`currencySymbols:${record.lead_currency}`)} ${record.lead_expected_volume ? currencyLocaleFormatter(record.lead_expected_volume) : 0}`,
        },
        {
            title: t('updated'),
            dataIndex: 'lead_status_updated_at',
            render: (value) => value ? moment(value).format(DATE_FORMAT.DE) : "-",
            ...useDateRangeFilterOptions('lead_status_updated_at'),
        },
        {
            title: t('created'),
            dataIndex: 'created_at',
            render: (value) => value ? moment(value).format(DATE_FORMAT.DE) : "-",
            ...useDateRangeFilterOptions('created_at'),
        },
        {
            title: '',
            dataIndex: 'client_id',
            render: (clientId) => <Link to={RouteStrings.AdminClientDrilldown.replace(':clientId', clientId)}>{t('view')} <RightOutlined/></Link>,
        },
    ];

    return (
        <div className="admin-home-tab">
            <Spacer/>
            <Row justify={"space-between"}
				 align={"bottom"}
            >
                <Col>
                    <Input placeholder={t('placeholders:freeTextSearch')}
                        allowClear
                        onChange={debounce(handleOnTextSearch, 500)}
                    />
                </Col>
                <Col>
                    <Button type="primary"
                        onClick={() => {
                            setAddLeadModalOpen(true);
                        }}>
                        {t("buttons:newLead")}
                    </Button>
                </Col>
            </Row>
            <Spacer/>

            <Checkbox checked={filterSelf}
                onChange={(e) => {
                    setFilterSelf(e.target.checked);
                }}
            >
                {t('buttons:myLeads')}
            </Checkbox>

            <Spacer hx={2}/>

            <Space align={"start"}>
                <Table rowKey={record => record.client_id}
                    columns={columns}
                    dataSource={withVerboseShortIDs(dataSource)}
                    scroll={{x: true}}
                    pagination={{position: ["bottomCenter"], pageSize: 20, total: dataSource?.length}}
                    loading={loading}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: evt => handleRowClicked(record),
                        };
                    }}
                />

                <PreviewPane paneType={PreviewPaneType.Lead}
                    clientId={selectedClientId}
                />
            </Space>

            <LeadModal existingLeadData={null}
					   modalOpen={addLeadModalOpen}
					   setModalOpen={setAddLeadModalOpen}
					   onDataChanged={() => fetchLeads()}
            />
        </div>
    );
};


