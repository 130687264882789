import { Button, message } from "antd";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useTranslation } from 'react-i18next';
import { adminGenerateContractForClient } from "../../../../../../api/clientService";
import { GetClientResponse } from "../../../../../../api/types";
import { DataItemsColumn } from "../../../../../common/presenters/data-items-column/DataItemsColumn";

interface NextStepsProposalSentProps {
    client: GetClientResponse
    onDataChanged: () => void
}

export const NextStepsProposalSent = (props: NextStepsProposalSentProps) => {
    const {t} = useTranslation();

    const handleGenerateContract = async () => {
        try {
            await adminGenerateContractForClient(props.client.client_id);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    return (
        <>
            <p>{t('leadNextSteps:labelStepProposalSent')}</p>
            <Spacer/>

            <DataItemsColumn dataItems={[
                {itemName: t('country'), itemValue: props.client.address_country ? t(`countryCodes:${props.client.address_country.toLowerCase()}`) : "-"},
                {
                    itemName: t('product'),
                    itemValue: props.client.financing_type ? t(`financingTypes:${props.client.financing_type}`) : "-"
                },
            ]}/>
            <Spacer/>

            <Button data-cy={"button_generate_contract"}
                type={"primary"}
                onClick={() => {
                    handleGenerateContract();
                }}
            >
                {t('buttons:createOffer')}
            </Button>
        </>
    );
};
