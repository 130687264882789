import {Button, message, Modal, Space} from "antd";
import {PutApplicationKYCConfirmReq} from "api/types";
import styles from "components/admin/presenters/application-state-buttons/ApplicationStateButtons.module.scss";
import {SubmitToSignatureModal} from "components/admin/presenters/applications-drilldown-tabs/company-tab/submit-to-signature-modal/SubmitToSignatureModal";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {ApplicationFlowType} from "storage/LocalAppDataManager";
import {Application, ApplicationStates} from "types/types";
import {SectionTitle} from "../../../common/presenters/section-title/SectionTitle";
import {adminExportApplication} from "../../../../api/applicationService";

export interface ApplicationStateButtonsProps {
    className?: string
    application: Application
    onKYCConfirm: (data: PutApplicationKYCConfirmReq) => void
    onStateChange: (newState: ApplicationStates) => void
}

export const ApplicationStateButtons = (props: ApplicationStateButtonsProps) => {
    const {t} = useTranslation();

    const [signatureModalOpen, setSignatureModalOpen] = useState(false);
    const [signatureConfirmModalOpen, setSignatureConfirmModalOpen] = useState(false);

    const [confirmNextState, setConfirmNextState] = useState(ApplicationStates.Open);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const handleExportApplication = async () => {
        try {
            await adminExportApplication(props.application.id);
            message.success(t('messages:archiveDataSent'), 2);
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    const renderReopenButton = () => {
        return (
            <Button className={styles.button} type="primary" size="large"
                onClick={() => {
                    setConfirmNextState(ApplicationStates.Open);
                    setConfirmModalOpen(true);
                }}>
                {t(`toApplicationState:reopen`)}
            </Button>
        );
    };

    const renderRejectButton = () => {
        return (
            <Button className={styles.buttonRed} type="primary" size="large"
                onClick={() => {
                    setConfirmNextState(ApplicationStates.Rejected);
                    setConfirmModalOpen(true);
                }}>
                {t(`toApplicationState:to_rejected`)}
            </Button>
        );
    };

    const renderNextStateButtons = (currentState: ApplicationStates) => {
        switch (currentState) {
        case ApplicationStates.Open:
            return (
                <Space>
                    <Button className={styles.button} type="primary" size="large"
                        onClick={() => {
                            setConfirmNextState(ApplicationStates.In_Review_Risk);
                            setConfirmModalOpen(true);
                        }}>
                        {t(`toApplicationState:to_in_review_risk`)}
                    </Button>

                    {renderRejectButton()}
                </Space>

            );
        case ApplicationStates.In_Review_Risk:
            return (
                <Space>
                    <Button className={styles.button} type="primary" size="large"
                        onClick={() => {
                            setSignatureModalOpen(true);
                        }}>
                        {t(`toApplicationState:to_signature_kyc`)}
                    </Button>

                    {renderRejectButton()}
                </Space>
            );
        case ApplicationStates.Signature_KYC:
            return (
                <>
                    <Space>
                        <Button className={styles.button} type="primary" size="large"
                            onClick={() => {
                                setConfirmNextState(ApplicationStates.Final_Review_Operations);
                                setConfirmModalOpen(true);
                            }}>
                            {t(`toApplicationState:to_final_review_operations`)}
                        </Button>

                        {renderRejectButton()}
                    </Space>
                    <Spacer/>

                    <Button className={styles.button} type="primary" size="large"
                        onClick={() => {
                            setConfirmNextState(ApplicationStates.In_Review_Risk);
                            setConfirmModalOpen(true);
                        }}>
                        {t(`toApplicationState:back_to_in_review_risk`)}
                    </Button>
                </>
            );
        case ApplicationStates.Final_Review_Operations:
            return (
                <>
                    <Space>
                        <Button className={styles.button} type="primary" size="large"
                            onClick={() => {
                                setConfirmNextState(ApplicationStates.Approved_Awaiting_Payout);
                                setConfirmModalOpen(true);
                            }}>
                            {t(`toApplicationState:to_approved_awaiting_payout`)}
                        </Button>

                        {renderRejectButton()}
                    </Space>
                    <Spacer/>

                    <Button className={styles.button} type="primary" size="large"
                        onClick={() => {
                            setConfirmNextState(ApplicationStates.Signature_KYC);
                            setConfirmModalOpen(true);
                        }}>
                        {t(`toApplicationState:back_to_signature_kyc`)}
                    </Button>
                </>
            );
        case ApplicationStates.Approved_Awaiting_Payout:
            return (
                <>
                    <Button className={styles.button} type="primary" size="large"
                        onClick={() => {
                            setConfirmNextState(ApplicationStates.Paid_Out);
                            setConfirmModalOpen(true);
                        }}>
                        {t(`toApplicationState:to_paid_out`)}
                    </Button>
                    <Spacer/>

                    <Button className={styles.button} type="primary" size="large"
                        onClick={() => {
                            setConfirmNextState(ApplicationStates.Final_Review_Operations);
                            setConfirmModalOpen(true);
                        }}>
                        {t(`toApplicationState:back_to_final_review_operations`)}
                    </Button>
                </>
            );
        case ApplicationStates.Rejected:
            return (
                <>
                    {renderReopenButton()}
                </>
            );
        case ApplicationStates.Paid_Out:
            return (
                <>
                    <Button className={styles.button} type="primary" size="large"
                        onClick={handleExportApplication}>
                        {t(`buttons:exportApplicationAgain`)}
                    </Button>
                    <Spacer/>

                    {renderReopenButton()}
                </>
            );
        default:
            return (
                <>
                    {renderReopenButton()}
                </>
            );
        }
    };

    return (
        <div className={styles.mainContainer}>
            <SectionTitle text={t('changeApplicationState')}/>

            {renderNextStateButtons(props.application.status)}

            <SubmitToSignatureModal modalOpen={signatureModalOpen}
                setModalOpen={setSignatureModalOpen}
                modal2Open={signatureConfirmModalOpen}
                setModal2Open={setSignatureConfirmModalOpen}
                applicationFlowType={props.application.type as ApplicationFlowType}
                onOk={(data) => {
                    props.onKYCConfirm({
                        client_id: props.application.client_id,
                        amount: parseFloat(data.amount),
                        duration: parseFloat(data.duration),
                        interest_rate: parseFloat(data.interest_rate),
                        required_signature: data.required_signature ? data.required_signature : ""
                    });
                }}
            />

            <Modal className={styles.modal}
                title={"Application Status Update"}
                visible={confirmModalOpen}
                okText={t("buttons:yes")}
                cancelText={t("buttons:no")}
                onOk={() => {
                    props.onStateChange(confirmNextState);
                    setConfirmModalOpen(false);
                }}
                onCancel={() => {
                    setConfirmModalOpen(false);
                }}>
                <p>
                    Are you sure you want to change the loan application to <b
                        className={styles.bold}>{t(`applicationStates:${confirmNextState}`)}</b> ?
                </p>
            </Modal>
        </div>
    );
};
