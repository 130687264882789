import * as React from "react";
import {useEffect, useState} from "react";
import {AutoComplete, Button, Col, Divider, Form, Input, message, Modal, Row, Select} from "antd";
import {
    ClientFinancingType,
    ClientType,
    CompanyIndustries,
    CompanyLegalForms,
    CompanySizes,
    Currencies,
    LeadSources,
    LeadStatus,
    LoanDurations,
} from "../../../../types/types";
import styles from "./LeadModal.module.scss";
import {useTranslation} from "react-i18next";
import {CountryCodes, LanguageCodes, RegionList} from "types/countries";
import {AnchoredSelect} from "../../../common/presenters/anchored-select/AnchoredSelect";
import {LocalizedNumberInput} from "../../../common/presenters/localized-number-input/LocalizedNumberInput";
import {adminCreateClient, adminGetCompanyProducts, adminUpdateClient} from "../../../../api/clientService";
import {GetBoUsersListItem, GetClientResponse, PutClientRequest} from "../../../../api/types";
import {adminGetBOUsers} from "../../../../api/boUserService";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import { getPostCodeValidationPattern } from "validators/inputs";
import { ConfirmModalClose } from "components/common/presenters/confirm-modal-close/ConfirmModalClose";

interface LeadModalProps {
    modalOpen: boolean
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    existingLeadData: GetClientResponse
    onDataChanged: () => void
}

export const LeadModal = (props: LeadModalProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [secondaryModalOpen, setSecondaryModalOpen] = React.useState(false);

    const defaultState = {
        lead_currency: Currencies.EUR,
    } as PutClientRequest;

    const [data, setData] = useState(defaultState);
    const [note, setNote] = useState(null as string);
    const [boContacts, setBoContacts] = useState([] as GetBoUsersListItem[]);

    const [companyProducts, setCompanyProducts] = useState([] as string[]);
    const [companyProductOptions, setCompanyProductOptions] = useState([] as {key: string; value: string}[]);

    const isUpdateOperation = props.existingLeadData ? true : false;

    const fetchSveaContactNames = async () => {
        try {
            const result = (await adminGetBOUsers()).data;
            setBoContacts(result?.filter(bo => !bo.is_deleted));
        } catch (e) {
            console.error(e);
            setBoContacts([]);
        }
    };

    const fetchCompanyProductOptions = async () => {
        try {
            const result = (await adminGetCompanyProducts()).data;
            setCompanyProducts(result);

            setCompanyProductOptions(
                result?.map(p => ({key: p, value: p})) || []
            );
        } catch (e) {
            console.error(e);
            setCompanyProducts([]);
            setCompanyProductOptions([]);
        }
    };

    useEffect(() => {
        if (props.modalOpen) {
            fetchSveaContactNames();
            fetchCompanyProductOptions();

            if (isUpdateOperation) {
                setData({
                    client_type: props.existingLeadData.client_type,
                    company_name: props.existingLeadData.company_name,
                    company_legal_form: props.existingLeadData.company_legal_form,
                    company_register_number: props.existingLeadData.company_register_number,
                    company_industry: props.existingLeadData.company_industry,
                    company_product: props.existingLeadData.company_product,
                    company_number_of_employees: props.existingLeadData.company_number_of_employees,
                    address_addition: props.existingLeadData.address_addition,
                    address_street: props.existingLeadData.address_street,
                    address_house_number: props.existingLeadData.address_house_number,
                    address_post_code: props.existingLeadData.address_post_code,
                    address_city: props.existingLeadData.address_city,
                    address_region: props.existingLeadData.address_region,
                    address_country: props.existingLeadData.address_country,
                    language: props.existingLeadData.language,
                    company_website: props.existingLeadData.company_website,
                    tax_number: props.existingLeadData.tax_number,
                    lead_status: props.existingLeadData.lead_status,
                    lead_source: props.existingLeadData.lead_source,
                    financing_type: props.existingLeadData.financing_type,
                    lead_currency: props.existingLeadData.lead_currency,
                    lead_expected_volume: props.existingLeadData.lead_expected_volume,
                    lead_expected_duration: props.existingLeadData.lead_expected_duration,
                    bo_contact_name: props.existingLeadData.bo_contact_name,
                    broker_name: props.existingLeadData.broker_name,
                } as PutClientRequest);
            } else {
                setData(defaultState);
            }
        }
    }, [props.modalOpen]);

    const handleSave = async () => {
        await form.validateFields();

        try {
            if (isUpdateOperation) {
                await adminUpdateClient(props.existingLeadData.client_id, {
                    ...data,
                    client_type: ClientType.Lead,
                });
            } else {
                await adminCreateClient({
                    ...data,
                    client_type: ClientType.Lead,
                    note: note,
                });
            }
            props.onDataChanged();
            props.setModalOpen(false);
            message.success(t("messages:dataSaved"), 2);
        } catch (e) {
            message.error(t("messages:couldNotSave"), 2);
            console.error(e);
        }
    };


    const legalFormOptions = () => {
        return CompanyLegalForms.map((val, i) => {
            return (
                <Select.Option data-cy={`company_legal_form_option_${i}`} key={i} value={val}>
                    {val}
                </Select.Option>
            );
        });
    };

    const companySizeOptions = () => {
        return CompanySizes.map((val, i) => {
            return (
                <Select.Option data-cy={`company_number_of_employees_option_${i}`} key={i} value={val}>
                    {val}
                </Select.Option>
            );
        });
    };

    const companyIndustryOptions = () => {
        return CompanyIndustries.map((val, i) => {
            return (
                <Select.Option data-cy={`company_industry_option_${i}`} key={i} value={val}>
                    {t(`company_industries:${val}`)}
                </Select.Option>
            );
        });
    };

    const leadSourceOptions = () => {
        return LeadSources.map((val, i) => {
            return (
                <Select.Option data-cy={`lead_source_option_${i}`} key={i} value={val}>
                    {val}
                </Select.Option>
            );
        });
    };

    const addressCountryOptions = () => {
        return CountryCodes.map((val, i) => {
            return (
                <Select.Option data-cy={`address_country_option_${i}`} key={i} value={val}>
                    {t(`countryCodes:${val}`)}
                </Select.Option>
            );
        });
    };

    const regionOptions = () => {
        const country: string = data.address_country;
        const regions: string[] = RegionList[country] || [];
        return regions.map((val, i) => {
            return (
                <Select.Option data-cy={`address_region_option_${i}`} key={i} value={val}>
                    {val}
                </Select.Option>
            );
        });
    };

    const languageOptions = () => {
        return LanguageCodes.map((val, i) => {
            return (
                <Select.Option data-cy={`language_option_${i}`} key={i} value={val}>
                    {t(`languageCodes:${val}`)}
                </Select.Option>
            );
        });
    };

    const leadStatusOptions = (t) => {
        return Object.keys(LeadStatus).map((k, i) => {
            const enumValue = LeadStatus[k];
            return (
                <Select.Option data-cy={`lead_status_option_${i}`} key={i} value={enumValue}>
                    {t(`leadStatuses:${enumValue}`)}
                </Select.Option>
            );
        });
    };

    const financingTypeOptions = (t) => {
        return Object.keys(ClientFinancingType).map((k, i) => {
            const enumValue = ClientFinancingType[k];
            return (
                <Select.Option data-cy={`financing_type_option_${i}`} key={i} value={enumValue}>
                    {t(`financingTypes:${enumValue}`)}
                </Select.Option>
            );
        });
    };

    const currencyOptions = () => {
        return Object.keys(Currencies).map((k, i) => {
            const enumValue = Currencies[k];
            return (
                <Select.Option data-cy={`lead_currency_option_${i}`} key={i} value={enumValue}>
                    {enumValue}
                </Select.Option>
            );
        });
    };

    const loanDurationOptions = () => {
        return Object.keys(LoanDurations).map((k, i) => {
            const enumValue = LoanDurations[k];
            return (
                <Select.Option
                    data-cy={`lead_expected_duration_option_${i}`}
                    key={i}
                    value={enumValue}
                >{`${enumValue} Months`}</Select.Option>
            );
        });
    };

    const contactNameOptions = () => {
        return boContacts.map((boContact, i) => {
            return (
                <Select.Option data-cy={`bo_contact_name_option_${i}`} key={i} value={boContact.bo_user_name}>
                    {boContact.bo_user_name}
                </Select.Option>
            );
        });
    };

    const handleSearchCompanyProduct = (searchText: string) => {
        if (!searchText) {
            setCompanyProductOptions(
                companyProducts?.map(p => ({key: p, value: p})) || []
            );
        } else {
            setCompanyProductOptions(
                companyProducts
                    ?.filter(p => p.toLowerCase().includes(searchText))
                    ?.map(p => ({key: p, value: p}))
            );
        }
    };

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };

    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        props.setModalOpen(false); 
    };

    return (
        <React.Fragment>
            <ConfirmModalClose visible={secondaryModalOpen} onCancel={secondaryModalOnCancel} onOk={secondaryModalOnOk}/>

            <Modal
                width={1000}
                centered
                title={isUpdateOperation ? t("modals:editLead") : t("modals:addLead")}
                visible={props.modalOpen}
                destroyOnClose={true}
                maskClosable={false}
                okText={t("buttons:ok")}
                cancelText={t("buttons:cancel")}
                onCancel={(e: any) => {
                    if (e.code === "Escape") {
                        e.preventDefault();
                        e.stopPropagation();
                        setSecondaryModalOpen(true);
                    } else {
                        props.setModalOpen(false); 
                    }
                }}
                footer={[
                    <div key="fr" className={styles.footerRow}>
                        <Button className={styles.buttonCancel} key="cancel"
                            onClick={() => {
                                props.setModalOpen(false); 
                            }}>
                            { t("buttons:cancel") }
                        </Button>
                        <Button className={styles.buttonOk} key="submit" onClick={() => {
                            handleSave();
                        }}>
                            { t("buttons:submit") }
                        </Button>
                    </div>
                ]}>
                <Form
                    layout={"vertical"}
                    form={form}
                >
                    <div className={styles.inputsContainer}>
                        <h4 className={styles.boldText}>{t("companyData")}</h4>
                        <Row gutter={36}>
                            <Col span={12}>
                                <h4>{t("company")}</h4>
                                <Input
                                    data-cy="input_company_name"
                                    value={data.company_name}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, company_name: e.target.value };
                                        });
                                    }}
                                />
                                <Spacer/>

                                <h4>{t("company_legal_form")}</h4>
                                <AnchoredSelect
                                    data-cy="select_company_legal_form"
                                    value={data.company_legal_form}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, company_legal_form: e };
                                        });
                                    }}
                                >
                                    {legalFormOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t("number_of_employees")}</h4>
                                <AnchoredSelect
                                    data-cy="select_number_of_employees"
                                    value={data.company_number_of_employees}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, company_number_of_employees: e };
                                        });
                                    }}
                                >
                                    {companySizeOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t("companyRegisterNumber")}</h4>
                                <Input
                                    data-cy="input_company_register_number"
                                    value={data.company_register_number}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, company_register_number: e.target.value };
                                        });
                                    }}
                                />
                            </Col>
                            <Col span={12}>
                                <h4>{t("industry_type")}</h4>
                                <AnchoredSelect
                                    data-cy="select_company_industry"
                                    value={data.company_industry}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, company_industry: e };
                                        });
                                    }}
                                >
                                    {companyIndustryOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t("company_product")}</h4>
                                <AutoComplete
                                    data-cy="select_company_product"
                                    options={companyProductOptions}
                                    onSearch={searchText => handleSearchCompanyProduct(searchText)}
                                    value={data.company_product}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, company_product: e };
                                        });
                                    }}
                                />
                                <Spacer/>

                                <h4>{t("source_of_leads")}</h4>
                                <AnchoredSelect
                                    data-cy="select_lead_source"
                                    value={data.lead_source}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, lead_source: e };
                                        });
                                    }}
                                >
                                    {leadSourceOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t("taxIdNumberShort")}</h4>
                                <Input
                                    data-cy="input_tax_number"
                                    value={data.tax_number}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, tax_number: e.target.value };
                                        });
                                    }}
                                />
                                <Spacer/>
                                <Spacer/>
                            </Col>
                        </Row>
                        <Spacer/>

                        <h4 className={styles.boldText}>{t("addressData")}</h4>
                        <Row gutter={36}>
                            <Col span={12}>
                                <h4>{t("country")}</h4>
                                <AnchoredSelect
                                    data-cy="select_address_country"
                                    value={data.address_country}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, address_country: e, address_region: ""};
                                        });
                                    }}
                                >
                                    {addressCountryOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t("addition")}</h4>
                                <Input
                                    data-cy="input_address_addition"
                                    value={data.address_addition}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, address_addition: e.target.value };
                                        });
                                    }}
                                />
                                <Spacer/>

                                <Row gutter={12}>
                                    <Col span={16}>
                                        <h4>{t("street")}</h4>
                                        <Input
                                            data-cy="input_address_street"
                                            className={styles.shortInput}
                                            value={data.address_street}
                                            onChange={(e) => {
                                                setData((prevState) => {
                                                    return { ...prevState, address_street: e.target.value };
                                                });
                                            }}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <h4>{t("houseNumber")}</h4>
                                        <Input
                                            data-cy="input_address_house_number"
                                            className={styles.shortInput}
                                            value={data.address_house_number}
                                            onChange={(e) => {
                                                setData((prevState) => {
                                                    return { ...prevState, address_house_number: e.target.value };
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Spacer/>

                                <Row gutter={12}>
                                    <Col span={8}>
                                        <h4>{t("postCode")}</h4>
                                        <Form.Item name="company_post_code"
                                            rules={[{required: false, message: t('formErrors:invalidPostCode'), pattern: getPostCodeValidationPattern()}]}>
                                            <Input data-cy="input_address_post_code"
                                                className={styles.shortInput}
                                                value={data.address_post_code}
                                                onChange={(e) => {
                                                    setData((prevState => {
                                                        return {...prevState, address_post_code: e.target.value};
                                                    }));
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <h4>{t("city2")}</h4>
                                        <Input
                                            data-cy="input_address_city"
                                            className={styles.shortInput}
                                            value={data.address_city}
                                            onChange={(e) => {
                                                setData((prevState) => {
                                                    return { ...prevState, address_city: e.target.value };
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Spacer/>
                            </Col>
                            <Col span={12}>
                                <h4>{t("region")}</h4>
                                <AnchoredSelect
                                    data-cy="select_address_region"
                                    value={data.address_region}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, address_region: e };
                                        });
                                    }}
                                >
                                    {regionOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t("language")}</h4>
                                <AnchoredSelect
                                    data-cy="select_language"
                                    value={data.language}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, language: e };
                                        });
                                    }}
                                >
                                    {languageOptions()}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t("website")}</h4>
                                <Input
                                    data-cy="input_company_website"
                                    value={data.company_website}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, company_website: e.target.value };
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Spacer/>

                        <Divider />

                        <Row gutter={36}>
                            <Col span={12}>
                                <h4>{t("brokerName")}</h4>
                                <Input
                                    data-cy="input_broker_name"
                                    value={data.broker_name}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, broker_name: e.target.value };
                                        });
                                    }}
                                />
                            </Col>
                            {!isUpdateOperation ? (
                                <Col span={12}>
                                    <h4>{t("leadStatus")}</h4>
                                    <AnchoredSelect
                                        data-cy="select_lead_status"
                                        value={data.lead_status}
                                        onChange={(e) => {
                                            setData((prevState) => {
                                                return { ...prevState, lead_status: e };
                                            });
                                        }}
                                    >
                                        {leadStatusOptions(t)}
                                    </AnchoredSelect>
                                </Col>
                            ) : null}
                        </Row>
                        <Spacer/>

                        <Divider />

                        <Row gutter={36}>
                            <Col span={12}>
                                <h4>{t("leadType")}</h4>
                                <AnchoredSelect
                                    data-cy="select_financing_type"
                                    value={data.financing_type}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, financing_type: e };
                                        });
                                    }}
                                >
                                    {financingTypeOptions(t)}
                                </AnchoredSelect>
                                <Spacer/>

                                <h4>{t("currency")}</h4>
                                <AnchoredSelect
                                    data-cy="select_lead_currency"
                                    value={data.lead_currency}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, lead_currency: e };
                                        });
                                    }}
                                >
                                    {currencyOptions()}
                                </AnchoredSelect>
                                <Spacer/>
                            </Col>
                            <Col span={12}>
                                <h4>{t("expectedVolume")}</h4>
                                <Form.Item name="lead_expected_volume"
                                    rules={[{required: false, message: t('formErrors:mustBeAtLeast', {amount: 5000}), type: "number", min: 5000}]}>
                                    <LocalizedNumberInput
                                        data-cy="input_lead_expected_volume"
                                        value={data.lead_expected_volume || 0}
                                        precision={2}
                                        onChange={(e) => {
                                            setData((prevState) => {
                                                return { ...prevState, lead_expected_volume: e as number };
                                            });
                                        }}
                                    />
                                </Form.Item>
                                <Spacer/>

                                <h4>{t("loanDuration")}</h4>
                                <AnchoredSelect
                                    data-cy="select_lead_expected_duration"
                                    value={data.lead_expected_duration}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, lead_expected_duration: e };
                                        });
                                    }}
                                >
                                    {loanDurationOptions()}
                                </AnchoredSelect>
                                <Spacer/>
                            </Col>
                        </Row>
                        <Spacer/>

                        <Divider />

                        <Row gutter={36}>
                            {!isUpdateOperation ? (
                                <Col span={12}>
                                    <h4>{t("notes")}</h4>
                                    <Input.TextArea
                                        data-cy="input_lead_notes"
                                        value={note}
                                        onChange={(e) => {
                                            setNote(e.target.value);
                                        }}
                                    />
                                </Col>
                            ) : null}

                            <Col span={12}>
                                <h4>{t("sveaContact")}</h4>
                                <AnchoredSelect
                                    data-cy="select_bo_contact_name"
                                    value={data.bo_contact_name}
                                    onChange={(e) => {
                                        setData((prevState) => {
                                            return { ...prevState, bo_contact_name: e };
                                        });
                                    }}
                                >
                                    {contactNameOptions()}
                                </AnchoredSelect>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Modal>
        </React.Fragment>
    );
};
