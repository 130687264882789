
export const CountryCodes = [
    "de",
    "at",
    "ch",
];

export const LanguageCodes = [
    "de",
    "en",
    "fr",
    "it",
];

export const RegionList = {
    "de": [
        "Baden-Württemberg",
        "Bayern",
        "Berlin",
        "Brandenburg",
        "Bremen",
        "Hamburg",
        "Hessen",
        "Mecklenburg-Vorpommern",
        "Niedersachsen",
        "Nordrhein-Westfalen",
        "Rheinland-Pfalz",
        "Saarland",
        "Sachsen",
        "Sachsen-Anhalt",
        "Schleswig-Holstein",
        "Thüringen",
    ],
    "at": [
        "Vorarlberg",
        "Tirol",
        "Salzburg",
        "Kärnten",
        "Steiermark",
        "Oberösterreich",
        "Niederösterreich",
        "Burgenland",
        "Wien",
    ],
    "ch": [
        "Aargau (AG)",
        "Bern (BE)",
        "Fribourg / Freiburg (FR)",
        "Genève / Genf (GE), Glarus (GL)",
        "Graubünden (GR)",
        "Jura (JU)",
        "Luzern (LU)",
        "Neuchâtel / Neuenburg (NE)",
        "St.Gallen (SG)",
        "Schaffhausen (SH)",
        "Schwyz (SZ)",
        "Solothurn (SO)",
        "Thurgau (TG)",
        "Ticino / Tessin (TI)",
        "Uri (UR)",
        "Valais / Wallis (VS)",
        "Vaud / Waadt (VD)",
        "Zug (ZG)",
        "Zürich (ZH)",
        "Appenzell Ausserrhoden (AR)",
        "Appenzell Innerrhoden (AI)",
        "Basel-Stadt (BS)",
        "Basel-Landschaft (BL)",
        "Obwalden (OW)",
        "Nidwalden (NW)",
    ],
};

export const GetAllRegions = (): string[] => {
    const result = [];

    for (const country in RegionList) {
        const regions: string[] =  RegionList[country];
        regions.forEach(r => result.push(r));
    }

    return result;
};
