import styles from 'components/common/pages/application-flow/ApplicationFlow.module.scss';
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { BackButton } from 'components/common/presenters/back-button/BackButton';
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { IconButton, IconButtonType } from "components/common/presenters/icon-button/IconButton";
import { Spacer } from 'components/common/presenters/spacer/Spacer';
import { goTo } from "navigation/navigationHelpers";
import * as React from "react";
import { useTranslation } from 'react-i18next';
import { RouteStrings } from "Routes";
import { ApplicationFlowType } from "storage/LocalAppDataManager";
import { LocalApplicationFlowDataManager } from "storage/LocalApplicationFlowDataManager";
import { LocalFinancingApplicationDataManager } from "storage/LocalFinancingApplicationDataManager";
import { Currencies } from 'types/types';

export const ApplicationStepTwoPage = () => {
    const { t } = useTranslation();

    const setApplicationTypeToFactoring = () => {
        const localFinancingApplicationDataManager = new LocalFinancingApplicationDataManager();

        const currentApplication = localFinancingApplicationDataManager.getLastCreatedApplication();

        if (currentApplication) {
            currentApplication.type = ApplicationFlowType.Factoring_Onboarding;

            localFinancingApplicationDataManager.updateLastCreatedApplication(currentApplication);
        }
    };

    const persistData = (type: ApplicationFlowType) => {
        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();

        appl.type = type;

        if (type === ApplicationFlowType.Factoring_Onboarding) {
            appl.amount = 0;
            appl.duration = 0;
            appl.currency = Currencies.EUR;
        }

        applDataManager.update(appl);
    };
	
    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus/>
            <CustomPageHeader />
            <div className={styles.container}>
                <BackButton route={RouteStrings.ApplicationFlowStepOne} />
                
                <div className={styles.innerContainer}>
                    <h2 className={styles.heading}>{ t("headings:processStepTwo")}</h2>

                    <Spacer hx={3}/>
                    
                    <div className={styles.buttonIconsContainer}>
                        <IconButton className={styles.iconButton} dataCy={"button_loan"} icon={IconButtonType.Loan} onClick={() => {
                            persistData(ApplicationFlowType.Loan);
                            goTo(RouteStrings.ApplicationFlowStepLoan);
                        }}>
                            <h4>{ t("loan") }</h4>
                        </IconButton>

                        <IconButton className={styles.iconButton} dataCy={"button_factoring"} icon={IconButtonType.Factoring} onClick={() => {
                            setApplicationTypeToFactoring();
                            persistData(ApplicationFlowType.Factoring_Onboarding);
                            goTo(RouteStrings.ApplicationFlowStepCompanyInfo);
                        }}>
                            <h4>{ t("factoring") }</h4>
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
