import * as React from "react";
import styles from "./ClientContractsTab.module.scss";
import {useEffect, useState} from "react";
import {GetClientResponse, PutContractRequest} from "../../../../../api/types";
import {
    Button,
    Checkbox, Col,
    DatePicker,
    Form,
    FormInstance,
    Input,
    InputNumber, message,
    Radio, Row,
    Space,
    Typography
} from "antd";
import {useTranslation} from "react-i18next";
import {Spacer} from "../../../../common/presenters/spacer/Spacer";
import {LocalizedNumberInput} from "../../../../common/presenters/localized-number-input/LocalizedNumberInput";
import {DATE_FORMAT} from "../../../../../api/apiConfig";
import {Currencies, RepaymentType} from "../../../../../types/types";
import {isIbanValid} from "validators/iban-validators";
import moment, {Moment} from "moment";
import {adminCalculateInstallmentRate} from "../../../../../api/contractService";

export interface LoanContractInputsProps {
    client: GetClientResponse
    form: FormInstance
}

export const LoanContractInputs = (props: LoanContractInputsProps) => {
    const {t} = useTranslation();

    const [selectedCurrency, setSelectedCurrency] = useState(Currencies.EUR);
    const [repaymentType, setRepaymentType] = useState(RepaymentType.Monthly);

    const [enableFirstRateAmount, setEnableFirstRateAmount] = useState(false);
    const [enableFinalRateAmount, setEnableFinalRateAmount] = useState(false);

    const [withGuarantor, setWithGuarantor] = useState(true);

    useEffect(() => {
        setSelectedCurrency(props.form.getFieldValue("currency_type"));
        setRepaymentType(props.form.getFieldValue("repayment_type"));
    });

    const handleCalculateInstallmentRate = async () => {
        try {
            await props.form.validateFields();

            const values = props.form.getFieldsValue();
            const req: PutContractRequest = {
                is_standard_general_assignment: values.is_standard_general_assignment,
                is_standard_negative_declaration: values.is_standard_negative_declaration,
                is_global_session: values.is_global_session,
                expected_volume: values.expected_volume,
                factoring_fee_30: values.factoring_fee_30,
                factoring_fee_45: values.factoring_fee_45,
                factoring_fee_60: values.factoring_fee_60,
                factoring_fee_90: values.factoring_fee_90,
                factoring_fee_120: values.factoring_fee_120,
                min_factoring_fee: values.min_factoring_fee,
                admin_fee: values.admin_fee,
                debitor_limit_fee_amount: values.debitor_limit_fee_amount,
                max_invoice_payout: values.max_invoice_payout,
                max_advance_payout: values.max_advance_payout,
                loan_amount: values.loan_amount,
                loan_amount_words: values.loan_amount_words,
                loan_usage: values.loan_usage,
                interest_rate: values.interest_rate,
                min_interest_rate: values.min_interest_rate,
                first_rate_amount: values.first_rate_amount,
                minimum_repayment_amount: values.minimum_repayment_amount,
                early_repayment_months: values.early_repayment_months,
                repayment_fee: values.repayment_fee,
                late_payment_fee: values.late_payment_fee,
                loan_duration: values.loan_duration,
                first_rate_days: values.first_rate_days,
                repayment_type: values.repayment_type,
                repayment_day: values.repayment_day,
                final_repayment: values.final_repayment,
                final_rate_date: values.final_rate_date ? moment(values.final_rate_date).format(DATE_FORMAT.API) : undefined,
                final_rate_days: values.final_rate_days,
                single_repayment_date: values.single_repayment_date ? moment(values.single_repayment_date).format(DATE_FORMAT.API) : undefined,
                repayment_bank_name: values.repayment_bank_name,
                repayment_bank_iban: values.repayment_bank_iban,
                repayment_bank_bic: values.repayment_bank_bic,
                liability_limit: values.liability_limit,
                currency_type: values.currency_type,
                admin_fee_per_rate: values.admin_fee_per_rate,
                first_rate_date: values.first_rate_date ? moment(values.first_rate_date).format(DATE_FORMAT.API) : undefined,
                paid_out_date: values.paid_out_date ? moment(values.paid_out_date).format(DATE_FORMAT.API) : undefined,
            };

            const installmentRate: string = (await adminCalculateInstallmentRate(props.client.client_id, req)).data;
            props.form.setFieldsValue({
                installment_rate: Number.parseFloat(installmentRate),
            });
        } catch (e) {
            console.error(e);
            message.error(t('messages:errorEncountered'), 2);
        }
    };

    return (
        <>
            <Form.Item name="currency_type">
                <Radio.Group data-cy={"radio_currency_type"}
                    onChange={e => {
                        const newValue = e.target.value;

                        setSelectedCurrency(newValue);
                        props.form.setFieldsValue({
                            currency_type: newValue,
                        });
                    }}
                >
                    <Radio value={Currencies.CHF}>{Currencies.CHF.toString()}</Radio>
                    <Radio value={Currencies.USD}>{Currencies.USD.toString()}</Radio>
                    <Radio value={Currencies.EUR}>{Currencies.EUR.toString()}</Radio>
                </Radio.Group>
            </Form.Item>
            <Spacer/>

            <Typography.Text strong>{t('loanContract')}</Typography.Text>
            <Spacer/>

            <Space size={32} wrap>
                <Form.Item name={"loan_amount"} label={t("loanAmount2")} rules={[{required: true, message: t('formErrors:required')}]}>
                    <LocalizedNumberInput data-cy="input_loan_amount"
                        precision={2}
                        suffix={selectedCurrency?.toString()}
                    />
                </Form.Item>

                <Form.Item name={"loan_amount_words"} label={t("loanAmountWords")}>
                    <Input data-cy="input_loan_amount_words"/>
                </Form.Item>
            </Space>

            <Form.Item name={"loan_usage"} label={t("loanUsage")}>
                <Input.TextArea data-cy="input_loan_usage" autoSize={{minRows: 4}}/>
            </Form.Item>

            <Space size={24} wrap>
                <Form.Item name={"paid_out_date"} label={t("paidOutDate")}>
                    <DatePicker data-cy="picker_paid_out_date" format={DATE_FORMAT.DE}/>
                </Form.Item>
                <Form.Item name={"first_rate_date"} label={t("firstRateDate")}>
                    <DatePicker data-cy="picker_first_rate_date"
                        format={DATE_FORMAT.DE}
                        onChange={(d: Moment) => {
                            props.form.setFieldsValue({
                                first_rate_date: d,
                                first_rate_days: d?.date(),
                            });
                        }}
                    />
                </Form.Item>
            </Space>
            <Spacer/>

            <Space size={24} wrap>
                <Form.Item name={"loan_duration"} label={t("loanDuration")} rules={[{required: true, message: t('formErrors:required')}]}>
                    <LocalizedNumberInput data-cy="input_loan_duration"
                        precision={0}
                        suffix={"Mo."}
                    />
                </Form.Item>
                <Form.Item name={"interest_rate"} label={t("interestRate2")}>
                    <LocalizedNumberInput data-cy="input_interest_rate"
                        precision={2}
                        suffix={"%"}
                    />
                </Form.Item>
                <Form.Item name={"repayment_day"} label={t("repaymentDay")}>
                    <InputNumber data-cy="input_repayment_day"
                        min={1}
                        max={31}
                        disabled={props.client.address_country.toLowerCase() !== "de"}
                    />
                </Form.Item>
            </Space>
            <Spacer/>

            <Space size={24} wrap>
                <Form.Item name={"admin_fee_per_rate"} label={t("adminFeePerRate")}>
                    <LocalizedNumberInput data-cy="admin_fee_per_rate"
                        precision={2}
                        suffix={selectedCurrency?.toString()}
                    />
                </Form.Item>
            </Space>
            <Spacer/>

            <Space size={24} wrap>
                <Form.Item name={"installment_rate"} label={t("installmentRate")}>
                    <LocalizedNumberInput data-cy="input_installment_rate"
                        disabled
                        precision={2}
                        suffix={selectedCurrency?.toString()}
                    />
                </Form.Item>

                <Button type={"primary"}
                    onClick={handleCalculateInstallmentRate}
                >
                    {t('buttons:calculate')}
                </Button>
            </Space>
            <Spacer/>

            <Checkbox data-cy={"check_enable_first_rate_amount"}
                onChange={e => {
                    const isChecked = e.target.checked;
                    setEnableFirstRateAmount(isChecked);

                    if (!isChecked) {
                        props.form.setFieldsValue({
                            first_rate_amount: undefined,
                        });
                    }
                }}
            >{t('enableFirstRateAmount')}</Checkbox>
            <Spacer/>

            <Space size={24} wrap>
                <Form.Item name={"first_rate_amount"} label={t("firstRateAmount")}>
                    <LocalizedNumberInput data-cy="input_first_rate_amount"
                        disabled={!enableFirstRateAmount}
                        precision={2}
                        suffix={selectedCurrency?.toString()}
                    />
                </Form.Item>

                <Form.Item name={"first_rate_days"} label={t("firstRateDays")}>
                    <LocalizedNumberInput data-cy="input_first_rate_days"
                        disabled
                        precision={0}
                        suffix={t("days")}
                    />
                </Form.Item>
            </Space>
            <Spacer/>

            {
                props.client.address_country.toLowerCase() === "de" ?
                    <>
                        <Checkbox data-cy={"check_enable_final_rate_amount"}
                            onChange={e => {
                                const isChecked = e.target.checked;
                                setEnableFinalRateAmount(isChecked);

                                if (!isChecked) {
                                    props.form.setFieldsValue({
                                        final_repayment: undefined,
                                        final_rate_date: undefined,
                                        final_rate_days: undefined,
                                    });
                                }
                            }}
                        >{t('enableFinalRateAmount')}</Checkbox>
                        <Spacer/>

                        <Space size={24} wrap>
                            <Form.Item name={"final_repayment"} label={t("finalRepayment")}>
                                <LocalizedNumberInput data-cy="input_final_repayment"
                                    disabled={!enableFinalRateAmount}
                                    precision={2}
                                    suffix={selectedCurrency?.toString()}
                                />
                            </Form.Item>

                            <Form.Item name={"final_rate_date"} label={t("finalRateDate")}>
                                <DatePicker data-cy="picker_final_rate_date"
                                    format={DATE_FORMAT.DE}
                                    disabled={!enableFinalRateAmount}
                                    onChange={(d: Moment) => {
                                        props.form.setFieldsValue({
                                            final_rate_days: d?.date(),
                                            final_rate_date: d,
                                        });
                                    }}
                                />
                            </Form.Item>

                            <Form.Item name={"final_rate_days"} label={t("finalRateDays")}>
                                <LocalizedNumberInput data-cy="input_final_rate_days"
                                    disabled
                                    precision={0}
                                    suffix={t('days')}
                                />
                            </Form.Item>
                        </Space>
                    </> : null
            }

            <Space size={24} wrap>
                <Form.Item name={"minimum_repayment_amount"} label={t("minimumRepaymentAmount")}>
                    <LocalizedNumberInput data-cy="input_minimum_repayment_amount"
                        precision={2}
                        suffix={selectedCurrency?.toString()}
                    />
                </Form.Item>
                <Form.Item name={"early_repayment_months"} label={t("earlyRepaymentMonths")}>
                    <LocalizedNumberInput data-cy="input_early_repayment_months"
                        precision={0}
                        suffix={"Mo."}
                    />
                </Form.Item>
                <Form.Item name={"repayment_fee"} label={t("repaymentFee")}>
                    <LocalizedNumberInput data-cy="input_repayment_fee"
                        precision={2}
                        suffix={"%"}
                    />
                </Form.Item>
            </Space>

            {
                props.client.address_country.toLowerCase() === "de" ?
                    <>
                        <Form.Item name={"min_interest_rate"} label={t("minInterestRate")}>
                            <LocalizedNumberInput data-cy="input_min_interest_rate"
                                precision={2}
                                suffix={"%"}
                            />
                        </Form.Item>
                        <Form.Item name={"repayment_type"} label={t("repaymentType")}>
                            <Radio.Group
                                onChange={e => {
                                    const newVal = e.target.value;
                                    setRepaymentType(newVal);
                                    props.form.setFieldsValue({
                                        repayment_type: newVal,
                                    });

                                    if (newVal !== RepaymentType.Once) {
                                        props.form.setFieldsValue({
                                            single_repayment_date: undefined,
                                        });
                                    }
                                }}
                            >
                                <Radio data-cy="radio_repayment_monthly" value={RepaymentType.Monthly}>{t(`repaymentTypes:${RepaymentType.Monthly}`)}</Radio>
                                <Radio data-cy="radio_repayment_quarterly" value={RepaymentType.Quarterly}>{t(`repaymentTypes:${RepaymentType.Quarterly}`)}</Radio>
                                <Radio data-cy="radio_repayment_yearly" value={RepaymentType.Yearly}>{t(`repaymentTypes:${RepaymentType.Yearly}`)}</Radio>
                                <Radio data-cy="radio_repayment_once" value={RepaymentType.Once}>{t(`repaymentTypes:${RepaymentType.Once}`)}</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item name={"single_repayment_date"} label={t("singleRepaymentDate")}>
                            <DatePicker data-cy="picker_single_repayment_date"
                                format={DATE_FORMAT.DE}
                                disabled={repaymentType !== RepaymentType.Once}
                            />
                        </Form.Item>

                        <Form.Item name={"repayment_bank_name"} label={t("repaymentBankName")}>
                            <Input data-cy={"input_repayment_bank_name"}/>
                        </Form.Item>

                        <Form.Item name={"repayment_bank_iban"} label={t("repaymentBankIban")}
                            rules={[({getFieldValue}) => ({
                                validator(_, value) {
                                    if (value && !isIbanValid(value)) {
                                        return Promise.reject(t('errors:invalidIBAN'));
                                    }
                                    return Promise.resolve();
                                },
                            })]}
                        >
                            <Input data-cy={"input_repayment_bank_iban"}/>
                        </Form.Item>

                        <Form.Item name={"repayment_bank_bic"} label={t("repaymentBankBic")}>
                            <Input data-cy={"input_repayment_bank_bic"}/>
                        </Form.Item>
                    </> : null
            }

            {
                props.client.address_country.toLowerCase() === "ch" ?
                    <>
                        <Form.Item name={"late_payment_fee"} label={t("latePaymentFee")}>
                            <LocalizedNumberInput data-cy="input_late_payment_fee"
                                precision={2}
                                suffix={"%"}
                            />
                        </Form.Item>

                        <Spacer/>
                        <Typography.Text strong>{t('personalGuarantee')}</Typography.Text>
                        <Spacer/>

                        <Checkbox data-cy="check_with_guarantor"
                            checked={withGuarantor}
                            onChange={(e) => setWithGuarantor(e.target.checked)}
                        >
                            {t("withPersonalGuarantee")}
                        </Checkbox>
                        <Spacer/>

                        {
                            withGuarantor ?
                                <Row>
                                    <Col>
                                        <Form.Item name={"guarantor_name"} label={t("guarantorName")}>
                                            <Input data-cy="input_guarantor_name"/>
                                        </Form.Item>

                                        <Form.Item name={"guarantor_date_of_birth"} label={t("guarantorDateOfBirth")}>
                                            <DatePicker data-cy="picker_guarantor_date_of_birth" format={DATE_FORMAT.DE}/>
                                        </Form.Item>

                                        <Form.Item name={"guarantor_postcode_city"} label={t("guarantorPostcodeCity")}>
                                            <Input data-cy="input_guarantor_postcode_city"/>
                                        </Form.Item>

                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <Form.Item name={"guarantee_currency"} label={t("guaranteeCurrency")}>
                                                    <Input data-cy="input_guarantee_currency" className={styles.shortInput}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={16}>
                                                <Form.Item name={"guarantee_amount"} label={t("guaranteeAmount")}>
                                                    <LocalizedNumberInput data-cy="input_guarantee_amount"
                                                        precision={2}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Form.Item name={"guarantee_amount_words"} label={t("guaranteeAmountWords")}>
                                            <Input data-cy="input_guarantee_amount_words"/>
                                        </Form.Item>

                                        <Form.Item name={"guarantee_end"} label={t("guaranteeEnd")}>
                                            <DatePicker data-cy="picker_guarantee_end" format={DATE_FORMAT.DE}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                : null
                        }
                    </>

                    : null
            }
        </>
    );
};
