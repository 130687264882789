import * as React from "react";
import styles from "./TableSearchInputs.module.scss";
import {useState} from "react";
import {Button, Input, Space} from "antd";
import {RedoOutlined, SearchOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export interface TextSearchProps {
    confirm: () => void
    onSearch: (searchText: string) => void
}

export const TextSearch = (props: TextSearchProps) => {
    const {t} = useTranslation();
    const [data, setData] = useState("");

    const onFinishSearch = () => {
        props.onSearch(data);
        props.confirm();
    };

    const onReset = () => {
        setData("");
        props.onSearch("");
        props.confirm();
    };

    return (
        <Space className={styles.container}
            direction={"vertical"}
        >
            <Input onChange={(e) => setData(e.target.value)}
                onPressEnter={onFinishSearch}
            />

            <Space>
                <Button type={"primary"}
                    icon={<SearchOutlined />}
                    onClick={onFinishSearch}
                >
                    {t('search')}
                </Button>

                <Button type={"primary"}
                    icon={<RedoOutlined />}
                    onClick={onReset}
                >
                    {t('buttons:reset')}
                </Button>
            </Space>
        </Space>
    );
};
